<template>
<!-- <h2>RESULTS</h2> -->
<fieldset>
  <legend>Résultats du bâtiment</legend>
  <ResultTemplate resultType="bati" />
</fieldset>

<fieldset>
  <legend>Résultats des pièces</legend>

  <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error sequi, nesciunt minima dolore rem voluptatem porro omnis at perspiciatis possimus totam quis inventore quo! Dolorem a nostrum corporis! Quas, eius.</p> -->


  <div class="card my-5">
    <div class="card-body">
      <div>
        <h5>Détail par pièce</h5>
        <ul class="nav nav-tabs nav-fill">
          <li
            class="nav-item"
            :class="{ error: !room.isRoomValid }"
            v-for="(room, roomIndex) in rooms" :key="`roomResultNav${roomIndex}`"
          >
            <a
              class="nav-link"
              :class="{ active: currentRoomResultIndex === roomIndex }"
              aria-current="page"
              href="#"
              @click.prevent="currentRoomResultIndex = roomIndex"
              ><component :is="getRoomIcon(room.roomType)"></component> Pièce {{ roomIndex + 1 }}</a>
          </li>
        </ul>
        <template v-for="(room, roomIndex) in rooms" :key="`roomResultContent${roomIndex}`">
          <div v-if="roomIndex === currentRoomResultIndex" class="content4Tabs">
            <template v-if="room.isRoomValid">
              <ResultTemplate resultType="room" :roomId="room.roomId" />
            </template>
            <template v-else>
              <div><i class="bi bi-patch-exclamation-fill text-danger" ></i> Erreurs détectées</div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</fieldset>

</template>


<script setup>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { getRoomIcon } from '@/appConfig/index.js';
// import ResultTEST from './ResultTEST.vue';
import ResultTemplate from './ResultTemplate.vue';
// import ChartBarComparison from "./charts/ChartBarComparison.vue";

import {discomforts} from "../appConfig/index.js";

import {CalculMaster} from '../class/calculMaster.class.js';

const currentRoomResultIndex = ref(0);

const store = useStore();
const simuData = computed(() => JSON.parse(JSON.stringify(store.getters.simuData)));
// const isDebug = computed(() => store.getters.isDebug);

const rooms = computed(() => {
  return store.getters.rooms;
})

//--
// CALCUL POUR BATI
store.dispatch('resetResult');

const runCalcul = (resultType, roomId = null) => {
  const calculMaster = new CalculMaster(simuData.value, {resultType: resultType, roomId: roomId});
  // const debug = calculMaster.getDebug();
  const {discomfortsResume } = calculMaster.setDiscomfortsAnaliz();
  store.dispatch('saveResult', {typeResult: resultType, dataResult: discomfortsResume, roomId: roomId});

  return discomfortsResume;
}


runCalcul('bati', null);
if (rooms.value) {
  rooms.value.forEach((room) => {
    runCalcul('room', room.roomId);
  })
}

//--

const simuResults = computed(() => store.getters.simuResults);

const data4Chart = {
  series: [],
  categories: [],
  // colors: ["#800080", "#008FFB", "#FF4560"],
  // title: "Comparaison d'inconfort par rapport au bâtiment"
  title: ''
}





const dataToCompare = [/*'environment', */'buildingInertia', 'buildingWindows'];

//-- BATI
const s = {};
s.name = 'Bâtiment';
s.data = [];

dataToCompare.forEach((varname) => {
  const batiValue = simuResults.value.bati[varname]?.result;
  data4Chart.categories.push(discomforts[varname].label);
  s.data.push(batiValue);
});
data4Chart.series.push(s);

//--ROOMS
// const roomsResults = simuResults.value.rooms;
const roomsResults = Object.values(simuResults.value.rooms);

// for(const [roomResult, roomId] of Object.values(roomsResults)) {
roomsResults.forEach( (roomResult, roomIdX) => {
  const s = {};
  s.name = 'Pièce ' + (roomIdX + 1);
  s.data = [];
  // console.log('ROOMS', {roomIdX, roomResult});
  dataToCompare.forEach((varname) => {
    s.data.push(roomResult[varname]?.result);
  });
  data4Chart.series.push(s);
});







</script>