<template>
  <div>
    <p>
      Des écarts de température, pouvant aller jusqu’à 10°C, peuvent être
      constatés entre un espace urbain, péri-urbain et rural. En cause, l’effet
      dit d’«&nbsp;îlot de chaleur urbain&nbsp;», particulièrement présent en
      ville lors d’épisodes de forte chaleur.
    </p>

    <p>
      Ainsi au cœur des villes et en période estivale, certains épisodes de
      chaleur peuvent avoir un effet néfaste sur le confort et la santé des
      habitants.
    </p>

    <p>
      La notion d’inconfort d’été ne sera donc pas identique selon si le
      bâtiment est situé en zone très végétalisée ou très urbanisée.
    </p>

    <figure>
      <!-- <figcaption>Ilots de chaleur</figcaption> -->
      <img
        src="@/assets/img/helps/ilot_de_chaleur_urbain.png"
        alt="Ilots de chaleur"
        style="max-width: 550px"

      />
    </figure>

    <div>
      Sources :
      <ul>
        <li>
          <a
            href="https://www.cerema.fr/fr/actualites/ilots-chaleur-agir-territoires-adapter-villes-au-changement"
            target="_blank"
          >
            Ilots de chaleur : Agir dans les territoires pour adapter les villes
            au changement climatique
          </a>
          - CEREMA
        </li>
      </ul>
    </div>

    <div>
      Pour aller plus loin :

      <ul>
        <li>
          <a
            href="https://www.cercle-promodul.inef4.org/publication/rechauffement-climatique-canicule-bulles-chaleur-urbaines/"
            target="_blank"
          >
            Réchauffement climatique, canicule : comment prévenir les
            «&nbsp;bulles&nbsp;» de chaleur urbaines ?
          </a>
          - Cercle Promodul / INEF4
        </li>
      </ul>
    </div>
  </div>
</template>
