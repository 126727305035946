export * from './config.questions.js';
export * from './config.forms.js';
export * from './config.steps.js';
export * from './config.discomforts.js';
export * from './config.recoTvx.js';
export * from './config.alerts.js';

import {questions} from './config.questions.js';

export const getRoomIcon = (roomType) => {
  const qst = questions.find(qst => qst.varname === 'roomType');

  const choice = qst.choices.find(item => item.value === roomType);
  return choice?.icon ?? null;
}