/**
 * - Un step de type 'form' doit avoir obligatoirement un sous subStep
 * - Le bouton "suivant" apparait uniquement dans les steps de type 'form'
 */

export const steps = [
  {
    id: 1,
    title: 'Configuration du bâtiment',
    description: null,
    type: 'form',
    icon: 'IconBuildingGear',
    validationButton: {
      text: null
    },
    subSteps: [
      {
        id: 1,
        title: 'Type de bâtiment',
        formName: 'buildingConfig',
        // componentName: 'FormBase2'
        componentName: 'FormBuildingType2'
      }
    ]
  },
  {
    id: 2,
    title: 'Situation géographique',
    description: null,
    type: 'form',
    icon: 'IconMapPoint',
    subSteps: [
      {
        id: 1,
        title: 'Localisation',
        formName: 'location',
        componentName: 'FormGeo2'
      },
      {
        id: 2,
        title: 'Environnement',
        formName: 'environment',
        componentName: 'FormEnvironment'
      },

    ]
  },
  {
    id: 3,
    title: 'Description du bâtiment',
    description: null,
    // topComponent: 'IntroStepBuildingDescription',
    type: 'form',
    icon: 'IconHouseFind',
    subSteps: [
      {
        id: 1,
        title: 'Murs',
        formName: 'buildingWalls',
        // componentName: 'FormBase2'
        componentName: 'FormBuildingWalls'
      },
      {
        id: 2,
        title: 'Toiture',
        formName: 'buildingRoof',
        // componentName: 'FormBase2',
        componentName: 'FormBuildingRoof',
      },
      {
        id: 3,
        title: 'Plancher',
        formName: 'buildingFloor',
        componentName: 'FormBase2'
      },
      {
        id: 4,
        title: 'Parois vitrées',
        formName: 'buildingWindows',
        componentName: 'FormBuildingWindows'
      },
      {
        id: 5,
        title: 'Equipements',
        formName: 'buildingGearsAndBehaviours',
        description: null,
        componentName: 'FormBuildingGears'
      },
      // {
      //   id: 6,
      //   title: 'Climatisation',
      //   formName: 'buildingClim',
      //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed fringilla eros. Ut pharetra ex a aliquet rutrum. Phasellus a nibh neque. Mauris egestas augue vel sem tincidunt euismod. Aenean fringilla, nulla a eleifend condimentum, ex nisi bibendum ex, at dictum neque odio sed elit.',
      //   componentName: 'FormBuildingClim'
      // },

    ]
  },
  {
    id: 4,
    title: 'Résultats',
    description: null,
    topComponent: 'IntroStepResults',
    icon: 'IconGraphBar',
    type: 'result',
    componentName: 'ResultsBati'
  }
  // {
  //   id: 4,
  //   title: 'Résultats du bâtiment',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed fringilla eros. Ut pharetra ex a aliquet rutrum. Phasellus a nibh neque. Mauris egestas augue vel sem tincidunt euismod. Aenean fringilla, nulla a eleifend condimentum, ex nisi bibendum ex, at dictum neque odio sed elit.',
  //   icon: 'IconGraphBar',
  //   type: 'form',
  //   subSteps: [
  //     {
  //       id: 1,
  //       title: '',
  //       questions: [
  //         // 'qstTest'
  //       ],
  //       componentName: 'ResultsBati'
  //     }
  //   ]
  // },
  // {
  //   id: 5,
  //   title: 'Pièces (Optionnel)',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed fringilla eros. Ut pharetra ex a aliquet rutrum. Phasellus a nibh neque. Mauris egestas augue vel sem tincidunt euismod. Aenean fringilla, nulla a eleifend condimentum, ex nisi bibendum ex, at dictum neque odio sed elit.',
  //   topComponent: 'RoomsManager',
  //   type: 'form',
  //   icon: 'IconFloorPlan',
  //   // storeLocation: 'simuData.rooms',
  //   // subSteps: [
  //   //   {
  //   //     id: 1,
  //   //     title: 'Configuration de la pièce',
  //   //     questions: ['roomShape'],
  //   //     componentName: 'FormBase2'
  //   //   },
  //   //   {
  //   //     id: 2,
  //   //     title: 'Détails de la pièce',
  //   //     questions: [],
  //   //     componentName: 'FormRoom'
  //   //   }
  //   // ]
  // },
  // {
  //   id: 6,
  //   title: 'Résultats',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed fringilla eros. Ut pharetra ex a aliquet rutrum. Phasellus a nibh neque. Mauris egestas augue vel sem tincidunt euismod. Aenean fringilla, nulla a eleifend condimentum, ex nisi bibendum ex, at dictum neque odio sed elit.',
  //   icon: 'IconGraphBar',
  //   type: 'result',
  //   componentName: 'ResultsMain'
  // }
]
