<template>
<svg version="1.1" id="Yes" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="Yes_1_">
	<path id="house" opacity="0.8" fill="none" stroke="currentColor" stroke-width="18" d="M382.167,164.845v98.504H204.876v-98.504
		l-39.4-0.178l128.04-128.04l128.04,128.04L382.167,164.845z"/>
	<path id="symbole" class="icon-symbole symbole-good" fill="#009640" d="M321.674,187.288c1.736-1.045,3.986-0.53,5.091,1.162l3.724,5.694
		c1.015,1.531,0.764,3.569-0.574,4.812l-0.015,0.022l-0.06,0.051l-0.206,0.191l-0.853,0.81c-4.694,4.554-9.241,9.248-13.642,14.082
		c-8.27,9.094-18.099,21.012-24.706,32.563c-1.854,3.223-6.357,3.915-9.035,1.134l-24.426-25.383
		c-1.442-1.5-1.398-3.885,0.103-5.326c0.03-0.03,0.059-0.052,0.088-0.074l7.386-6.666c1.339-1.207,3.341-1.295,4.782-0.213
		l12.464,9.345C301.265,200.295,312.3,192.923,321.674,187.288z"/>
	<path id="fan_1_" fill="#010202" d="M268.066,84.26v14.48h19.066v38.152c-3.941,2.609-6.355,7.389-6.355,12.529v7.24h25.422v-7.24
		c0-5.14-2.408-9.919-6.355-12.529V98.74h19.066V84.26H268.066 M255.355,149.421c-14.045,0-25.422,4.851-25.422,10.861
		s11.376,10.86,25.422,10.86s25.422-4.85,25.422-10.86S269.4,149.421,255.355,149.421 M331.621,149.421
		c-14.052,0-25.422,4.851-25.422,10.861s11.37,10.86,25.422,10.86s25.422-4.85,25.422-10.86S345.673,149.421,331.621,149.421
		 M280.777,163.902v7.24c0,5.205,2.415,9.984,6.355,12.529c3.941,2.602,8.77,2.602,12.711,0c3.947-2.545,6.355-7.382,6.355-12.529
		v-7.24H280.777z"/>
</g>
</svg>

</template>