<template>
<svg version="1.1" id="Calque_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="moto">
	<path id="gears_1_" fill="#010202" d="M202.376,157.639c0-9.185-3.261-17.033-9.744-23.525c-6.521-6.502-14.35-9.754-23.544-9.754
		c-9.175,0-17.023,3.252-23.525,9.754c-6.502,6.492-9.743,14.341-9.743,23.525s3.241,17.023,9.743,23.525s14.351,9.764,23.525,9.764
		c9.194,0,17.023-3.262,23.544-9.764C199.116,174.662,202.376,166.823,202.376,157.639z M302.203,224.195
		c0-4.512-1.668-8.396-4.929-11.695c-3.299-3.299-7.204-4.947-11.716-4.947s-8.416,1.648-11.715,4.947
		c-3.261,3.299-4.929,7.184-4.929,11.695c0,4.588,1.63,8.512,4.891,11.773c3.223,3.24,7.166,4.852,11.753,4.852
		c4.588,0,8.531-1.611,11.754-4.852C300.572,232.707,302.203,228.783,302.203,224.195z M302.203,91.081
		c0-4.502-1.668-8.407-4.929-11.696c-3.299-3.289-7.204-4.938-11.716-4.938s-8.416,1.648-11.715,4.938
		c-3.261,3.289-4.929,7.194-4.929,11.696c0,4.598,1.63,8.521,4.891,11.763c3.223,3.251,7.166,4.882,11.753,4.882
		c4.588,0,8.531-1.631,11.754-4.882C300.572,99.603,302.203,95.679,302.203,91.081z M252.27,145.81v24.037
		c0,0.872-0.303,1.725-0.909,2.54c-0.606,0.834-1.289,1.289-2.086,1.365l-20.132,3.129c-0.947,3.031-2.351,6.313-4.17,9.875
		c2.957,4.172,6.862,9.137,11.715,14.938c0.606,0.967,0.91,1.82,0.91,2.617c0,1.023-0.304,1.857-0.91,2.465
		c-2.01,2.596-5.573,6.482-10.729,11.639s-8.55,7.734-10.198,7.734c-0.948,0-1.858-0.303-2.73-0.91l-14.938-11.715
		c-3.223,1.668-6.56,2.994-10.009,4.037c-0.967,9.365-1.972,16.076-2.996,20.152c-0.606,2.084-1.914,3.107-3.904,3.107h-24.189
		c-0.947,0-1.819-0.303-2.578-0.967c-0.796-0.645-1.213-1.402-1.308-2.273l-2.995-19.887c-2.938-0.871-6.199-2.199-9.744-4.018
		l-15.336,11.563c-0.606,0.607-1.479,0.91-2.616,0.91c-0.947,0-1.857-0.342-2.729-1.043c-12.474-11.525-18.71-18.463-18.71-20.795
		c0-0.797,0.303-1.611,0.91-2.465c0.872-1.213,2.635-3.525,5.326-6.9c2.692-3.373,4.739-6.027,6.104-7.924
		c-1.972-3.828-3.488-7.373-4.55-10.654l-19.753-3.127c-0.872-0.096-1.592-0.512-2.199-1.232c-0.606-0.739-0.909-1.592-0.909-2.54
		v-24.047c0-0.872,0.303-1.716,0.909-2.54c0.607-0.815,1.289-1.271,2.086-1.365l20.132-3.118c0.947-3.033,2.351-6.322,4.17-9.877
		c-2.957-4.161-6.862-9.146-11.715-14.947c-0.606-0.957-0.91-1.819-0.91-2.597c0-1.043,0.304-1.915,0.91-2.606
		c1.915-2.598,5.46-6.455,10.673-11.563c5.194-5.119,8.625-7.668,10.255-7.668c0.967,0,1.877,0.303,2.73,0.909l14.957,11.696
		c2.938-1.563,6.274-2.947,10.009-4.161c0.967-9.354,1.952-16.027,2.995-20.018c0.606-2.076,1.915-3.118,3.886-3.118h24.189
		c0.947,0,1.819,0.322,2.597,0.976c0.777,0.645,1.213,1.403,1.308,2.275l2.996,19.885c2.938,0.863,6.18,2.209,9.743,4.028
		l15.336-11.563c0.701-0.606,1.555-0.909,2.598-0.909c0.947,0,1.857,0.341,2.729,1.033c12.474,11.525,18.729,18.463,18.729,20.805
		c0,0.691-0.304,1.517-0.91,2.464c-1.062,1.384-2.882,3.726-5.46,7.023c-2.616,3.289-4.55,5.887-5.876,7.801
		c2.009,4.151,3.487,7.706,4.436,10.654l19.753,2.995c0.872,0.17,1.593,0.625,2.199,1.364
		C251.967,144.009,252.27,144.852,252.27,145.81z M335.491,215.095v18.199c0,1.385-6.483,2.73-19.374,4.037
		c-1.062,2.332-2.351,4.588-3.905,6.75c4.398,9.781,6.635,15.771,6.635,17.932c0,0.342-0.189,0.645-0.53,0.91
		c-10.578,6.162-15.962,9.232-16.113,9.232c-0.683,0-2.692-2.027-5.99-6.104c-3.299-4.076-5.536-7.033-6.749-8.854
		c-1.744,0.189-3.033,0.266-3.905,0.266s-2.161-0.076-3.904-0.266c-1.214,1.82-3.45,4.777-6.749,8.854s-5.308,6.104-5.99,6.104
		c-0.151,0-5.535-3.07-16.113-9.232c-0.341-0.266-0.531-0.568-0.531-0.91c0-2.16,2.237-8.15,6.636-17.932
		c-1.555-2.162-2.844-4.418-3.905-6.75c-12.891-1.307-19.374-2.652-19.374-4.037v-18.199c0-1.383,6.483-2.729,19.374-4.037
		c1.137-2.502,2.426-4.758,3.905-6.748c-4.398-9.801-6.636-15.771-6.636-17.934c0-0.359,0.19-0.664,0.531-0.91
		c0.341-0.189,1.857-1.061,4.55-2.615c2.691-1.555,5.231-3.033,7.658-4.418c2.427-1.383,3.754-2.066,3.905-2.066
		c0.683,0,2.691,2.01,5.99,6.029c3.299,4.037,5.535,6.957,6.749,8.777c1.743-0.172,3.032-0.246,3.904-0.246s2.161,0.074,3.905,0.246
		c4.398-6.162,8.417-11.014,11.943-14.559l0.796-0.248c0.341,0,5.725,3.033,16.113,9.1c0.341,0.246,0.53,0.551,0.53,0.91
		c0,2.162-2.236,8.133-6.635,17.934c1.479,1.99,2.768,4.246,3.905,6.748C329.007,212.367,335.491,213.712,335.491,215.095z
		 M335.491,81.982v18.198c0,1.394-6.483,2.729-19.374,4.028c-1.062,2.341-2.351,4.597-3.905,6.768
		c4.398,9.791,6.635,15.771,6.635,17.933c0,0.351-0.189,0.654-0.53,0.91c-10.578,6.151-15.962,9.232-16.113,9.232
		c-0.683,0-2.692-2.038-5.99-6.114c-3.299-4.066-5.536-7.014-6.749-8.834c-1.744,0.171-3.033,0.256-3.905,0.256
		s-2.161-0.085-3.904-0.256c-1.214,1.82-3.45,4.768-6.749,8.834c-3.299,4.076-5.308,6.114-5.99,6.114
		c-0.151,0-5.535-3.081-16.113-9.232c-0.341-0.256-0.531-0.56-0.531-0.91c0-2.161,2.237-8.142,6.636-17.933
		c-1.555-2.171-2.844-4.427-3.905-6.768c-12.891-1.299-19.374-2.635-19.374-4.028V81.982c0-1.384,6.483-2.73,19.374-4.028
		c1.137-2.512,2.426-4.768,3.905-6.759c-4.398-9.791-6.636-15.771-6.636-17.942c0-0.341,0.19-0.645,0.531-0.909
		c0.341-0.171,1.857-1.033,4.55-2.598c2.691-1.555,5.231-3.033,7.658-4.417s3.754-2.085,3.905-2.085c0.683,0,2.691,2.019,5.99,6.047
		s5.535,6.957,6.749,8.777c1.743-0.171,3.032-0.266,3.904-0.266s2.161,0.095,3.905,0.266c4.398-6.151,8.417-11.005,11.943-14.559
		l0.796-0.266c0.341,0,5.725,3.033,16.113,9.1c0.341,0.265,0.53,0.568,0.53,0.909c0,2.171-2.236,8.151-6.635,17.942
		c1.479,1.991,2.768,4.247,3.905,6.759C329.007,79.252,335.491,80.599,335.491,81.982z"/>
	<path id="handTap" fill="currentColor" d="M371.57,113.161c0-25.315,20.534-45.84,45.841-45.84c25.308,0,45.839,20.525,45.839,45.84
		c0,3.376-2.736,6.112-6.111,6.112c-3.373,0-6.111-2.736-6.111-6.112c0-18.567-15.041-33.616-33.617-33.616
		c-18.557,0-33.617,15.048-33.617,33.616c0,3.376-2.737,6.112-6.112,6.112C374.309,119.273,371.57,116.537,371.57,113.161z
		 M478.534,146.777c-3.568,0-7.068,0.883-10.221,2.594c-3.629-11.245-15.662-17.412-26.93-13.784
		c-0.859,0.287-1.736,0.629-2.58,1.026v-23.453c0-11.81-9.564-21.392-21.392-21.392c-11.81,0-21.393,9.582-21.393,21.392v61.122
		l-2.912-4.679c-5.937-10.22-19.036-13.681-29.255-7.735c-10.204,5.952-13.674,19.034-7.72,29.252l0,0l22.395,38.201
		c1.512,3.01,5.205,4.219,8.198,2.705c3.024-1.525,4.234-5.201,2.707-8.211c-0.097-0.223-0.224-0.432-0.367-0.639l-22.363-38.199
		c-2.626-4.33-1.227-9.965,3.12-12.574c4.33-2.61,9.963-1.225,12.574,3.119c0.063,0.098,0.126,0.191,0.16,0.287l0.126,0.174
		l14.261,22.922c1.783,2.881,5.541,3.756,8.421,1.971c1.798-1.111,2.896-3.084,2.88-5.201v-82.515c0-5.062,4.108-9.168,9.168-9.168
		c5.063,0,9.168,4.106,9.168,9.168v51.954c0,3.376,2.739,6.112,6.112,6.112c3.375,0,6.112-2.736,6.112-6.112v-9.168
		c0-5.063,4.107-9.169,9.168-9.169c5.063,0,9.168,4.105,9.168,9.169v15.28c0,3.375,2.738,6.113,6.111,6.113
		c3.377,0,6.113-2.738,6.113-6.113v-3.056c0-5.063,4.109-9.169,9.17-9.169s9.166,4.106,9.166,9.169v27.504
		c0,16.506-5.41,27.727-5.475,27.824c-1.496,3.021-0.287,6.684,2.736,8.195c0.861,0.43,1.785,0.652,2.738,0.652
		c2.324,0,4.426-1.305,5.477-3.373c0.287-0.559,6.748-13.756,6.748-33.299v-27.504C499.925,156.359,490.376,146.777,478.534,146.777
		L478.534,146.777z"/>
</g>
</svg>
</template>