export const discomforts = {
  buildingInertia: {
    label: "Inertie",
    coef: 3,
    questions: {
      inertiaLevel: { coef: 8, form: "__calculated" }
    }
  },

  geography: {
    label: "Zone géographique",
    coef: 3,
    questions: {
      zoneClimat: {coef: 5, form: "location" },
      isLittoral: { coef: 2, form: "location" },
      altitude: { coef: 3, form: "location" }
    }
  },
  environment: {
    label: "Environnement proche",
    coef: 1,
    questions: {
      externalSurroundings: {coef: 2, form: "environment" },
      wallsColor: { coef: 2, form: "buildingWalls" },
      roofColor: { coef: 4, form: "buildingRoof" },
      externalGreenSpace: { coef: 1, form: "environment" },
      externalGroundType: { coef: 1, form: "environment" },
    }
  },
  buildingInsulation: {
    label: "Isolation",
    coef: 2,
    questions: {
      insulationLevel: { coef: 10, form: "__calculated" }
    }
  },
  buildingWindows: {
    label: "Vitrages",
    coef: 5,
    questions: {
      windowsIsShadow: {coef: 2, form: "buildingWindows" },
      windowsGlassType: {coef: 1, form: "buildingWindows" },
      windowsProtectionType: {coef: 3, form: "buildingWindows" },
      windowsProtectionControl:{coef: 3, form: "buildingWindows"},
      windowsOrientations: {coef: 3, form: "buildingWindows" },
    }
  },
  usersBehaviour: {
    label: "Occupant",
    coef: 3,
    questions: {
      userBehaviorVentil: {coef: 4, form: "environment" },
      userBehaviorSolarProtection: {coef: 4, form: "buildingGearsAndBehaviours" },
      userBehaviorElecEquipments: {coef: 2, form: "buildingGearsAndBehaviours" }
    }
  },
  ventilation: {
    label: "Ventilation",
    coef: 5,
    questions: {
      canOpenWindowsNight: { coef: 5, form: "environment" },
      overventilationType: {coef: 2, form: "buildingGearsAndBehaviours" },
      windowsRoomCrossover: {coef: 4, form: "buildingWindows" },
    }
  },
};
