<template>
<div>
  <p>Les propriétés des matériaux constituant les surfaces aux alentours proches du bâtiment (terrasses, allées de jardin etc.) ont un impact direct sur les ressentis de l’occupant à l’intérieur, particulièrement en matière de confort d’été.</p>

  <p>En effet, si l’environnement du bâtiment est très artificialisé (donc peu végétalisé) :</p>
  <ul>
    <li>L’absorption et le stockage de la chaleur par les surfaces urbaines sera plus importante (bitume, béton, revêtements etc., qui contribuent à emmagasiner la chaleur, puis à la restituer en déphasage dans le temps, limitant la baisse de température nocturne – on parle d’inertie thermique) ;</li>
    <li>Les phénomènes d’évapostranspiration et d’évaporation seront faibles (phénomènes qui sont favorisés par la présence de végétation, de sol naturel et d’eau et qui contribuent à favoriser naturellement le rafraîchissement urbain).</li>
  </ul>

  <p>Le type de surface artificielle extérieure est donc importante à considérer.</p>


  <p>Pour aller plus loin : </p>
  <ul>
    <li><a href="https://lab.cercle-promodul.inef4.org/knowledge/post/inertie-thermique" target="_blank">
      Inertie thermique
    </a> - Cercle Promodul / INEF4</li>
    <li><a href="https://lab.cercle-promodul.inef4.org/knowledge/post/ilot-de-chaleur-urbain" target="_blank">
      Les îlots de chaleurs
    </a> - Cercle Promodul / INEF4</li>
  </ul>
</div>
</template>
