<template>
<svg version="1.1" id="root" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="shadow">
	<g id="tree">
		<path id="tree_x5F_color" fill="currentColor" opacity="0.25" d="M292.015,123.082c9.978,8.831,16.067,20.417,16.067,33.14
			c0,23.3-20.288,42.816-47.708,48.367v-8.362c0.058-1.567,0.459-3.045,1.147-4.2l14.576-21.569c0.7-1.152,1.113-2.631,1.159-4.218
			v-10.648c0-1.373-0.619-1.927-1.364-1.229l-14.141,13.106c-0.78,0.681-1.376,0.123-1.376-1.248v-44.994
			c0-1.369-0.676-2.147-1.525-1.714l-20.837,10.921c-0.872,0.542-1.468,1.82-1.502,3.298v32.489c0,1.37-0.631,1.965-1.411,1.334
			l-16.089-13.297c-0.837-0.612-1.835-0.381-2.523,0.613l-2.717,4.27c-0.585,0.901-0.597,2.362-0.035,3.263
			c0.035,0.052,0.07,0.109,0.103,0.144l21.48,29.513c0.654,0.902,1.193,2.777,1.193,4.166v9.551
			c-31.377-3.065-55.689-24.038-55.689-49.556c0-12.723,6.102-24.309,16.078-33.14c-0.08-1.443-0.171-2.9-0.171-4.343
			c0-34.527,21.376-62.493,47.73-62.493s47.719,27.966,47.719,62.493C292.175,120.182,292.083,121.639,292.015,123.082z"/>
		<path id="tree_x5F_shape" fill="#010202" d="M307.841,110.954C317.968,123.533,324,139.23,324,156.222
			c0,36.29-27.317,66.548-63.625,73.485v14.018c0,15.624,14.404,24.113,14.404,24.113c0.826,0.486,0.998,1.675,0.367,2.668
			l-1.766,2.773h40.838c-0.322,2.667-0.643,5.336-0.94,8.003h-10.035v-0.073H187.3v-7.93h34.519
			c-0.447-1.278-0.665-2.219-0.665-2.219c-0.311-1.312,0.057-2.808,0.86-3.46c0,0,14.484-11.385,14.484-23.876v-12.882
			c-40.195-3.764-71.584-35.716-71.584-74.621c0-16.992,6.021-32.689,16.158-45.268c0.012-0.018,0.023-0.018,0.035-0.035
			c2.879-44.654,30.115-79.685,63.35-79.685c33.224,0,60.46,35.031,63.351,79.685C307.818,110.936,307.83,110.936,307.841,110.954z
			 M308.082,156.222c0-12.723-6.089-24.309-16.067-33.14c0.068-1.443,0.16-2.9,0.16-4.343c0-34.527-21.365-62.493-47.719-62.493
			s-47.73,27.966-47.73,62.493c0,1.443,0.092,2.9,0.171,4.343c-9.976,8.831-16.078,20.417-16.078,33.14
			c0,25.518,24.312,46.49,55.689,49.556v-9.551c0-1.389-0.539-3.264-1.193-4.166l-21.48-29.513
			c-0.033-0.035-0.068-0.092-0.103-0.144c-0.562-0.901-0.55-2.362,0.035-3.263l2.717-4.27c0.688-0.994,1.686-1.225,2.523-0.613
			l16.089,13.297c0.78,0.631,1.411,0.036,1.411-1.334v-32.489c0.035-1.478,0.631-2.756,1.502-3.298l20.837-10.921
			c0.849-0.433,1.525,0.346,1.525,1.714v44.994c0,1.37,0.597,1.929,1.376,1.248l14.141-13.106c0.745-0.698,1.364-0.144,1.364,1.229
			v10.648c-0.046,1.587-0.458,3.066-1.159,4.218l-14.576,21.569c-0.688,1.155-1.089,2.633-1.147,4.2v8.362
			C287.794,199.039,308.082,179.522,308.082,156.222z"/>
	</g>
	<g id="house_1_">
		<g id="XMLID_2_">
			<g>
				<polygon id="house_x5F_shadow" fill="#010202" opacity="0.25" points="493.343,169.636 425.151,217.898 379.532,258.516 379.532,169.636 
					436.438,118.419 				"/>
				<path id="house" fill="#010202" d="M550.241,190.242H516.1v91.039H356.777v-91.039h-34.142L436.438,87.816L550.241,190.242z
					 M493.343,258.516v-88.88l-56.904-51.217l-56.907,51.217v88.88H493.343z"/>
			</g>
		</g>
		<g>
			<rect x="425.151" y="231.585" fill="#010202" width="22.587" height="38.9"/>
			<rect x="397.507" y="165.196" fill="#010202" width="21.467" height="32.51"/>
			<rect x="452.723" y="165.196" fill="#010202" width="21.466" height="32.51"/>
		</g>
	</g>
	<path id="sun" fill="#010202" d="M110.073,119.454c-20.645,0-37.378-16.729-37.378-37.378c0-20.645,16.733-37.378,37.378-37.378
		c20.649,0,37.378,16.733,37.378,37.378C147.451,102.726,130.722,119.454,110.073,119.454z M110.073,111.445
		c16.217,0,29.368-13.151,29.368-29.369s-13.151-29.368-29.368-29.368s-29.368,13.15-29.368,29.368S93.855,111.445,110.073,111.445z
		 M79.865,112.279c1.564,1.564,1.564,4.099,0,5.663l-9.438,9.438c-1.501,1.627-4.036,1.71-5.653,0.208
		c-0.568-0.521-0.97-1.199-1.158-1.939c-0.359-1.418,0.079-2.931,1.148-3.921l9.438-9.449
		C75.767,110.714,78.301,110.714,79.865,112.279L79.865,112.279z M64.764,36.768c1.564-1.56,4.098-1.56,5.663,0l9.438,9.443
		c1.622,1.502,1.715,4.031,0.208,5.653c-0.521,0.563-1.2,0.965-1.951,1.152c-1.418,0.365-2.919-0.073-3.916-1.147l-9.443-9.438
		C63.205,40.866,63.205,38.332,64.764,36.768z M110.073,18c2.211,0,4.005,1.794,4.005,4.005v13.349c0,2.211-1.794,4.005-4.005,4.005
		s-4.005-1.794-4.005-4.005V22.005C106.068,19.794,107.862,18,110.073,18z M67.355,82.076c0,2.211-1.794,4.005-4.005,4.005H50.001
		c-2.211,0-4.005-1.794-4.005-4.005s1.794-4.004,4.005-4.004h13.349C65.562,78.072,67.355,79.865,67.355,82.076z M110.073,124.794
		c2.211,0,4.005,1.793,4.005,4.004v13.35c0,2.21-1.794,4.004-4.005,4.004s-4.005-1.794-4.005-4.004v-13.35
		C106.068,126.587,107.862,124.794,110.073,124.794z M174.149,82.076c0,2.211-1.794,4.005-4.005,4.005h-13.349
		c-2.211,0-4.005-1.794-4.005-4.005s1.794-4.004,4.005-4.004h13.349C172.355,78.072,174.149,79.865,174.149,82.076z
		 M140.275,112.279c1.564-1.564,4.099-1.564,5.663,0l9.438,9.449c1.471,1.575,1.429,4.025-0.094,5.559
		c-1.533,1.523-3.983,1.564-5.559,0.094l-9.449-9.428C138.711,116.388,138.711,113.843,140.275,112.279L140.275,112.279z
		 M155.376,36.768c1.565,1.564,1.565,4.098,0,5.663l-9.438,9.438c-1.575,1.47-4.036,1.429-5.559-0.094
		c-1.522-1.528-1.564-3.989-0.104-5.564l9.438-9.443C151.278,35.203,153.812,35.203,155.376,36.768L155.376,36.768L155.376,36.768z"
		/>
</g>
</svg>
</template>