<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="SIMPLE">
	<polygon id="S_x5F_Verti" fill="#676767" points="407.032,27.898 407.032,216.416 421.571,221.256 421.571,25.987 	"/>
	<polygon id="S_x5F_Horiz" fill="#676767" points="421.981,220.971 407.054,215.2 207.887,259.48 225.855,269.703 	"/>
	<polygon class="item-to-focus" id="S_x5F_Verre1" opacity="0.6" fill="#009FE3" stroke="#27348B" stroke-width="4" stroke-miterlimit="10" points="
		225.855,111.231 225.855,117.897 225.855,269.703 421.571,221.073 421.571,41.06 326.105,33.651 	"/>
</g>
</svg>  
</template>