<template>
<svg version="1.1" id="Yes" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="No">
	<path id="house_1_" opacity="0.8" fill="none" stroke="currentColor" stroke-width="18" d="M382.679,164.428v98.508H205.388v-98.508
		l-39.4-0.178l128.04-128.04l128.04,128.04L382.679,164.428z"/>
	<path id="symbol" class="icon-symbole symbole-bad" fill="#E30613" d="M308.438,217.533l21.589,21.579l-12.953,12.964l-21.59-21.59l-21.629,21.649l-12.963-12.964
		l21.639-21.639l-21.639-21.641l12.963-12.959l21.629,21.641l21.65-21.58l12.953,12.958L308.438,217.533z"/>
	<path id="fan" opacity="0.25" fill="#010202" d="M270.066,84.26v14.48h19.066v38.152c-3.941,2.609-6.355,7.389-6.355,12.529v7.24
		h25.422v-7.24c0-5.14-2.408-9.919-6.355-12.529V98.74h19.066V84.26H270.066 M257.354,149.421c-14.045,0-25.422,4.851-25.422,10.861
		s11.376,10.86,25.422,10.86s25.422-4.851,25.422-10.86S271.4,149.421,257.354,149.421 M333.621,149.421
		c-14.052,0-25.422,4.851-25.422,10.861s11.37,10.86,25.422,10.86s25.422-4.851,25.422-10.86S347.672,149.421,333.621,149.421
		 M282.777,163.902v7.24c0,5.205,2.415,9.984,6.355,12.53c3.941,2.602,8.77,2.602,12.711,0c3.947-2.546,6.355-7.382,6.355-12.53
		v-7.24H282.777z"/>
</g>
</svg>

</template>