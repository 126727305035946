<template>
  <div>
    <header class="header-main">
      <!-- :class="'header-' + screenSize.name" -->
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-lg-2 d-flex flex-column justify-content-center align-items-center">
            <router-link :to="{ name: 'home' }">
              <img src="@/assets/logo.png" alt="logo" class="logo" />
            </router-link>
            <small class="logo-by-promodul">by LAB PROMODUL</small>
          </div>
          <div class="col-sm-12 col-lg-7 d-flex justify-content-start align-items-center">
            <div class="w-100">
              <h1 class="title">Le confort d'été facile</h1>
              <h2 class="sub-title">
                <strong>R</strong>ecommandations <strong>E</strong>t <strong>S</strong>olutions <strong>P</strong>our l’<strong>I</strong>nconfort et le <strong>R</strong>afraichissement
                l’<strong>E</strong>té
              </h2>
            </div>
          </div>
          <div class="col-sm-12 col-lg-3 d-flex align-items-center justify-content-center justify-content-xl-end">
            <SocialNetwork />
          </div>
        </div>
      </div>
    </header>

    <div class="d-flex justify-content-between align-items-center mx-5" v-if="isShowDebugMode">
    </div>

    <main class="container-xxl my-5">
      <RouterView />
    </main>



    <footer class="footer mt-auto  bg-light- mt-5" id="theFooter">
      <!-- screenSize: {{ screenSize }} -->
      <div class="container-xxl">
        <div class="m-2 row">
          <div class="col-12 col-lg-3 text-center my-2">
            <img src="@/assets/logo_fdcp.png" alt="Cercle Promodul / INEF4" class="logo" style="width:200px" />
          </div>
          <div class="col-12 col-lg-9 text-center text-muted my-2">
            <p><strong>&copy;&nbsp;{{ currentYear }} <a href="https://cercle-promodul.inef4.org" target="_blank">Cercle Promodul / INEF4</a> - v{{ versionString }}</strong><br>
              Cet outil à vocation pédagogique, et pour l'utilisation du plus grand nombre, a été conçu et développé par le <a href="https://cercle-promodul.inef4.org" target="_blank">Fonds de
                Dotation Cercle Promodul / INEF4</a> dans le cadre d'un <a href="https://cercle-promodul.inef4.org/nos-soutiens/" target="_blank">projet d'action soutenu par le Mécénat</a>. Son
              utilisation est gratuite et n'est pas destinée à un usage commercial.</p>
          </div>
        </div>
      </div>

      <div class="form-check form-switch mx-5" v-if="appMode !== 'production'">
        <input class="form-check-input" type="checkbox" role="switch" id="DebugBtn" v-model="isDebug" />
        <label class="form-check-label" for="DebugBtn">Debug</label>
      </div>
    </footer>

    <div class="debug-bloc container" v-if="isDebug">
      simuResults:
      <pre>{{ simuResults }}</pre>
      simuData:
      <pre>{{ simuData }}</pre>
      steps:
      <pre>{{ steps }}</pre>
    </div>

    <RgpdMessage />
    <HelpModalBox />
    <FormModalBox />
  </div>
</template>

<script setup>
  import { event } from 'vue-gtag';
  import { matomoTrackPageView } from '@/utils/matomoTracker.js';
  import { useStore } from "vuex";
  import {
    useRouter,
    useRoute
  } from "vue-router";
  import { ref, watch, onMounted, onBeforeMount, computed, inject } from "vue";
  import { steps, forms } from "./appConfig";
  // import {useDebugManager} from './composable/useDebugManager.js';

  import SocialNetwork from "./components/Header/SocialNetwork.vue";
  import HelpModalBox from "./components/HelpModalBox.vue";
  import RgpdMessage from "./components/RgpdMessage.vue";

  const emitter = inject('emitter'); // Inject `emitter`

  //-- STORE
  const store = useStore();

  const simuData = computed(() => store.getters.simuData);
  const simuResults = computed(() => store.getters.simuResults);

  const route = useRoute();
  const router = useRouter();

  onBeforeMount(() => {
    window.addEventListener("resize", getScreenSize);
    getScreenSize();
    initStepsData();
  });


  //-- APP ROUTE MANAGEMENT
  const routeName = computed(() => route.name);
  watch(routeName, () => {

    const isDebugRoute = route.matched.findIndex((itm) => itm.name === 'debug') > -1;
    if (!store.isIntro && !isDebugRoute) {
      // alert('GO HOME');
      router.push({ name: 'home' });
    };


    event(route.name, { method: 'Google' });
  });
  //-- //APP ROUTE MANAGEMENT


  onMounted(() => {
    window.addEventListener("keydown", enterKeyPressValidation);
    matomoTrackPageView();
  });

  const enterKeyPressValidation = (event) => {
    if (event.isComposing || event.key === 'Enter') {
      const validationBtn = document.getElementById('formValidationButton');
      if (validationBtn) {
        validationBtn.click();
        return;
      }
    }
  };

  emitter.on("form-modal-open", (e) => {
    // alert('yop' + e);
    if (!e) {
      window.addEventListener("keydown", enterKeyPressValidation);
    } else {
      window.removeEventListener("keydown", enterKeyPressValidation);
    }
  });




  const screenSize = ref({ w: null, h: null });
  const getScreenSize = () => {
    let w = window.innerWidth;
    let h = window.innerHeight;
    screenSize.value = { w, h };
  };

  watch(
    screenSize,
    (newVal) => {
      // alert('dr')

      store.state.screenSize = newVal;
    },
    { deep: true }
  );

  const initStepsData = () => {


    let stepsStored = [];
    steps.forEach((step, stepIndex) => {
      let allStepQuestions = [];
      if (step.subSteps && step.subSteps.length > 0) {
        step.subSteps.forEach((subStep) => {
          if (!subStep.formName) return;
          // console.log(subStep.formName);
          // console.log(subStep.formName, forms[subStep.formName].questions);
          forms[subStep.formName].questions.forEach((subQst) => {
            allStepQuestions.push(subQst);
          });
        });
      }

      stepsStored.push({ ...step, allStepQuestions, isAllowed: stepIndex === 0 });
    });
    store.state.steps = stepsStored;
  };

  const isDebug = ref(false);
  const isShowDebugMode = ref(true);
  // const versionString = ref("0.0.0");
  const currentYear = new Date().getFullYear();

  const versionString = computed(() => {
    return `${process.env.VUE_APP_VERSION_NUM_MAJ}.${process.env.VUE_APP_VERSION_NUM_MIN}.${process.env.VUE_APP_VERSION_NUM_REV}`;
  });

  const appMode = process.env.VUE_APP_MODE;

  watch(isDebug, (newVal) => {
    // alert("rrr" + newVal);
    store.commit('setDebugMode', newVal);
    // store.state.isDebug = newVal;
  });
</script>


<style lang="scss">
  .debug-bloc {
    // width: 50vw;
    width: 100%;
    // max-width: 1060px;
    max-height: 80vh;
    overflow: auto;
    margin: 2rem auto;
    padding: 1rem;
    border: 8px solid greenyellow;
    border-radius: 1rem;
    background-color: #333;
    color: greenyellow;
    font-family: "Courier New", Courier, monospace;
    font-size: 1rem;

    pre {
      width: 100%;
      white-space: pre-wrap;
    }
  }
</style>