<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="medium_1_">
	<path id="medium" fill="#010202" d="M232.048,213.456c0-2.148-0.789-4.012-2.35-5.59c-1.578-1.559-3.424-2.348-5.572-2.348
		s-4.027,0.789-5.588,2.348c-1.563,1.578-2.35,3.441-2.35,5.59s0.787,4.012,2.35,5.572c1.561,1.576,3.439,2.366,5.588,2.366
		s3.994-0.79,5.572-2.366C231.259,217.467,232.048,215.604,232.048,213.456z M374.819,142.061c0-4.195-1.611-7.904-4.834-11.076
		c-3.223-3.188-6.898-4.783-11.025-4.783h-43.619c0-4.784,1.98-11.379,5.94-19.753c3.961-8.393,5.942-15.037,5.942-19.905
		c0-8.09-1.311-14.098-3.961-17.957c-2.652-3.894-7.922-5.841-15.859-5.841c-2.148,2.148-3.727,5.673-4.717,10.541
		c-0.99,4.866-2.249,10.069-3.776,15.54c-1.527,5.505-3.993,10.036-7.385,13.595c-1.812,1.88-4.983,5.639-9.532,11.278
		c-0.336,0.402-1.275,1.644-2.852,3.708c-1.579,2.064-2.871,3.759-3.895,5.084c-1.041,1.31-2.467,3.071-4.28,5.271
		c-1.829,2.182-3.474,3.994-4.95,5.438c-1.494,1.444-3.089,2.921-4.783,4.398c-1.695,1.493-3.34,2.616-4.951,3.355
		c-1.611,0.738-3.088,1.107-4.398,1.107h-3.977v79.333h3.977c1.074,0,2.367,0.117,3.895,0.37c1.527,0.234,2.888,0.502,4.096,0.805
		c1.191,0.285,2.77,0.738,4.699,1.359c1.947,0.621,3.39,1.09,4.346,1.426c0.957,0.336,2.417,0.84,4.398,1.545
		c1.979,0.704,3.172,1.141,3.59,1.309c17.438,6.025,31.57,9.045,42.395,9.045h15.003c15.845,0,23.782-6.896,23.782-20.691
		c0-2.148-0.201-4.465-0.621-6.949c2.484-1.325,4.447-3.49,5.891-6.512s2.166-6.041,2.166-9.096s-0.739-5.908-2.216-8.561
		c4.363-4.127,6.545-9.045,6.545-14.751c0-2.064-0.403-4.347-1.225-6.864c-0.822-2.533-1.864-4.497-3.104-5.891
		c2.65-0.1,4.866-2.031,6.646-5.84C373.93,148.304,374.819,144.948,374.819,142.061z M390.696,141.944
		c0,7.352-2.048,14.08-6.076,20.207c0.738,2.719,1.107,5.57,1.107,8.542c0,6.361-1.577,12.317-4.698,17.84
		c0.234,1.745,0.368,3.524,0.368,5.337c0,8.357-2.483,15.709-7.451,22.053c0.102,11.496-3.424,20.559-10.539,27.222
		c-7.082,6.647-16.481,9.97-28.129,9.97h-15.977c-7.938,0-15.76-0.939-23.479-2.785c-7.736-1.863-16.681-4.564-26.853-8.125
		c-9.566-3.305-15.273-4.951-17.086-4.951h-35.695c-4.381,0-8.123-1.56-11.229-4.647c-3.088-3.104-4.633-6.848-4.633-11.211v-79.333
		c0-4.363,1.545-8.123,4.633-11.211c3.105-3.088,6.848-4.648,11.229-4.648h33.951c2.987-1.98,8.627-8.375,16.984-19.216
		c4.799-6.176,9.214-11.481,13.258-15.843c1.981-2.082,3.458-5.606,4.398-10.607c0.956-5.001,2.215-10.238,3.792-15.675
		c1.56-5.454,4.129-9.919,7.669-13.393c3.223-3.054,6.949-4.582,11.162-4.582c6.931,0,13.174,1.343,18.711,4.028
		c5.539,2.686,9.752,6.882,12.639,12.571c2.904,5.706,4.346,13.393,4.346,23.06c0,7.687-1.979,15.608-5.957,23.799h21.818
		c8.593,0,16.027,3.137,22.304,9.415C387.541,126.051,390.696,133.436,390.696,141.944z"/>
	<path id="symbole_1_" class="icon-symbole symbole-good" fill="#009741" d="M531.753,101.192c2.745-1.652,6.307-0.838,8.051,1.839l5.89,9.008
		c1.605,2.421,1.209,5.644-0.908,7.61l-0.023,0.036l-0.093,0.08l-0.327,0.303l-1.349,1.279
		c-7.423,7.204-14.614,14.627-21.574,22.273c-13.082,14.384-28.628,33.233-39.077,51.503c-2.933,5.096-10.054,6.19-14.289,1.793
		l-38.635-40.146c-2.28-2.373-2.21-6.143,0.163-8.424c0.047-0.046,0.093-0.082,0.14-0.116l11.682-10.543
		c2.119-1.909,5.284-2.048,7.564-0.338l19.713,14.78C499.472,121.767,516.927,110.106,531.753,101.192z"/>
</g>
</svg>
</template>