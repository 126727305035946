<template>



    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[formName]['wallsComposition']"
    v-model="theValue['wallsComposition']"
    :qstParams="getQstParam('wallsComposition')"
    />

    <template v-if="!isNew">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['wallsInsulationImprovement']"
      v-model="theValue['wallsInsulationImprovement']"
      :qstParams="getQstParam('wallsInsulationImprovement')"
      />
    </template>

    <div v-if="isDebug" class="debug-bloc">
      wallsInsulationImprovement: {{ theValue['wallsInsulationImprovement'] }}<br>
      wallsInsulationType: {{ theValue['wallsInsulationType'] }}
    </div>

    <TransitionQst class="transition">
    <template v-if="theValue['wallsInsulationImprovement'] !== 'none' && theValue['wallsInsulationImprovement'] !== 'unknow'">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['wallsInsulationType']"
      v-model="theValue['wallsInsulationType']"
      :qstParams="getQstParam('wallsInsulationType')"
      />
    </template>
  </TransitionQst>

    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['wallsColor']"
    v-model="theValue['wallsColor']"
    :qstParams="getQstParam('wallsColor')"
    />

</template>

<script setup>
  import { ref, watch, computed } from 'vue';
  import { useStore } from 'vuex';
  import InputFormRowGlobal from './InputFormRowGlobal.vue';

  const props = defineProps({
  stepId: {
    type: Number,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  },
  formName: {
      type: String,
      required: true
    },
  formQst: {
    type: Array,
    required: true
  },
  validationObj: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);

const theValue = ref(props.modelValue[[props.formName]]);
watch (theValue, (newVal) => {
    emit('update:modelValue', newVal)
  });

const getQstParam = (varname) => {
  const param = props.formQst.find((qst) => qst.varname === varname);
  return param;
}

  //-- FOR THE SPECIFIC FORM

const store = useStore();
const simuData = computed(() => store.getters.simuData);
const isNew = computed(() => simuData.value.buildingConfig.buildingProjectState === 'new');
const isDebug = computed(() => store.getters.isDebug);


watch (isNew, (newVal) => {
  alert('YYYY')
  if (newVal === true) {
    theValue.value['wallsInsulationImprovement'] = 'unknow';
  }
});


const wallsInsulationImprovement = computed(() => theValue.value['wallsInsulationImprovement']);
watch (wallsInsulationImprovement, (newVal) => {
  if (newVal === 'none') {
    theValue.value['wallsInsulationType'] = 'ite';
  } else if (newVal === 'unknow') {
    theValue.value['wallsInsulationType'] = 'iti'
  }
}, {deep: true});
</script>
