<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="crossed">
	<g>
		<path fill="currentColor" d="M420.735,112.761c0-13.462,10.928-24.375,24.375-24.375c13.477,0,24.375,10.913,24.375,24.375
			s-10.898,24.375-24.375,24.375h-48.75c-3.105,0-5.625-2.52-5.625-5.625s2.52-5.625,5.625-5.625h48.75
			c7.266,0,13.125-5.874,13.125-13.125s-5.859-13.125-13.125-13.125c-7.236,0-13.125,5.874-13.125,13.125v2.681
			c0,3.105-2.52,5.625-5.625,5.625s-5.625-2.52-5.625-5.625V112.761z"/>
		<path opacity="0.4" fill="currentColor" enable-background="new    " d="M398.235,176.511c0-3.105,2.52-5.625,5.625-5.625h108.75
			c17.607,0,31.875,14.268,31.875,31.875s-14.268,31.875-31.875,31.875s-31.875-14.268-31.875-31.875v-3.75
			c0-3.105,2.52-5.625,5.625-5.625s5.625,2.52,5.625,5.625v3.75c0,11.396,9.229,20.625,20.625,20.625s20.625-9.229,20.625-20.625
			s-9.229-20.625-20.625-20.625H403.86C400.755,182.136,398.235,179.617,398.235,176.511z"/>
		<path opacity="0.7" fill="currentColor" enable-background="new    " d="M480.735,127.761c0-17.607,14.268-31.875,31.875-31.875
			s31.875,14.268,31.875,31.875s-14.268,31.875-31.875,31.875H388.86c-3.105,0-5.625-2.52-5.625-5.625s2.52-5.625,5.625-5.625
			h123.75c11.396,0,20.625-9.236,20.625-20.625s-9.229-20.625-20.625-20.625s-20.625,9.236-20.625,20.625v3.75
			c0,3.105-2.52,5.625-5.625,5.625s-5.625-2.52-5.625-5.625V127.761z"/>
	</g>
	<g>
		<polygon fill="currentColor" points="250.148,201.417 298.286,153.277 250.148,105.141 250.148,141.241 141.837,141.241 
			141.837,165.312 250.148,165.312 		"/>
		<polygon points="322.356,213.454 202.009,213.454 202.009,189.381 177.938,189.381 177.938,237.522 
			346.426,237.522 346.426,191.637 322.356,191.637 		"/>
		<polygon points="262.185,32.93 168.56,117.171 204.527,117.171 262.185,65.296 322.356,119.454 322.356,144.762 
			346.426,144.762 346.426,141.241 382.532,141.241 		"/>
		<rect x="322.356" y="144.762" opacity="0.35" fill="#009FE3" width="24.07" height="46.875"/>
	</g>
	<g>
		<path fill="currentColor" d="M120.875,123.513h-48.75c-3.105,0-5.625,2.52-5.625,5.625s2.52,5.625,5.625,5.625h48.75
			c0.602,0,1.194-0.037,1.784-0.081v-11.304C122.075,123.459,121.482,123.513,120.875,123.513z"/>
		<path opacity="0.4" fill="currentColor" enable-background="new    " d="M79.625,168.513c-3.105,0-5.625,2.52-5.625,5.625
			s2.52,5.625,5.625,5.625h43.034v-11.25H79.625z"/>
		<path opacity="0.7" fill="currentColor" enable-background="new    " d="M64.625,146.013c-3.105,0-5.625,2.52-5.625,5.625
			s2.52,5.625,5.625,5.625h58.034v-11.25H64.625z"/>
	</g>
	<g>
		<path fill="#009640" class="icon-symbole symbole-good" d="M463.269,187.779c2.045-1.23,4.695-0.624,5.996,1.369l4.385,6.707c1.195,1.803,0.9,4.203-0.676,5.667
			l-0.018,0.026l-0.068,0.06l-0.244,0.226l-1.004,0.953c-5.529,5.364-10.885,10.892-16.066,16.585
			c-9.739,10.71-21.316,24.747-29.097,38.351c-2.184,3.795-7.487,4.61-10.641,1.335l-28.769-29.895
			c-1.698-1.767-1.646-4.576,0.121-6.273c0.036-0.035,0.069-0.061,0.104-0.086l8.699-7.851c1.577-1.421,3.935-1.525,5.632-0.251
			l14.679,11.005C439.231,203.1,452.229,194.417,463.269,187.779z"/>
	</g>
</g>
</svg>
</template>