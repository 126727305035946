//-- POUR LE DEV et la période de test
const appVersionMaj = process.env.VUE_APP_VERSION_NUM_MAJ;
const appVersionMin = process.env.VUE_APP_VERSION_NUM_MIN;
const appVersionRev = process.env.VUE_APP_VERSION_NUM_REV;
const appMode = process.env.VUE_APP_MODE;
const currentVersion = { maj: appVersionMaj, min: appVersionMin, rev: appVersionRev };
let lastVersion = undefined;

try {
  lastVersion = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_LOCALSTORAGE_PREFIX}_version`));
  console.log("currentVersion:", currentVersion, "lastVersion:", lastVersion);
} catch (e) {
  console.log("Last Version Error", e);
}

if (!lastVersion || (currentVersion?.maj !== lastVersion?.maj && currentVersion?.min !== lastVersion?.min)) {
  localStorage.clear();
  if (appMode !== "production") {
    alert("Le store a été réinitialisé, suite à la détection d'une nouvelle version !");
  }
  localStorage.setItem(`${process.env.VUE_APP_LOCALSTORAGE_PREFIX}_version`, JSON.stringify({ maj: appVersionMaj, min: appVersionMin, rev: appVersionRev }));
  window.document.location.reload();
}
//--

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import store from "./store";

import "@popperjs/core";
// import 'bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./scss/app.scss";

// import './assets/main.css'

import Tabs from "./components/custom-tabs/CustomTabs.vue";
import Tab from "./components/custom-tabs/CustomTab.vue";
import "./components/custom-tabs/tabs.scss";

import VueSocialSharing from "vue-social-sharing";

import VueApexCharts from "vue3-apexcharts";
import VueGtag from "vue-gtag";
import VueMatomo from "vue-matomo";

const app = createApp(App);

// Créer le plugin
const TabsPlugin = {
  install(app) {
    // Ajouter les composants
    app.component("custom-tabs", Tabs);
    app.component("custom-tab", Tab);
  },
};

app.use(VueSocialSharing);

//-- ICONS
import IconBase from "@/components/icons/IconBase.vue";
app.component("IconBase", IconBase);
import IconLogoCerclePromodul from "./components/icons/IconLogoCerclePromodul.vue";
app.component("IconLogoCerclePromodul", IconLogoCerclePromodul);
import IconLogoLeLab from "./components/icons/IconLogoLeLab.vue";
app.component("IconLogoLeLab", IconLogoLeLab);
import IconFrance from "./components/icons/IconFrance.vue";
app.component("IconFrance", IconFrance);
import IconWall from "./components/icons/IconWall.vue";
app.component("IconWall", IconWall);
import IconHouseFind from "./components/icons/IconHouseFind.vue";
app.component("IconHouseFind", IconHouseFind);
import IconGraphBar from "./components/icons/IconGraphBar.vue";
app.component("IconGraphBar", IconGraphBar);
import IconBuildingGear from "./components/icons/IconBuildingGear.vue";
app.component("IconBuildingGear", IconBuildingGear);
import IconMapPoint from "./components/icons/IconMapPoint.vue";
app.component("IconMapPoint", IconMapPoint);
import IconBuildingType from "./components/icons/IconBuildingType.vue";
app.component("IconBuildingType", IconBuildingType);
import IconFloorPlan from "./components/icons/IconFloorPlan.vue";
app.component("IconFloorPlan", IconFloorPlan);

import IconBedroom from "./components/icons/IconBedroom.vue";
app.component("IconBedroom", IconBedroom);
import IconKitchen from "./components/icons/IconKitchen.vue";
app.component("IconKitchen", IconKitchen);
import IconLivingRoom from "./components/icons/IconLivingRoom.vue";
app.component("IconLivingRoom", IconLivingRoom);
import IconUnknow from "./components/icons/IconUnknow.vue";
app.component("IconUnknow", IconUnknow);

import IconRoomLevel2Bottom from "./components/icons/IconRoomLevel2Bottom.vue";
app.component("IconRoomLevel2Bottom", IconRoomLevel2Bottom);
import IconRoomLevel2Top from "./components/icons/IconRoomLevel2Top.vue";
app.component("IconRoomLevel2Top", IconRoomLevel2Top);
import IconRoomLevel3Bottom from "./components/icons/IconRoomLevel3Bottom.vue";
app.component("IconRoomLevel3Bottom", IconRoomLevel3Bottom);
import IconRoomLevel3Inter from "./components/icons/IconRoomLevel3Inter.vue";
app.component("IconRoomLevel3Inter", IconRoomLevel3Inter);
import IconRoomLevel3Top from "./components/icons/IconRoomLevel3Top.vue";
app.component("IconRoomLevel3Top", IconRoomLevel3Top);

import IconExternalGreenSpaceDense from "./components/icons/IconExternalGreenSpaceDense.vue";
app.component("IconExternalGreenSpaceDense", IconExternalGreenSpaceDense);
import IconExternalGreenSpaceLight from "./components/icons/IconExternalGreenSpaceLight.vue";
app.component("IconExternalGreenSpaceLight", IconExternalGreenSpaceLight);
import IconExternalGreenSpaceNone from "./components/icons/IconExternalGreenSpaceNone.vue";
app.component("IconExternalGreenSpaceNone", IconExternalGreenSpaceNone);

import IconExternalSurroundingRural from "./components/icons/IconExternalSurroundingRural.vue";
app.component("IconExternalSurroundingRural", IconExternalSurroundingRural);
import IconExternalSurroundingPeriurbain from "./components/icons/IconExternalSurroundingPeriurbain.vue";
app.component("IconExternalSurroundingPeriurbain", IconExternalSurroundingPeriurbain);
import IconExternalSurroundingUrbain from "./components/icons/IconExternalSurroundingUrbain.vue";
app.component("IconExternalSurroundingUrbain", IconExternalSurroundingUrbain);

import IconCanOpenWindowsNightYes from "./components/icons/IconCanOpenWindowsNightYes.vue";
app.component("IconCanOpenWindowsNightYes", IconCanOpenWindowsNightYes);
import IconCanOpenWindowsNightNo from "./components/icons/IconCanOpenWindowsNightNo.vue";
app.component("IconCanOpenWindowsNightNo", IconCanOpenWindowsNightNo);

import IconThumbGood from "./components/icons/IconThumbGood.vue";
app.component("IconThumbGood", IconThumbGood);
import IconThumbMedium from "./components/icons/IconThumbMedium.vue";
app.component("IconThumbMedium", IconThumbMedium);
import IconThumbBad from "./components/icons/IconThumbBad.vue";
app.component("IconThumbBad", IconThumbBad);

import IconWindowsRoomCrossoverYes from "./components/icons/IconWindowsRoomCrossoverYes.vue";
app.component("IconWindowsRoomCrossoverYes", IconWindowsRoomCrossoverYes);
import IconWindowsRoomCrossoverNo from "./components/icons/IconWindowsRoomCrossoverNo.vue";
app.component("IconWindowsRoomCrossoverNo", IconWindowsRoomCrossoverNo);

import IconIsFanYes from "./components/icons/IconIsFanYes.vue";
app.component("IconIsFanYes", IconIsFanYes);
import IconIsFanNo from "./components/icons/IconIsFanNo.vue";
app.component("IconIsFanNo", IconIsFanNo);

import IconIsClimYes from "./components/icons/IconIsClimYes.vue";
app.component("IconIsClimYes", IconIsClimYes);
import IconIsClimNo from "./components/icons/IconIsClimNo.vue";
app.component("IconIsClimNo", IconIsClimNo);

import IconProjectTypeNew from "./components/icons/IconProjectTypeNew.vue";
app.component("IconProjectTypeNew", IconProjectTypeNew);
import IconProjectTypeOld from "./components/icons/IconProjectTypeOld.vue";
app.component("IconProjectTypeOld", IconProjectTypeOld);

import IconIsLittoralYes from "./components/icons/IconIsLittoralYes.vue";
app.component("IconIsLittoralYes", IconIsLittoralYes);
import IconIsLittoralNo from "./components/icons/IconIsLittoralNo.vue";
app.component("IconIsLittoralNo", IconIsLittoralNo);

import IconWindowsGlassTypeDouble from "./components/icons/IconWindowsGlassTypeDouble.vue";
app.component("IconWindowsGlassTypeDouble", IconWindowsGlassTypeDouble);
import IconWindowsGlassTypeSimple from "./components/icons/IconWindowsGlassTypeSimple.vue";
app.component("IconWindowsGlassTypeSimple", IconWindowsGlassTypeSimple);

import IconIsShadowNo from "./components/icons/IconIsShadowNo.vue";
app.component("IconIsShadowNo", IconIsShadowNo);
import IconIsShadowYes from "./components/icons/IconIsShadowYes.vue";
app.component("IconIsShadowYes", IconIsShadowYes);

import IconSolarProtectionExt from "./components/icons/IconSolarProtectionExt.vue";
app.component("IconSolarProtectionExt", IconSolarProtectionExt);
import IconSolarProtectionInt from "./components/icons/IconSolarProtectionInt.vue";
app.component("IconSolarProtectionInt", IconSolarProtectionInt);
import IconSolarProtectionNone from "./components/icons/IconSolarProtectionNone.vue";
app.component("IconSolarProtectionNone", IconSolarProtectionNone);

import IconSolarProtectionControlAuto from "./components/icons/IconSolarProtectionControlAuto.vue";
app.component("IconSolarProtectionControlAuto", IconSolarProtectionControlAuto);
import IconSolarProtectionControlMoto from "./components/icons/IconSolarProtectionControlMoto.vue";
app.component("IconSolarProtectionControlMoto", IconSolarProtectionControlMoto);
import IconSolarProtectionControlManual from "./components/icons/IconSolarProtectionControlManual.vue";
app.component("IconSolarProtectionControlManual", IconSolarProtectionControlManual);

import IconIntroAdvices from "./components/icons/IconIntroAdvices.vue";
app.component("IconIntroAdvices", IconIntroAdvices);
import IconIntroChart from "./components/icons/IconIntroChart.vue";
app.component("IconIntroChart", IconIntroChart);
import IconIntroComfort from "./components/icons/IconIntroComfort.vue";
app.component("IconIntroComfort", IconIntroComfort);
import IconIntroGoodBad from "./components/icons/IconIntroGoodBad.vue";
app.component("IconIntroGoodBad", IconIntroGoodBad);

import IconBuildingTypeLC from "./components/icons/IconBuildingTypeLC.vue";
app.component("IconBuildingTypeLC", IconBuildingTypeLC);
import IconBuildingTypeMI from "./components/icons/IconBuildingTypeMI.vue";
app.component("IconBuildingTypeMI", IconBuildingTypeMI);

import IconPosteTvx from "./components/icons/IconPosteTvx.vue";
app.component("IconPosteTvx", IconPosteTvx);
//-- /ICONS

//-- AIDES
import HelpBtn from "@/components/HelpButton.vue";
app.component("HelpBtn", HelpBtn);

import IntroStepBuildingDescription from "@/components/IntroStepBuildingDescription.vue";
app.component("IntroStepBuildingDescription", IntroStepBuildingDescription);
import IntroStepResults from "@/components/IntroStepResults.vue";
app.component("IntroStepResults", IntroStepResults);

import HelpTest from "@/components/helps/HelpTest.vue";
app.component("HelpTest", HelpTest);

import IntroSavoirPlusRafraichissementPassif from "@/components/helps/IntroSavoirPlusRafraichissementPassif.vue";
app.component("IntroSavoirPlusRafraichissementPassif", IntroSavoirPlusRafraichissementPassif);
import HelpExternalSurroundings from "@/components/helps/HelpExternalSurroundings.vue";
app.component("HelpExternalSurroundings", HelpExternalSurroundings);
import HelpExternalGroundType from "@/components/helps/HelpExternalGroundType.vue";
app.component("HelpExternalGroundType", HelpExternalGroundType);
import HelpStructureComposition from "@/components/helps/HelpStructureComposition.vue";
app.component("HelpStructureComposition", HelpStructureComposition);
import HelpColor from "@/components/helps/HelpColor.vue";
app.component("HelpColor", HelpColor);
import HelpWindowsOrientations from "@/components/helps/HelpWindowsOrientations.vue";
app.component("HelpWindowsOrientations", HelpWindowsOrientations);
import HelpWindowsProtectionControl from "@/components/helps/HelpWindowsProtectionControl.vue";
app.component("HelpWindowsProtectionControl", HelpWindowsProtectionControl);
import HelpWindowsRoomCrossover from "@/components/helps/HelpWindowsRoomCrossover.vue";
app.component("HelpWindowsRoomCrossover", HelpWindowsRoomCrossover);
import HelpIsLittoral from "@/components/helps/HelpIsLittoral.vue";
app.component("HelpIsLittoral", HelpIsLittoral);
import HelpAltitude from "@/components/helps/HelpAltitude.vue";
app.component("HelpAltitude", HelpAltitude);
import HelpZoneClimat from "@/components/helps/HelpZoneClimat.vue";
app.component("HelpZoneClimat", HelpZoneClimat);
import HelpExternalGreenSpace from "@/components/helps/HelpExternalGreenSpace.vue";
app.component("HelpExternalGreenSpace", HelpExternalGreenSpace);
import HelpUserBehaviorVentil from "@/components/helps/HelpUserBehaviorVentil.vue";
app.component("HelpUserBehaviorVentil", HelpUserBehaviorVentil);
import HelpUserBehaviorSolarProtection from "@/components/helps/HelpUserBehaviorSolarProtection.vue";
app.component("HelpUserBehaviorSolarProtection", HelpUserBehaviorSolarProtection);
import HelpUserBehaviorElecEquipments from "@/components/helps/HelpUserBehaviorElecEquipments.vue";
app.component("HelpUserBehaviorElecEquipments", HelpUserBehaviorElecEquipments);
import HelpOverventilationType from "@/components/helps/HelpOverventilationType.vue";
app.component("HelpOverventilationType", HelpOverventilationType);
import HelpIsAirBlower from "@/components/helps/HelpIsAirBlower.vue";
app.component("HelpIsAirBlower", HelpIsAirBlower);
import HelpIsClim from "@/components/helps/HelpIsClim.vue";
app.component("HelpIsClim", HelpIsClim);
import HelpClimNbRoom from "@/components/helps/HelpClimNbRoom.vue";
app.component("HelpClimNbRoom", HelpClimNbRoom);
import HelpClimType from "@/components/helps/HelpClimType.vue";
app.component("HelpClimType", HelpClimType);
import HelpWallsComposition from "@/components/helps/HelpWallsComposition.vue";
app.component("HelpWallsComposition", HelpWallsComposition);
import HelpRecoTvx from "@/components/helps/HelpRecoTvx.vue";
app.component("HelpRecoTvx", HelpRecoTvx);

//-- /AIDES

//-- TOP COMPONENTS
import RoomsManager from "@/components/RoomsManager.vue";
app.component("RoomsManager", RoomsManager);
//-- /TOP COMPONENTS

//-- FORM MODAL
import FormModalBox from "@/components/FormModalBox.vue";
app.component("FormModalBox", FormModalBox);

//-- STEPS COMPONENTS
// import FormBase from "@/components/FormBase.vue";
// app.component('FormBase', FormBase);
import FormBase2 from "@/components/FormBase2.vue";
app.component("FormBase2", FormBase2);
// import FormGeo from "@/components/FormGeo.vue";
// app.component('FormGeo', FormGeo);
import FormGeo2 from "@/components/FormGeo2.vue";
app.component("FormGeo2", FormGeo2);
// import FormBuildingType from "@/components/FormBuildingType.vue";
// app.component('FormBuildingType', FormBuildingType);
import FormBuildingType2 from "@/components/FormBuildingType2.vue";
app.component("FormBuildingType2", FormBuildingType2);
import FormBuildingWalls from "@/components/FormBuildingWalls.vue";
app.component("FormBuildingWalls", FormBuildingWalls);
import FormBuildingRoof from "@/components/FormBuildingRoof.vue";
app.component("FormBuildingRoof", FormBuildingRoof);
import ResultsMain from "@/components/ResultsMain.vue";
app.component("ResultsMain", ResultsMain);
import ResultsBati from "@/components/ResultsBati.vue";
app.component("ResultsBati", ResultsBati);
import FormRoom from "@/components/FormRoom.vue";
app.component("FormRoom", FormRoom);
import FormBuildingWindows from "@/components/FormBuildingWindows.vue";
app.component("FormBuildingWindows", FormBuildingWindows);
import FormBuildingGears from "@/components/FormBuildingGears.vue";
app.component("FormBuildingGears", FormBuildingGears);
import FormEnvironment from "@/components/FormEnvironment.vue";
app.component("FormEnvironment", FormEnvironment);
//-- /STEPS COMPONENTS

//-- QST COMPONENTS
import QstInsulationImprovement from "@/components/QstInsulationImprovement.vue";
app.component("QstInsulationImprovement", QstInsulationImprovement);

import MapFranceZoneClimat from "@/components/MapFranceZoneClimat.vue";
app.component("MapFranceZoneClimat", MapFranceZoneClimat);
//-- /QST COMPONENTS

//-- TRANSITIONS
import TransitionQst from "@/transitions/TransitionQst.vue";
app.component("TransitionQst", TransitionQst);
import TransitionStep from "@/transitions/TransitionStep.vue";
app.component("TransitionStep", TransitionStep);
//-- /TRANSITIONS

import mitt from "mitt";
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.provide("emitter", emitter);

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "RESPIRE";
  next();
});

if (appMode === "production") {
  app.use(VueGtag, {
    config: { id: "G-3HV9P0P07R" },
  });
}

app.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: "https://matomo.inef4.org/",
  siteId: 9,
  router: router,
  enableLinkTracking: appMode === "production",
  // enableLinkTracking: true,
});

import VueCookies from "vue-cookies";
app.use(VueCookies, { expires: "7d" });
// js
// const $cookies = inject('$cookies');

// app.use(emitter);
app.use(router);
app.use(TabsPlugin);
app.use(store);
app.use(VueApexCharts);
app.mount("#app");
