<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1000px"
	 height="1000px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
<g id="Calque_4">
	<path id="tableau" fill="#EC671C" d="M760.212,682.594c36.856,0,67.038-30.171,67.038-67.037V246.851
		c0-36.866-30.182-67.038-67.038-67.038H408.265c10.05,20.106,16.759,43.609,16.759,67.038h335.188v368.706H458.543v67.037"/>
	<g id="homme">
		<path fill="#293377" d="M592.618,347.406v67.038H391.506v435.743h-67.038V649.075h-67.038v201.112h-67.037V582.038h-50.278
			V414.444c0-36.866,30.181-67.038,67.038-67.038H592.618 M357.987,246.851c0,36.865-30.181,67.037-67.038,67.037
			c-36.857,0-67.038-30.172-67.038-67.037c0-36.866,30.181-67.038,67.038-67.038C327.806,179.813,357.987,209.984,357.987,246.851"
			/>
	</g>
	<g id="lignes">
		<polyline fill="#08672F" points="458.542,329.887 458.542,296.369 726.692,296.369 726.692,329.887 458.542,329.887 		"/>
		<polyline fill="#08672F" points="617.76,378.542 617.76,346.661 726.692,346.661 726.692,378.542 617.76,378.542 		"/>
		<rect x="467" y="444.785" fill="#08672F" width="259.692" height="33.519"/>
		<rect x="467" y="494.258" fill="#08672F" width="259.692" height="33.52"/>
		<polyline fill="#08672F" points="617.76,428.014 617.76,396.133 726.692,396.133 726.692,428.014 617.76,428.014 		"/>
		<rect x="467" y="543.73" fill="#08672F" width="259.692" height="33.52"/>
	</g>
</g>
</svg>
</template>