<template>
<svg version="1.1" id="Calque_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="auto">
	<path id="gears" fill="#010202" d="M201.245,168.879c0-9.185-3.262-17.033-9.744-23.525c-6.521-6.502-14.35-9.754-23.544-9.754
		c-9.175,0-17.023,3.252-23.525,9.754c-6.502,6.492-9.743,14.341-9.743,23.525s3.241,17.022,9.743,23.525
		c6.502,6.502,14.351,9.763,23.525,9.763c9.195,0,17.023-3.261,23.544-9.763C197.983,185.901,201.245,178.063,201.245,168.879z
		 M301.071,235.436c0-4.512-1.668-8.397-4.93-11.696c-3.299-3.299-7.203-4.947-11.715-4.947s-8.416,1.648-11.715,4.947
		c-3.262,3.299-4.93,7.185-4.93,11.696c0,4.588,1.631,8.512,4.891,11.772c3.223,3.241,7.166,4.853,11.754,4.853
		s8.531-1.611,11.754-4.853C299.44,243.947,301.071,240.023,301.071,235.436z M301.071,102.321c0-4.502-1.668-8.407-4.93-11.696
		c-3.299-3.289-7.203-4.938-11.715-4.938s-8.416,1.648-11.715,4.938c-3.262,3.289-4.93,7.194-4.93,11.696
		c0,4.598,1.631,8.521,4.891,11.763c3.223,3.251,7.166,4.882,11.754,4.882s8.531-1.631,11.754-4.882
		C299.44,110.843,301.071,106.919,301.071,102.321z M251.137,157.05v24.037c0,0.872-0.303,1.725-0.908,2.54
		c-0.607,0.834-1.289,1.289-2.086,1.365l-20.133,3.128c-0.947,3.032-2.35,6.313-4.17,9.876c2.957,4.171,6.863,9.137,11.715,14.938
		c0.607,0.967,0.91,1.82,0.91,2.616c0,1.023-0.303,1.858-0.91,2.465c-2.01,2.597-5.572,6.483-10.729,11.639
		c-5.156,5.156-8.551,7.734-10.199,7.734c-0.947,0-1.857-0.303-2.73-0.909l-14.938-11.716c-3.223,1.668-6.559,2.995-10.008,4.038
		c-0.967,9.364-1.973,16.075-2.996,20.151c-0.607,2.085-1.914,3.108-3.904,3.108h-24.19c-0.947,0-1.819-0.304-2.578-0.967
		c-0.796-0.645-1.213-1.402-1.308-2.274l-2.995-19.886c-2.938-0.872-6.199-2.199-9.744-4.019L123.9,236.479
		c-0.606,0.606-1.479,0.909-2.616,0.909c-0.947,0-1.857-0.341-2.729-1.042c-12.474-11.525-18.71-18.464-18.71-20.796
		c0-0.796,0.303-1.611,0.91-2.464c0.872-1.214,2.635-3.526,5.326-6.9c2.692-3.374,4.739-6.028,6.104-7.924
		c-1.972-3.829-3.488-7.374-4.55-10.654l-19.753-3.128c-0.872-0.095-1.592-0.512-2.199-1.231c-0.606-0.739-0.909-1.593-0.909-2.54
		v-24.047c0-0.872,0.303-1.716,0.909-2.54c0.607-0.815,1.289-1.271,2.086-1.365l20.132-3.118c0.947-3.033,2.351-6.322,4.17-9.877
		c-2.957-4.161-6.862-9.146-11.715-14.947c-0.606-0.957-0.91-1.819-0.91-2.597c0-1.043,0.304-1.915,0.91-2.606
		c1.915-2.598,5.46-6.455,10.673-11.563c5.194-5.119,8.625-7.668,10.255-7.668c0.967,0,1.877,0.303,2.73,0.909l14.957,11.696
		c2.938-1.563,6.274-2.947,10.009-4.161c0.967-9.354,1.952-16.027,2.995-20.018c0.606-2.076,1.915-3.118,3.886-3.118h24.19
		c0.947,0,1.818,0.322,2.596,0.976c0.777,0.645,1.213,1.403,1.309,2.275l2.996,19.885c2.938,0.863,6.18,2.209,9.742,4.028
		l15.336-11.563c0.701-0.606,1.555-0.909,2.598-0.909c0.947,0,1.857,0.341,2.73,1.033c12.473,11.525,18.729,18.463,18.729,20.805
		c0,0.691-0.303,1.517-0.91,2.464c-1.061,1.384-2.881,3.726-5.459,7.023c-2.617,3.289-4.551,5.887-5.877,7.801
		c2.01,4.151,3.488,7.706,4.436,10.654l19.754,2.995c0.871,0.17,1.592,0.625,2.199,1.364
		C250.834,155.249,251.137,156.092,251.137,157.05z M334.358,226.336v18.199c0,1.384-6.482,2.729-19.373,4.037
		c-1.063,2.332-2.352,4.588-3.906,6.749c4.398,9.781,6.635,15.771,6.635,17.933c0,0.342-0.189,0.645-0.529,0.91
		c-10.578,6.161-15.963,9.232-16.113,9.232c-0.684,0-2.693-2.028-5.99-6.104c-3.299-4.075-5.537-7.033-6.75-8.853
		c-1.744,0.189-3.033,0.266-3.904,0.266c-0.873,0-2.162-0.076-3.904-0.266c-1.215,1.819-3.451,4.777-6.75,8.853
		c-3.299,4.076-5.307,6.104-5.99,6.104c-0.15,0-5.535-3.071-16.113-9.232c-0.34-0.266-0.531-0.568-0.531-0.91
		c0-2.161,2.238-8.151,6.637-17.933c-1.555-2.161-2.844-4.417-3.906-6.749c-12.891-1.308-19.373-2.653-19.373-4.037v-18.199
		c0-1.384,6.482-2.729,19.373-4.037c1.137-2.503,2.426-4.759,3.906-6.749c-4.398-9.801-6.637-15.771-6.637-17.933
		c0-0.36,0.191-0.664,0.531-0.91c0.342-0.189,1.857-1.062,4.551-2.616c2.691-1.555,5.23-3.033,7.658-4.417
		c2.426-1.384,3.754-2.066,3.904-2.066c0.684,0,2.691,2.01,5.99,6.028c3.299,4.038,5.535,6.957,6.75,8.777
		c1.742-0.171,3.031-0.246,3.904-0.246c0.871,0,2.16,0.075,3.904,0.246c4.398-6.161,8.418-11.014,11.943-14.559l0.797-0.247
		c0.34,0,5.725,3.033,16.113,9.1c0.34,0.246,0.529,0.55,0.529,0.91c0,2.161-2.236,8.132-6.635,17.933
		c1.479,1.99,2.768,4.246,3.906,6.749C327.875,223.606,334.358,224.952,334.358,226.336z M334.358,93.223v18.198
		c0,1.394-6.482,2.729-19.373,4.028c-1.063,2.341-2.352,4.597-3.906,6.768c4.398,9.791,6.635,15.771,6.635,17.933
		c0,0.351-0.189,0.654-0.529,0.91c-10.578,6.151-15.963,9.232-16.113,9.232c-0.684,0-2.693-2.038-5.99-6.114
		c-3.299-4.066-5.537-7.014-6.75-8.834c-1.744,0.171-3.033,0.256-3.904,0.256c-0.873,0-2.162-0.085-3.904-0.256
		c-1.215,1.82-3.451,4.768-6.75,8.834c-3.299,4.076-5.307,6.114-5.99,6.114c-0.15,0-5.535-3.081-16.113-9.232
		c-0.34-0.256-0.531-0.56-0.531-0.91c0-2.161,2.238-8.142,6.637-17.933c-1.555-2.171-2.844-4.427-3.906-6.768
		c-12.891-1.299-19.373-2.635-19.373-4.028V93.223c0-1.384,6.482-2.73,19.373-4.028c1.137-2.512,2.426-4.768,3.906-6.759
		c-4.398-9.791-6.637-15.771-6.637-17.942c0-0.341,0.191-0.645,0.531-0.909c0.342-0.171,1.857-1.033,4.551-2.598
		c2.691-1.555,5.23-3.033,7.658-4.417c2.426-1.384,3.754-2.085,3.904-2.085c0.684,0,2.691,2.019,5.99,6.047s5.535,6.957,6.75,8.777
		c1.742-0.171,3.031-0.266,3.904-0.266c0.871,0,2.16,0.095,3.904,0.266c4.398-6.151,8.418-11.005,11.943-14.559l0.797-0.266
		c0.34,0,5.725,3.033,16.113,9.1c0.34,0.265,0.529,0.568,0.529,0.909c0,2.171-2.236,8.151-6.635,17.942
		c1.479,1.991,2.768,4.247,3.906,6.759C327.875,90.492,334.358,91.839,334.358,93.223z"/>
	<path id="clock" fill="currentColor" d="M426.628,228.717c3.191,0,5.589,0,8.765-0.794c0.795,5.574,2.396,11.162,4.779,15.147
		c-3.984,0.793-8.764,1.588-13.544,1.588c-43.837,0-79.703-35.866-79.703-79.702c0-43.837,35.866-79.703,79.703-79.703
		c43.837,0,79.703,35.866,79.703,79.703c0,3.985-0.637,7.729-1.275,11.955l-0.313,1.588c-4.793-2.383-9.572-3.985-15.146-4.78
		c0.795-3.183,0.795-5.574,0.795-8.764c0-35.073-28.69-63.762-63.763-63.762c-35.07,0-63.762,28.689-63.762,63.762
		C362.867,200.027,391.558,228.717,426.628,228.717 M430.613,167.345l24.707,14.345c-3.19,2.397-6.368,4.78-8.767,7.97
		l-27.896-16.734v-47.821h11.955V167.345 M472.382,210.784l10.039-21.918l9.963,21.918l21.918,9.963l-21.918,10.04l-9.963,21.841
		l-10.039-21.841l-21.842-10.04L472.382,210.784z"/>
</g>
</svg>
</template>