<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1000px"
	 height="1000px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
<g id="chart">
	<path fill="#010202" d="M213.656,786.344V172.75H172.75v654.5h654.5v-40.906H213.656z"/>
	<path fill="#243F93" d="M245.656,500h163.625v245.438H245.656V500z M450.188,254.563h163.625v490.875H450.188V254.563z
		 M654.719,418.188h163.625v327.25H654.719V418.188z"/>
</g>
</svg>

</template>