
export const materialsInertiaLevel = {
  "wood": 0,//light
  "parget": 0,//light
  "monomurBrick": 0,//light
  "aeratedConcrete": 0,//light
  "brickWall": 0,//light
  "solidBrick": 1,//heavy
  "cinderBlock": 1,
  "concret": 1,//heavy
  "stone": 1,//heavy
  //--
  "unknow": 0,
  "carpet": 0
};

export const inertiaValues = [
  {label: 'Very heavy', value: 'veryHeavy', level: 3, coef: 0},
  {label: 'heavy', value: 'heavy', level: 2, coef: 1},
  {label: 'medium', value: 'medium', level: 1, coef: 2},
  {label: 'light', value: 'light', level: 0, coef: 3},
]

export const yearsConstructionLevel = [
  // {value: '1974-', label: "avant 1974", level: 0},
  // {value: '1974-1982', label: "entre 1974 et 1982", level: 0},
  // {value: '1983-1989', label: "entre 1983 et 1989", level: 0},
  // {value: '1990-2000', label: "entre 1990 et 2000", level: 0},
  // {value: '2001-2006', label: "entre 2001 et 2006", level: 1},
  // {value: '2007-2012', label: "entre 2007 et 2012", level: 1},
  // {value: '2012+', label: "après 2012", level: 2},
  {value: '1974-', label: "avant 1974", level: 0},
  {value: '1974-2000', label: "entre 1974 et 2000", level: 0},
  {value: '2001-2012', label: "entre 2001 et 2012", level: 1},
  {value: '2012+', label: "après 2012", level: 2},
];

export const getPeriodInsulationInfo = (period) => {
  return yearsConstructionLevel.find((item) => item.value === period) || {value: 'unknow', label: 'Ne sais pas', level: 0};
};

export const insulationValues = [
  {label: 'good', value: 'good', level: 2, coef: 0},
  {label: 'medium', value: 'medium', level: 1, coef: 1},
  {label: 'bad', value: 'bad', level: 0, coef: 2},
];

export const getInsulationInfo = (value, varname = 'label') => {
  return insulationValues.find(item => item[varname] === value);
}