<template>

<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<path id="LC" fill="currentColor" d="M383.506,243.515h-8.35V53.144c0-5.531-4.488-10.02-10.02-10.02H224.863
	c-5.531,0-10.02,4.488-10.02,10.02v190.371h-8.35c-2.783,0-5.01,2.244-5.01,5.01v8.35h187.031v-8.35
	C388.516,245.759,386.254,243.515,383.506,243.515z M254.922,74.853c0-2.766,2.227-5.01,5.01-5.01h16.699
	c2.748,0,5.01,2.244,5.01,5.01v16.699c0,2.766-2.262,5.01-5.01,5.01h-16.699c-2.783,0-5.01-2.244-5.01-5.01V74.853z
	 M254.922,114.931c0-2.766,2.227-5.01,5.01-5.01h16.699c2.748,0,5.01,2.244,5.01,5.01v16.699c0,2.766-2.262,5.01-5.01,5.01h-16.699
	c-2.783,0-5.01-2.244-5.01-5.01V114.931z M276.631,176.718h-16.699c-2.783,0-5.01-2.244-5.01-5.01v-16.699
	c0-2.766,2.227-5.01,5.01-5.01h16.699c2.748,0,5.01,2.244,5.01,5.01v16.699C281.641,174.474,279.379,176.718,276.631,176.718z
	 M308.359,243.515h-26.719v-35.068c0-2.766,2.227-5.01,5.01-5.01h16.699c2.748,0,5.01,2.244,5.01,5.01V243.515z M335.078,171.708
	c0,2.766-2.262,5.01-5.01,5.01h-16.699c-2.783,0-5.01-2.244-5.01-5.01v-16.699c0-2.766,2.227-5.01,5.01-5.01h16.699
	c2.748,0,5.01,2.244,5.01,5.01V171.708z M335.078,131.63c0,2.766-2.262,5.01-5.01,5.01h-16.699c-2.783,0-5.01-2.244-5.01-5.01
	v-16.699c0-2.766,2.227-5.01,5.01-5.01h16.699c2.748,0,5.01,2.244,5.01,5.01V131.63z M335.078,91.552c0,2.766-2.262,5.01-5.01,5.01
	h-16.699c-2.783,0-5.01-2.244-5.01-5.01V74.853c0-2.766,2.227-5.01,5.01-5.01h16.699c2.748,0,5.01,2.244,5.01,5.01V91.552z"/>
</svg>

</template>