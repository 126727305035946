<template>
  <div>
    <p>
      La sensation de chaleur au sein d’un bâtiment est accentuée par différents
      facteurs : les aménagements urbains dans son environnement proche, ainsi
      que les propriétés des matériaux le constituant (ou qui en sont proche).
      Intégrer davantage la notion de biodiversité (au sein même de milieux très
      urbanisés), tout comme penser à l’emploi de l’eau comme rafraîchissement
      urbain, est nécessaire.
    </p>

    <p>
      En effet, un environnement très végétalisé (avec la présence de sol
      naturel et d’eau) favorisera les phénomènes d’évapotranspiration et
      d’évaporation qui contribuent naturellement au rafraîchissement urbain.
    </p>

    <div>
      Pour aller plus loin :

      <ul>
        <li>
          <a
            href="https://lab.cercle-promodul.inef4.org/knowledge/post/ilot-de-chaleur-urbain"
            target="_blank"
          >Îlot de chaleur urbain</a> - Cercle Promodul / INEF4
        </li>
      </ul>
    </div>
  </div>
</template>
