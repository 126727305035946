// import {getObjectValueFromString} from '../functions/utils.js';

export const recoTvx = [
  {
    id:"rt-1",
    order: 1,
    theme: 'ENVIR',
    poste: 'env',
    title: "Surfaces extérieures végétalisées ou en bois",
    description: "L’environnement proche du bâtiment est important en cas de forte chaleur. Les surfaces minéralisées stockent la chaleur et accentuent le phénomène. Favoriser les surfaces végétalisées (arbres, arbustes, gazon, ou les terrasses en bois) limitera l’élévation rapide de la température et moins de chaleur sera transmise au bâtiment. De plus, l’évapotranspiration de la végétation va rafraîchir l’air aux abords.",
    conditions: [
      // {
      //   andOr: null,
      //   simuDataPath: 'buildingConfig.buildingProjectState',
      //   compType: "std",
      //   comp: '===',
      //   value: 'new',
      // },
      // {
      //   andOr: '&&',
      //   simuDataPath: 'environment.externalGroundType',
      //   compType: "std",
      //   comp: '!==',
      //   value: 'revegetation',
      // }
      {
        andOr: null,
        simuDataPath: 'environment.externalGroundType',
        compType: "fx",
        fx: {
          isNegative: true,
          name: 'includes()',
        },
        comp: null,
        value: "['revegetation', 'woodenTerrace']",
      }
    ]
  },

  {
    id:'rt-2',
    order: 1,
    theme: 'ENVEL',
    poste: 'roof',
    title: "Toiture végétalisée (pour une pente inférieure à 20%)",
    description: "La végétalisation fera office de barrière protectrice pour le bâtiment et permettra de limiter le stockage de la chaleur du jour en réduisant la température de la paroi du bâtiment. Elle permet également d'améliorer l'inertie thermique du bâtiment et atténue l'effet d'îlot de chaleur urbain (en ville notamment dans des espaces très minéralisés).",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingRoof.roofType',
        compType: "std",
        comp: '===',
        value: 'flat',
      }
    ]
  },

  {
    id:'rt-3',
    order: 12,
    theme: 'ENVEL',
    poste: 'roof',
    title: "Revêtement d'étanchéité de couleur claire",
    description: "Les toitures et les terrasses des bâtiments sont très fortement exposées au rayonnement solaire direct. La nature des matériaux qui les composent et leur couleur peuvent avoir un effet sur l’impact du rayonnement : plus un matériau tend vers une couleur claire (jusqu’à blanche), plus il réfléchit le rayonnement solaire direct, et moins il stocke d’énergie sous forme de chaleur.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingRoof.roofType',
        compType: "std",
        comp: '===',
        value: 'flat',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingRoof.roofColor',
        compType: "std",
        comp: '!==',
        value: 'light',
      }
    ]
  },

  {
    id:'rt-4',
    order: 8,
    theme: 'ENVEL',
    poste: 'roof',
    title: "Isolation thermique de la toiture",
    description: "Les toitures sont très fortement exposées au rayonnement solaire direct. L’isolation thermique va permettre de contenir l’élévation de la température de l’air intérieur par la chaleur qui sera rayonnée et transmise. Les ventilations obligatoires en sous face de couverture pour les toitures en pente contribuent à évacuer une partie de cette chaleur. Pour les toitures plates la pose de l’isolant sur la structure l’empêche d’être soumise au rayonnement direct.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'new',
      },
      {
        andOr: '&&',
        simuDataPath: 'calculated.debug.inertia.isroofITE',
        compType: "std",
        comp: "===",
        value: "false",
        
      },
      {
        andOr: '&&',
        simuDataPath: 'calculated.debug.inertia.sides.roof.materialInertia',
        compType: "std",
        comp: '===',
        value: '1',
      }
    ]
  },

  // {
  //   id:'rt-4',
  //   theme: 'ENVEL',
  //   poste: 'roof',
  //   title: "Priviligier ITE",
  //   description: null,
  //   conditions: [
  //     {
  //       andOr: null,
  //       simuDataPath: 'buildingConfig.buildingProjectState',
  //       compType: "std",
  //       comp: '===',
  //       value: 'new',
  //     },
  //     {
  //       andOr: '&&',
  //       simuDataPath: 'calculated.debug.inertia.isroofITE',
  //       compType: "std",
  //       comp: "===",
  //       value: "false",
        
  //     },
  //     {
  //       andOr: '&&',
  //       simuDataPath: 'calculated.debug.inertia.sides.roof.materialInertia',
  //       compType: "std",
  //       comp: '===',
  //       value: '1',
  //     }
  //   ]
  // },

  {
    id:'rt-5',
    order: 11,
    theme: 'ENVEL',
    poste: 'roof',
    title: "Tuiles de couleur claire",
    description: "Les toitures des bâtiments sont très fortement exposées au rayonnement solaire direct. La nature des matériaux qui les composent et leur couleur peuvent avoir un effet sur l’impact du rayonnement : plus un matériau tend vers une couleur claire (jusqu’à blanche), plus il réfléchit le rayonnement solaire direct, et moins il stocke d’énergie sous forme de chaleur.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingRoof.roofType',
        compType: "std",
        comp: "===",
        value: "lost",
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingRoof.roofColor',
        compType: "std",
        comp: "!==",
        value: "light",
        
      },
    ]
  },

  {
    id:'rt-6',
    order: 7,
    theme: 'ENVEL',
    poste: 'roof',
    title: "Isolation thermique des combles perdus",
    description: "Les toitures sont très fortement exposées au rayonnement solaire direct. L’isolation thermique des combles perdues doit se faire sur le plancher et l’espace offert doit permettre de maximiser l’épaisseur d’isolant à mettre en œuvre. Il faut au minimum que l’isolant et l’épaisseur retenue permettent une résistance thermique de 7 m².K/W. La ventilation de la couverture et des combles est aussi indispensable (chatières de ventilation, closoir ventilé sur le faîtage de la toiture) ",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'new',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingRoof.roofType',
        compType: "std",
        comp: "===",
        value: "lost",
      },
      {
        andOr: '&&',
        simuDataPath: 'calculated.debug.inertia.isroofITE',
        compType: "std",
        comp: "===",
        value: "false",
        
      },
      {
        andOr: '&&',
        simuDataPath: 'calculated.debug.inertia.sides.roof.materialInertia',
        compType: "std",
        comp: '===',
        value: '1',
      }
    ]
  },

  {
    id:'rt-7',
    order: 10,
    theme: 'ENVEL',
    poste: 'roof',
    title: "Tuiles de couleur claire",
    description: "Les toitures des bâtiments sont très fortement exposées au rayonnement solaire direct. La nature des matériaux qui les composent et leur couleur peuvent avoir un effet sur l’impact du rayonnement : plus un matériau tend vers une couleur claire (jusqu’à blanche), plus il réfléchit le rayonnement solaire direct, et moins il stocke d’énergie sous forme de chaleur.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingRoof.roofType',
        compType: "std",
        comp: "===",
        value: "converted",
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingRoof.roofColor',
        compType: "std",
        comp: "!==",
        value: "light",
      },
    ]
  },

  {
    id:'rt-8',
    order: 9,
    theme: 'ENVEL',
    poste: 'walls',
    title: "Isolation thermique par l'extérieur des parois",
    description: "Les murs, selon leur orientation, sont très fortement exposés au rayonnement solaire direct. L’isolation thermique par l’extérieur lorsque c’est possible va permettre de contenir l’élévation de la température de l’air intérieur. De plus elle favorise le confort intérieur et limite les besoins de refroidissement surtout si les parois intérieures sont à forte inertie thermique (comme en béton, briques, pierre, terre-crue...).",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'new',
      },
      {
        andOr: '&&',
        simuDataPath: 'calculated.debug.inertia.isWallsITE',
        compType: "std",
        comp: "===",
        value: "false",
        
      },
      {
        andOr: '&&',
        simuDataPath: 'calculated.debug.inertia.sides.roof.materialInertia',
        compType: "std",
        comp: '===',
        value: '1',
      }
    ]
  },

  {
    id:'rt-9',
    order: 13,
    theme: 'ENVEL',
    poste: 'walls',
    title: "Murs extérieurs de couleur claire",
    description: "La mise en place de revêtement à albédo élevé - le plus souvent de couleur claire - sur les murs est une solution efficace qui permet de réfléchir une partie de l’énergie plutôt que la stocker dans les parois.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingWalls.wallsComposition',
        compType: "fx",
        fx: {
          isNegative: true,
          name: 'includes()',
        },
        comp: null,
        value: "['stone', 'solidBrick']",
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingWalls.wallsColor',
        compType: "std",
        comp: "!==",
        value: "light",
        
      }
    ]
  },

  {
    id:'rt-10',
    order: 2,
    theme: 'WINDO',
    poste: 'windows',
    title: "Gestion automatisée des protections solaires et/ou occultants",
    description: "L’automatisation du contrôle des protections solaires (volets battants/roulants/coulissants, brise-soleils orientables, stores) grâce à un système actif de gestion, augmentera leurs effets bénéfiques en tenant compte de la température extérieure, du niveau de luminosité et rayonnement, des conditions météo, de la qualité de l’air etc. Le pilotage des occultants étant adapté selon l’intensité du flux solaire et la température intérieure, l’irradiation solaire sera bloquée au plus tôt pour limiter l’élévation de température dans la pièce. Le fonctionnement automatique pendant la journée et l’absence d’occupants pour manœuvrer les protections solaires limitent significativement l’élévation des températures intérieures.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingWindows.windowsOrientations',
        compType: "fx",
        fx: {
          isNegative: true,
          name: 'includes()',
        },
        comp: null,
        value: "['N', 'NE', 'NW']",
        
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingWindows.windowsProtectionControl',
        compType: "std",
        comp: '!==',
        value: 'auto',
      }
    ]
  },

  //-- deleted
  // {
  //   id:'rt-11',
  //   theme: 'WINDO',
  //   poste: 'windows',
  //   title: "Priviligier masque à l'extérieur",
  //   description: null,
  //   conditions: [
  //     {
  //       andOr: null,
  //       simuDataPath: 'buildingWindows.windowsOrientations',
  //       compType: "fx",
  //       fx: {
  //         isNegative: true,
  //         name: 'includes()',
  //       },
  //       comp: null,
  //       value: "['N', 'NE', 'NW']",
        
  //     },
  //     {
  //       andOr: '&&',
  //       simuDataPath: 'buildingWindows.windowsProtectionType',
  //       compType: "std",
  //       comp: '===',
  //       value: 'int',
  //     }
  //   ]
  // },

  {
    id:'rt-12',
    order: 6,
    theme: 'ENVEL',
    poste: 'roof',
    title: "Isolation thermique des combles aménagés",
    description: "Initialement, les combles étaient des espaces tampons, dans lesquels l’air circulait librement. Aujourd’hui, très souvent, ils sont aménagés. Situé sous la toiture fortement soumise au rayonnement solaire direct la température intérieure peut devenir très rapidement intenable. Une isolation renforcée (avec un R minimum de 6 m².K/W) associée à des systèmes de ventilation et surventilation, de protections solaires efficaces des fenêtres de toits, du choix des revêtements contribuera à réduire l’inconfort lié à l’aménagement de cette pièce.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'new',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingRoof.roofType',
        compType: "std",
        comp: "===",
        value: "converted",
      }
    ]
  },

  // -- deleted
  // {
  //   id:'rt-13',
  //   theme: 'WINDO',
  //   poste: 'windows',
  //   title: "Verre à protection solaire",
  //   description: null,
  //   conditions: [
  //     {
  //       andOr: null,
  //       simuDataPath: 'buildingConfig.buildingProjectState',
  //       compType: "std",
  //       comp: '===',
  //       value: 'new',
  //     },
  //   ]
  // },

  {
    id:'rt-14',
    order: 5,
    theme: 'WINDO',
    poste: 'windows',
    title: "Création d’une ouverture supplémentaire (si cela est possible)",
    description: "Un bâtiment traversant, c'est-à-dire disposant d’ouvertures vitrées sur au moins deux façades d’orientation différente, permet une meilleure ventilation la nuit, évacuant ainsi la chaleur durant les heures les plus fraiches de la nuit grâce aux courants d'air.",
    conditions: [
      // {
      //   andOr: null,
      //   simuDataPath: 'buildingConfig.buildingProjectState',
      //   compType: "std",
      //   comp: '===',
      //   value: 'new',
      // },
      {
        andOr: null,
        simuDataPath: 'buildingWindows.windowsRoomCrossover',
        compType: "std",
        comp: '!==',
        value: 'true',
      },
    ]
  },

  //--rt-15 deleted

  {
    id:'rt-16',
    order: 1,
    theme: 'VENTIL',
    poste: 'users',
    title: "Surventilation nocturne (ventilation naturelle)",
    description: "Si l’environnement proche du bâtiment le permet, la surventilation en ventilation naturelle est très efficace : ventiler la nuit, lorsque les températures extérieures sont plus fraîches, permet d’évacuer la chaleur emmagasinée durant la journée. Cela est particulièrement intéressant dans les logements traversants mais également par l’ouverture d’une fenêtre au rez-de-chaussée et d’une seconde en partie haute à l’étage.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingType',
        compType: "std",
        comp: '===',
        value: 'MI',
      },
      {
        andOr: "&&",
        simuDataPath: 'environment.userBehaviorVentil',
        compType: "std",
        comp: '!==',
        value: 'good',
      },
    ]
  },

  // {
  //   id:'rt-17',
  //   theme: 'Comportement',
  //   poste: 'Protections solaires',
  //   title: "Améliorer",
  //   description: null,
  //   conditions: [
  //     {
  //       andOr: null,
  //       simuDataPath: 'buildingWindows.userBehaviorSolarProtection',
  //       compType: "std",
  //       comp: '!==',
  //       value: 'good',
  //     },
  //   ]
  // },

  // {
  //   id:'rt-18',
  //   theme: 'Comportement',
  //   poste: 'Equipements électriques',
  //   title: "Améliorer",
  //   description: null,
  //   conditions: [
  //     {
  //       andOr: null,
  //       simuDataPath: 'buildingWindows.userBehaviorElecEquipments',
  //       compType: "std",
  //       comp: '!==',
  //       value: 'good',
  //     },
  //   ]
  // },

  {
    id:'rt-19',
    order: 1,
    theme: 'EQUIP',
    poste: 'vmc',
    title: "Brasseur d'air fixe",
    description: "Un brasseur d'air fixe permet d'abaisser la température intérieure ressentie par les occupants grâce au déplacement d’air. Une vitesse d’air bien dimensionnée permettra d’obtenir une sensation de brise agréable, sans une consommation énergétique trop importante. Attention cependant, son utilisation n'est utile que si l'occupant est présent dans la pièce en question.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingGearsAndBehaviours.isAirBlower',
        compType: "std",
        comp: '===',
        value: 'false',
      },
      // {
      //   andOr: null,
      //   simuDataPath: 'buildingConfig.buildingProjectState',
      //   compType: "std",
      //   comp: '===',
      //   value: 'new',
      // },
      // {
      //   andOr: '||',
      //   simuDataPath: 'buildingConfig.buildingProjectState',
      //   compType: "std",
      //   comp: '===',
      //   value: 'existing',
      // },
    ]
  },

  {
    id:'rt-20',
    order: 2,
    theme: 'EQUIP',
    poste: 'vmc',
    title: "Equipement de surventilation (ventilation mécanique)",
    description: "L’installation d’une ventilation mécanique avec option de surventilation (par exemple VMC double flux, VMI par insufflation) permet de rafraichir la température ambiante la nuit, notamment quand l’environnement du bâtiment ne permet pas une ouverture des fenêtres. Sur-ventiler la nuit, lorsque les températures extérieures sont plus fraîches, permet d’évacuer la chaleur emmagasinée durant la journée.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingGearsAndBehaviours.overventilationType',
        compType: "std",
        comp: '===',
        value: 'none',
      }
    ]
  },

  {
    id:'rt-21',
    order: 3,
    theme: 'EQUIP',
    poste: 'vmc',
    title: "Puits climatique couplé à un système de ventilation ",
    description: "Le puits climatique est une solution permettant d'insuffler de l'air frais dans le bâtiment. L’air extérieur, au lieu d’être insufflé directement dans le bâtiment (comme c’est le cas pour la ventilation), va se rafraîchir par son passage dans les conduits sous terre, profitant de la fraicheur naturelle du sol.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingType',
        compType: "std",
        comp: '!==',
        value: 'LC',
      }
    ]
  },

  {
    id:'rt-22',
    order: 3,
    theme: 'WINDO',
    poste: 'windows',
    title: "Gestion automatisée d’ouverture des fenêtres",
    description: "Un système d'automatisation et de gestion programmée d'ouverture des fenêtres permet le renouvellement de l'air intérieur tout en assurant des effets de ventilation (et de surventilation nocturne) pendant les épisodes de chaleur. Certains systèmes intègrent également un détecteur anti-intrusion.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'new',
      },
    ]
  },

  {
    id:'rt-23',
    order: 4,
    theme: 'EQUIP',
    poste: 'vmc',
    title: "Puits rafraichissant",
    description: "Le puits rafraichissant est une solution permettant de faire circuler l’été l’eau du système de chauffage (par le circulateur) dans les fondations du bâtiment pour être rafraichie. L'eau est refroidie par son passage dans les conduits sous terre, profitant de la fraicheur naturelle du sol et de l’inertie lourde des fondations en béton. Ce liquide recircule ensuite en plafond dans les émetteurs déjà installés (servant au chauffage l’hiver).",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'new',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingConfig.buildingType',
        compType: "std",
        comp: '===',
        value: 'MI',
      }
    ]
  },

  {
    id:'rt-24',
    order: 2,
    theme: 'ENVEL',
    poste: 'roof',
    title: "Isolation thermique de la toiture",
    description: "Les toitures sont très fortement exposées au rayonnement solaire direct. L’isolation thermique va permettre de contenir l’élévation de la température de l’air intérieur par la chaleur qui sera rayonnée et transmise. Les ventilations obligatoires en sous face de couverture pour les toitures en pente contribuent à évacuer une partie de cette chaleur. Pour les toitures plates la pose de l’isolant sur la structure l’empêche d’être soumise au rayonnement direct.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'existing',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingConfig.buildingConstructionYear',
        compType: "std",
        comp: '<',
        value: '2000',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingRoof.roofType',
        compType: "std",
        comp: '===',
        value: 'flat',
      },
      {
        andOr: "&&",
        conditions: [
          {
            andOr: null,
            simuDataPath: 'buildingRoof.roofInsulationImprovement',
            compType: "std",
            comp: '<',
            value: '2000',
          },
          {
            andOr: "||",
            simuDataPath: 'buildingRoof.roofInsulationImprovement',
            compType: "fx",
            fx: {
              isNegative: false,
              name: 'includes()',
            },
            comp: null,
            value: "['none', 'unknow']",
          },
        ]
      }
    ]
  },

  {
    id:'rt-25',
    order: 3,
    theme: 'ENVEL',
    poste: 'roof',
    title: "Isolation thermique des combles perdus",
    description: "Les toitures sont très fortement exposées au rayonnement solaire direct. L’isolation thermique des combles perdues doit se faire sur le plancher et l’espace offert doit permettre de maximiser l’épaisseur d’isolant à mettre en œuvre. Il faut au minimum que l’isolant et l’épaisseur retenue permettent une résistance thermique de 7 m².K/W La ventilation de la couverture et des combles est aussi indispensable (chatières de ventilation, closoir ventilé sur le faîtage de la toiture).",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'existing',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingConfig.buildingConstructionYear',
        compType: "std",
        comp: '<',
        value: '2000',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingRoof.roofType',
        compType: "std",
        comp: '===',
        value: 'lost',
      },
      {
        andOr: "&&",
        conditions: [
          {
            andOr: null,
            simuDataPath: 'buildingRoof.roofInsulationImprovement',
            compType: "std",
            comp: '<',
            value: '2000',
          },
          {
            andOr: "||",
            simuDataPath: 'buildingRoof.roofInsulationImprovement',
            compType: "fx",
            fx: {
              isNegative: false,
              name: 'includes()',
            },
            comp: null,
            value: "['none', 'unknow']",
          },
        ]
      }
    ]
  },

  {
    id:'rt-26',
    order: 4,
    theme: 'ENVEL',
    poste: 'roof',
    title: "Isolation thermique des combles aménagés",
    description: "Initialement, les combles étaient des espaces tampons, dans lesquels l’air circulait librement. Aujourd’hui, très souvent, ils sont aménagés. Situé sous la toiture fortement soumise au rayonnement solaire direct la température intérieure peut devenir très rapidement intenable. Une isolation renforcée (avec un R minimum de 6 m².K/W) associée à des systèmes de ventilation et surventilation, de protections solaires efficaces des fenêtres de toits, du choix des revêtements contribuera à réduire l’inconfort lié à l’aménagement de cette pièce.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'existing',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingConfig.buildingConstructionYear',
        compType: "std",
        comp: '<',
        value: '2000',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingRoof.roofType',
        compType: "std",
        comp: '===',
        value: 'converted',
      },
      {
        andOr: "&&",
        conditions: [
          {
            andOr: null,
            simuDataPath: 'buildingRoof.roofInsulationImprovement',
            compType: "std",
            comp: '<',
            value: '2000',
          },
          {
            andOr: "||",
            simuDataPath: 'buildingRoof.roofInsulationImprovement',
            compType: "fx",
            fx: {
              isNegative: false,
              name: 'includes()',
            },
            comp: null,
            value: "['none', 'unknow']",
          },
        ]
      }
    ]
  },

  {
    id:'rt-27',
    order: 5,
    theme: 'ENVEL',
    poste: 'walls',
    title: "Isolation thermique par l'extérieur des parois",
    description: "Les murs, selon leur orientation, sont très fortement exposés au rayonnement solaire direct. L’isolation thermique par l’extérieur lorsque c’est possible va permettre de contenir l’élévation de la température de l’air intérieur. De plus elle favorise le confort intérieur et limite les besoins de refroidissement surtout si les parois intérieures sont à forte inertie thermique (comme en béton, briques, pierre, terre-crue...).",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'existing',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingConfig.buildingConstructionYear',
        compType: "std",
        comp: '<',
        value: '2000',
      },
      {
        andOr: "&&",
        conditions: [
          {
            andOr: null,
            simuDataPath: 'buildingWalls.wallsInsulationImprovement',
            compType: "std",
            comp: '<',
            value: '2000',
          },
          {
            andOr: "||",
            simuDataPath: 'buildingWalls.wallsInsulationImprovement',
            compType: "fx",
            fx: {
              isNegative: false,
              name: 'includes()',
            },
            comp: null,
            value: "['none', 'unknow']",
          },
        ]
      }
    ]
  },

  {
    id:'rt-28',
    order: 4,
    theme: 'WINDO',
    poste: 'windows',
    title: "Verres à contrôle solaire",
    description: "Les verres à contrôle solaire permettent de filtrer la chaleur du rayonnement solaire. Composé d’un verre revêtu d’une couche transparente de métaux, le verre réfléchit l’énergie du rayonnement solaire vers l’extérieur et réduit les entrées de chaleur solaire tout en gardant un bon niveau de luminosité.",
    conditions: [
      // {
      //   andOr: null,
      //   simuDataPath: 'buildingConfig.buildingProjectState',
      //   compType: "std",
      //   comp: '===',
      //   value: 'existing',
      // },
      {
        andOr: null,
        simuDataPath: 'buildingWindows.windowsOrientations',
        compType: "fx",
        fx: {
          isNegative: true,
          name: 'includes()',
        },
        comp: null,
        value: "['N', 'NE', 'NW']",
      },
      {
        andOr: "&&",
        simuDataPath: 'buildingWindows.windowsGlassType',
        compType: "std",
        comp: '===',
        value: 'SV',
        
      },
    ]
  },

  // {
  //   id:'rt-29',
  //   theme: 'VENTIL',
  //   poste: 'vmc',
  //   title: "Mise en place de protection solaire Exterieur anti-intrusion ",
  //   description: null,
  //   conditions: [
  //     {
  //       andOr: null,
  //       simuDataPath: 'environment.canOpenWindowsNight',
  //       compType: "std",
  //       comp: '===',
  //       value: false,
  //     },
  //     {
  //       andOr: '&&',
  //       simuDataPath: 'buildingConfig.buildingNbLevelTotal',
  //       compType: "std",
  //       comp: '<=',
  //       value: 1,
        
  //     },
  //   ]
  // },

  {
    id:'rt-30',
    order: 1,
    theme: 'WINDO',
    poste: 'windows',
    title: "Protections solaires",
    description: "Les surfaces vitrées peuvent être facteur d’une élévation significative de la température intérieure si pleinement exposées au soleil. L’installation et l'usage régulier des protections solaires sont donc essentiels pour diminuer l'apport de chaleur. Cela peut-être dans un premier temps l’installation et l’utilisation des stores intérieurs, mais le plus pertinent et efficace reste les volets, brises soleil extérieurs, masques, stores, volets, stores-banne (de couleur clair et en bois de préférence pour les volets).",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingWindows.windowsOrientations',
        compType: "fx",
        fx: {
          isNegative: true,
          name: 'includes()',
        },
        comp: null,
        value: "['N', 'NE', 'NW']",
        
      },
      {
        andOr: "&&",
        conditions: [
          {
            andOr: null,
            simuDataPath: 'buildingWindows.windowsProtectionType',
            compType: "std",
            comp: '!==',
            value: 'ext',
          },
          {
            andOr: "||",
            simuDataPath: 'buildingWindows.userBehaviorSolarProtection',
            compType: "std",
            comp: '!==',
            value: 'good',
          }
        ]
      },
    ]
  },

  {
    id:'rt-31',
    order: 1,
    theme: 'VENTIL',
    poste: 'vmc',
    title: "Surventilation nocturne (ventilation naturelle)",
    description: "Si l’environnement proche du bâtiment le permet, la surventilation en ventilation naturelle est très efficace : ventiler la nuit, lorsque les températures extérieures sont plus fraîches, permet d’évacuer la chaleur emmagasinée durant la journée. Cela est particulièrement intéressant dans les logements traversants.",
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingType',
        compType: "std",
        comp: '===',
        value: 'LC',
      },
      {
        andOr: "&&",
        simuDataPath: 'environment.userBehaviorVentil',
        compType: "std",
        comp: '!==',
        value: 'good',
      },
    ]
  },

  {
    id:'rt-32',
    order: 5,
    theme: 'EQUIP',
    poste: 'clim',
    title: "Sobriété dans l'utilisation de la climatisation",
    description: `Recourir à un appareil de climatisation requiert un bon usage du système en :
    <ul>
    <li>climatisant uniquement la ou les pièces qui sont occupées ;</li>
    <li>pensant à bien éteindre les systèmes s’il n’y a pas d’occupant dans la pièce ou dans le bâtiment ;</li>
    <li>opérant une température de consigne égale ou supérieure à 26°C (d’autant que le corps humain tolère difficilement des variations de températures supérieures à 10°C entre l’air intérieur et extérieur, pouvant également engendrer des chocs thermiques pour l’organisme).</li>
    </ul>`,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingGearsAndBehaviours.isClim',
        compType: "std",
        comp: '===',
        value: 'true',
      }
    ]
  },


]