<template>

<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="MI" fill="currentColor">
	<path d="M304.163,96.417c-5.063-5.061-13.264-5.061-18.327,0l-86.146,86.129c-2.531,2.532-2.531,6.649,0,9.18
		c2.548,2.532,6.633,2.532,9.18,0l0,0L295,105.581l86.13,86.146c2.532,2.532,6.649,2.532,9.18,0c2.532-2.531,2.532-6.647,0-9.18
		l-30.51-30.493v-42.677c0-3.577-2.902-6.479-6.48-6.479h-12.959c-3.576,0-6.481,2.903-6.481,6.479v16.758L304.163,96.417z"/>
	<path d="M295,119.655l77.759,77.76v54.523c0,10.732-8.706,19.44-19.44,19.44H236.68
		c-10.731,0-19.44-8.708-19.44-19.44v-54.523L295,119.655z"/>
</g>
</svg>

</template>