<template>
  <div class="tabs">
    <!-- tabs: <pre>{{ tabs }}</pre>
    selectedTabIndex: {{ selectedTabIndex }} -->
    <ul class="tabs__list">
      <li v-for="(tab, tabIndex) in tabs" :class="{ 'is-active': tab.isActive }" :key="`tab-${tabIndex}`" :id="`tab-${tabIndex}`">
        <a href="#" @click.prevent="setSelectedTab(tabIndex)">{{ tab.title }}</a>
      </li>
    </ul>
    <div class="tabs__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedTabIndex: null,
      tabs: []
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.setSelectedTab(0);
    },
    addTab(tab) {
      this.tabs.push(tab);
    },
    setSelectedTab (tabIndex) {
      if(this.isTabAllowed(tabIndex)) {
        this.selectedTabIndex = tabIndex;
      }
    },
    isTabAllowed (tabIndex) {
      if (this.tabs[tabIndex]?.isAllowed) {
        return true
      }
      return false
    },
    selectTab(selectedTabIndex) {
      this.tabs.forEach( (tab, tabIndex) => {

        if (tabIndex === selectedTabIndex) {
          console.log(tab);
          if (tab.isAllowed) {
            tab.activateTab();
            // return
          } else {
            alert('NO !')
            tab.deactivateTab();
          }
          
        } else {
          tab.deactivateTab();
        }
      });
    }
  },
  watch: {
    selectedTabIndex (newVal) {
      // alert('rrr' + newVal)
      this.selectTab(newVal)
    }
  }
};
</script>

