<template>


    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['windowsIsShadow']"
    v-model="theValue['windowsIsShadow']"
    :qstParams="getQstParam('windowsIsShadow')"
    />

    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['windowsGlassType']"
    v-model="theValue['windowsGlassType']"
    :qstParams="getQstParam('windowsGlassType')"
    />

    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['windowsOrientations']"
    v-model="theValue['windowsOrientations']"
    :qstParams="getQstParam('windowsOrientations')"
    />

    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['windowsProtectionType']"
    v-model="theValue['windowsProtectionType']"
    :qstParams="getQstParam('windowsProtectionType')"
    />

  <TransitionQst class="transition">
    <div v-if="showPorectionType">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['windowsProtectionControl']"
      v-model="theValue['windowsProtectionControl']"
      :qstParams="getQstParam('windowsProtectionControl')"
      />
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['userBehaviorSolarProtection']"
      v-model="theValue['userBehaviorSolarProtection']"
      :qstParams="getQstParam('userBehaviorSolarProtection')"
      />
    </div>
  </TransitionQst>


    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['windowsRoomCrossover']"
    v-model="theValue['windowsRoomCrossover']"
    :qstParams="getQstParam('windowsRoomCrossover')"
    />

</template>

<script setup>
  import { ref, watch, computed } from 'vue';
  import InputFormRowGlobal from './InputFormRowGlobal.vue';

  const props = defineProps({
  stepId: {
    type: Number,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  },
  formName: {
      type: String,
      required: true
    },
  formQst: {
    type: Array,
    required: true
  },
  validationObj: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);

const theValue = ref(props.modelValue[[props.formName]]);
watch (theValue, (newVal) => {
    emit('update:modelValue', newVal)
  });

const getQstParam = (varname) => {
  const param = props.formQst.find((qst) => qst.varname === varname);
  return param;
}

//-- FOR THE SPECIFIC FORM
const windowsProtectionType = computed(() => theValue.value['windowsProtectionType']);
const showPorectionType = computed(() => theValue.value['windowsProtectionType'] !== 'none');

watch(windowsProtectionType, (newVal) => {
  if(newVal === 'none') {
    theValue.value.windowsProtectionControl = 'manual';
    theValue.value.userBehaviorSolarProtection = 'bad';
  }
})

// Permet de forcer l'utilisateur à re-valider son choix
watch((showPorectionType), (newVal) => {
  if (newVal === true) {
    theValue.value.windowsProtectionControl = null;
    theValue.value.userBehaviorSolarProtection = null;
  }
})

</script>
