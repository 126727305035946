<template>
  <div>

    <div class="alert alert-info">
      <p style="font-style:italic">
      Cet outil ne tient pas compte de l’usage de systèmes de
      climatisation dans ses calculs pour évaluer le confort été. L’objectif
      étant de présenter des possibilités de rafraichissement passifs ou semi
      passifs (à faible consommation énergétique) pour limiter au maximum
      l’usage de la climatisation en période estivale.
    </p>
    </div>

    <p>
      Se tourner vers la climatisation comme moyen de rafraîchissement immédiat
      est très habituel, surtout en période de très fortes chaleurs.
    </p>
    <p>
      Mais un mauvais usage (température de consigne très basse, usage
      systématique, ouverture des fenêtres dans le même temps) constitue un
      non-sens :
    </p>
    <ul>
      <li>
        Les appareils de climatisation sont en effet très consommateurs en
        énergie, surtout si le bâtiment n’est pas bien isolé, ce qui sera
        contreproductif et n’atténuera pas la sensation d’inconfort sur le long
        terme.
      </li>
      <li>
        De plus qu’un usage répété contribue grandement au phénomène d’îlots de
        chaleur urbains en ville, accentuant encore davantage la sensation
        d’inconfort (puisque l’air chaud intérieur extrait est rejeté à
        l’extérieur du bâtiment, contribuant à surchauffer l’environnement
        direct du bâtiment).
      </li>
    </ul>
  </div>
</template>
