<template>
  <!-- <h1>Chart One</h1> -->

  <div id="chart">
    <apexchart type="radar" :options="chartOptions" :series="series" :height="500"></apexchart>
    <!-- datalabels: <pre>{{ dataLabels }}</pre>
    dataSeries: <pre>{{ dataSeries }}</pre> -->
  </div>
</template>

<script lang="js">

import VueApexCharts from 'vue3-apexcharts';

export default {
  el: '#app',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    dataLabels: {
      type: Array,
      required: true,
      default:  () => ['aa', 'bb','cc']
    },
    dataSeries: {
      type: Array,
      required: true,
      default:  () => [60, 30, 55]
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      series: [{
        name: 'Taux d\'inconfort',
        data: this.dataSeries,
      }],
      chartOptions: {
        chart: {
          // height: 400,
          // height: 200,
          // width: 200,
          type: 'radar',
          toolbar: {
            show: false
          }
        },
        title: {
          text: this.title
        },
        plotOptions: {
          radar: {
            // size: 150,
            polygons: {
              strokeColors: ['#c70505', '#df6e23','#f8e004', '#9ddf23', '#49df23'],
              fill: {
                colors: ['#c7050505', '#df6e2310','#f8e00415', '#9ddf2320', '#49df2325']
                // colors: ['#FEFAFA', '#FCF1EC','#FBEFD9', '#F0EDC2', '#D8EBAB']
              }
            }
          }
        },
        colors: ['#09c'],
        markers: {
          size: 2,
          colors: ['#fff'],
          strokeColor: '#09c',
          strokeWidth: 2,
        },
        xaxis: {
          categories: this.dataLabels,
          min: 0,
          max: 100,
          // position: 'top',
          labels: {
            show: true,
            // minWidth: 0,
            // maxWidth: 150,
            minHeight: 30,
            maxHeight: 60,
            rotate: 0,
            trim: true,
            style: {
              colors: 'red',
              fontSize: '16px',
              // fontWeight: 900,
              cssClass: 'apexcharts-xaxis-label',
            },
            formatter: (val) => {
              const sp = val.split(' ');
              return sp.length >= 2 ? sp : val;
            }
          }
        },
        yaxis: {
          show: false,
          min: 0,
          max: 100,
          tickAmount: 5
        }
      }
    }
  },

}
</script>
