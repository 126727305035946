<template>

<div>
  <p>On peut distinguer 3 types de gestion des protections solaires :</p>

  <ul>
    <li><strong>Automatisée :</strong> consiste à associer aux actionneurs des protections solaires différents capteurs, connectés ou non à des systèmes domotiques. Ce système permet de bloquer au plus tôt l’irradiation solaire selon la programmation effectuée en amont par l’occupant.</li>
    <li><strong>Motorisée :</strong> un câble électrique est installé entre le moteur des protections solaires et un bouton interrupteur installé à l'intérieur ou extérieur du bâtiment. Les mouvements d'ouverture et de fermeture des protections solaires seront possibles via l’utilisation de cet interrupteur par l’occupant à un moment donné.</li>
    <li><strong>Manuelle :</strong> l’ouverture et fermeture des protections solaires sont ici assurées manuellement par simple tirage ou par l'intermédiaire d'une manivelle par l’occupant à un moment donné.</li>
  </ul>
</div>
</template>