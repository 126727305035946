export const forms = {
  buildingConfig: {
    description: null,
    typeSave: 'object',
    questions: [
      'buildingType',
      'buildingProjectState',
      'buildingConstructionYear',
      'buildingNbLevelTotal',
      // 'buildingLevel'
    ],
  },
  location: {
    typeSave: 'object',
    questions: [
      'department',
      'zoneClimat',
      'isLittoral',
      'altitude'
    ],
  },
  environment: {
    typeSave: 'object',
    questions: [
      'externalSurroundings',
      'externalGreenSpace',
      'externalGroundType',
      'canOpenWindowsNight',
      'userBehaviorVentil'
      // 'externalIsDropShadow'
    ],
  },
  buildingWalls: {
    typeSave: 'object',
    questions: [
      'wallsComposition',
      'wallsInsulationImprovement',
      'wallsInsulationType',
      'wallsColor'
    ],
  },
  buildingRoof: {
    typeSave: 'object',
    questions: [
      'roofType',
      'roofColor',
      'roofComposition',
      'roofInsulationImprovement',
      'roofInsulationType'
    ],
  },
  buildingFloor: {
    typeSave: 'object',
    questions: [
      // 'floorType',
      'floorComposition',
      'floorInsulationImprovement'
    ],
  },
  buildingWindows: {
    typeSave: 'object',
    questions: [
      'windowsIsShadow',
      'windowsGlassType',
      'windowsOrientations',
      'windowsProtectionType',
      // 'windowsProtectionPosition',
      'windowsProtectionControl',
      'userBehaviorSolarProtection',
      'windowsRoomCrossover'
    ],
  },
  buildingGearsAndBehaviours: {
    typeSave: 'object',
    questions: [
      // 'vmcType',
      'isAirBlower',
      'overventilationType',
      'userBehaviorElecEquipments',
      'isClim',
      'climType',
      'climNbRoom'
    ],
  },
  // buildingClim: {
  //   typeSave: 'object',
  //   questions: [
  //     'isClim',
  //     'climType',
  //     'climNbRoom'
  //   ],
  // },
  rooms: {
    description: null,
    typeSave: 'collection',
    collectionFormName: 'room',
    // questions: [],
  },
  room: {
    description: null,
    typeSave: 'object',
    questions: [
      'roomId',
      'roomType',
      'roomLevel',
      'roomShape',
      'roomOrientationDeg',
      'roomFloorComposition',
      'roomFlooring',
      'roomRoofComposition',
      'canOpenWindowsNight'
    ],
  },
  walls: {
    description: null,
    typeSave: 'collection',
    collectionFormName: 'wall',
    // questions: ['roomType','roomShape'],
  },
  wall: {
    description: null,
    typeSave: 'object',
    questions: [
      /*'wallId',*/
      'wallType',
      'wallOrientation',
      'windowsProtectionType',
      'windowsProtectionControl',
      'windowsGlassType'
    ],
  },

  calculated: {
    description: null,
    typeSave: '__calculated',
    questions: ['inertiaLevel','insulationLevel'],
  },
}
