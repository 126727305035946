export const badPointsGenerics = [
  {
    id: "INERT",
    title: "Inertie",
    content: `L'<a href="https://lab.cercle-promodul.inef4.org/knowledge/post/inertie-thermique" target="_blank">inertie thermique</a> globale de la structure du bâtiment favorise la régulation des températures ambiantes. Ce sont les matériaux lourds, et notamment le béton, la pierre, la brique qui possèdent les meilleures qualités vis à vis de l'inertie. Dans une approche globale de l'inertie du bâtiment, le rôle des parois intérieures (cloisonnement, revêtements) est aussi à considérer pour compléter l'effet positif des parois lourdes.`
  },
  {
    id: "ZONEGEO",
    title: "Zone géographique",
    content: `Les bâtiments situés en zones H2C, H2D et H3, sont plus à risque d'être inconfortables l'été. Ces bâtiments nécessitent une approche systémique des solutions à envisager.`
  },
  {
    id: "ENVIR",
    title: "Environnement proche",
    content: `La présence de surfaces fortement minéralisées (pas ou peu végétalisées) contribue à emmagasiner et rayonner jusque dans la nuit la chaleur dans l'environnement proche du bâtiment. Cela nécessite un renforcement des <a href="https://lab.cercle-promodul.inef4.org/knowledge/post/rafraichissement-passif" target="_blank">solutions passives</a> proposées.`
  },
  {
    id: "ISOL",
    title: "Isolation",
    content: `Un bâtiment peu ou mal isolé sera davantage soumis à l'élévation des températures durant l'été.`
  },
  {
    id: "WINDO",
    title: "Vitrages",
    content: `Des vitrages sans protections solaires engendreront beaucoup d'inconfort pour la plupart des orientations, sauf au nord. Dans le cas où les vitrages sont équipés de protections solaires manuelles, attention à bien anticiper leur fermeture au plus tôt de la journée.`
  },
  {
    id: "OCCUP",
    title: "Occupant",
    content: `Les comportements des occupants jouent un rôle essentiel : ouverture et fermeture des fenêtres, mise en place de protections solaires, ventilation nocturne en logement traversant, limitation du recours aux appareils dégageant de la chaleur.`
  },
  {
    id: "VENTIL",
    title: "Ventilation",
    content: `La ventilation nocturne permet d'une part d'abaisser la température intérieure, mais aussi d'évacuer la chaleur emmagasinée afin de restaurer les conditions de confort lors de la journée suivante. Un logement non traversant ainsi que la non-possibilité d'ouverture des fenêtres la nuit pour ventiler accentuera l'inconfort. De même que l'absence de systèmes de ventilation et surventilation performants ne permet pas d'évacuer la chaleur emmagasinée.`
  }
]

export const badPointsDynamics = [
  {
    id: 'alt-1',
    order: 1,
    varname: 'null',
    theme: 'INERT',
    poste: 'Inertie Mur',
    title: `L'isolation, un des éléments nécessaires à la réduction des inconforts, atténue les effets bénéfiques de l‘<a href="https://lab.cercle-promodul.inef4.org/knowledge/post/inertie-thermique" target="_blank">inertie thermique</a> si elle est mise en œuvre par l'intérieur. Il faudra renforcer les autres solutions.`,
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'calculated.debug.inertia.isWallsITE',
        compType: "std",
        comp: "===",
        value: "false",
        
      },
      {
        andOr: '&&',
        simuDataPath: 'calculated.debug.inertia.sides.walls.materialInertia',
        compType: "std",
        comp: '===',
        value: '1',
      }
    ]
  },

  // {
  //   id: 'alt-2',
  //   varname: 'null',
  //   theme: 'INERT',
  //   poste: 'Inertie Toiture',
  //   title: "L'isolation par l'intérieur de la toiture impacte la capacite de rafraichissement de la paroi lourde.",
  //   description: null,
  //   conditions: [
  //     {
  //       andOr: null,
  //       simuDataPath: 'calculated.debug.inertia.isRoofITE',
  //       compType: "std",
  //       comp: "===",
  //       value: "false",
        
  //     },
  //     {
  //       andOr: '&&',
  //       simuDataPath: 'calculated.debug.inertia.sides.roof.materialInertia',
  //       compType: "std",
  //       comp: '===',
  //       value: '1',
  //     }
  //   ]
  // },

  {
    id: 'alt-3',
    order: 1,
    varname: null,
    theme: 'WINDO',
    poste: 'Protection solaire',
    title: "Les fenêtres ne possèdent aucune protection solaire / occultants.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingWindows.windowsOrientations',
        compType: "fx",
        fx: {
          isNegative: true,
          name: 'includes()',
        },
        comp: null,
        value: "['N', 'NE', 'NW']",
        
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingWindows.windowsProtectionType',
        compType: "std",
        comp: '===',
        value: 'none',
      }
    ]
  },

  {
    id: 'alt-4',
    order: 1,
    varname: 'null',
    theme: 'WINDO',
    poste: 'Protection solaire',
    title: "Des protections solaires non automatisées peuvent être contre-productives si peu ou mal utilisées.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingWindows.windowsProtectionType',
        compType: "std",
        comp: '!==',
        value: 'none',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingWindows.windowsOrientations',
        compType: "fx",
        fx: {
          isNegative: true,
          name: 'includes()',
        },
        comp: null,
        value: "['N', 'NE', 'NW']",
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingWindows.windowsProtectionControl',
        compType: "std",
        comp: '!==',
        value: 'auto',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingWindows.userBehaviorSolarProtection',
        compType: "std",
        comp: '!==',
        value: 'good',
      }
    ]
  },
  {
    id: 'alt-5',
    order: 1,
    varname: 'null',
    theme: 'VENTIL',
    poste: 'Ventilation',
    title: "L'impossibilité de créer de la ventilation nocturne par ouverture des fenêtres nécessite la mise en œuvre d'autres solutions de rafraichissement.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'environment.canOpenWindowsNight',
        compType: "std",
        comp: "===",
        value: false,
        
      },
      {
        andOr: '&&',
        simuDataPath: 'calculated.result.inertiaLevel',
        compType: "fx",
        fx: {
          isNegative: true,
          name: 'includes()',
        },
        comp: null,
        value: "['heavy', 'veryHeavy']",
      },
    ]
  },

  {
    id: 'alt-6',
    order: 1,
    varname: 'null',
    theme: 'VENTIL',
    poste: 'Ventilation',
    title: "L'impossibilité de créer de la ventilation nocturne par ouverture des fenêtres favorise l'accumulation de la chaleur et l'élévation des températures.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'environment.canOpenWindowsNight',
        compType: "std",
        comp: "===",
        value: false,
        
      },
      {
        andOr: '&&',
        simuDataPath: 'calculated.result.inertiaLevel',
        compType: "fx",
        fx: {
          isNegative: false,
          name: 'includes()',
        },
        comp: null,
        value: "['heavy', 'veryHeavy']",
      },
    ]
  },

  {
    id: 'alt-7',
    order: 1,
    varname: 'null',
    theme: 'OCCUP',
    poste: 'Occupant',
    title: "Le bâtiment étant traversant, le manque de régularité dans l'ouverture des fenêtres la nuit aux heures les plus fraiches, ne permet pas d'évacuer pleinement la chaleur emmagasinée le jour.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingWindows.windowsRoomCrossover',
        compType: "std",
        comp: "===",
        value: true,
      },
      {
        andOr: '&&',
        simuDataPath: 'environment.canOpenWindowsNight',
        compType: "std",
        comp: "===",
        value: true,
      },
      {
        andOr: '&&',
        simuDataPath: 'environment.userBehaviorVentil',
        compType: "std",
        comp: "!==",
        value: 'good',
      }
    ]
  },

  {
    id: 'alt-8',
    order: 1,
    varname: 'null',
    theme: 'ZONEGEO',
    poste: 'Zone climat',
    title: "La zone géographique où se situe le bâtiment implique des températures estivales élevées et donc une plus grande vigilance.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'location.zoneClimat',
        compType: "fx",
        fx: {
          isNegative: false,
          name: 'includes()',
        },
        comp: null,
        value: "['H2c', 'H2d', 'H3']",
      },
    ]
  },

  {
    id: 'alt-9',
    order: 1,
    varname: 'null',
    theme: 'INERT',
    poste: 'Sous toiture',
    title: "La toiture étant fortement soumise aux rayons du soleil les combles aménagés présentent des risques de surchauffe estivale. Il faudra renforcer les solutions pour anticiper et prévenir la montée en température.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingRoof.roofType',
        compType: "std",
        comp: "===",
        value: 'converted',
      }
    ]
  },

  {
    id: 'alt-10',
    order: 1,
    varname: 'null',
    theme: 'ENVIR',
    poste: 'Sous toiture',
    title: "Les abords du bâtiment sont très minéralisés.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'environment.externalGroundType',
        compType: "fx",
        fx: {
          isNegative: true,
          name: 'includes()',
        },
        comp: null,
        value: "['revegetation', 'woodenTerrace']",
      }

    ]
  },

  {
    id: 'alt-11',
    order: 1,
    varname: 'null',
    theme: 'ISOL',
    poste: 'Sous toiture',
    title: "L'isolation insuffisante de la toiture contribue au risque de surchauffe.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingConfig.buildingProjectState',
        compType: "std",
        comp: '===',
        value: 'existing',
      },
      {
        andOr: '&&',
        simuDataPath: 'buildingConfig.buildingConstructionYear',
        compType: "std",
        comp: '<',
        value: '2000',
      },
      {
        andOr: "&&",
        conditions: [
          {
            andOr: null,
            simuDataPath: 'buildingRoof.roofInsulationImprovement',
            compType: "std",
            comp: '<',
            value: '2000',
          },
          {
            andOr: "||",
            simuDataPath: 'buildingRoof.roofInsulationImprovement',
            compType: "fx",
            fx: {
              isNegative: false,
              name: 'includes()',
            },
            comp: null,
            value: "['none', 'unknow']",
          },
        ]
      }

    ]
  },

  {
    id: 'alt-12',
    order: 1,
    varname: 'null',
    theme: 'ISOL',
    poste: 'Isolation',
    title: "L'isolation insuffisante du bâtiment contribue au risque de surchauffe.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'calculated.result.insulationLevel',
        compType: "std",
        comp: '!==',
        value: 'good',
      }
    ]
  },

  {
    id: 'alt-13',
    order: 1,
    varname: 'null',
    theme: 'OCCUP',
    poste: 'Occupant',
    title: "Le bâtiment manque de régularité dans l'ouverture des fenêtres la nuit aux heures les plus fraiches, ne permet pas d'évacuer pleinement la chaleur emmagasinée le jour.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'environment.canOpenWindowsNight',
        compType: "std",
        comp: '===',
        value: 'true',
      },
      {
        andOr: "&&",
        simuDataPath: 'buildingWindows.windowsRoomCrossover',
        compType: "std",
        comp: '===',
        value: 'false',
      },
      {
        andOr: "&&",
        simuDataPath: 'environment.userBehaviorVentil',
        compType: "std",
        comp: '!==',
        value: 'good',
      }
    ]
  },


  {
    id: 'alt-15',
    order: 1,
    varname: 'null',
    theme: 'INERT',
    poste: 'Inertie',
    title: `L'<a href="https://lab.cercle-promodul.inef4.org/knowledge/post/inertie-thermique" target="_blank">inertie thermique</a> du bâtiment est faible, les parois étant globalement composées de « matériaux légers ».`,
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'calculated.result.inertiaLevel',
        compType: "std",
        comp: '===',
        value: 'light',
      }
    ]
  },

  {
    id: 'alt-16',
    order: 1,
    varname: 'null',
    theme: 'OCCUP',
    poste: 'Occupant',
    title: "Le manque de régularité dans l'utilisation des protections solaires ne permet pas de limiter les apports solaires.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingWindows.windowsProtectionType',
        compType: "std",
        comp: '!==',
        value: 'none',
      },
      {
        andOr: "&&",
        simuDataPath: 'buildingWindows.userBehaviorSolarProtection',
        compType: "std",
        comp: '!==',
        value: 'good',
      }
    ]
  },

  {
    id: 'alt-17',
    order: 1,
    varname: 'null',
    theme: 'ENVIR',
    poste: 'ENVIR',
    title: `Le bâtiment est situé en zone urbaine et peut être fortement impacté par les <a href="https://lab.cercle-promodul.inef4.org/knowledge/post/ilot-de-chaleur-urbain" target="_blank">îlots de chaleur urbains</a> (risque renforcé).`,
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'environment.externalSurroundings',
        compType: "std",
        comp: '===',
        value: 'urban',
      },
      {
        andOr: "&&",
        simuDataPath: 'environment.externalGreenSpace',
        compType: "std",
        comp: '!==',
        value: 'dense',
      }
    ]
  },

  {
    id: 'alt-18',
    order: 1,
    varname: 'null',
    theme: 'OCCUP',
    poste: 'Occupant',
    title: "L'utilisation d'appareils et systèmes dégageant de la chaleur, en particulier pendant les heures les plus chaudes, contribuent à l'inconfort.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingGearsAndBehaviours.userBehaviorElecEquipments',
        compType: "std",
        comp: '===',
        value: 'bad',
      }
    ]
  },

];


export const goodPointsDynamics = [
  {
    id: 'pf-1',
    order: 1,
    varname: null,
    theme: 'Bâtiment',
    poste: 'Inertie',
    title: `Le bâtiment possède une bonne <a href="https://lab.cercle-promodul.inef4.org/knowledge/post/inertie-thermique" target="_blank">inertie thermique</a> permettant de mieux réguler la température ambiante.`,
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'calculated.result.inertiaLevel',
        compType: "fx",
        fx: {
          isNegative: false,
          name: 'includes()',
        },
        comp: null,
        value: "['heavy', 'veryHeavy']",
      }
    ]
  },
  {
    id: 'pf-2',
    order: 1,
    varname: null,
    theme: 'Bâtiment',
    poste: 'Ventilation',
    title: "Le bâtiment a un potentiel de ventilation important car traversant.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingWindows.windowsRoomCrossover',
        compType: "std",
        comp: "===",
        value: true,
      },
      {
        andOr: '&&',
        simuDataPath: 'environment.canOpenWindowsNight',
        compType: "std",
        comp: "===",
        value: true,
      },
    ]
  },
  {
    id: 'pf-3',
    order: 1,
    varname: null,
    theme: 'Bâtiment',
    poste: 'Ventilation',
    title: "Le bâtiment peut être ventilé la nuit grâce à l'ouverture des fenêtres.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'buildingWindows.windowsRoomCrossover',
        compType: "std",
        comp: "===",
        value: false,
      },
      {
        andOr: '&&',
        simuDataPath: 'environment.canOpenWindowsNight',
        compType: "std",
        comp: "===",
        value: true,
      },
    ]
  },
  {
    id: 'pf-4',
    order: 1,
    varname: null,
    theme: 'Bâtiment',
    poste: 'Isolation',
    title: "Le bâtiment est bien isolé thermiquement.",
    description: null,
    conditions: [
      {
        andOr: null,
        simuDataPath: 'calculated.result.insulationLevel',
        compType: "fx",
        fx: {
          isNegative: false,
          name: 'includes()',
        },
        comp: null,
        value: "['good']",
      }
    ]
  }
];

