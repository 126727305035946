<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1000px"
	 height="1000px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
<g id="confort">
		<path fill="#F49819" stroke="#293377" stroke-width="13" stroke-miterlimit="10" d="M515.956,523.38
			c0-35.428,28.418-63.817,63.816-63.817h111.681c35.09,0,63.82,28.731,63.82,63.817v68.933
			c-37.02,13.086-63.82,48.175-63.82,89.649v64.784H515.956V523.38 M308.546,681.649v65.097h175.5V523.38
			c0-35.428-28.388-63.817-63.818-63.817H308.546c-35.089,0-63.818,28.731-63.818,63.817v68.618
			C281.748,605.086,308.546,640.487,308.546,681.649 M776.338,620.074c-31.286,5.08-52.975,34.774-52.975,66.685v91.897H276.636
			v-95.728c0-35.245-28.575-63.818-63.818-63.818S149,647.684,149,682.929v159.545c0,35.087,28.73,63.815,63.818,63.815v63.82
			h63.818v-63.82h446.728v63.82h63.82v-63.82c35.088,0,63.816-28.729,63.816-63.815V682.929
			C851,644.318,816.227,613.374,776.338,620.074z"/>
		<path fill="#293377" d="M797.382,469.422h127.45L499.998,87.076L75.168,469.422h127.451L499.998,201.33L797.382,469.422
			 M287.585,163.993V87.076h-127.45v191.171L287.585,163.993z"/>
</g>
</svg>
</template>