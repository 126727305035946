<template>
  <g>
 <path  d="M463.866,427.061c-34.651,36.328-82.149,60.674-136.094,65.245
	C210.415,502.31,107.201,415.286,97.222,297.93C87.234,180.604,174.246,77.376,291.586,67.416
	c52.715-4.502,102.558,10.629,142.399,39.263c2.488-1.668,5.118-3.175,7.852-4.56C398.56,68.488,343.043,50.377,284.13,55.401
	C159.873,65.979,67.706,175.287,78.267,299.563c10.583,124.243,119.895,216.431,244.172,205.851
	c60.571-5.157,113.476-33.817,150.612-76.218C469.801,428.984,466.724,428.249,463.866,427.061z"/>
<g>
	<path  d="M34.914,242.32l3.261-18.949l3.484,0.602l-2.48,14.407l10.689,1.845l2.292-13.311l3.436,0.593
		l-2.291,13.311l14.555,2.512l-0.782,4.542L34.914,242.32z"/>
	<path  d="M66.509,198.246c8.327,2.414,10.127,9.774,8.403,15.725c-1.932,6.664-7.886,10.506-15.005,8.442
		c-7.536-2.186-10.393-8.858-8.417-15.675C53.539,199.668,59.623,196.25,66.509,198.246z M61.081,217.846
		c4.931,1.43,9.552-0.594,10.82-4.969c1.238-4.272-1.509-8.543-6.579-10.013c-3.814-1.106-9.257-0.423-10.791,4.867
		C52.998,213.021,56.8,216.604,61.081,217.846z"/>
	<path  d="M64.469,188.942c-2.223-0.961-4.067-1.699-5.909-2.321l1.637-3.792l3.663,1.292l0.042-0.097
		c-1.542-2.05-2.368-5.637-0.69-9.527c1.405-3.259,5.366-7.548,12.748-4.361l12.851,5.547l-1.846,4.28l-12.407-5.356
		c-3.469-1.497-6.967-1.334-8.709,2.702c-1.217,2.82-0.338,5.795,1.526,7.465c0.405,0.406,1.03,0.791,1.697,1.079l12.94,5.586
		l-1.847,4.279L64.469,188.942z"/>
	<path  d="M74.055,132.097l24.392,14.411c1.793,1.058,3.863,2.22,5.32,2.896l-2.129,3.602l-3.61-1.887l-0.054,0.091
		c1.524,2.561,1.644,6.261-0.486,9.862c-3.153,5.334-9.707,6.998-15.836,3.377c-6.741-3.921-8.173-10.918-4.993-16.298
		c1.995-3.374,4.802-4.79,7.021-4.833l0.054-0.091l-12.05-7.119L74.055,132.097z M89.32,146.528
		c-0.458-0.271-1.111-0.595-1.65-0.729c-2.685-0.786-5.896,0.268-7.675,3.277c-2.453,4.148-0.573,8.581,3.888,11.217
		c4.086,2.414,8.757,2.221,11.318-2.11c1.589-2.688,1.419-6.111-0.901-8.467c-0.42-0.432-0.894-0.774-1.477-1.118L89.32,146.528z"/>
	<path  d="M104.547,140.768c1.483-0.61,3.641-2.039,5.037-3.905c2.031-2.714,1.742-4.915,0.191-6.075
		c-1.628-1.218-3.313-0.825-6.305,1.236c-3.961,2.791-7.123,3.138-9.372,1.456c-3.023-2.263-3.505-6.791-0.206-11.202
		c1.554-2.078,3.461-3.496,4.938-4.178l1.783,2.79c-1.068,0.458-2.798,1.478-4.227,3.386c-1.65,2.207-1.406,4.307-0.011,5.351
		c1.55,1.16,3.168,0.452,6.097-1.524c3.905-2.634,6.897-3.24,9.688-1.152c3.295,2.466,3.526,7.006-0.122,11.883
		c-1.681,2.248-3.74,3.95-5.593,4.812L104.547,140.768z"/>
	<path  d="M121.563,76.632l19.697,20.369c1.448,1.497,3.136,3.166,4.361,4.205l-3.007,2.908l-2.981-2.777
		l-0.076,0.074c0.79,2.875-0.075,6.473-3.083,9.382c-4.454,4.307-11.214,4.174-16.163-0.945c-5.458-5.568-4.985-12.697-0.494-17.041
		c2.818-2.725,5.899-3.347,8.05-2.799l0.077-0.073l-9.731-10.063L121.563,76.632z M132.456,94.601
		c-0.371-0.383-0.913-0.869-1.397-1.142c-2.381-1.471-5.757-1.305-8.271,1.124c-3.464,3.351-2.826,8.125,0.776,11.85
		c3.3,3.413,7.855,4.465,11.472,0.967c2.247-2.172,2.988-5.518,1.375-8.404c-0.29-0.529-0.656-0.984-1.127-1.471L132.456,94.601z"/>
	<path  d="M147.142,85.857c3.567,4.527,8.21,3.985,11.923,1.17c2.659-2.015,3.94-3.655,4.923-5.251l2.478,1.95
		c-0.899,1.532-2.667,3.845-5.917,6.308c-6.288,4.767-12.913,3.834-17.186-1.798c-4.274-5.632-4.012-12.82,1.939-17.331
		c6.668-5.055,12.509-1.036,15.114,2.397c0.527,0.694,0.853,1.298,1.073,1.678L147.142,85.857z M156.185,75.174
		c-1.597-2.193-5.157-4.781-9.334-1.613c-3.756,2.847-3.001,7.257-1.512,9.834L156.185,75.174z"/>
	<path  d="M169.32,44.898c2.334-1.605,5.186-3.361,8.45-5c5.916-2.969,10.757-3.829,14.743-2.85
		c4.035,0.955,7.385,3.498,9.754,8.214c2.39,4.759,2.736,9.461,1.103,13.64c-1.613,4.222-5.795,8.163-11.995,11.275
		c-2.934,1.473-5.458,2.578-7.649,3.406L169.32,44.898z M186.387,68.619c1.128-0.35,2.664-1.066,4.273-1.874
		c8.802-4.419,11.321-11.318,7.367-19.192c-3.407-6.903-9.86-9.135-18.568-4.765c-2.129,1.069-3.651,2.05-4.631,2.813
		L186.387,68.619z"/>
	<path  d="M234.01,39.694c2.783,8.208-2.223,13.893-8.091,15.884c-6.568,2.228-13.125-0.456-15.503-7.472
		c-2.518-7.429,1.321-13.587,8.041-15.867C225.426,29.875,231.71,32.908,234.01,39.694z M214.833,46.455
		c1.647,4.861,5.95,7.491,10.262,6.029c4.212-1.429,6.145-6.123,4.45-11.121c-1.274-3.759-4.948-7.831-10.162-6.062
		C214.167,37.069,213.402,42.236,214.833,46.455z"/>
	<path  d="M242.812,18.947l1.508,6.559l6.503-1.495l0.728,3.162l-6.503,1.495l2.832,12.316
		c0.651,2.832,1.897,4.233,4.426,3.652c1.187-0.272,2.032-0.617,2.567-0.888l0.923,3.066c-0.802,0.533-2.141,1.089-3.896,1.492
		c-2.116,0.486-3.96,0.264-5.304-0.619c-1.573-0.93-2.504-2.852-3.122-5.542l-2.865-12.458l-3.871,0.89l-0.727-3.162l3.872-0.89
		l-1.259-5.474L242.812,18.947z"/>
	<path  d="M275.901,43.857l-0.76-2.879l-0.158,0.021c-1.173,2.014-3.684,4.011-7.306,4.495
		c-5.145,0.688-8.212-2.275-8.662-5.636c-0.752-5.618,4.297-9.419,14.119-10.682l-0.064-0.48c-0.257-1.92-1.297-5.3-6.494-4.606
		c-2.362,0.316-4.739,1.317-6.383,2.611l-1.422-2.643c1.932-1.53,4.867-2.752,8.07-3.18c7.769-1.038,10.308,3.56,10.931,8.217
		l1.162,8.69c0.271,2.016,0.638,3.971,1.165,5.514L275.901,43.857z M273.631,32.09c-5.052,0.576-10.664,2.157-10.061,6.67
		c0.367,2.736,2.535,3.767,4.897,3.451c3.307-0.441,5.149-2.643,5.621-4.709c0.1-0.454,0.14-0.948,0.083-1.38L273.631,32.09z"/>
	<path  d="M291.963,12.464l0.281,6.726l6.668-0.279l0.137,3.241l-6.668,0.279l0.529,12.63
		c0.121,2.903,1.088,4.51,3.682,4.402c1.218-0.051,2.11-0.234,2.688-0.403l0.345,3.185c-0.886,0.376-2.304,0.678-4.104,0.753
		c-2.169,0.091-3.941-0.465-5.102-1.58c-1.376-1.203-1.94-3.263-2.055-6.021l-0.535-12.774l-3.969,0.167l-0.136-3.243l3.969-0.165
		l-0.234-5.613L291.963,12.464z"/>
	<path  d="M305.967,42.439l0.568-23.434l4.659,0.113l-0.567,23.433L305.967,42.439z M311.885,12.548
		c0.017,1.454-1.176,2.587-3.028,2.542c-1.642-0.039-2.778-1.23-2.744-2.682c0.035-1.5,1.283-2.633,2.978-2.592
		C310.837,9.858,311.92,11.047,311.885,12.548z"/>
	<path  d="M343.395,33.567c-0.992,8.614-7.95,11.617-14.108,10.909c-6.894-0.792-11.678-6.024-10.828-13.386
		c0.898-7.796,7.003-11.726,14.055-10.915C339.829,21.017,344.215,26.445,343.395,33.567z M323.157,31.484
		c-0.587,5.101,2.179,9.319,6.706,9.84c4.42,0.508,8.174-2.912,8.778-8.157c0.453-3.946-1.126-9.199-6.601-9.828
		C326.568,22.709,323.667,27.057,323.157,31.484z"/>
	<path  d="M352.203,30.008c0.577-2.351,0.997-4.292,1.304-6.211l4.014,0.984l-0.664,3.826l0.102,0.025
		c1.765-1.861,5.165-3.271,9.281-2.263c3.447,0.845,8.338,4.038,6.426,11.844l-3.328,13.591l-4.528-1.11l3.213-13.121
		c0.898-3.668,0.155-7.09-4.115-8.137c-2.984-0.731-5.771,0.629-7.107,2.745c-0.333,0.466-0.607,1.147-0.781,1.852l-3.352,13.685
		l-4.528-1.11L352.203,30.008z"/>
</g>
<path fill="none" stroke="#FFFFFF" stroke-width="2.6697" stroke-linecap="round" stroke-linejoin="round" d="M175.214,291.854"/>
<path fill="none" stroke="#FFFFFF" stroke-width="2.6697" stroke-linecap="round" stroke-linejoin="round" d="M152.352,345.819"/>
<path fill="none" stroke="#FFFFFF" stroke-width="2.6697" stroke-linecap="round" stroke-linejoin="round" d="M73.591,319.815"/>
<polygon  points="99.645,385.955 144.646,336.086 80.059,314.765 "/>
<polygon fill="none" stroke="#FFFFFF" stroke-width="2.1893" stroke-linecap="round" stroke-linejoin="round" points="
	99.645,385.955 144.646,336.086 80.059,314.765 "/>
<polygon  points="144.646,336.082 182.992,363.025 99.645,385.955 "/>
<polygon fill="none" stroke="#FFFFFF" stroke-width="2.1637" stroke-linecap="round" stroke-linejoin="round" points="
	144.646,336.082 182.992,363.025 99.645,385.955 "/>
<polygon  points="163.396,291.829 182.992,363.025 144.646,336.082 "/>
<polygon fill="none" stroke="#FFFFFF" stroke-width="2.1893" stroke-linecap="round" stroke-linejoin="round" points="
	163.396,291.829 182.992,363.025 144.646,336.082 "/>
<polygon  points="80.059,314.765 109.221,260.724 163.396,291.829 "/>
<polygon fill="none" stroke="#FFFFFF" stroke-width="2.1893" stroke-linecap="round" stroke-linejoin="round" points="
	80.059,314.765 109.221,260.724 163.396,291.829 "/>
<polygon  points="99.645,385.955 29.56,423.019 30.885,341.654 "/>
<polygon fill="none" stroke="#FFFFFF" stroke-width="2.1893" stroke-linecap="round" stroke-linejoin="round" points="
	99.645,385.955 29.56,423.019 30.885,341.654 "/>
<polygon  points="30.885,341.654 80.059,314.765 99.645,385.955 "/>
<polygon fill="none" stroke="#FFFFFF" stroke-width="2.1893" stroke-linecap="round" stroke-linejoin="round" points="
	30.885,341.654 80.059,314.765 99.645,385.955 "/>
<polygon  points="80.059,314.765 54.079,280.146 30.885,341.654 "/>
<polygon fill="none" stroke="#FFFFFF" stroke-width="2.1893" stroke-linecap="round" stroke-linejoin="round" points="
	80.059,314.765 54.079,280.146 30.885,341.654 "/>
<polygon  points="163.396,291.829 109.221,260.724 166.144,235.998 "/>
<polygon fill="none" stroke="#FFFFFF" stroke-width="2.1893" stroke-linecap="round" stroke-linejoin="round" points="
	163.396,291.829 109.221,260.724 166.144,235.998 "/>
<polygon  points="54.079,280.146 109.221,260.724 80.059,314.765 "/>
<polygon fill="none" stroke="#FFFFFF" stroke-width="2.1893" stroke-linecap="round" stroke-linejoin="round" points="
	54.079,280.146 109.221,260.724 80.059,314.765 "/>
<polygon  points="80.059,314.765 144.646,336.086 163.396,291.829 "/>
<polygon fill="none" stroke="#FFFFFF" stroke-width="2.1893" stroke-linecap="round" stroke-linejoin="round" points="
	80.059,314.765 144.646,336.086 163.396,291.829 "/>
<path d="M401.368,187.399c-7.787-9.123-18.027-16.533-30.781-22.232c-12.749-5.681-28.109-8.907-46.106-9.75
	c-15.781-0.591-30.339-0.207-43.65,1.03c-13.301,1.247-24.774,2.69-34.488,4.356c-11.386,2.249-21.888,4.713-31.575,7.473v67.98
	v153.948c0,6.657,0.82,12.115,2.496,16.372c1.689,4.318,4.082,7.706,7.29,10.188c3.185,2.499,6.838,4.159,10.991,5
	c4.149,0.807,8.729,1.254,13.707,1.254h14.978v-89.377c8.038,2.256,17.027,4.102,27.015,5.626
	c9.978,1.542,21.032,2.294,33.237,2.294c15.22,0,28.735-2.17,40.495-6.641c11.78-4.446,21.705-10.503,29.725-18.299
	c8.004-7.725,14.303-16.812,18.899-27.188c3.047-6.892,5.189-14.195,6.561-21.761c0.664-3.844,1.259-7.741,1.546-11.738
	c0.832-12.966-0.434-25.378-3.744-37.17C414.631,207.007,409.115,196.55,401.368,187.399z M355.809,279.647
	c-3.396,5.833-7.58,10.548-12.428,14.15c-4.864,3.548-10.172,6.2-16.011,7.903c-5.793,1.64-11.619,2.636-17.457,2.895
	c-13.858,0.824-29.083-1.255-45.685-6.255l-0.211-52.702l-0.219-49.502l0.43,0.413c4.978-2.796,10.533-4.857,16.602-6.219
	c5.293-1.417,11.563-2.303,18.904-2.708c7.32-0.439,15.598,0.09,24.746,1.452c5.251,0.806,10.534,2.617,15.785,5.377
	c5.243,2.775,9.813,6.783,13.694,12.061c3.878,5.279,6.778,12.125,8.765,20.585c1.894,8.422,2.077,18.631,0.392,30.549
	c-0.151,0.994-0.392,1.934-0.586,2.947C361.097,268.113,358.906,274.521,355.809,279.647z"/>
  </g>
</template>