<template>
  <Transition
    name="trans-step"
    >
    <slot></slot> <!-- passer le contenu du slot -->
  </Transition>
</template>

<script setup>
</script>

<style lang="scss">
.trans-step-enter-active,
.trans-step-leave-active 
{
  transition: opacity 0.3s ease;
}
.trans-step-enter-from,
.trans-step-leave-to
{
  opacity: 0;
}

// .trans-step-enter-active,
// .trans-step-leave-active 
// {
//   animation: fade-in 0.5s;
// }
// .trans-step-enter-from,
// .trans-step-leave-to
// {
//   animation: fade-in 0.5s reverse;
// }

// .trans-step-enter-active {
//   animation: fade-in 0.5s;
// }
// .trans-step-leave-active {
//   animation: fade-in 0.5s ;
// }

// @keyframes fade-in {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

/*
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

@keyframes roll-in {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}

@keyframes gelatine {
  from, to { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  75% { transform: scale(0.95, 1.05); }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
*/
</style>