import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import {initRoom} from '../functions/initItems.js'

export default createStore({
  plugins: [createPersistedState({
    key: `${process.env.VUE_APP_LOCALSTORAGE_PREFIX}_store`,
    paths: ['version','simuData'],
  })],
  state () {
    return {
      isDebug: false,
      currentStep: 1,
      screenSize: {w: null, h: null},
      version: {maj: null, min: null, rev: null},
      //--
      steps: [],
      simuData: {
        "createdAt": null,
        "updatedAt": null
      },
      simuResults: {
        bati: null,
        rooms: {}
      }
    }
  },
  getters: {
    isDebug(state) {
      return state.isDebug;
    },
    simuData(state) {
      return state.simuData;
    },
    rooms(state) {
      return state.simuData.rooms;
    },
    room: (state) => ({roomId}) => {
      return state.simuData.rooms.find(room => room.roomId === roomId);
    },
    steps(state) {
      return state.steps;
    },
    currentStep(state) {
      return state.currentStep;
    },
    currentStepIndex (state) {
      return state.steps.findIndex(step => step.id === state.currentStep);
    },
    nextStep (state, getters) {
      let nextStepIdx = null;
      const currentStepIdx = getters.currentStepIndex;
      const nbSteps = state.steps.length;

      if (currentStepIdx === nbSteps - 1) {//current is last step
        // alert('Last Step')
        nextStepIdx = currentStepIdx;
      } else {
        nextStepIdx = currentStepIdx + 1;
      }

      // console .log('nextStep()', currentStepIdx, nextStepIdx);

      return state.steps[nextStepIdx];
    },
    simuResults (state) {
      return state.simuResults;
    },
    batiType (state) {
      return state.simuData.buildingConfig.buildingType;
    },
    roofType (state) {
      return state.simuData.buildingRoof.roofType;
    }
  },
  mutations: {
    setDebugMode(state, val) {
      state.isDebug = val;
    },
    increment (state, incValue) {
      // state.count++
      state.count += incValue
    },
    simuDataToState (state, simuData) {
      for (const [key, value] of Object.entries(simuData)) {
        // console.log(`${key}: ${value}`, value);
        state.simuData[key] = value;
      }
      // state.simuData = {...state.simuData , simuData };
    },
    saveBuilding (state, buildingData) {
      for (const [key, value] of Object.entries(buildingData)) {
        state.simuData.building[key] = value;
      }
    },
    saveRoom (state, {roomData, roomIndex = null}) {
      // console.log('roomIndex', roomIndex);
      // console.log('roomData', roomData);
      if (!state.simuData.rooms) state.simuData.rooms = [];
      if (roomIndex === -1) {
        console.error('ERROR (saveRoom) roomIndex unknow (-1)');
      } else if (roomIndex !== null) {
        state.simuData.rooms[roomIndex] = roomData;
      } else {
        state.simuData.rooms.push(roomData);
      }
    },
    removeRoom (state, {roomIndex}) {
      // console.log('removeRoom', roomIndex);
      state.simuData.rooms.splice(roomIndex, 1);
    },
    changeStepAllowedStatusByIdx (state, payload) {
      // console.log('changeStepAllowedStatusByIdx', payload);
      const idx = payload.idx;
      const status = payload.status;
      state.steps[idx].isAllowed = status;
    },

    setCurrentStep (state, stepId) {
      state.currentStep = stepId;
    },

    setRoomFormValidation (state, value) {
      state.simuData.isRoomFormValid = value;
    },

    saveResultToState (state, {typeResult, dataResult, roomId}) {

      if (typeResult == 'room' && !roomId) {
        throw new Error("saveResultToState : No ID for Room Result");
      }

      if (typeResult == 'bati') {
        state.simuResults.bati = dataResult;
      } else if (typeResult == 'room') {
        if (!state.simuResults.rooms[roomId]) {
          state.simuResults.rooms[roomId] = {}
        }
        state.simuResults.rooms[roomId] = dataResult;
      } else {
        // throw new Error("saveResultToState : Unknow Type Result:"+typeResult);
        console.error("saveResultToState : Unknow Type Result:"+typeResult);
      }

    },
    resetResultToState (state) {
      state.simuResults = {
        bati: null,
        rooms: {}
      }
    }

  },
  actions: {
    asyncIncrement ({ commit }, payload) {
      const incValue = payload.incValue > 0 ? payload.incValue : 1;
      commit('increment', incValue);
    },
    saveSimuData ({ commit }, payload) {
      commit('simuDataToState', payload);
    },
    setStepAllowedStatus ({ commit, state }, payload) {
      const id = payload.id;
      const status = payload.status;
      const stepIndex = state.steps.findIndex(step => step.id === id);
      commit('changeStepAllowedStatusByIdx', {idx: stepIndex, status: status});
    },
    disallowAllNextSteps ({ commit, getters, state }) {
      const currentStepIndex = getters.currentStepIndex;
      const nbSteps = state.steps.length;

      for (let i = currentStepIndex + 1 ; i < nbSteps ; i++) {
        // console.log('disallowAllNextSteps', currentStepIndex, i);
        commit('changeStepAllowedStatusByIdx', {idx: i, status: false});
      }
    },
    //-- BUILDING
    updateBuilding ({ commit }, {buildingBata}) {
      commit('saveBuilding', buildingBata);
    },
    //-- /BUILDING
    //-- ROOMS
    createRoom ({ commit }) {
      const roomData = initRoom();
      commit('saveRoom', {roomData});

      return roomData.roomId;
    },
    updateRoom ({ commit, state }, {roomId, roomData}) {
      const roomIndex = state.simuData.rooms.findIndex((room) => {
        // console.log('room.roomId', room.roomId, 'roomId', roomId);
        return room.roomId === roomId
      });
      commit('saveRoom', {roomIndex, roomData});
    },
    deleteRoom ({ commit, state }, {roomId}) {
      const roomIndex = state.simuData.rooms.findIndex(room => room.roomId === roomId);
      commit('removeRoom', {roomIndex});
    },
    //-- /ROOMS
    //-- RESULTS
    saveResult ({ commit }, {typeResult, dataResult, roomId}) {
      // console.log({typeResult, dataResult, roomId});
      commit('saveResultToState', {typeResult, dataResult, roomId});
    },
    resetResult({ commit }) {
      commit('resetResultToState');
    }
  }
})