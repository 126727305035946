import {forms, questions} from '../appConfig/index.js';

export const initRoom = () => {
  const nbWalls = 4;
  let room = {};

  forms.room.questions.forEach((qstVarName) => {
    const qst = questions.find(q => q.varname === qstVarName);
    room[qstVarName] = qst.default;
  });
  room.roomId = `r-${Date.now()}`;
  room.walls = {};
 
  for(let i = 1; i <= nbWalls; i++){
    room.walls[`wall${i}`] = initWall(i);
  }
  return room;
}

export const initWall = (wallId) => {
  let wall = {
    id: wallId,
    status: null,
    info2D: {type: null, showWallBefore: null, showWallAfter: null}
  }

  forms.wall.questions.forEach((qstVarName) => {
    wall[qstVarName] = null;
  });



  return wall;
}