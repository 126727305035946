import { createRouter, createWebHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/IntroView.vue"),
      meta: { title: "RESPIRE | Accueil" },
    },
    {
      path: "/steps",
      name: "steps",
      component: () => import("../views/StepsView.vue"),
      meta: { title: "RESPIRE | Projet" },
    },
    {
      path: "/debug",
      name: "debug",
      component: () => import("../views/debug/DebugHomeView.vue"),
      children: [
        { path: "alerts", name: "debugAlerts", component: () => import("../views/debug/AlertsView.vue") },
        { path: "questions", name: "debugQuestions", component: () => import("../views/debug/QuestionsView.vue") },
      ],
      meta: { title: "RESPIRE | Debug" },
    },
    // {
    //   path: '/alerts',
    //   name: 'debugAlerts',
    //   component: () => import('../views/debug/AlertsView.vue')
    // },
    // {
    //   path: '/questions',
    //   name: 'debugQuestions',
    //   component: () => import('../views/debug/QuestionsView.vue')
    // },
    {
      path: "/sandbox",
      name: "sandbox",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/sandBox/TestsDivers.vue"),
    },
    {
      path: "/testSlot",
      name: "testSlot",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/sandBox/TestSlots.vue"),
    },
    {
      path: "/testMapZoneClimat",
      name: "testMapZoneClimat",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/sandBox/TestMapFrance.vue"),
    },
  ],
});

export default router;
