<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="new">
	<path id="crane" opacity="0.5" fill="currentColor" d="M55.316,66.932v19.002l9.984,14.832h-9.984v11.278h19.217v27.06
		c0,4.993,4.128,9.183,9.608,10.25v120.583h54.647l-42.037-11.278l42.037-11.278v22.556H151.4V149.363
		c5.455-1.077,9.608-5.267,9.608-10.259v-27.06h114.699v30.075h-3.203l-16.014,15.038l16.014,15.038h3.503v4.738
		c-10.271,1.723-18.016,9.203-18.016,18.131c0,10.182,10.108,18.483,22.52,18.483c12.423,0,22.52-8.301,22.532-18.483
		c0-2.036-2.014-3.7-4.517-3.7c-2.489,0-4.504,1.664-4.504,3.7c0,6.109-6.055,11.083-13.512,11.083
		c-7.457,0-13.511-4.973-13.511-11.083c0-6.128,6.055-11.102,13.511-11.102c2.49,0,4.504-1.665,4.504-3.701v-8.066h3.502
		l16.014-15.038l-16.014-15.038h-3.203v-30.075h19.817l-28.538-45.112H161.008V54.91c0-5.796-5.555-10.534-12.311-10.534H86.894
		c-6.806,0-12.36,4.738-12.36,10.534v12.022H55.316 M282.827,100.766h-23.358l12.135-18.042L282.827,100.766 M261.583,78.209
		L251.6,93.042l-9.971-14.832H261.583 M243.743,100.766h-19.968l9.984-14.832L243.743,100.766 M225.889,78.209l-9.971,14.832
		l-9.971-14.832H225.889 M208.049,100.766h-19.954l9.982-14.832L208.049,100.766 M190.209,78.209l-9.984,14.832l-9.971-14.832
		H190.209 M161.008,100.766V87.98l1.376-2.046l9.971,14.832H161.008 M96.752,247.38v-22.556l42.037,11.278L96.752,247.38
		 M138.789,224.824l-42.037-11.278l42.037-11.278V224.824 M96.752,202.268v-22.556l42.037,11.278L96.752,202.268 M138.789,179.712
		l-42.037-11.278l42.037-11.268V179.712 M96.752,157.166v-7.528h28.024L96.752,157.166 M89.546,94.177V62.262
		c0-3.642,3.253-6.608,7.206-6.608h43.237c3.954,0,7.207,2.967,7.207,6.608v31.915c0,3.623-3.252,6.589-7.207,6.589H96.752
		C92.799,100.766,89.546,97.799,89.546,94.177 M73.182,93.042L63.173,78.209h11.36v12.796L73.182,93.042"/>
	<path id="house" fill="#010202" d="M427.061,106.668l-58.354,52.518v91.178h46.683v23.343h-70.025v-93.368h-35.012L427.061,75.302
		l87.052,78.346l-28.697,28.698v-23.16L427.061,106.668z"/>
	<g id="pen" fill="currentColor">
		<path d="M532.575,170.299c1.666,0,3.216,0.673,4.447,1.903l14.884,14.897c2.483,2.45,2.509,6.461,0.067,8.923
			c-0.044,0.022-0.044,0.046-0.067,0.046l-11.673,11.671l-23.864-23.866l11.672-11.671
			C529.227,171.005,530.868,170.333,532.575,170.299L532.575,170.299z"/>
		<polygon points="509.487,190.608 533.375,214.556 462.804,285.377 438.732,285.377 438.732,261.306 		"/>
	</g>
</g>
</svg>
  
</template>