<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1000px"
	 height="1000px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
<g id="goodBad">
	<path fill="#CC1236" d="M825.672,500l137.874,137.81l-82.788,82.788l-137.81-137.874L604.756,720.979L522,638.189L660.192,500
		L522,361.811l82.756-82.789l138.192,138.255l138.192-137.874l82.786,82.788L825.672,500z"/>
	<path fill="#0A9A39" d="M585.248,244.849c14.298-8.643,32.917-4.388,42.104,9.605l30.775,47.025
		c8.339,12.718,6.33,29.508-4.808,39.847l-0.057,0.123l-0.485,0.429l-1.704,1.638l-7.055,6.691
		c-38.817,37.601-76.41,76.41-112.792,116.373c-68.376,75.258-149.651,173.744-204.338,269.316
		c-15.269,26.645-52.556,32.364-74.64,9.365L50.28,535.384c-11.918-12.412-11.556-32.117,0.857-44.045
		c0.238-0.239,0.485-0.485,0.723-0.667l61.018-55.05c11.07-9.976,27.681-10.708,39.543-1.826l103.111,77.257
		C416.502,352.34,507.752,291.445,585.248,244.849z"/>
</g>
</svg>
</template>