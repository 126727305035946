<template>
<svg version="1.1" id="root" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="no-shadow">
	<g id="house_2_">
		<g id="XMLID_1_">
			<g>
				<path id="house_3_" fill="#010202" d="M549.729,190.235h-34.142v91.035H356.265v-91.035h-34.142L435.927,87.81L549.729,190.235z
					 M492.831,258.506v-88.876l-56.904-51.217l-56.907,51.217v88.876H492.831z"/>
			</g>
		</g>
		<g>
			<rect x="424.64" y="231.577" fill="#010202" width="22.587" height="38.898"/>
			<rect x="396.995" y="165.189" fill="#010202" width="21.467" height="32.509"/>
			<rect x="452.211" y="165.189" fill="#010202" width="21.466" height="32.509"/>
		</g>
	</g>
	<path id="sun_1_" fill="#010202" d="M109.561,119.448c-20.645,0-37.378-16.729-37.378-37.378c0-20.645,16.733-37.378,37.378-37.378
		c20.649,0,37.378,16.733,37.378,37.378C146.939,102.719,130.21,119.448,109.561,119.448z M109.561,111.438
		c16.217,0,29.368-13.151,29.368-29.369s-13.151-29.368-29.368-29.368S80.193,65.853,80.193,82.07S93.344,111.438,109.561,111.438z
		 M79.354,112.272c1.564,1.564,1.564,4.099,0,5.663l-9.438,9.438c-1.501,1.627-4.036,1.71-5.653,0.208
		c-0.568-0.521-0.97-1.199-1.158-1.939c-0.359-1.418,0.079-2.931,1.148-3.921l9.438-9.449
		C75.255,110.708,77.789,110.708,79.354,112.272L79.354,112.272z M64.252,36.761c1.564-1.56,4.098-1.56,5.663,0l9.438,9.443
		c1.622,1.502,1.715,4.031,0.208,5.653c-0.521,0.563-1.2,0.965-1.951,1.152c-1.418,0.365-2.919-0.073-3.916-1.147l-9.443-9.438
		C62.693,40.859,62.693,38.325,64.252,36.761z M109.561,17.994c2.211,0,4.005,1.794,4.005,4.005v13.349
		c0,2.211-1.794,4.005-4.005,4.005s-4.005-1.794-4.005-4.005V21.999C105.556,19.788,107.35,17.994,109.561,17.994z M66.844,82.07
		c0,2.211-1.794,4.005-4.005,4.005H49.49c-2.211,0-4.005-1.794-4.005-4.005s1.794-4.004,4.005-4.004h13.349
		C65.05,78.065,66.844,79.859,66.844,82.07z M109.561,124.788c2.211,0,4.005,1.793,4.005,4.004v13.35
		c0,2.21-1.794,4.004-4.005,4.004s-4.005-1.794-4.005-4.004v-13.35C105.556,126.581,107.35,124.788,109.561,124.788z M173.637,82.07
		c0,2.211-1.794,4.005-4.005,4.005h-13.349c-2.211,0-4.005-1.794-4.005-4.005s1.794-4.004,4.005-4.004h13.349
		C171.843,78.065,173.637,79.859,173.637,82.07z M139.764,112.272c1.564-1.564,4.099-1.564,5.663,0l9.438,9.449
		c1.471,1.575,1.429,4.025-0.094,5.559c-1.533,1.523-3.983,1.564-5.559,0.094l-9.449-9.428
		C138.199,116.381,138.199,113.837,139.764,112.272L139.764,112.272z M154.865,36.761c1.565,1.564,1.565,4.098,0,5.663l-9.438,9.438
		c-1.575,1.47-4.036,1.429-5.559-0.094c-1.522-1.528-1.564-3.989-0.104-5.564l9.438-9.443
		C150.767,35.196,153.3,35.196,154.865,36.761L154.865,36.761L154.865,36.761z"/>
	
		<path id="onde" opacity="0.80" fill="none" stroke="currentColor" stroke-width="11" stroke-linecap="round" stroke-linejoin="round" d="
		M223.432,193.612c48.239-16.125,74.274-68.291,58.146-116.538 M212.485,160.862c30.137-10.074,46.412-42.691,36.334-72.838
		 M201.535,128.103c12.055-4.03,18.566-17.068,14.535-29.132"/>
</g>
</svg>
</template>