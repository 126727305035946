// import { useStore } from 'vuex';


// export function getImgUrl (imgName) {
//   let img = new URL(`/src/assets/img/forms/${imgName}.png`, import.meta.url);
//   if (img.pathname === "/undefined") {
//     img = new URL(`/src/assets/img/forms/no-image.png`, import.meta.url);
//   }
//   console.log('getImgUrl', imgName, img, img.pathname);
//   return img;
// }

export function getImgUrl(imgName,imgDir = "img/forms") {
  // console.log('getImgUrl', imgName);
  const images = require.context('@/assets/' + imgDir, false, /\.png$/);
  // console.log('getImgUrl', images);
  try {
    return images('./' + imgName + ".png");
  } catch (e) {
    console.error('getImgUrl', e)
    return images("./no-image.png");
  }
}

// export const getObjectValueFromString = (string, startPoint) => {
//   try{
//     // const propertyPath = 'myData.level1.test';
//     console.log('getObjectValueFromString startPoint', startPoint);
//     const propertyPath = string;
//     console.log('getObjectValueFromString propertyPath', propertyPath);
//     const keys = propertyPath.split('.'); // sépare la chaîne en une liste de clés
//     console.log('getObjectValueFromString keys', keys);
//     const value = keys.reduce((obj, key) => obj[key], startPoint); // utilise chaque clé pour accéder à la propriété suivante
//     return value;
//   } catch (e) {
//     console.log('ERROR', e);
//   }

// }

//no-useless-escape
export const getObjectValueFromString = (string, startPoint) => {
  try{
    // const propertyPath = 'myData.level1.test';
    // console.log('getObjectValueFromString startPoint', startPoint);
    const propertyPath = string;
    // console.log('getObjectValueFromString propertyPath', propertyPath);
    const keys = propertyPath.split(/[.\[\]]+/); //eslint-disable-line
    // console.log('getObjectValueFromString keys', keys);
    const value = keys.reduce((acc, key) => {
      if (key && acc) {
        return acc[key];
      }
      return acc;
    }, startPoint); // utilise chaque clé pour accéder à la propriété suivante
    return value;
  } catch (e) {
    console.log('ERROR', e);
  }

}

//--- A TESTER pour rechercher une variable avec un chemin pointé sous forme de string
export const findInObjectWithDotPath = ({storeStartPoint, stringPathValue}) => {
  // const store = useStore();//eslint-disable-line
  const strToEval = `${storeStartPoint}${stringPathValue}`;
  const exec = eval(strToEval);
  console.log({storeStartPoint, stringPathValue, strToEval, exec});
  alert(exec);
  return exec;
}

