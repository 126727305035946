<template>
  <svg version="1.1" id="Yes" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="Yes_1_">
	<path opacity="0.8" fill="none" stroke="currentColor" stroke-width="18" d="M382.167,164.845v98.504H204.876v-98.504l-39.4-0.178
		l128.04-128.04l128.04,128.04L382.167,164.845z"/>
	<g>
		<path fill="none" d="M480.524,188.752v90.406h-90.407v-90.406H480.524z M437.558,276.363l-0.045,0.015l-0.265,0.132l-0.073,0.015
			l-0.059-0.015l-0.266-0.147c-0.028,0-0.073,0-0.087,0.029l-0.016,0.044l-0.059,1.605l0.015,0.074l0.036,0.059l0.392,0.279
			l0.058,0.015l0.044-0.015l0.398-0.279l0.043-0.074l0.015-0.059l-0.066-1.605C437.616,276.392,437.586,276.363,437.558,276.363z
			 M438.558,275.936l-0.058,0.016l-0.693,0.353l-0.028,0.03l-0.015,0.044l0.066,1.618l0.022,0.045l0.028,0.029l0.751,0.353
			c0.045,0.015,0.089,0,0.118-0.029l0.014-0.058l-0.132-2.311C438.616,275.981,438.595,275.951,438.558,275.936z M435.865,275.951
			c-0.036-0.016-0.073-0.016-0.104,0.015l-0.021,0.059l-0.125,2.311c0,0.043,0.021,0.073,0.058,0.087h0.061l0.749-0.353l0.045-0.029
			l0.016-0.045l0.059-1.618l-0.009-0.044l-0.036-0.044L435.865,275.951z"/>
		<path fill="#009640" class="icon-symbole symbole-good" d="M463.479,203.097c1.736-1.045,3.986-0.53,5.091,1.162l3.724,5.694c1.015,1.531,0.764,3.569-0.574,4.812
			l-0.015,0.022l-0.06,0.051l-0.206,0.191l-0.853,0.81c-4.694,4.554-9.241,9.248-13.642,14.082
			c-8.27,9.094-18.099,21.012-24.706,32.563c-1.854,3.223-6.357,3.915-9.035,1.134l-24.426-25.383
			c-1.442-1.5-1.398-3.885,0.103-5.326c0.03-0.03,0.059-0.052,0.088-0.074l7.386-6.666c1.339-1.207,3.341-1.295,4.782-0.213
			l12.464,9.345C443.07,216.105,454.105,208.732,463.479,203.097z"/>
	</g>
	<g>
		<path fill="currentColor" d="M302.162,100.8c-9.194,0-16.645,7.984-16.645,17.834v67.896c-11.482,9.193-13.335,25.954-4.142,37.436
			c9.187,11.482,25.948,13.341,37.43,4.148c11.482-9.193,13.341-25.955,4.135-37.436c-1.222-1.535-2.601-2.913-4.135-4.148v-67.896
			C318.806,108.784,311.355,100.8,302.162,100.8z M312.148,160.92c-4.85,0.085-9.388-1.099-13.367-2.125
			c-2.444-0.637-4.655-1.209-6.605-1.405v-38.756c0-5.91,4.473-10.702,9.987-10.702c5.513,0,9.986,4.792,9.986,10.702v2.061h-6.657
			c-1.847,0-3.329,1.489-3.329,3.329c0,1.833,1.482,3.329,3.329,3.329h6.657v6.658h-6.657c-1.847,0-3.329,1.489-3.329,3.329
			c0,1.833,1.482,3.329,3.329,3.329h6.657v6.658h-6.657c-1.847,0-3.329,1.489-3.329,3.329c0,1.833,1.482,3.329,3.329,3.329h6.657
			V160.92z"/>
		<path fill="#009FE3" d="M279.358,140.772l-12.332-7.234v-8.052l6.096-6.092l-5.066-5.024l-4.593,4.596l-4.597-4.596l-5.059,5.024
			l6.093,6.092v8.052l-8.908,5.133l-7.126-4.029l-2.067-8.299l-6.841,1.851l1.674,6.309l-6.305,1.674l1.852,6.875l8.302-2.209
			l7.126,4.029v10.292l-7.126,4.029l-8.302-2.206l-1.852,6.875l6.305,1.67l-1.674,6.277l6.875,1.851l2.21-8.302l7.126-4.029
			l8.73,5.136v8.045l-6.093,6.096l5.059,5.025l4.597-4.594l4.593,4.594l5.024-5.025l-6.054-6.096v-8.045l12.332-7.168
			 M254.556,144.871l8.908-5.167l8.908,5.167v10.258l-8.908,5.17l-8.908-5.17"/>
	</g>
</g>
</svg>

</template>