<template>
  <div class="help-modal-overlay" v-if="isOpen" @click.stop="closeHelp"></div>


  <div class="help-modal-wrapper" v-if="isOpen">

    <div class="help-modal-container">
      <div class="top-bar">
        <h1 class="help-modal-title">
          {{ helpTitle }}
        </h1>
        <button class="btn btn-danger btn-sm" @click.prevent="closeHelp">X</button>
      </div>

      <!-- <pre>{{data}}</pre>
        helpComponenentName: {{helpComponenentName}} -->
      <!-- <div class="help-modal-content"> -->
      <component :is="helpComponenentName" class="help-modal-content" />
      <!-- </div> -->
    </div>
  </div>

</template>

<script>
  import { matomoTrackEvent } from '@/utils/matomoTracker';
  export default {
    name: "helpModalBox",
    data() {
      return {
        isOpen: false,
        data: {
          componentName: null,
          title: null
        },
      };
    },
    computed: {
      helpComponenentName() {
        return this.data.componentName;
      },
      helpTitle() {
        return this.data.title;
      }
    },
    mounted() {
      this.emitter.on("help-modal-open", (isOpen) => {
        // alert('ttt :' + isOpen);
        this.isOpen = isOpen;
      });
      this.emitter.on("help-modal-data", (data) => {
        // alert('ttt :' + isOpen);
        this.data = data;
        matomoTrackEvent('ModalBox', 'Open', this.data.title, 1);
      });
    },
    methods: {
      closeHelp() {
        // this.isOpen = false;
        this.emitter.emit("help-modal-open", false);
      },
    },
    watch: {
      isOpen(newVal) {
        if (newVal) {
          // alert('Block scoll');
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .help-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 500;
  }

  .help-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 501;
    pointer-events: none;

    .top-bar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid #ccc;
      margin: 0 0 0.8rem 0;

      h1 {
        font-size: 1.5rem;
      }
    }

    .help-modal-container {
      pointer-events: auto;
      background-color: #fff;
      padding: 1rem;
      width: 80%;
      min-width: 320px;
      max-width: 1290px;
      // height: auto;
      // height: 100%;
      min-height: 30vh;
      max-height: 90vh;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);

      .help-modal-title {
        font-size: 1.2rem;
        max-height: 20vh;
      }

      .help-modal-content {
        // border: 1px solid red;
        overflow-x: hidden;
        // height: 100%;
        min-height: 30vh;
        max-height: 80vh;
        padding: 1rem;

        h2 {
          font-size: 1.1rem !important;
        }

        p {
          text-align: justify;
        }
      }

    }
  }
</style>
