<template>

  <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['isAirBlower']"
      v-model="theValue['isAirBlower']"
      :qstParams="getQstParam('isAirBlower')"
      />

  <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['overventilationType']"
      v-model="theValue['overventilationType']"
      :qstParams="getQstParam('overventilationType')"
      />

      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['userBehaviorElecEquipments']"
      v-model="theValue['userBehaviorElecEquipments']"
      :qstParams="getQstParam('userBehaviorElecEquipments')"
      />

    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['isClim']"
    v-model="theValue['isClim']"
    :qstParams="getQstParam('isClim')"
    />

  <TransitionQst class="transition">
    <div v-if="theValue['isClim']">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[formName]['climType']"
      v-model="theValue['climType']"
      :qstParams="getQstParam('climType')"
      />

      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[formName]['climNbRoom']"
      v-model="theValue['climNbRoom']"
      :qstParams="getQstParam('climNbRoom')"
      />
    </div>
  </TransitionQst>
  <!-- formName: <pre>{{ formName }}</pre> -->
  <!-- inputValidationObj: <pre>{{ props.validationObj[formName] }}</pre> -->

<!-- theValue['roofType']: {{ theValue['roofType'] }}<br />
theValue['roofComposition']: {{ theValue['roofComposition'] }}<br />
theValue['roofColor']: {{ theValue['roofColor'] }}<br /> -->

</template>

<script setup>
  import { ref, watch, computed} from 'vue';
  // import { useStore } from 'vuex';
  import InputFormRowGlobal from './InputFormRowGlobal.vue';

  const props = defineProps({
  stepId: {
    type: Number,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  },
  formName: {
      type: String,
      required: true
    },
  formQst: {
    type: Array,
    required: true
  },
  validationObj: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);

const theValue = ref(props.modelValue[[props.formName]]);
watch (theValue, (newVal) => {
    emit('update:modelValue', newVal)
  });

const getQstParam = (varname) => {
  const param = props.formQst.find((qst) => qst.varname === varname);
  return param;
}

  //-- FOR THE SPECIFIC FORM
// const store = useStore();
// const simuData = computed(() => store.getters.simuData);

const isClim = computed(() => theValue.value['isClim']);
watch(isClim, (newVal) => {

  if (newVal === true) {
    theValue.value['climType'] = null;
    theValue.value['climNbRoom'] = null;
  } else {
    theValue.value['climType'] = 'none';
    theValue.value['climNbRoom'] = 'none';
  }
}, {deep: true});

// const roofInsulationImprovement = computed(() => theValue.value['roofInsulationImprovement']);
// watch (roofInsulationImprovement, (newVal) => {
//   if (newVal === 'none') {
//     theValue.value['roofInsulationType'] = 'ite';
//   } else if (newVal === 'unknow') {
//     theValue.value['roofInsulationType'] = 'iti'
//   }
// }, {deep: true});
</script>
