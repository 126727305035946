<template>
  <div>
    <!-- <h3>Template 2 : {{ props.resultType }} / {{ props.roomId}}</h3> -->

    <!-- simuResults: <pre>{{ simuResults }}</pre>
    data4Chart: <pre>{{ data4Chart }}</pre> -->



    <!----------------------------------------------------
        BATI RESULTS
    ---------------------------------------------------->
    <template v-if="props.resultType === 'bati'">

      <CustomBlock v-if="showFinalScoreBar">
        <template v-slot:title>
          Niveau général d'inconfort : {{ finalScore }}%
        </template>
        <template v-slot:body>
          <ChartFinalScore :score="finalScore" />
        </template>
      </CustomBlock>

      <CustomBlock>
        <template v-slot:title>
          Evaluation qualitative du confort d'été
        </template>
        <template v-slot:body>
          <div class="row">
            <!-- <div class="col-12 col-md-6 d-flex justify-content-center align-items-center">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae
                ipsam vero aliquam ipsa voluptatem accusantium quaerat amet
                sequi aspernatur provident. Mollitia, minus deleniti aliquam
                sint qui est eaque placeat ipsam.
              </p>
            </div> -->
            <div class="col col-12 col-md-6-">
              <div class="card-">
                <div class="card-body-">
                  <MainScore :mainScore="finalScore" :roomsScore="[]" :isDebug="isDebug" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </CustomBlock>


      <CustomBlock>
        <template v-slot:title>
          Origines et évaluation des sources d’inconfort
        </template>
        <template v-slot:body>
          <div class="alert alert-info">
            <p>L’outil propose 7 potentielles sources d’inconfort, cartographiées ci-après.<br>Le graphique ci-dessous reprend les sources d’inconfort en fonction des données saisies précédemment. Plus le point est éloigné du centre du graphique, plus la source d’inconfort associée sera grande (et inversement).</p>
          </div>
          <hr>
          <div class="row d-flex align-items-start mt-5">
            <div class="col-12 col-lg-6">
              <ChartRadar
                :dataLabels="chartDataDiscomforts.labels"
                :dataSeries="chartDataDiscomforts.series"
              />
              <div class="debug-bloc" v-if="isDebug">
              <div class="result-resume-table table-responsive">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Inconforts</th>
                      <th>Coef.</th>
                      <th>%</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        discomfortResult, discomfortName
                      ) in discomfortsResume"
                      :key="`discomfortsResume-${discomfortName}`"
                    >
                      <td>{{ discomforts[discomfortName]?.label }}</td>
                      <td>{{ discomfortResult.coef }}</td>
                      <td>{{ discomfortResult.result }} %</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
            <div class="col-12 col-lg-6">
              <ResAlerts />
              <!-- <ResAlerts2 /> -->
            </div>
          </div>
        </template>
      </CustomBlock>

      <!-- <CustomBlock>
        <template v-slot:title>
          [Graph de test]
        </template>
        <template v-slot:body>
          <ChartColumns :data4Chart="data4ChartDiscomfortsSrc" />
        </template>
      </CustomBlock> -->


      <CustomBlock>
        <template v-slot:title>
          Estimation des durées d'inconfort
        </template>
        <template v-slot:body>
          <div class="row">
            <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-center">
              <!-- <h4>Nombre de jours potentiels de risque d'inconfort sur la période estivale</h4> -->


                <div class="row w-100 h-100">
                  <div class="col-12 col-lg-6- d-flex flex-column justify-content-center align-items-center">
                    <!-- <strong style="font-size: 4rem;">{{ quantData.resume.nbHotDays }}</strong> -->

                    <template v-if="rangeNbHotDays.min === 0">
                      <strong style="font-size: 2.5rem;">Moins de {{ rangeNbHotDays.max }}</strong>
                    </template>
                    <template v-else>
                      <span style="font-size: 1.5rem;">Entre</span>
                      <strong style="font-size: 2.5rem;">{{ rangeNbHotDays.min }} et {{ rangeNbHotDays.max }}</strong>
                    </template>
                    <span style="font-size: 1.5rem;">Jours d'inconfort</span>
                    <span style="font-size: .8rem;">(potentiel de risque sur la période estivale)</span>
                  </div>
                  <!-- <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                    <strong style="font-size: 4rem;">{{ quantData.resume.nbHotMonth }}</strong>
                    <span>MOIS</span>
                  </div> -->
                </div>
                
                <div>
                  <small>On estime dans l’évaluation qu’une journée est qualifiée d’inconfortable dès lors que la température opérative, c’est-à-dire la température ressentie par l’occupant, dépasse les 28°C pendant 3h consécutives.</small>
                </div>
                
                <!-- <div>
                  <small>Nombre d'heures chaudes / an: {{ quantData.resume.nbHotHours }}</small><br>
                  <small>Degrés-heure Total: {{ quantData.resume.degreHourTotal }}</small>
                </div> -->

              <!-- <ul class="list-group list-group-flush w-100">
                <li class="list-group-item">
                  Nombre d'heures chaudes / an:
                  <strong>{{ quantData.resume.nbHotHours }}</strong>
                </li>
                <li class="list-group-item">
                  JOURS :
                  <strong>{{ quantData.resume.nbHotDays }}</strong>
                </li>
                <li class="list-group-item">
                  MOIS :
                  <strong>{{ quantData.resume.nbHotMonth }}</strong>
                </li>
                <li class="list-group-item">
                  Degrés-heure Total:
                  <strong>{{ quantData.resume.degreHourTotal }}</strong>
                </li>
              </ul> -->
            </div>
            <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
              <!-- <ChartCustom /> -->
              <div class="w-100">
                <h5 class="text-center">Répartition des jours d’inconfort</h5>
                <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo harum maiores unde, animi repellendus, ipsa sint beatae inventore velit architecto saepe nisi minus nemo sit quo suscipit impedit dicta ratione!</p> -->
                <ChartColumns :data4Chart="data4ChartQuantitatif" />
              </div>
            </div>
          </div>
        </template>
      </CustomBlock>

      <CustomBlock>
        <template v-slot:title>
          Recommandations de travaux privilégiant les techniques de rafraîchissement passif
          <HelpBtn helpComponent="HelpRecoTvx" helpTitle="Recommandations de travaux privilégiant les techniques de rafraîchissement passif" />
        </template>
        <template v-slot:body>
          <!-- <small>TODO !</small> -->
          <div class="alert alert-info">
            
            <p>Cette série de conseils et pistes d’améliorations, non exhaustive, a pour objectif d’améliorer le confort de vie et limiter le recours systématique à la climatisation (car il est urgent d’avoir une utilisation raisonnée de la climatisation, de retarder au maximum son utilisation).</p>
            <p>Nous préconisons également d’adopter une approche systémique, c’est-à-dire de combiner plusieurs techniques de rafraichissement pour une meilleure efficacité. En effet, la plupart de ces techniques ne pourront contrer les effets de l’augmentation des températures si elles sont utilisées seules.</p>
            <p>Enfin, nous encourageons à tenir compte des réglementations administratives et de sécurité pour certaines solutions et à faire appel à des professionnels qualifiés pour obtenir des conseils personnalisés ainsi que pour une mise en œuvre et une qualité de pose optimales.</p>
          </div>
          <div class="alert alert-warning d-flex flex-row justify-content-start align-items-center">
            <i class="bi bi-exclamation-square fs-5 m-3"></i>
            <div><strong>Les recommandations proposées ci-après tiennent compte des données saisies au-préalable dans l’outil, et sont relatives aux configurations du bâtiment décrit.</strong></div>
          </div>
          <hr>
          <!-- <ResReco focusTvxPoste="Ventilation" /> -->
          <ResRecoBatiView />
        </template>
      </CustomBlock>

      <CustomBlock>
        <template v-slot:title>
          Pour aller plus loin : des outils disponibles sur le LAB
        </template>
        <template v-slot:body>

          <!-- PORTRAIT  -->
          <div class="d-flex justify-content-center align-items-center flex-wrap">
            <template v-for="(itemLAB, itemLABIndex) in listeItemsLAB1" :key="`itemLAB-${itemLABIndex}`" >

              
              <div class="card mb-3" style="max-width: 600px;" v-if="itemLAB.format === 'portrait'">
                <div class="row g-0">
                  <div class="col-md-4">
                    <a :href="itemLAB.link" target="_blank"><img :src="itemLAB.img" class="img-fluid rounded-start" alt="image"></a>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">{{itemLAB.type}}</h5>
                      <div class="card-text">
                        <a :href="itemLAB.link" target="_blank">{{itemLAB.title}}</a></div>
                      <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- /PORTRAIT  -->



        <!-- LANDSCAPE  -->
        <div class="d-flex justify-content-evenly align-items-center flex-wrap">
          <template v-for="(itemLAB, itemLABIndex) in listeItemsLAB2" :key="`itemLAB2-${itemLABIndex}`" >
            <div class="card mb-3" style="max-width: 450px;">
              <div class="row g-0">
                <div class="col-12">
                  <a :href="itemLAB.link" target="_blank"><img :src="itemLAB.img" class="img-fluid rounded-start" style="max-height: 252px;" alt="image"></a>
                </div>
                <div class="col-12">
                  <div class="card-body">
                    <h5 class="card-title">{{itemLAB.type}}</h5>
                    <div class="card-text">
                      <a :href="itemLAB.link" target="_blank">{{itemLAB.title}}</a></div>
                    <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- /LANDSCAPE  -->

        </template>
      </CustomBlock>

    </template>




    <!----------------------------------------------------
        ROOMS RESULTS
    ---------------------------------------------------->
    <template v-else-if="props.resultType === 'room'">
      finalScore ROOM = {{ finalScore }}
      <MainScore :mainScore="finalScore" :roomsScore="roomsScore" />

      <div class="row d-flex align-items-center">
        <div class="col-12 col-lg-6">
          <!-- <ChartPyramid :data4Chart="data4Chart" /> -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- roomData: <pre>{{ roomData }}</pre> -->
          <!-- <div class="d-flex justify-content-center align-items-center">
        <component :is="getRoomIcon(roomData.roomType)" width="100" height="100"></component>
      </div> -->

          <!-- <div class="result-resume-table- table-responsive">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>Inconforts</th>
                  <th>Pièce</th>
                  <th>Bâti</th>
                </tr>
              </thead>
              <tbody>
                <tr>
              <td>Environnement</td>
              <td>{{ discomfortsResume.environment?.result }} %</td>
              <td>{{ simuResults.bati.environment?.result }} %</td>
            </tr>
                <tr>
                  <td>Inertie</td>
                  <td>{{ discomfortsResume.buildingInertia?.result }} %</td>
                  <td>{{ simuResults.bati.buildingInertia?.result }} %</td>
                </tr>
                <tr>
                  <td>Parois vitrée</td>
                  <td>{{ discomfortsResume.buildingWindows?.result }} %</td>
                  <td>{{ simuResults.bati.buildingWindows?.result }} %</td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </div>
      </div>
    </template>
    <template v-else> ERROR ! resultType: {{ props.resultType }} -> unknow ! </template>



    <!----------------------------------------------------
        DEBUG RESULTS
    ---------------------------------------------------->
    <div class="debug" v-if="isDebug">
      <fieldset>
        <legend>
          DEBUG :
          <select class="form-select-" v-model="degubItemToShow">
            <option :value="null">Selection...</option>
            <option
              v-for="debugItem in debugItems"
              :key="`debugItem-${debugItem}`"
              :value="debugItem"
            >
              {{ debugItem }}
            </option>
          </select>
        </legend>

        <!-- quantData : <pre>{{ quantData }}</pre> -->

        <div v-if="degubItemToShow === 'pasHoraire'">
          <div class="result-resume-table table-responsive debug-bloc">
            <h2>pasHoraire</h2>

            <div class="row">
              <div class="col">
                setCorrectionRaw :
                <pre>{{ debug.setCorrectionRaw }}</pre>
              </div>
              <div class="col">
                setCorrectionModule :
                <pre>{{ debug.setCorrectionModule }}</pre>
              </div>
              <div class="col">
                setEfficiency :
                <pre>{{ debug.setEfficiency }}</pre>
              </div>
              <div class="col">
                getQuantitativeData :
                <pre>{{ debug.getQuantitativeData }}</pre>
              </div>
            </div>

            <ul>
              <li>buildingType: {{ debug.buildingType }}</li>
              <li>
                setIsProctectionOrCalm: {{ debug.setIsProctectionOrCalm }}
              </li>
            </ul>

            <div>
              <div class="row">
                <div class="col">chartDataQuantitatif monthsData : <pre>{{ chartDataQuantitatif.monthsData }}</pre></div>
                <div class="col">chartDataQuantitatif OUT : <pre>{{ chartDataQuantitatif.out }}</pre></div>
                <div class="col">chartDataQuantitatif DEBUG : <pre>{{ chartDataQuantitatif.debug }}</pre></div>
              </div>
              <!-- counterHotHours : <pre>{{ quantData.counterHotHours }}</pre> -->
              pasHoraireDebugMonth: {{ pasHoraireDebugMonthSelected }}
              <select
                class="form-select"
                v-model="pasHoraireDebugMonthSelected"
              >
                <option
                  v-for="m in pasHoraireDebugMonthList"
                  :key="`month-${m}`"
                  :value="m"
                >
                  MOIS {{ m }}
                </option>
              </select>
            </div>
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th v-for="th in quantDataMatriceTH" :key="th">{{ th }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(itemHour, indexHour) in quantDataMatrice"
                  :key="`qst-${indexHour}`"
                >
                  <td v-for="th in quantDataMatriceTH" :key="th">
                    {{ itemHour[th] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="degubItemToShow === 'discomfortsDetails'">
          <div class="result-resume-table table-responsive debug-bloc">
            <h2>discomfortsDetails</h2>
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>discomfortName</th>
                  <th>discomfortCoef</th>
                  <th>Qst</th>
                  <th>Nb Choices Max</th>
                  <th>QstCoef</th>
                  <th>QstValue</th>
                  <th>QstValueCoef</th>
                  <th>Ratio</th>
                  <th>Pond.</th>
                </tr>
              </thead>
              <tbody
                v-for="(discomfortData, discomfortName) in discomfortsDetails"
                :key="`discomfortDetails-${discomfortName}`"
                :id="`discomfortDetails-${discomfortName}`"
              >
                <tr
                  v-for="(qstData, qstVarname) in discomfortData.questions"
                  :key="`qst-${qstVarname}`"
                >
                  <td>{{ discomfortName }}</td>
                  <td>{{ discomfortData.coef }}</td>
                  <td>
                    {{ qstVarname }}<br /><small>{{ qstData.label }}</small>
                  </td>
                  <td>{{ qstData.nbChoicesMax }}</td>
                  <td>{{ qstData.coef }}</td>
                  <td>{{ qstData?.value?.value }}</td>
                  <td>{{ qstData?.value?.coef }}</td>
                  <td>{{ qstData.ratio }}</td>
                  <td>{{ qstData.pond }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="degubItemToShow === 'inertiaInsulation'">
          <div class="debug-bloc">
            <h2>inertiaInsulation</h2>
            <div class="row">
              <div class="col">
                INERTIA: {{ debug.simuData.calculated.inertiaLevel }}
                <pre>{{ debug.inertia }}</pre>
              </div>
              <div class="col">
                INSULATION: {{ debug.simuData.calculated.insulationLevel }}
                <pre>{{ debug.insulation }}</pre>
              </div>
              <div class="col" v-if="props.resultType === 'room'">
                nbWallLight: {{ debug?.nbWallLight }}
                wallsCompositionLevel:
                <pre>{{ debug?.wallsCompositionLevel }}</pre>
                <hr />
                ORIENTATIONS:
                <pre>{{ debug?.orientationWorstCoef }}</pre>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import ChartRadar from "./charts/ChartRadar.vue";
import ChartFinalScore from "./charts/ChartFinalScore.vue";
import MainScore from "./charts/MainScore.vue";
import ChartColumns from "./charts/ChartColumns.vue";
// import ChartCustom from "./charts/ChartCustom.vue";
// import ChartPyramid from "./charts/ChartPyramid.vue";
import { discomforts /*, getRoomIcon*/ } from "../appConfig/index.js";
import ResAlerts from "./ResAlertes.vue";
// import ResAlerts2 from "./ResAlertes2.vue";
// import ResReco from "./ResReco.vue";
import ResRecoBatiView from "./ResRecoBatiView.vue";
import CustomBlock from "./CustomBlock.vue";
import {/*roundNumber, */getRangeNumber} from '../functions/numbers.js';


import { CalculMaster } from "../class/calculMaster.class.js";

const props = defineProps({
  resultType: {
    type: String,
    required: true,
  },
  roomId: {
    type: Number,
    //Required if resultType === 'room'
  },
});

const showFinalScoreBar = ref(false);

const store = useStore();
const simuData = computed(() =>
  JSON.parse(JSON.stringify(store.getters.simuData))
);
const isDebug = computed(() => store.getters.isDebug);
// const roomData = computed(() => store.getters.room({roomId: props.roomId}));
//--
const calculMaster = new CalculMaster(simuData.value, {
  resultType: props.resultType,
  roomId: props.roomId,
});
const { discomfortsDetails, discomfortsResume, chartDataDiscomforts, finalScore } = calculMaster.setDiscomfortsAnaliz();
const quantData = calculMaster.getQuantitativeData();


store.dispatch("saveResult", {
  typeResult: props.resultType,
  familyResult: 'discomforts',
  dataResult: discomfortsResume,
  roomId: props.roomId,
});
store.dispatch("saveResult", {
  typeResult: props.resultType,
  familyResult: 'TOTO',
  dataResult: finalScore,
  roomId: props.roomId,
});

const debug = calculMaster.getDebug();
const calculatedData = calculMaster.getCalculatedData();
store.dispatch("saveSimuData", {
  calculated: {
    result: calculatedData,
    debug:{
      inertia: debug.inertia,
      insulation: debug.insulation
    }
  }
});


const degubItemToShow = ref("pasHoraire");
const debugItems = ["discomfortsDetails", "pasHoraire", "inertiaInsulation"];

const rangeNbHotDays = computed(() => getRangeNumber(quantData.resume.nbHotDays));

const data4ChartQuantitatif = computed(() => {
  const data = {
    apexchart: {
      height: 280,
      type: 'bar'
    },
    series: {
      name: 'Nombre de jours chauds',
      data: chartDataQuantitatif.value.serie,
    },
    chartOptions: {
      chart: {
        toolbar: {
          show: false
        },
      },
      xaxis: {
        categories: [
            "Mai",
            "Juin",
            "Juil.",
            "Août",
            "Sept.",
        ],
        tooltip: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      yaxis: {
        max: 31,
        tickAmount: 3,
      }
    }
  };

  return data;
});

//-- PAS HORAIRE
const pasHoraireDebugMonthSelected = ref(5);
const pasHoraireDebugMonthList = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

const quantDataMatriceTH = computed(() => {
  return Object.keys(quantData.nMatrice[0]);
});

const quantDataMatrice = computed(() => {
  return quantData.nMatrice.filter(
    (item) => item.month === pasHoraireDebugMonthSelected.value
  );
});
//-- /PAS HORAIRE

const chartDataQuantitatif = computed(() => {
  const out = [];
  const debug = [];
  const serie =[];// may to september
  let monthIndex = 0;
  let lastMonth = null;
  for (const [monthKey, monthValue] of Object.entries(quantData.counterHotHours)) {//month
    // console.log(`${monthKey}: ${monthValue}`);
    out.push({monthKey, monthValue});

    if (!serie[monthIndex]) {
      serie[monthIndex] = 0;
    }

    for (const [dayKey, dayValue] of Object.entries(monthValue.days)) {//days
      if(dayValue.isHot === true) {
        serie[monthIndex] += 1;
        debug.push({monthIndex, dayKey, dayValue})
      }
    }
    if (lastMonth !== monthKey) {
      monthIndex++;
    }
    lastMonth = monthKey;
  }
  return {serie, out, debug};
});

//--
const simuResults = computed(() => store.getters.simuResults);

const listeItemsLAB1 = [
  {
    type: "Guide",
    title: "Rafraîchissement passif et confort d'été : panorama de solutions pour l'adaptation du bâtiment au changement climatique",
    link: "https://lab.cercle-promodul.inef4.org/tool_type/guides-rapports-et-retours-dexperiences/tool/rafraichissement-passif-et-confort-dete-panorama-de-solutions-pour-ladaptation-du-batiment-au-changement-climatique",
    img: "https://lab.cercle-promodul.inef4.org/storage/uploads/IPJWTqhlX5BlgFuyHIaxi0t0q6nkuzTpiN5HpBkj.png",
    format: "portrait"
  },
];

const listeItemsLAB2 = [
  {
    type: "Test de connaissances",
    title: "Confort d'été et adaptation des bâtiments au réchauffement climatique : tendre vers le rafraîchissement passif",
    link: "https://lab.cercle-promodul.inef4.org/tool_type/tester-et-ameliorer-ses-connaissances/tool/confort-dete-et-adaptation-des-batiments-au-rechauffement-climatique-tendre-vers-le-rafraichissement-passif",
    img: "https://lab.cercle-promodul.inef4.org/storage/uploads/h4zAujNqr61gHhMbYmllDnqEOyFs8D9NHpnkJUT3.jpeg",
    format: "landscape"
  },
  {
    type: "Fiche pratique dédiée",
    title: "Confort d'été et rafraîchissement passif : comprendre et passer à l'action",
    link: "https://lab.cercle-promodul.inef4.org/tool_type/fiches-pratiques/tool/confort-dete-et-rafraichissement-passif-comprendre-et-passer-a-laction",
    img: "https://lab.cercle-promodul.inef4.org/storage/uploads/DOFQAgeih90ZxKRCayxHxt7dwjKZ9g7zW9XYIv7u.jpeg",
    format: "landscape"
  },
]


//-- COmparaison Resultats Bati vs Current Room
const roomsScore = ref([
  {'label': 'Pièce 1', score: 55},
  // {'label': 'Pièce 2', score: 67},
])
const data4Chart = {
  series: [
    {
      name: "Identique au bâtiment",
      data: [],
    },
    {
      name: "Plus confortable",
      data: [],
    },
    {
      name: "Moins confortable",
      data: [],
    },
  ],
  categories: [],
  colors: ["#800080", "#008FFB", "#FF4560"],
  // title: "Comparaison d'inconfort par rapport au bâtiment"
  title: "",
};
if (props.resultType === "room") {
  const dataToCompare = [
    /*'environment', */ "buildingInertia",
    "buildingWindows",
  ];
  dataToCompare.forEach((varname) => {
    const roomValue = discomfortsResume[varname]?.result;
    const batiValue = simuResults.value.bati[varname]?.result;

    const diff = batiValue - roomValue;

    // console.log('dataToCompare', {roomValue, batiValue, diff});

    data4Chart.series[0].data.push(0.1);
    if (diff === 0) {
      // data4Chart.series[2].data.push(3);
      data4Chart.series[1].data.push(0);
      data4Chart.series[2].data.push(0);
    } else if (diff > 0) {
      data4Chart.series[1].data.push(diff);
      data4Chart.series[2].data.push(0);
    } else {
      data4Chart.series[1].data.push(0);
      data4Chart.series[2].data.push(diff);
    }

    data4Chart.categories.push(discomfortsDetails[varname].label);
  });
}
</script>

<style scoped lang="scss">
.result-resume-table {
  .table {
    font-size: 0.9rem;
    thead {
      // background-color: #333;
      // color: #fff;
      overflow: auto;

      th {
        background-color: #333;
        color: #fff;
        text-align: center;
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
    tbody {
      border-top: 2px solid #ccc;

      tr {
        border-top: 0;
      }

      &:nth-child(even) {
        background-color: #f5f5f5;
      }
      &:nth-child(odd) {
        background-color: #fff;
      }
    }
  }
}
</style>
