<template>
  <!-- isTvxOverlay: {{ isTvxOverlay }} -->
  <div class="container">
  <div class="recoTvxMain-bloc row">
    <div class="recoTvxMain-bati col-md-12 col-lg-4">

      <div class="batiView">
        <div class="batiWrapper">
          <SvgBatiView
          class="roof"
          itemType="roof"
          :activeItems="activeItems"
          :roofType="roofType"
          :selectedTvx="selectedTvx"
          @setSelectedTvx="setSelectedTvx"
          />
          <SvgBatiView
          class="bati"
          :itemType="batiType"
          :activeItems="activeItems"
          :selectedTvx="selectedTvx"
          @setSelectedTvx="setSelectedTvx"
          />
        </div>
        <div class="envWrapper">
          <SvgBatiView
          class="env"
          itemType="env"
          :activeItems="activeItems"
          :selectedTvx="selectedTvx"
          @setSelectedTvx="setSelectedTvx"
          />
        </div>

      </div>

      <div class="othersTvx">
        <div v-for="oTvx in othersTvx" :key="oTvx.id">
          <IconPosteTvx
            class="recoTvx-icon-item"
            :class="{ canBeFocus: isTvxActive(oTvx.id), selectedTvx: selectedTvx === oTvx.id}"
            :iconType="oTvx.id"
            @click="setSelectedTvx(oTvx.id)"
          ></IconPosteTvx>
        </div>
      </div>



    </div>
    <div
    class="recoTvxMain-liste col-md-12 col-lg-8"
    :class="{'no-overlayed': !isTvxOverlay}"
    id="listeTvx"
    @mouseenter="setFocus"
    @mouseleave="unsetFocus"
    >
      <template v-if="activeItems.length > 0">
        <ResReco :focusTvxPoste="selectedTvx" class="listeWrapper" id="listeReco"></ResReco>
      </template>
      <template v-else>
      <div class="d-flex justify-align-center align-items-center">
        <div class="alert alert-success m-2 p-3 m-lg-4 p-lg-5" role="alert">
          <h4 class="alert-heading">Bravo !</h4>
          <p>Aucune recommandation particulière n'est à signaler.</p>
          <!-- <hr>
          <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, dolore obcaecati vero alias sit dicta sed ratione impedit? Consequuntur molestiae voluptates accusamus voluptatem facere inventore a aut id, deleniti quasi?</p> -->
        </div>
      </div>
    </template>
    </div>
  </div>
</div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import ResReco from "./ResReco.vue";
import SvgBatiView from "./svg/BatiView.vue";
import { useConditionsManager } from "../composable/useConditionsManager.js";
import { recoTvx } from "@/appConfig";


const isTvxOverlay = ref(false);
onMounted(() => {
  isTvxOverlay.value = isOverlay();
})

const store = useStore();

const screenSize = computed(() => {
  return store.state.screenSize;
})

const recoTvxItems = useConditionsManager(recoTvx);

const selectedTvx = ref(null);

const activeItems = computed(() => {
  let out = [];
  recoTvxItems.messages.forEach((tvx) => {
    if (!out.includes(tvx.poste)) {
      out.push(tvx.poste);
    }
  });

  return out;
})

const othersTvx = ref([
  {id: 'clim', label: 'Climatisation'},
  {id: 'users', label: 'Utilisateurs'},
  {id: 'vmc', label: 'Ventilation'},
  // {id: 'equip', label: 'Equipements'}
]);


const batiType = computed(() => {
  const type = store.getters.batiType
  return `bati${type}`;
});
const roofType = computed(() => {
  return store.getters.roofType;
});

const setSelectedTvx = (tvx) => {
  if ( isTvxActive(tvx) )
    selectedTvx.value = tvx;
}

const isTvxActive = (item) => {
  return activeItems.value.includes(item)
}

const isOverlay = () => {
  const elm1 = document.getElementById('listeTvx');
  const elm2 = document.getElementById('listeReco');
  if (!elm2) return false;
  const offsetHeight1 = elm1.offsetHeight;
  const offsetHeight2 = elm2.offsetHeight;
  // console.log({offsetHeight1, offsetHeight2});
  return offsetHeight1 < offsetHeight2;
};

const setFocus = (elm) => {
  if (screenSize.value.w < 990 || activeItems.value.length === 0 || !isOverlay()) return;
  elm.target.focus({ preventScroll: true });
  elm.target.classList.add('focused');
  elm.target.scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" });
}

const unsetFocus = (elm) => {
  elm.target.classList.remove('focused');
  document.body.focus();
}

</script>