<template>
  <div class="cc_banner cc_container" :class="{'cc_container--open': showRgpd}" v-if="showRgpd">

    <div class="cc_message">
      <svg class="cookie-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><circle cx="10" cy="21" r="2" fill="currentColor"/><circle cx="23" cy="20" r="2" fill="currentColor"/><circle cx="13" cy="10" r="2" fill="currentColor"/><circle cx="14" cy="15" r="1" fill="currentColor"/><circle cx="23" cy="5" r="2" fill="currentColor"/><circle cx="29" cy="3" r="1" fill="currentColor"/><circle cx="16" cy="23" r="1" fill="currentColor"/><path fill="currentColor" d="M16 30C8.3 30 2 23.7 2 16S8.3 2 16 2h.3l1.4.1l-.3 1.2c-.1.4-.2.9-.2 1.3c0 2.8 2.2 5 5 5c1 0 2-.3 2.9-.9l1.3 1.5c-.4.4-.6.9-.6 1.4c0 1.3 1.3 2.4 2.7 1.9l1.2-.5l.2 1.3c.1.6.1 1.2.1 1.7c0 7.7-6.3 14-14 14zm-.7-26C9 4.4 4 9.6 4 16c0 6.6 5.4 12 12 12s12-5.4 12-12v-.4c-2.3.1-4.2-1.7-4.2-4v-.2c-.5.1-1 .2-1.6.2c-3.9 0-7-3.1-7-7c0-.2 0-.4.1-.6z"/>
      </svg>
      <div>
        <h6 class="cc_title">Cette application utilise des cookies</h6>
        <p>En utilisant cette application, vous acceptez l'utilisation de Cookies pour réaliser des statistiques Google Analytics.<br>Aucune autre donnée n'est conservée.</p>
      </div>
    </div>

    <a
      href="#"
      @click.prevent="closeMsg()"
      class="cc_btn cc_btn_accept_all">Ok</a>
  </div>
</template>

<script setup>
import { ref, onMounted,  inject } from "vue";

const $cookies = inject('$cookies');
const showRgpd = ref(false);

const closeMsg = () => {
  showRgpd.value = false;
  writeCookieRgpd();
}

const writeCookieRgpd = () => {
  $cookies.set("respire_rgpd", true);
}

onMounted(() => {
  if (!$cookies.isKey("respire_rgpd")) {
    showRgpd.value = true;
  }
});
</script>


<style lang="scss" scoped>
.cc_banner-wrapper {
  z-index: 9001;
  position: relative
}

.cc_container {
  display: flex;
  justify-content: space-between;
  align-items: center;



  .cc_message {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .cookie-icon {
      margin: 2rem;
      width: 50px;
      height: 50px;
    }
    .cc_title {
      color: #fff;
      // font-size: 0.8em;
    }
  }
}

.cc_container .cc_btn {
  cursor: pointer;
  text-align: center;
  font-size: 0.6em;
  transition: font-size 200ms;
  line-height: 1em
}

.cc_container .cc_message {
  font-size: 0.6em;
  transition: font-size 200ms;
  margin: 0;
  padding: 0;
  line-height: 1.5em;
}

.cc_container .cc_logo {
  display: none;
  text-indent: -1000px;
  overflow: hidden;
  width: 100px;
  height: 22px;
  background-size: cover;
  // background-image: url(//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/1.0.10/logo.png);
  opacity: 0.9;
  transition: opacity 200ms
}

.cc_container .cc_logo:hover,.cc_container .cc_logo:active {
  opacity: 1
}

@media screen and (min-width: 500px) {
  .cc_container .cc_btn {
      font-size:0.8em
  }

  .cc_container .cc_message {
      font-size: 0.8em
  }
}

@media screen and (min-width: 768px) {
  .cc_container .cc_btn {
      font-size:1em
  }

  .cc_container .cc_message {
      font-size: 1em;
      line-height: 1em
  }
}

@media screen and (min-width: 992px) {
  .cc_container .cc_message {
      font-size:1em
  }
}

@media print {
  .cc_banner-wrapper,.cc_container {
      display: none
  }
}

.cc_container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding: 10px
}

.cc_container .cc_btn {
  padding: 8px 10px;
  background-color: #f1d600;
  cursor: pointer;
  transition: font-size 200ms;
  text-align: center;
  font-size: 0.6em;
  display: block;
  width: 33%;
  margin-left: 10px;
  float: right;
  max-width: 120px
}

.cc_container .cc_message {
  transition: font-size 200ms;
  font-size: 0.6em;
  // display: block
}

@media screen and (min-width: 500px) {
  .cc_container .cc_btn {
      font-size:0.8em
  }

  .cc_container .cc_message {
      margin-top: 0.5em;
      font-size: 0.8em
  }
}

@media screen and (min-width: 768px) {
  .cc_container {
      padding:15px 30px 15px
  }

  .cc_container .cc_btn {
      font-size: 1em;
      padding: 8px 15px
  }

  .cc_container .cc_message {
      font-size: 1em
  }
}

@media screen and (min-width: 992px) {
  .cc_container .cc_message {
      font-size:1em
  }
}

.cc_container {
  background: rgba(0, 0, 0, .8);
  color: #fff;
  font-size: 1rem;
  // font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial;
  box-sizing: border-box
}

.cc_container ::-moz-selection {
  background: #ff5e99;
  color: #fff;
  text-shadow: none
}

.cc_container .cc_btn,.cc_container .cc_btn:visited {
  color: #000;
  background-color: #f1d600;
  transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out;
  -webkit-transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out;
  border-radius: 5px;
  -webkit-border-radius: 5px
}

.cc_container .cc_btn:hover,.cc_container .cc_btn:active {
  background-color: #fff;
  color: #000
}

.cc_container a,.cc_container a:visited {
  text-decoration: none;
  color: #31a8f0;
  transition: 200ms color
}

.cc_container a:hover,.cc_container a:active {
  color: #b2f7ff
}

@-webkit-keyframes slideUp {
  0% {
      -webkit-transform: translateY(66px);
      transform: translateY(66px)
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes slideUp {
  0% {
      -webkit-transform: translateY(66px);
      -ms-transform: translateY(66px);
      transform: translateY(66px)
  }

  100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }
}

.cc_container,.cc_message,.cc_btn {
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  -o-animation-duration: 0.8s;
  -webkit-animation-name: slideUp;
  animation-name: slideUp
}

</style>
