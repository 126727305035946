<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="RURAL_1_">
	<path id="hills" opacity="0.3" fill="currentColor" d="M590.325,140.881c-36.914-12.19-83.048-19.438-133.108-19.438
		c-97.228,0-179.666,27.321-208.424,65.153c-33.75-13.801-79.317-22.267-129.467-22.267c-45.14,0-86.563,6.86-119,18.294v61.287h590
		V140.881z"/>
	<g id="tree">
		<path fill="#FFFFFF" d="M504.679,169.109c0,7.95-5.869,15.2-15.55,20.78c-10.18,5.87-24.59,9.88-40.88,10.89v-6.11
			c0-0.88,0.54-2.08,1.21-2.65l10.84-9.4l10.92-9.47c0.04-0.03,0.07-0.05,0.101-0.09c0.57-0.58,0.57-1.52-0.021-2.09l-2.76-2.73
			c-0.7-0.63-1.71-0.78-2.56-0.38l-16.301,8.49c-0.789,0.41-1.43,0.03-1.43-0.85v-20.77c-0.04-0.93-0.64-1.76-1.53-2.1l-21.109-6.98
			c-0.86-0.28-1.55,0.22-1.55,1.09v28.75c0,0.89-0.61,1.24-1.391,0.8l-14.33-8.37c-0.76-0.45-1.39-0.09-1.39,0.79v6.8
			c0.01,0.27,0.06,0.53,0.12,0.78c0.18,0.71,0.54,1.37,1.06,1.91l14.78,13.79c0.69,0.73,1.1,1.68,1.15,2.68v5.34
			c-23.2-2.96-41.351-12.14-46.72-23.76c-1.06-2.29-1.63-4.68-1.63-7.14c0-8.14,6.18-15.53,16.28-21.18
			c-0.06-0.92-0.15-1.85-0.15-2.78c0-22.06,21.65-39.93,48.36-39.93c26.7,0,48.36,17.87,48.36,39.93c0,0.93-0.101,1.86-0.17,2.78
			C498.489,153.579,504.679,160.969,504.679,169.109z"/>
		<path fill="currentColor" d="M520.81,169.109c0,9.98-5.15,19.25-13.931,26.92c-13.08,11.43-34.25,19.33-58.62,20.77v8.23
			c0,7.98,14.67,15.26,14.67,15.26c0.82,0.41,1.19,1.36,0.881,2.21c0,0-0.221,0.59-0.67,1.41h-52.261l-1.79-1.78
			c-0.63-0.62-0.46-1.39,0.38-1.7c0,0,14.591-5.42,14.591-15.4v-8.96c-32.36-3.9-57.67-19.33-63.291-38.78
			c-0.77-2.66-1.18-5.39-1.18-8.18c0-10.87,6.11-20.89,16.37-28.93c0.01-0.01,0.03-0.01,0.04-0.02
			c2.93-28.53,30.53-50.92,64.2-50.92s61.27,22.39,64.19,50.92c0.01,0.01,0.029,0.01,0.039,0.02
			C514.699,148.219,520.81,158.239,520.81,169.109z M489.129,189.889c9.681-5.58,15.55-12.83,15.55-20.78
			c0-8.14-6.189-15.53-16.289-21.18c0.069-0.92,0.17-1.85,0.17-2.78c0-22.06-21.66-39.93-48.36-39.93
			c-26.71,0-48.36,17.87-48.36,39.93c0,0.93,0.09,1.86,0.15,2.78c-10.1,5.65-16.28,13.04-16.28,21.18c0,2.46,0.57,4.85,1.63,7.14
			c5.37,11.62,23.52,20.8,46.72,23.76v-5.34c-0.051-1-0.46-1.95-1.15-2.68l-14.78-13.79c-0.52-0.54-0.88-1.2-1.06-1.91
			c-0.061-0.25-0.11-0.51-0.12-0.78v-6.8c0-0.88,0.63-1.24,1.39-0.79l14.33,8.37c0.78,0.44,1.391,0.09,1.391-0.8v-28.75
			c0-0.87,0.689-1.37,1.55-1.09l21.109,6.98c0.891,0.34,1.49,1.17,1.53,2.1v20.77c0,0.88,0.641,1.26,1.43,0.85l16.301-8.49
			c0.85-0.4,1.859-0.25,2.56,0.38l2.76,2.73c0.591,0.57,0.591,1.51,0.021,2.09c-0.03,0.04-0.061,0.06-0.101,0.09l-10.92,9.47
			l-10.84,9.4c-0.67,0.57-1.21,1.77-1.21,2.65v6.11C464.539,199.769,478.949,195.759,489.129,189.889z"/>
	</g>
	<g id="herbes" fill="currentColor">
		<path d="M360.121,229.516c0.503-0.375,1.001-0.725,1.502-1.058c0.504-0.331,1.027-0.664,1.568-0.993
			c0-0.25-0.02-0.508-0.06-0.777c-0.043-0.271-0.065-0.531-0.065-0.776c0-3.146,0.502-6.13,1.504-8.95
			c1.004-2.814,2.426-5.381,4.265-7.701c-2.758,1.117-5.153,2.723-7.18,4.816c-2.028,2.089-3.587,4.501-4.671,7.237
			c0.753,1.241,1.403,2.558,1.942,3.944C359.471,226.651,359.868,228.065,360.121,229.516z"/>
		<path d="M342.311,219.637c1.755,2.112,3.115,4.472,4.074,7.085c1.839,0.868,3.522,1.934,5.056,3.197
			c1.515,1.262,2.888,2.679,4.101,4.25c0-2.693-0.498-5.217-1.502-7.576c-1.003-2.359-2.387-4.442-4.139-6.245
			c-1.758-1.802-3.819-3.239-6.179-4.319c-2.366-1.075-4.882-1.654-7.551-1.736C338.505,215.744,340.559,217.524,342.311,219.637z"
			/>
		<path d="M371.437,234.948c2.569-2.546,5.634-4.272,9.188-5.186c-0.835-0.204-1.664-0.365-2.479-0.467
			c-0.815-0.104-1.661-0.154-2.538-0.154c-5.602,0-10.345,1.928-14.233,5.777c-2.628,2.605-4.347,5.609-5.2,8.991h10.074
			C367.177,240.448,368.902,237.465,371.437,234.948z"/>
		<path d="M351.09,243.841c0.377-1.719,0.921-3.37,1.632-4.939c-1.754-2.944-4.148-5.302-7.183-7.09
			c-3.026-1.774-6.387-2.67-10.06-2.67c-0.878,0-1.725,0.054-2.54,0.154c-0.811,0.109-1.642,0.263-2.477,0.467
			c3.556,0.914,6.605,2.64,9.157,5.186c2.515,2.513,4.246,5.5,5.216,8.961h6.242C351.084,243.884,351.084,243.862,351.09,243.841z"
			/>
	</g>
	<g id="line">
		<rect x="0.326" y="243.909"  fill="currentColor" width="590" height="5.115"/>
		<!-- <path fill="#010202" d="M500.138,243.909h-52.256h-44.633h-10.074h-5.097h-6.242h-37.597c0,0.001,0,0.001,0,0.002
			c0.023,0.545,0.042,1.092,0.042,1.643c0,0.8-0.033,1.593-0.081,2.381c-0.021,0.35-0.049,0.699-0.08,1.046
			c-0.001,0.014-0.002,0.028-0.003,0.042H590v-1.089v-4.026H500.138z"/> -->
	</g>
	<g id="house2">
		<path fill="currentColor" d="M89.1,132.072c1.286-1.287,3.372-1.287,4.658,0l5.905,5.906v-4.259c0-0.909,0.737-1.647,1.647-1.647h3.293
			c0.91,0,1.647,0.738,1.647,1.647v10.846l6.107,6.103c0.643,0.644,0.643,1.69,0,2.333c-0.644,0.644-1.69,0.644-2.334,0l-0.48-0.484
			v17.433c0,2.728-2.213,4.941-4.941,4.941h-26.35c-2.728,0-4.941-2.214-4.941-4.941v-17.433l-0.48,0.484
			c-0.646,0.644-1.688,0.644-2.333,0c-0.644-0.644-0.644-1.69,0-2.333l0,0L89.1,132.072z"/>
	</g>
	<g id="house1">
		<path fill="currentColor" d="M186.242,145.868c1.784-1.785,4.676-1.785,6.46,0l8.191,8.191v-5.907c0-1.261,1.022-2.284,2.284-2.284
			h4.568c1.261,0,2.285,1.023,2.285,2.284v15.043l8.47,8.464c0.892,0.892,0.892,2.344,0,3.236c-0.893,0.893-2.344,0.893-3.236,0
			l-0.667-0.672v24.179c0,3.783-3.069,6.853-6.852,6.853h-36.545c-3.783,0-6.852-3.069-6.852-6.853v-24.179l-0.667,0.672
			c-0.895,0.893-2.34,0.893-3.236,0c-0.892-0.892-0.892-2.344,0-3.236l0,0L186.242,145.868z"/>
	</g>
</g>
</svg>
</template>