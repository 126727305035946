<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <path
      id="gisPoiMap0"
      fill="currentColor"
      fill-opacity="1"
      stroke="none"
      stroke-dasharray="none"
      stroke-dashoffset="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="4"
      stroke-opacity="1"
      stroke-width="68.837"
      d="m70.387 70l-3.854 7.247l18.87-3.085c-3.808-1.91-8.963-3.275-15.016-4.162Zm-48.61 1.58C13.037 73.885 7.5 77.662 7.5 83.272a8.4 8.4 0 0 0 .774 3.497l30.285-4.95zM91.79 80l-42.15 6.87l11.116 12.646C79.01 97.881 92.5 92.05 92.5 83.272c0-1.17-.252-2.257-.71-3.271zm-49.272 8.055l-28.48 4.655C21.566 97.374 34.853 100 50 100c.918 0 1.815-.026 2.719-.045z"
      opacity="1"
    />
    <path
      id="gisPoiMap1"
      fill="currentColor"
      fill-opacity="1"
      fill-rule="nonzero"
      stroke="none"
      stroke-dasharray="none"
      stroke-dashoffset="0"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="4"
      stroke-opacity="1"
      stroke-width="4.15"
      d="M50.002 0c-16.3 0-29.674 13.333-29.674 29.596c0 6.252 1.987 12.076 5.342 16.865l19.234 33.25l.082.107c.759.991 1.5 1.773 2.37 2.348c.87.576 1.95.92 3.01.814c2.118-.212 3.415-1.708 4.646-3.376l.066-.086l21.234-36.141l.012-.023c.498-.9.866-1.816 1.178-2.708a29.246 29.246 0 0 0 2.17-11.05C79.672 13.333 66.302 0 50.002 0zm0 17.045c7.071 0 12.59 5.509 12.59 12.55c0 7.043-5.519 12.55-12.59 12.55c-7.072 0-12.594-5.508-12.594-12.55c0-7.04 5.523-12.55 12.594-12.55z"
      color="currentColor"
      color-interpolation="sRGB"
      color-rendering="auto"
      display="inline"
      opacity="1"
      vector-effect="none"
      visibility="visible"
    />
  </svg>
</template>
