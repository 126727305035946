<template>
  <svg version="1.1" id="Yes" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="No">
	<path opacity="0.8" fill="none" stroke="currentColor" stroke-width="18" d="M382.679,164.428v98.508H205.388v-98.508l-39.4-0.178
		l128.04-128.04l128.04,128.04L382.679,164.428z"/>
	<path fill="#E30613" class="icon-symbole symbole-bad" d="M445.779,233.199l21.589,21.579l-12.953,12.964l-21.59-21.59l-21.629,21.649l-12.963-12.964l21.639-21.639
		l-21.639-21.641l12.963-12.959l21.629,21.641l21.65-21.58l12.953,12.958L445.779,233.199z"/>
	<g opacity="0.25">
		<path fill="currentColor" d="M302.162,100.8c-9.194,0-16.645,7.984-16.645,17.834v67.897c-11.482,9.192-13.335,25.953-4.142,37.436
			c9.187,11.482,25.948,13.342,37.43,4.148c11.482-9.193,13.341-25.955,4.135-37.437c-1.222-1.534-2.601-2.913-4.135-4.147v-67.897
			C318.806,108.784,311.355,100.8,302.162,100.8z M312.148,160.92c-4.85,0.085-9.388-1.099-13.367-2.125
			c-2.444-0.637-4.655-1.209-6.605-1.405v-38.756c0-5.91,4.473-10.702,9.987-10.702c5.513,0,9.986,4.792,9.986,10.702v2.061h-6.657
			c-1.847,0-3.329,1.489-3.329,3.329c0,1.833,1.482,3.329,3.329,3.329h6.657v6.658h-6.657c-1.847,0-3.329,1.489-3.329,3.329
			c0,1.833,1.482,3.329,3.329,3.329h6.657v6.658h-6.657c-1.847,0-3.329,1.489-3.329,3.329c0,1.833,1.482,3.329,3.329,3.329h6.657
			V160.92z"/>
		<path fill="#009FE3" d="M279.358,140.772l-12.332-7.234v-8.052l6.096-6.092l-5.066-5.024l-4.593,4.596l-4.597-4.596l-5.059,5.024
			l6.093,6.092v8.052l-8.908,5.133l-7.126-4.029l-2.067-8.299l-6.841,1.851l1.674,6.309l-6.305,1.674l1.852,6.875l8.302-2.209
			l7.126,4.029v10.292l-7.126,4.029l-8.302-2.206l-1.852,6.875l6.305,1.67l-1.674,6.278l6.875,1.85l2.21-8.301l7.126-4.029
			l8.73,5.136v8.044l-6.093,6.096l5.059,5.025l4.597-4.594l4.593,4.594l5.024-5.025l-6.054-6.096v-8.044l12.332-7.168
			 M254.556,144.871l8.908-5.167l8.908,5.167v10.258l-8.908,5.17l-8.908-5.17"/>
	</g>
</g>
</svg>
</template>