<template>
<h5 class="text-center">Points de vigilance</h5>
<div class="accordion" id="alerts-list">
  <div class="accordion-item" v-for="(alert, alertIdex) in alertsGenerics" :key="`alt-${alertIdex}`">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#alert-${alertIdex}`" aria-expanded="false" :aria-controls="`alert-${alertIdex}`">

        {{alert.title}}

        <div class="alert-nb-items">
          <template v-if="getAlertForThemeId(alert.id).length > 0">
            <i class="bi bi-exclamation-triangle-fill"></i>
            <!-- &nbsp;{{ getAlertForThemeId(alert.id).length }} -->
          </template>
        </div>

      </button>
    </h2>
    <div :id="`alert-${alertIdex}`" class="accordion-collapse collapse" data-bs-parent="#alerts-list">
      <div class="accordion-body">
        
        <!-- {{alert.id}} -->
        <!-- <span v-if="isDebug">[{{ alert.id }}] - </span> -->
        <!-- <pre>{{ getAlertForThemeId(alert.id) }}</pre> -->
        
        <div class="alertsWrapper" v-if="getAlertForThemeId(alert.id).length > 0">
          <ul class="my-0">
            <template
              v-for="(alertMsg, alertMsgIndex) in getAlertForThemeId(alert.id)"
              :key="`recoTvxTestB-${alertMsgIndex}`"
            >
              <li>
                <span v-if="isDebug">[{{ alertMsg.id }}] - </span>
                <div v-html="alertMsg.title"></div>
                <div v-if="alertMsg.description" v-html="alertMsg.description"></div>
              </li>
            </template>
          </ul>
        </div>
        <div v-html="alert.content"></div>
      </div>
    </div>
  </div>
</div>

<div class="debug-bloc" v-if="isDebug">
      <div class="row">
        <div class="col">
          <h4>alertsData.messages</h4>
          <pre>{{ alertsData.messages }}</pre>
        </div>
        <div class="col">
          <h4>alertsData.debug</h4>
          <pre>{{ alertsData.debug }}</pre>
        </div>
      </div>
    </div>

</template>

<script setup>
import { useConditionsManager } from "../composable/useConditionsManager.js";
import { useStore } from "vuex";
import { computed } from "vue";
import {badPointsDynamics, badPointsGenerics} from "@/appConfig";

const store = useStore();
const isDebug = computed(() => store.getters.isDebug);

// const isInfoToShow = computed(() => Object.keys(sortedAlerts.value).length > 0);
const alertsData = useConditionsManager(badPointsDynamics);



const getAlertForThemeId = (themeId) => {
  //-- En attente de validation des alerts
  // if (process.env.VUE_APP_MODE === 'production') {
  //   return [];
  // }
  //-- /En attente de validation des alerts
  const newList = [];
  const alertList = alertsData.messages.filter((altM) => altM.theme === themeId);

  alertList.forEach((dum) => {
    // if (!newList[dum[itemBaseSort.value]]) {
    //   newList[dum[itemBaseSort.value]] = [];
    // }
    newList.push({
      id: dum.id,
      theme: dum.theme,
      title: dum.title,
      poste: dum.poste,
      description: dum.description,
    });
  });

  return newList;
};


const alertsGenerics = badPointsGenerics;
</script>

<style lang="scss" scoped>
// .accordion-collapse.collapsing,
// .accordion-collapse.show{
//   background-color: #f5f5f5;
// }

.accordion-button {
  font-weight: bold;
  // display: flex;
  // justify-content: space-evenly;
  // align-items: center;
  position: relative;

  .alert-nb-items{
    // background-color: darkred;
    text-align: center;
    position: absolute;
    right: calc(var(--bs-accordion-btn-padding-y) * 3) ;
    color: darkred;
    // width: 45px;
    // height: 15px;
    // margin-left:auto;
  }
}

.alertsWrapper {
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem 0;
  color: darkred;
  border-color: darkred;
  background-color: rgba(139, 0, 0, 0.1);
}
</style>


