<template>
    <a :class="btnClass" @click.prevent="openHelpBox"><slot><i class="bi bi-info-circle-fill"></i></slot></a>
</template>

<script>

export default {
  props: {
    helpComponent: {
      required: false,
      default: ''
    },
    helpTitle: {
      required: false,
      default: ''
    },
    btnClass: {
      required: false,
      default: 'help-button'
    }
  },
  data () {
    return {
      helpOpen: false,
    }
  },
  computed: {
    // theClass () {
    //   return this.btnClass
    // }
  },
  methods: {
    openHelpBox () {
      this.helpOpen = true;
      this.emitter.emit("help-modal-open", this.helpOpen);
      this.emitter.emit("help-modal-data", {componentName: this.helpComponent, title: this.helpTitle});
    }
  }
}
</script>

<style lang="scss" scoped>




</style>