<template>
  <div>
    <p>
      Pour savoir si votre bâtiment est situé en zone littoral, consultez la
      <a
        href="https://www.observatoire-des-territoires.gouv.fr/outils/cartographie-interactive/#c=indicator&i=typo_loilitt.zonage_loilitt&view=map53"
        target="_blank"
        >carte interactive</a
      >
      de l'<a
        href="https://www.observatoire-des-territoires.gouv.fr/"
        target="_blank"
        >Observatoire des territoires – Agence nationale de la cohésion des
        territoires</a
      >
    </p>
  </div>
</template>
