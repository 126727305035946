<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="old">
	<g id="trees" fill="currentColor">
		<g id="XMLID_3_">
			<g>
				<path opacity="0.25" d="M539.359,167.018c9.291,5.234,14.963,12.101,14.963,19.642c0,13.811-18.895,25.378-44.433,28.667v-4.955
					c0.054-0.93,0.427-1.806,1.068-2.489l13.575-12.785c0.651-0.683,1.037-1.559,1.079-2.5v-6.312c0-0.813-0.576-1.143-1.271-0.727
					l-13.17,7.766c-0.726,0.405-1.282,0.073-1.282-0.737v-26.67c0-0.812-0.63-1.271-1.42-1.015l-19.408,6.473
					c-0.811,0.32-1.367,1.078-1.398,1.955v19.257c0,0.811-0.588,1.163-1.313,0.79l-14.985-7.882
					c-0.779-0.363-1.709-0.225-2.35,0.362l-2.531,2.531c-0.545,0.534-0.555,1.4-0.032,1.935c0.032,0.031,0.063,0.063,0.096,0.086
					l20.005,17.493c0.609,0.534,1.111,1.646,1.111,2.469v5.659c-29.223-1.815-51.865-14.247-51.865-29.371
					c0-7.541,5.682-14.408,14.973-19.642c-0.074-0.855-0.159-1.72-0.159-2.574c0-20.465,19.909-37.042,44.454-37.042
					s44.442,16.577,44.442,37.042C539.508,165.298,539.422,166.163,539.359,167.018z"/>
				<path d="M554.097,159.83c9.433,7.455,15.05,16.758,15.05,26.83c0,21.511-25.44,39.444-59.257,43.557v8.309
					c0,9.26,13.415,14.292,13.415,14.292c0.77,0.288,0.931,0.993,0.342,1.581l-1.644,1.645h38.033
					c-0.299,1.58-0.599,3.162-0.876,4.742h-9.346v-0.043H441.832v-4.699h32.149c-0.416-0.759-0.62-1.314-0.62-1.314
					c-0.288-0.779,0.054-1.665,0.801-2.051c0,0,13.491-6.75,13.491-14.152v-7.636c-37.438-2.232-66.67-21.169-66.67-44.229
					c0-10.072,5.607-19.375,15.049-26.83c0.011-0.011,0.021-0.011,0.032-0.021c2.681-26.468,28.047-47.231,59.001-47.231
					c30.943,0,56.31,20.763,59.001,47.231C554.076,159.818,554.087,159.818,554.097,159.83z M554.322,186.66
					c0-7.541-5.672-14.408-14.963-19.642c0.063-0.855,0.148-1.72,0.148-2.574c0-20.465-19.897-37.042-44.442-37.042
					s-44.454,16.577-44.454,37.042c0,0.854,0.085,1.719,0.159,2.574c-9.291,5.234-14.973,12.101-14.973,19.642
					c0,15.124,22.643,27.556,51.865,29.371v-5.659c0-0.822-0.502-1.935-1.111-2.469l-20.005-17.493
					c-0.032-0.022-0.063-0.055-0.096-0.086c-0.522-0.534-0.513-1.4,0.032-1.935l2.531-2.531c0.641-0.587,1.57-0.726,2.35-0.362
					l14.985,7.882c0.726,0.373,1.313,0.021,1.313-0.79v-19.257c0.031-0.877,0.588-1.635,1.398-1.955l19.408-6.473
					c0.79-0.257,1.42,0.203,1.42,1.015v26.67c0,0.811,0.557,1.143,1.282,0.737l13.17-7.766c0.694-0.416,1.271-0.086,1.271,0.727
					v6.312c-0.042,0.941-0.428,1.817-1.079,2.5l-13.575,12.785c-0.642,0.684-1.015,1.56-1.068,2.489v4.955
					C535.428,212.038,554.322,200.47,554.322,186.66z"/>
			</g>
		</g>
		<g id="XMLID_1_">
			<g>
				<path opacity="0.25" d="M125.73,170.837c8.7,4.9,14.01,11.33,14.01,18.39c0,12.931-17.69,23.761-41.6,26.841v-4.641
					c0.05-0.87,0.4-1.689,1-2.33l12.71-11.97c0.61-0.64,0.97-1.46,1.01-2.341v-5.909c0-0.761-0.54-1.07-1.19-0.681l-12.33,7.271
					c-0.68,0.38-1.2,0.069-1.2-0.69v-24.97c0-0.76-0.59-1.19-1.33-0.95l-18.17,6.06c-0.76,0.3-1.28,1.01-1.31,1.83v18.029
					c0,0.76-0.55,1.09-1.23,0.74l-14.03-7.38c-0.73-0.34-1.6-0.21-2.2,0.34l-2.37,2.37c-0.51,0.5-0.52,1.311-0.03,1.811
					c0.03,0.029,0.06,0.06,0.09,0.08l18.73,16.379c0.57,0.5,1.04,1.541,1.04,2.311v5.3c-27.36-1.7-48.56-13.34-48.56-27.5
					c0-7.06,5.32-13.489,14.02-18.39c-0.07-0.8-0.15-1.61-0.15-2.41c0-19.16,18.64-34.68,41.62-34.68s41.61,15.52,41.61,34.68
					C125.87,169.227,125.79,170.037,125.73,170.837z"/>
				<path d="M139.53,164.107c8.83,6.98,14.09,15.69,14.09,25.12c0,20.14-23.82,36.931-55.48,40.78v7.779
					c0,8.67,12.56,13.381,12.56,13.381c0.72,0.27,0.87,0.93,0.32,1.48l-1.54,1.539h35.61c-0.28,1.48-0.56,2.961-0.82,4.44h-8.75
					v-0.04H34.42v-4.4h30.1c-0.39-0.71-0.58-1.23-0.58-1.23c-0.27-0.729,0.05-1.559,0.75-1.92c0,0,12.63-6.319,12.63-13.25v-7.149
					c-35.05-2.09-62.42-19.819-62.42-41.41c0-9.43,5.25-18.14,14.09-25.12c0.01-0.01,0.02-0.01,0.03-0.02
					c2.51-24.78,26.26-44.22,55.24-44.22c28.97,0,52.72,19.44,55.24,44.22C139.51,164.097,139.521,164.097,139.53,164.107z
					 M139.74,189.227c0-7.06-5.31-13.489-14.01-18.39c0.06-0.8,0.14-1.61,0.14-2.41c0-19.16-18.63-34.68-41.61-34.68
					s-41.62,15.52-41.62,34.68c0,0.8,0.08,1.61,0.15,2.41c-8.7,4.9-14.02,11.33-14.02,18.39c0,14.16,21.2,25.8,48.56,27.5v-5.3
					c0-0.77-0.47-1.811-1.04-2.311l-18.73-16.379c-0.03-0.021-0.06-0.051-0.09-0.08c-0.49-0.5-0.48-1.311,0.03-1.811l2.37-2.37
					c0.6-0.55,1.47-0.68,2.2-0.34l14.03,7.38c0.68,0.35,1.23,0.02,1.23-0.74v-18.029c0.03-0.82,0.55-1.53,1.31-1.83l18.17-6.06
					c0.74-0.24,1.33,0.19,1.33,0.95v24.97c0,0.76,0.52,1.07,1.2,0.69l12.33-7.271c0.65-0.39,1.19-0.08,1.19,0.681v5.909
					c-0.04,0.881-0.4,1.701-1.01,2.341l-12.71,11.97c-0.6,0.641-0.95,1.46-1,2.33v4.641
					C122.05,212.988,139.74,202.158,139.74,189.227z"/>
			</g>
		</g>
	</g>
	<g id="house_1_">
		<g id="XMLID_2_">
			<g>
				<polygon fill="currentColor" opacity="0.15" points="357.281,125.176 357.281,231.417 221.241,231.417 221.241,125.176 289.261,63.956 				"/>
				<path fill="#010202" d="M425.291,149.806h-40.81v108.82h-190.44v-108.82h-40.81l136.03-122.43L425.291,149.806z
					 M357.281,231.417v-106.24l-68.02-61.22l-68.02,61.22v106.24H357.281z"/>
			</g>
		</g>
		<g>
			<rect x="275.77" y="199.225" fill="#010202" width="26.999" height="46.498"/>
			<rect x="242.727" y="119.869" fill="#010202" width="25.659" height="38.859"/>
			<rect x="308.727" y="119.869" fill="#010202" width="25.659" height="38.859"/>
		</g>
	</g>
</g>
</svg>

</template>