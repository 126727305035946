<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="NOTcrossed">
	<g opacity="0.5">
		<path fill="currentColor" d="M421.247,111.503c0-13.462,10.928-24.375,24.375-24.375c13.477,0,24.375,10.913,24.375,24.375
			s-10.898,24.375-24.375,24.375h-48.75c-3.105,0-5.625-2.52-5.625-5.625s2.52-5.625,5.625-5.625h48.75
			c7.266,0,13.125-5.874,13.125-13.125s-5.859-13.125-13.125-13.125c-7.236,0-13.125,5.874-13.125,13.125v2.681
			c0,3.105-2.52,5.625-5.625,5.625s-5.625-2.52-5.625-5.625V111.503z"/>
		<path opacity="0.4" fill="currentColor" enable-background="new    " d="M398.747,175.253c0-3.105,2.52-5.625,5.625-5.625h108.75
			c17.607,0,31.875,14.268,31.875,31.875c0,17.608-14.268,31.877-31.875,31.877s-31.875-14.269-31.875-31.877v-3.75
			c0-3.105,2.52-5.625,5.625-5.625s5.625,2.52,5.625,5.625v3.75c0,11.397,9.229,20.627,20.625,20.627s20.625-9.229,20.625-20.627
			c0-11.396-9.229-20.626-20.625-20.626h-108.75C401.267,180.878,398.747,178.359,398.747,175.253z"/>
		<path opacity="0.7" fill="currentColor" enable-background="new    " d="M481.247,126.503c0-17.607,14.268-31.875,31.875-31.875
			s31.875,14.268,31.875,31.875s-14.268,31.875-31.875,31.875h-123.75c-3.105,0-5.625-2.52-5.625-5.625s2.52-5.625,5.625-5.625
			h123.75c11.396,0,20.625-9.236,20.625-20.625s-9.229-20.625-20.625-20.625s-20.625,9.236-20.625,20.625v3.75
			c0,3.105-2.52,5.625-5.625,5.625s-5.625-2.52-5.625-5.625V126.503z"/>
	</g>
	<polygon fill="currentColor" points="250.66,200.16 298.798,152.02 250.66,103.883 250.66,139.983 142.349,139.983 142.349,164.054 250.66,164.054 	"/>
	<polygon points="262.696,31.672 169.072,115.914 205.039,115.914 262.696,64.039 322.868,118.196 322.868,143.504 322.868,190.38 
		322.868,212.198 202.521,212.198 202.521,188.125 178.45,188.125 178.45,236.267 346.938,236.267 346.938,190.38 346.938,143.504 
		346.938,139.983 383.043,139.983 	"/>
	<g>
		<path fill="currentColor" d="M121.387,122.255h-48.75c-3.105,0-5.625,2.52-5.625,5.625s2.52,5.625,5.625,5.625h48.75
			c0.602,0,1.194-0.037,1.784-0.081V122.12C122.587,122.202,121.994,122.255,121.387,122.255z"/>
		<path opacity="0.4" fill="currentColor" enable-background="new    " d="M80.137,167.255c-3.105,0-5.625,2.52-5.625,5.625
			c0,3.105,2.52,5.625,5.625,5.625h43.034v-11.25H80.137z"/>
		<path opacity="0.7" fill="currentColor" enable-background="new    " d="M65.137,144.755c-3.105,0-5.625,2.52-5.625,5.625
			s2.52,5.625,5.625,5.625h58.034v-11.25H65.137z"/>
	</g>
	<path fill="#E30613" class="icon-symbole symbole-bad" d="M471.729,163.224l25.646,25.634l-15.388,15.399l-25.646-25.646l-25.694,25.717l-15.399-15.4l25.705-25.704
		l-25.705-25.706l15.399-15.394l25.694,25.706l25.718-25.634l15.388,15.394L471.729,163.224z"/>
</g>
</svg>
</template>