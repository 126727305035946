<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="1000px"
    height="1000px"
    viewBox="0 0 1000 1000"
    enable-background="new 0 0 1000 1000"
    xml:space="preserve"
  >
  <g id="_x33__levels_bottom">
      <rect
        id="empty_1_"
        x="115.756"
        y="413.254"
        display="inline"
        fill="none"
        stroke="currentColor"
        stroke-width="5"
        stroke-miterlimit="10"
        width="707.895"
        height="154.721"
      />
      <polygon
        id="roof"
        display="inline"
        fill="none"
        stroke="currentColor"
        stroke-width="5"
        stroke-miterlimit="10"
        points="469.704,255.023 
		677.764,328.333 885.827,401.642 469.704,401.642 53.579,401.642 261.64,328.333 	"
      />

      <rect
        id="empty"
        x="115.757"
        y="575.536"
        display="inline"
        fill="none"
        stroke="currentColor"
        stroke-width="5"
        stroke-miterlimit="10"
        width="707.893"
        height="154.72"
      />

      <rect
        id="selected_2_"
        x="115.757"
        y="737.816"
        fill="currentColor"
        display="inline"
        stroke="currentColor"
        stroke-width="5"
        stroke-miterlimit="10"
        width="707.893"
        height="154.721"
      />
    </g>
  </svg>
</template>
