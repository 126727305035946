<template>
  <template v-for="qst in props.formQst" :key="qst.varname">
    <InputFormRowGlobal
    v-if="isShowQst(qst.conditionsToShow, qst.varname)"
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[formName][qst.varname]"
    v-model="theValue[formName][qst.varname]"
    :qstParams="getQstParam(qst.varname)"
    />
    <!-- theValue: {{ theValue }} -->
  </template>

  <!-- formName: {{ formName }} -->

  <!-- props.formQst: <pre>{{ props.formQst }}</pre> -->

  <!-- validationObj: <pre>{{ props.validationObj.$errors }}</pre> -->
  <!-- validationObj: <pre>{{ props.validationObj }}</pre> -->

</template>

<script setup>
import { ref, watch } from 'vue';
import InputFormRowGlobal from './InputFormRowGlobal.vue';
import {useCheckSimuDataValueProject} from '../composable/useCheckSimuDataValueProject.js';


const props = defineProps({
  stepId: {
    type: Number,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  },
  formName: {
    type: String,
    required: true
  },
  formQst: {
    type: Array,
    required: true
  },
  validationObj: {
    type: Object,
    required: true
  }
});

const {isShowQst} = useCheckSimuDataValueProject(props.modelValue);

const emit = defineEmits(['update:modelValue']);

const theValue = ref(props.modelValue);
watch (theValue, (newVal) => {
    emit('update:modelValue', newVal)
  });

const getQstParam = (varname) => {
  const param = props.formQst.find((qst) => qst.varname === varname);
  return param;
}
</script>
