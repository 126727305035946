<template>
  <div class="step-content">
    <!-- formData.value['isRoomValid'] = {{ formData['isRoomValid'] }}
    <hr>
    isFormValide: {{ isFormValide }} -->
    <!-- isRdc: {{ isRdc }} / isTopLevel: {{ isTopLevel }} / isSingleStorey: {{ isSingleStorey }}
    <hr>
    buildingNbLevelTotal: <pre>{{ buildingNbLevelTotal }}</pre>
    roomLevelChoices: <pre>{{ roomLevelChoices }}</pre> -->

    <!-- v$: <pre>{{  v$ }}</pre> -->

    <div v-if="v$.$dirty">
      <div v-if="!formData['isRoomValid']" class="text-danger fs-3">
        <i class="bi bi-patch-exclamation-fill"></i> Erreurs détectées !
      </div>
      <!-- <div v-else  class="text-success fs-3">
        <i class="bi bi-patch-check-fill"></i> Formulaire valide !
      </div> -->
      <!-- <hr> -->
    </div>

    <form
      @submit.prevent="submitForm()"
      class="form-step"
      :class="{ 'form-in-error': isFormValide === false }"
    >
      <fieldset>
        <legend>Configuation de la pièce</legend>
        <InputFormRowGlobal
          v-bind="$attrs"
          :inputValidationObj="v$['roomType']"
          v-model="formData['roomType']"
          :qstParams="getQstParam('roomType')"
        />

        <template v-if="!isSingleStorey">
          <InputFormRowGlobal
            v-bind="$attrs"
            :inputValidationObj="v$['roomLevel']"
            v-model="formData['roomLevel']"
            :qstParams="getQstParam('roomLevel')"
            :choicesList="roomLevelChoices"
          />
        </template>

        <InputFormRowGlobal
          v-bind="$attrs"
          :inputValidationObj="v$['canOpenWindowsNight']"
          v-model="formData['canOpenWindowsNight']"
          :qstParams="getQstParam('canOpenWindowsNight')"
        />
      </fieldset>

      <fieldset>
        <legend>Description des murs</legend>

        <InputFormRowGlobal
          v-bind="$attrs"
          :inputValidationObj="v$['roomShape']"
          v-model="formData['roomShape']"
          :qstParams="getQstParam('roomShape')"
        />
        <template v-if="formData['roomShape']">
          <div class="row my-5">
            <div
              class="col col-lg-6 col-md-12 d-flex justify-content-center align-items-start"
            >
              <TheRoom
                @selectWall="openWallForm"
                :wallSelected="currentWallForm"
                :roomId="formData.roomId"
                @updateOrientation="updateOrientation"
              ></TheRoom>
            </div>
            <div class="col col-lg-6 col-md-12">
              <ul class="nav nav-tabs nav-fill">
                <li
                  class="nav-item"
                  :class="{ error: v$.walls && v$.walls[`wall${i}`].$error }"
                  v-for="i in 4"
                  :key="`fromWall${i}`"
                >
                  <a
                    class="nav-link"
                    :class="{ active: currentWallForm === i }"
                    aria-current="page"
                    href="#"
                    @click.prevent="openWallForm(i)"
                    >Mur {{ i }}</a
                  >
                </li>
              </ul>
              <template v-for="i in 4" :key="`fromWallContent${i}`">
                <div v-if="i === currentWallForm" class="content4Tabs">
                  <!-- <h6>MUR {{ i }}</h6> -->
                  <small
                    >Orientation :
                    {{ formData.walls[`wall${i}`].wallOrientation }} / Type de
                    mur : {{ formData.walls[`wall${i}`].info2D.type }}.</small
                  >
                  <hr />

                  <template v-if="v$.walls">
                    <!-- <pre>{{ v$.walls[`wall${i}`] }}</pre> -->
                    <template v-if="formData.walls[`wall${i}`].info2D.type === 'int'">
                      <InputFormRowGlobal
                      displayType="nbcol-1"
                      v-bind="$attrs"
                      :inputValidationObj="v$.walls[`wall${i}`].wallType"
                      v-model="formData.walls[`wall${i}`].wallType"
                      :qstParams="getQstParam('wallType')"
                    />
                    </template>
                    <template v-else>
                      <InputFormRowGlobal
                        displayType="nbcol-1"
                        v-bind="$attrs"
                        :inputValidationObj="
                          v$.walls[`wall${i}`].windowsProtectionType
                        "
                        v-model="formData.walls[`wall${i}`].windowsProtectionType"
                        :qstParams="getQstParam('windowsProtectionType')"
                        @click="triggerSolarProtection({wall:`wall${i}`})"
                      />

                      <InputFormRowGlobal
                        v-if="formData.walls[`wall${i}`].windowsProtectionType !== 'none'"
                        displayType="nbcol-1"
                        v-bind="$attrs"
                        :inputValidationObj="
                          v$.walls[`wall${i}`].windowsProtectionControl
                        "
                        v-model="
                          formData.walls[`wall${i}`].windowsProtectionControl
                        "
                        :qstParams="getQstParam('windowsProtectionControl')"
                      />
                      <InputFormRowGlobal
                        displayType="nbcol-1"
                        v-bind="$attrs"
                        :inputValidationObj="
                          v$.walls[`wall${i}`].windowsGlassType
                        "
                        v-model="formData.walls[`wall${i}`].windowsGlassType"
                        :qstParams="getQstParam('windowsGlassType')"
                      />
                    </template>


                  </template>
                </div>
              </template>
            </div>
          </div>
        </template>
      </fieldset>

      <template v-if="!isTopLevel">
        <fieldset>
          <legend>Description du plafond</legend>
          <InputFormRowGlobal
            v-bind="$attrs"
            :inputValidationObj="v$['roomRoofComposition']"
            v-model="formData['roomRoofComposition']"
            :qstParams="getQstParam('roomRoofComposition')"
          />
        </fieldset>
      </template>

      <fieldset>
        <legend>Description du sol</legend>
        <template v-if="!isRdc">
          <InputFormRowGlobal
            v-bind="$attrs"
            :inputValidationObj="v$['roomFloorComposition']"
            v-model="formData['roomFloorComposition']"
            :qstParams="getQstParam('roomFloorComposition')"
          />
        </template>

        <InputFormRowGlobal
          v-bind="$attrs"
          :inputValidationObj="v$['roomFlooring']"
          v-model="formData['roomFlooring']"
          :qstParams="getQstParam('roomFlooring')"
        />
      </fieldset>

      <div class="alert alert-danger" v-if="v$.$errors.length > 0">
        <ul class="error-feedback">
          <li v-for="error in v$.$errors" :key="error.$uid">
            {{ error.$uid }} - {{ error.$message }}
          </li>
        </ul>
      </div>

      <LayoutFormFooter>
        <template v-slot:cancel-btn
          ><button
            type="button"
            class="btn btn-danger mx-5"
            @click.prevent="cancelRoom()"
          >
            <i class="bi bi-trash3-fill"></i> Supprimer
          </button></template
        >
      </LayoutFormFooter>
    </form>

    <!-- isFormRoomValide: {{ isFormRoomValide ? "OUI" : "NON" }}
    <hr />
    <button
      type="button"
      class="btn btn-primary"
      @click.prevent="valideRoomForm(true)"
    >
      TRUE
    </button>
    <button
      type="button"
      class="btn btn-danger"
      @click.prevent="valideRoomForm(false)"
    >
      FALSE
    </button> -->
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { onBeforeMount, ref, reactive, computed, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import InputFormRowGlobal from "./InputFormRowGlobal.vue";
import TheRoom from "./TheRoom.vue";
import LayoutFormFooter from "./LayoutFormFooter.vue";
import { forms, questions } from "../appConfig/index.js";

const props = defineProps({
  id: {
    required: true,
  },
});

//-- INIT VARIABLES
const emit = defineEmits("closeModal");
const store = useStore();
const simuData = computed(() => {
  return store.getters.simuData;
});
const roomData = computed(() => {
  return store.getters.room({ roomId: props.id });
});

const formData = ref({ ...roomData.value });
const rules = reactive({});
const isFormValide = ref(null);
//--

//-- INIT DATA STORED
onBeforeMount(async () => {
  await initData();
});

const initData = async () => {
  const walls = formData.value.walls;

  const varListToCheck = [
    { formName: "buildingWindows", varName: "windowsProtectionType" },
    { formName: "buildingWindows", varName: "windowsProtectionControl" },
    { formName: "buildingWindows", varName: "windowsGlassType" },
  ];
  if (walls) {
    for (let wallData of Object.values(walls)) {
      varListToCheck.forEach((varItem) => {
        if (wallData[varItem.varName] === null) {
          wallData[varItem.varName] =
            simuData.value[varItem.formName][varItem.varName];
        }
      });
    }
  }

  if (formData.value["roomRoofComposition"] === null) {
    formData.value["roomRoofComposition"] =
      simuData.value.buildingRoof.roofComposition;
  }
  if (formData.value["roomFloorComposition"] === null) {
    formData.value["roomFloorComposition"] =
      simuData.value.buildingFloor.floorComposition;
  }

  formData.value["isRoomValid"] =
    roomData.value["isRoomValid"] && roomData.value["isRoomValid"] === true
      ? true
      : false;
  return true;
};
//--

//-- FORM ACTIONS
//formData.value = {...roomData.value};

let v$ = useVuelidate(rules, formData.value, { $lazy: true, $autoDirty: true });

const submitForm = async () => {
  const result = await v$.value.$validate();
  formData.value["isRoomValid"] = result;
  isFormValide.value = result;
  if (result) {
    store.dispatch("updateRoom", {
      roomId: formData.value.roomId,
      roomData: formData.value,
    });
    emit("closeModal");
  } else {
    // alert("KO :(");
    setTimeout(() => {
      isFormValide.value = null;
    }, 1000);
  }
};

const getQstParam = (varname) => {
  const param = questions.find((qst) => qst.varname === varname);
  return param;
};

const cancelRoom = () => {
  if (confirm("Voulez-vous supprimer cette pièce ?")) {
    store.dispatch("deleteRoom", { roomId: props.id });
    emit("closeModal");
  }
};

const updateOrientation = (oriValue) => {
  formData.value.roomOrientationDeg = oriValue;
};

watch(
  formData,
  () => {
    store.dispatch("updateRoom", {
      roomId: formData.value.roomId,
      roomData: formData.value,
    });
  },
  { deep: true }
);
//--

//-- WALL MANAGER
const currentWallForm = ref(1);
const openWallForm = (wallNum) => {
  currentWallForm.value = wallNum;
};

forms.room.questions.forEach((qstVarName) => {
  const qst = questions.find((q) => q.varname === qstVarName);
  rules[qstVarName] = qst.validation;
});

rules.walls = {};
forms.wall.questions.forEach((qstVarName) => {
  const qst = questions.find((q) => q.varname === qstVarName);
  for (let i = 1; i <= 4; i++) {
    if (!rules.walls[`wall${i}`]) rules.walls[`wall${i}`] = {};

    if (qstVarName === "wallType") {
      rules.walls[`wall${i}`][qstVarName] = {
        required: requiredIf(() => {
          return formData.value.walls[`wall${i}`].info2D.type === "int";
        }),
      };
    } else if (
      qstVarName === "windowsProtectionType" ||
      qstVarName === "windowsProtectionControl" ||
      qstVarName === "windowsGlassType"
    ) {
      rules.walls[`wall${i}`][qstVarName] = {
        required: requiredIf(() => {
          return formData.value.walls[`wall${i}`].info2D.type === "ext";
        }),
      };
    } else {
      rules.walls[`wall${i}`][qstVarName] = qst.validation;
    }
  }
});
const triggerSolarProtection = ({wall}) => {
  const isProtectionType = formData.value.walls[wall].windowsProtectionType;
  if (isProtectionType === 'none') {
    formData.value.walls[wall].windowsProtectionControl = 'manual';
  }
}
//--

//-- FORM EXECPTIONS
const buildingNbLevelTotal = computed(
  () => simuData.value.buildingConfig.buildingNbLevelTotal
);
if (buildingNbLevelTotal.value === 1) {
  formData.value.roomLevel = "bottom";
}

const isSingleStorey = computed(() => buildingNbLevelTotal.value === 1);
const isRdc = computed(
  () => formData.value.roomLevel === "bottom" || isSingleStorey.value
);
const isTopLevel = computed(
  () => formData.value.roomLevel === "top" || isSingleStorey.value
);

// const roomLevelChoices = [...getQstParam('roomLevel').choices];
// if (buildingNbLevelTotal.value === 1) {
//   roomLevelChoices.splice(1,2);// delete top and intermed levels choices
// } else if (buildingNbLevelTotal.value === 2) {
//   roomLevelChoices.splice(1,1);// delete intermed level choice
// }
let roomLevelChoices = [];
const IconPrefix =
  buildingNbLevelTotal.value > 2 ? "IconRoomLevel3" : "IconRoomLevel2";
if (buildingNbLevelTotal.value === 2) {
  roomLevelChoices = [
    {
      value: "bottom",
      label: "Rez de chaussée",
      icon: `${IconPrefix}Bottom`,
      coef: null,
    },
    {
      value: "top",
      label: "Dernier étage",
      icon: `${IconPrefix}Top`,
      coef: null,
    },
  ];
} else {
  roomLevelChoices = [
    {
      value: "bottom",
      label: "Rez de chaussée",
      icon: `${IconPrefix}Bottom`,
      coef: null,
    },
    {
      value: "middle",
      label: "Intermédiaire",
      icon: `${IconPrefix}Inter`,
      coef: null,
    },
    {
      value: "top",
      label: "Dernier étage",
      icon: `${IconPrefix}Top`,
      coef: null,
    },
  ];
}

// let roofCompsitionChoices = [...getQstParam('roomLevel').choices];

// {value: "parget", label: "Plâtre", coef: null},
</script>

