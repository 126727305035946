<template>
  <div>
    <p>Une ventilation mécanique avec option de surventilation (par exemple VMC double flux, VMI par insufflation) permet de rafraichir la température ambiante la nuit, notamment quand l’environnement du bâtiment ne permet pas une ouverture des fenêtres. Surventiler la nuit, lorsque les températures extérieures sont plus fraîches, permet d’évacuer la chaleur emmagasinée durant la journée.</p>
  
    <div class="row">
      <div class="col-12 col-lg-6">
        <figure>
      <!-- <figcaption>TITRE</figcaption> -->
      <img
        src="@/assets/img/helps/surventilation1.png"
        alt="Ventilation Mécanique par insufflation"
      />
    </figure>
      </div>
      <div class="col-12 col-lg-6">
        <figure>
      <img
        src="@/assets/img/helps/surventilation2.png"
        alt="Ventilation Mécanique Contrôlée Double Flux"
      />
    </figure>
      </div>
    </div>
  </div>
</template>