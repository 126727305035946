<template>
  <form
  @submit.prevent="qstManager.submitForm()"
  class="form-step"
  :class="{'form-in-error': qstManager.isFormValide === false}"
  >
    <div class="container-">
      <slot :qstManager="qstManager"></slot>
    </div>

    <LayoutFormFooter>
      <template v-slot:validation-btn-text v-if="props.validationButton?.text">{{props.validationButton?.text }}</template>
    </LayoutFormFooter>
  </form>
</template>

<script setup>
import {reactive} from 'vue';
import LayoutFormFooter from './LayoutFormFooter.vue';
import { useFormQstManager } from '../composable/useFormQstManager.js';

const props = defineProps({
  // stepId: {
  //   type: Number,
  //   required: true
  // },
  formType: {
    type: String,
    required: true
  },
  formItem: {
    type: [String, Number],
    required: true
  },
  validationButton: {
    type: Object,
    required: false
  },
  // stepTitle: {
  //   type: String,
  //   required: false,
  //   default: ''
  // },
  // stepDescription: {
  //   type: String,
  //   required: false,
  //   default: ''
  // },
  isFormValide: {
    type: Boolean,
    required: false,
    default: null
  }
})

// const emit = defineEmits(['submitForm']);
// const submitForm = () => {
//   emit('submitForm', props.stepId)
// }

const qstManager = reactive(useFormQstManager({formType: props.formType, formItem: props.formItem}));






</script>
