export const getDateFromNthHourOfYear = (nthHour, year = null) => {
  // Calculer la durée d'une heure en millisecondes
  const hourInMilliseconds = 60 * 60 * 1000;

  // Obtenir la date actuelle
  const currentDate = !year ? new Date() : new Date(year, 0, 1);

  // Obtenir le jour de l'année actuel (de 0 à 364 ou 365)
  const currentDayOfYear = Math.floor((currentDate - new Date(currentDate.getFullYear(), 0, 0)) / 86400000);

  // Calculer le nombre total d'heures écoulées depuis le début de l'année
  const totalHours = (currentDayOfYear * 24) + currentDate.getHours();

  // Calculer la différence entre le nombre total d'heures et l'heure spécifiée
  const hDiff = Math.abs(nthHour - totalHours);
  const hourDifference = hDiff <= 24 ? 24 - hDiff : hDiff;
  // const hourDifference = nthHour - totalHours;

  // Calculer le décalage en millisecondes pour atteindre l'heure spécifiée
  const timeOffset = hourDifference * hourInMilliseconds;

  // Ajouter le décalage à la date actuelle
  const targetDate = new Date(currentDate.getTime() + timeOffset);

  // Formater la date de façon abrégée (par exemple : dd/mm/yyyy)
  const formattedDate = `${targetDate.getDate()}/${targetDate.getMonth() + 1}/${targetDate.getFullYear()}`;

  const formattedHour = `${targetDate.getHours()}h ${targetDate.getMinutes()}m ${targetDate.getSeconds()} s`;

  const formattedDataHourShort = `${targetDate.getDate()}/${targetDate.getMonth() + 1} ${targetDate.getHours()}h`;

  return {
    hourInMilliseconds,
    currentDate,
    currentDayOfYear,
    totalHours,
    hourDifference,
    timeOffset,
    targetDate,
    formattedDate,
    formattedHour,
    formattedDataHourShort,
    month: targetDate.getMonth() + 1,
    day: targetDate.getDate(),
    hour: targetDate.getHours()
  };
}