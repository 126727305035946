<template>
<g>
	<g>
		<path d="M121.963,202.082c0,3.318-0.141,6.114-0.417,8.381c-0.276,2.267-0.692,4.147-1.243,5.641c-0.554,1.493-1.246,2.57-2.076,3.233s-1.797,0.996-2.902,0.996H16.941c-3.65,0-6.719-1.077-9.208-3.234C5.245,214.941,4,211.432,4,206.562V11.618c0-1.104,0.358-2.099,1.078-2.985C5.797,7.749,6.986,7.03,8.646,6.476c1.659-0.551,3.899-0.995,6.72-1.328C18.186,4.816,21.696,4.65,25.9,4.65c4.314,0,7.853,0.166,10.618,0.497c2.765,0.333,4.978,0.777,6.637,1.328c1.659,0.555,2.847,1.273,3.567,2.157C47.44,9.52,47.8,10.515,47.8,11.618V184.33h67.525c1.105,0,2.073,0.307,2.902,0.914s1.521,1.603,2.076,2.986c0.55,1.383,0.967,3.209,1.243,5.475C121.822,195.972,121.963,198.764,121.963,202.082z"/>
		<path d="M323.542,204.074c1.327,3.98,2.157,7.133,2.49,9.457c0.33,2.322-0.114,4.065-1.329,5.227c-1.22,1.162-3.377,1.878-6.469,2.157c-3.101,0.274-7.359,0.413-12.775,0.413c-5.643,0-10.04-0.082-13.192-0.248c-3.152-0.165-5.558-0.497-7.214-0.994c-1.659-0.499-2.822-1.189-3.484-2.076c-0.665-0.882-1.222-2.044-1.66-3.484l-14.434-43.136h-80.633l-13.604,41.975c-0.444,1.551-1.024,2.849-1.742,3.9c-0.721,1.052-1.882,1.882-3.484,2.488c-1.604,0.606-3.874,1.021-6.804,1.243c-2.93,0.223-6.773,0.332-11.529,0.332c-5.09,0-9.072-0.166-11.945-0.498c-2.876-0.331-4.868-1.133-5.973-2.403c-1.108-1.271-1.493-3.071-1.161-5.395c0.332-2.322,1.161-5.417,2.488-9.291l66.198-190.464c0.662-1.878,1.438-3.398,2.321-4.561c0.885-1.161,2.295-2.048,4.23-2.655s4.618-0.994,8.049-1.16c3.427-0.167,7.964-0.251,13.604-0.251c6.524,0,11.725,0.084,15.596,0.251c3.87,0.166,6.884,0.553,9.041,1.16c2.158,0.607,3.707,1.522,4.646,2.737c0.938,1.219,1.744,2.878,2.407,4.977L323.542,204.074z M224.991,46.626h-0.164l-30.362,91.249h60.888L224.991,46.626z"/>
		<path d="M509.029,155.959c0,7.633-1.053,14.547-3.152,20.739c-2.104,6.196-5.007,11.672-8.711,16.425c-3.707,4.759-8.13,8.852-13.273,12.277c-5.145,3.432-10.813,6.251-17.006,8.462c-6.196,2.215-12.802,3.844-19.827,4.896c-7.022,1.052-14.848,1.575-23.474,1.575h-57.073c-3.65,0-6.72-1.077-9.21-3.234c-2.487-2.157-3.731-5.667-3.731-10.537V19.416c0-4.864,1.244-8.377,3.731-10.533c2.49-2.156,5.56-3.236,9.21-3.236h53.921c13.159,0,24.305,1.107,33.429,3.317c9.125,2.215,16.814,5.56,23.061,10.039c6.249,4.479,11.035,10.148,14.354,17.004c3.318,6.86,4.978,14.932,4.978,24.224c0,5.201-0.664,10.095-1.991,14.684s-3.265,8.768-5.806,12.525c-2.547,3.763-5.67,7.081-9.376,9.954c-3.707,2.878-7.939,5.202-12.69,6.969c6.078,1.108,11.695,3.043,16.837,5.806c5.144,2.768,9.623,6.333,13.439,10.703c3.817,4.37,6.83,9.485,9.044,15.345C507.92,142.082,509.029,148.66,509.029,155.959z M452.619,65.539c0-4.313-0.663-8.183-1.99-11.613c-1.326-3.427-3.318-6.276-5.972-8.543c-2.656-2.267-6.001-4.009-10.04-5.228c-4.037-1.215-9.433-1.825-16.175-1.825h-22.066v55.746h24.39c6.305,0,11.334-0.746,15.098-2.238c3.756-1.493,6.883-3.541,9.371-6.139c2.49-2.599,4.34-5.642,5.562-9.126C452.01,73.09,452.619,69.413,452.619,65.539zM463.734,157.452c0-4.977-0.828-9.428-2.487-13.354s-4.096-7.219-7.299-9.873c-3.213-2.655-7.302-4.699-12.279-6.139c-4.976-1.436-11.446-2.157-19.41-2.157h-25.883v61.055h31.523c6.079,0,11.196-0.635,15.346-1.906c4.146-1.271,7.741-3.152,10.783-5.641c3.043-2.489,5.418-5.585,7.134-9.291C462.877,166.439,463.734,162.211,463.734,157.452z"/>
	</g>
	<g>
		<path d="M108.229,145.351c0,1.537-0.341,2.672-1.027,3.406c-0.685,0.735-1.627,1.103-2.83,1.103H77.177c0,1.902,0.226,3.63,0.676,5.183s1.167,2.873,2.154,3.957c0.984,1.087,2.253,1.913,3.806,2.479c1.552,0.569,3.414,0.853,5.585,0.853c2.204,0,4.14-0.158,5.809-0.476c1.669-0.315,3.114-0.667,4.333-1.053c1.217-0.383,2.229-0.733,3.03-1.052c0.801-0.315,1.452-0.476,1.952-0.476c0.301,0,0.551,0.06,0.751,0.176c0.201,0.117,0.366,0.326,0.501,0.626c0.135,0.301,0.226,0.726,0.275,1.277c0.05,0.551,0.075,1.244,0.075,2.078c0,0.736-0.016,1.362-0.05,1.879c-0.034,0.518-0.084,0.961-0.15,1.327c-0.065,0.368-0.166,0.676-0.301,0.926c-0.134,0.25-0.31,0.493-0.526,0.727c-0.216,0.234-0.811,0.551-1.777,0.952c-0.969,0.4-2.204,0.793-3.706,1.176c-1.502,0.385-3.223,0.718-5.159,1.003c-1.938,0.283-4.006,0.426-6.21,0.426c-3.974,0-7.455-0.501-10.443-1.503c-2.989-1.002-5.484-2.52-7.487-4.559c-2.003-2.035-3.498-4.607-4.483-7.712c-0.986-3.106-1.477-6.745-1.477-10.92c0-3.972,0.516-7.553,1.552-10.743c1.035-3.188,2.537-5.893,4.508-8.114c1.969-2.219,4.365-3.914,7.188-5.083c2.82-1.167,6.001-1.754,9.541-1.754c3.739,0,6.937,0.552,9.591,1.654c2.655,1.102,4.833,2.629,6.537,4.582c1.702,1.953,2.954,4.268,3.756,6.937c0.801,2.672,1.202,5.577,1.202,8.716V145.351z M96.009,141.745c0.101-3.54-0.61-6.319-2.128-8.34c-1.52-2.019-3.866-3.03-7.037-3.03c-1.603,0-2.997,0.301-4.182,0.901c-1.187,0.601-2.171,1.412-2.956,2.43c-0.785,1.02-1.386,2.221-1.803,3.605c-0.418,1.388-0.661,2.865-0.726,4.434H96.009z"/>
	</g>
</g>
</template>