<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="YES" fill="currentColor">
	<g>
		<g opacity="0.2" fill="currentColor">
			<polygon points="145.831,70.393 145.831,159.528 183.231,151.702 183.231,77.898 			"/>
		</g>
		<g opacity="0.2" fill="currentColor">
			<polygon points="145.831,70.393 103.365,61.871 103.365,168.415 145.831,159.528 			"/>
		</g>
		<g opacity="0.2" fill="currentColor">
			<polygon points="103.365,168.415 103.365,266.728 145.831,241.779 145.831,159.528 			"/>
		</g>
		<g opacity="0.2" fill="currentColor">
			<polygon points="145.831,241.779 183.231,219.804 183.231,151.702 145.831,159.528 			"/>
		</g>
		<g>
			<g>
				<path d="M145.319,166.907c-1.393,0-2.759-0.476-3.86-1.37c-1.43-1.163-2.261-2.908-2.261-4.751V71.651
					c0-1.835,0.823-3.572,2.242-4.735c1.42-1.163,3.288-1.626,5.083-1.267l37.4,7.506c2.859,0.574,4.917,3.085,4.917,6.001v73.804
					c0,2.897-2.031,5.398-4.868,5.992l-37.4,7.826C146.157,166.865,145.737,166.907,145.319,166.907z M151.441,79.122v74.13
					l25.158-5.265V84.171L151.441,79.122z"/>
			</g>
			<g>
				<path d="M102.854,175.793c-1.393,0-2.759-0.476-3.86-1.37c-1.431-1.162-2.261-2.907-2.261-4.75V63.129
					c0-1.834,0.822-3.572,2.242-4.735c1.42-1.163,3.288-1.627,5.083-1.267l42.466,8.522c2.859,0.574,4.917,3.085,4.917,6.002v89.135
					c0,2.897-2.031,5.398-4.868,5.992l-42.466,8.886C103.691,175.75,103.271,175.793,102.854,175.793z M108.975,70.601v91.537
					l30.224-6.325V76.666L108.975,70.601z"/>
			</g>
			<g>
				<path d="M102.854,274.103c-1.049,0-2.098-0.27-3.04-0.809c-1.906-1.089-3.081-3.118-3.081-5.313v-98.309
					c0-2.897,2.031-5.398,4.868-5.992l42.466-8.887c1.803-0.379,3.682,0.078,5.113,1.241c1.431,1.163,2.262,2.907,2.262,4.751
					v82.247c0,2.17-1.149,4.178-3.021,5.277l-42.466,24.948C104.998,273.822,103.925,274.103,102.854,274.103z M108.975,174.646
					v82.641l30.224-17.756v-71.209L108.975,174.646z"/>
			</g>
			<g>
				<path d="M145.319,249.154c-1.049,0-2.098-0.27-3.04-0.809c-1.906-1.09-3.081-3.117-3.081-5.313v-82.247
					c0-2.897,2.031-5.398,4.868-5.992l37.4-7.826c1.803-0.378,3.681,0.077,5.113,1.24c1.431,1.163,2.262,2.908,2.262,4.751v68.1
					c0,2.169-1.149,4.178-3.021,5.277l-37.4,21.974C147.464,248.873,146.392,249.154,145.319,249.154z M151.441,165.759v66.578
					l25.158-14.78v-57.062L151.441,165.759z"/>
			</g>
		</g>
	</g>
	<g>
		<path d="M392.939,228.025H197.061c-3.38,0-6.121-2.74-6.121-6.122V79.171c0-3.38,2.741-6.122,6.121-6.122h195.879
			c3.38,0,6.121,2.741,6.121,6.122v142.733C399.061,225.285,396.32,228.025,392.939,228.025z M203.182,215.783h183.637V85.292
			H203.182V215.783z"/>
	</g>
	<g>
		<g opacity="0.2" fill="currentColor">
			<polygon points="445.192,69.736 445.192,158.872 407.792,151.046 407.792,77.242 			"/>
		</g>
		<g opacity="0.2" fill="currentColor">
			<polygon points="445.192,69.736 487.658,61.215 487.658,167.758 445.192,158.872 			"/>
		</g>
		<g opacity="0.2" fill="currentColor">
			<polygon points="487.658,167.758 487.658,266.072 445.192,241.123 445.192,158.872 			"/>
		</g>
		<g opacity="0.2" fill="currentColor">
			<polygon points="445.192,241.123 407.792,219.148 407.792,151.046 445.192,158.872 			"/>
		</g>
		<g>
			<g>
				<path d="M444.681,166.251c-0.418,0-0.838-0.042-1.254-0.13l-37.4-7.826c-2.836-0.594-4.868-3.094-4.868-5.992V78.5
					c0-2.917,2.058-5.428,4.917-6.001l37.4-7.506c1.8-0.359,3.665,0.104,5.084,1.267s2.242,2.9,2.242,4.735v89.136
					c0,1.843-0.831,3.588-2.262,4.751C447.439,165.775,446.073,166.251,444.681,166.251z M413.401,147.331l25.158,5.265V78.466
					l-25.158,5.049V147.331z"/>
			</g>
			<g>
				<path d="M487.146,175.137c-0.417,0-0.838-0.042-1.254-0.13l-42.466-8.886c-2.836-0.593-4.867-3.094-4.867-5.991V70.994
					c0-2.917,2.058-5.428,4.916-6.001l42.467-8.522c1.8-0.358,3.663,0.104,5.084,1.267c1.419,1.163,2.241,2.9,2.241,4.735v106.543
					c0,1.844-0.831,3.589-2.261,4.751C489.904,174.662,488.539,175.137,487.146,175.137z M450.802,155.157l30.224,6.325V69.944
					l-30.224,6.065V155.157z"/>
			</g>
			<g>
				<path d="M487.146,273.447c-1.072,0-2.144-0.281-3.101-0.844l-42.466-24.948c-1.872-1.1-3.021-3.107-3.021-5.277V160.13
					c0-1.844,0.831-3.588,2.261-4.751c1.432-1.163,3.311-1.618,5.114-1.24l42.466,8.887c2.836,0.594,4.867,3.094,4.867,5.991v98.309
					c0,2.195-1.175,4.223-3.081,5.313C489.245,273.177,488.195,273.447,487.146,273.447z M450.802,238.875l30.224,17.756v-82.642
					l-30.224-6.324V238.875z"/>
			</g>
			<g>
				<path d="M444.681,248.498c-1.072,0-2.145-0.281-3.101-0.844l-37.401-21.974c-1.872-1.099-3.021-3.107-3.021-5.277v-68.1
					c0-1.844,0.831-3.588,2.262-4.751c1.431-1.162,3.311-1.618,5.113-1.241l37.401,7.826c2.836,0.594,4.867,3.094,4.867,5.992
					v82.248c0,2.194-1.176,4.222-3.081,5.312C446.778,248.229,445.729,248.498,444.681,248.498z M413.401,216.901l25.158,14.78
					v-66.578l-25.158-5.265V216.901z"/>
			</g>
		</g>
	</g>
	<g>
		<path fill="none" d="M374.328,72.976v158.655H215.672V72.976H374.328z M298.925,226.725l-0.079,0.026l-0.464,0.231l-0.129,0.026
			l-0.103-0.026l-0.466-0.258c-0.05,0-0.129,0-0.153,0.052l-0.026,0.077l-0.103,2.816l0.024,0.129l0.064,0.104l0.687,0.49
			l0.103,0.026l0.077-0.026l0.698-0.49l0.077-0.13l0.026-0.103l-0.117-2.816C299.028,226.776,298.975,226.725,298.925,226.725z
			 M300.68,225.976l-0.103,0.026l-1.215,0.619l-0.05,0.053l-0.026,0.077l0.117,2.84l0.038,0.079l0.05,0.051l1.317,0.619
			c0.079,0.026,0.156,0,0.208-0.051l0.024-0.103l-0.232-4.056C300.783,226.055,300.745,226.002,300.68,225.976z M295.955,226.002
			c-0.064-0.026-0.129-0.026-0.182,0.026l-0.038,0.103l-0.22,4.056c0,0.076,0.039,0.129,0.103,0.153h0.105l1.315-0.619l0.079-0.051
			l0.026-0.079l0.103-2.84l-0.015-0.077l-0.064-0.079L295.955,226.002z"/>
		<path fill="#009640" class="icon-symbole symbole-good" d="M344.416,98.149c3.046-1.834,6.997-0.93,8.933,2.04l6.535,9.994c1.781,2.687,1.341,6.262-1.008,8.444
			l-0.025,0.04l-0.104,0.089l-0.362,0.336l-1.497,1.42c-8.237,7.992-16.216,16.229-23.938,24.713
			c-14.513,15.958-31.762,36.874-43.357,57.144c-3.254,5.655-11.155,6.87-15.855,1.99l-42.867-44.544
			c-2.531-2.633-2.453-6.817,0.181-9.347c0.053-0.052,0.103-0.091,0.155-0.129l12.962-11.699c2.35-2.117,5.863-2.272,8.393-0.374
			l21.873,16.398C308.598,120.977,327.965,108.039,344.416,98.149z"/>
	</g>
	<path fill="currentColor" d="M490.071,33.163c0-1.619-1.307-2.934-2.934-2.934c-1.609,0-2.923,1.324-2.923,2.934v4.398h-4.392
		c-1.627,0-2.933,1.306-2.933,2.933c0,1.61,1.306,2.924,2.933,2.924h4.392v4.391c-0.01,1.626,1.313,2.933,2.923,2.933
		c1.627,0,2.934-1.306,2.934-2.924c0-0.008,0-0.008,0-0.008v-4.391h4.399c1.608,0,2.933-1.314,2.933-2.924
		c0-1.627-1.324-2.933-2.933-2.933h-4.399V33.163z M529.895,27.313c0-1.627-1.313-2.932-2.932-2.932s-2.934,1.305-2.934,2.932l0,0
		v4.4h-4.391c-1.618,0-2.933,1.306-2.933,2.924c0,1.618,1.314,2.924,2.933,2.924l0,0h4.391v4.4c0,1.618,1.315,2.932,2.934,2.932
		s2.932-1.313,2.932-2.932v-4.4h4.4c1.609,0,2.924-1.306,2.924-2.924c0-1.619-1.314-2.924-2.924-2.924h-4.4V27.313z M522.121,65.725
		c0-1.627-1.314-2.933-2.925-2.933c-1.627,0-2.933,1.306-2.933,2.924c0,0,0,0,0,0.008v4.39h-4.399c-1.618,0-2.934,1.314-2.934,2.925
		c0,1.627,1.315,2.933,2.934,2.933h4.399v4.399c0,1.61,1.306,2.934,2.933,2.934c1.61,0,2.925-1.324,2.925-2.934v-4.399h4.39
		c1.627,0,2.934-1.306,2.934-2.933c0-1.61-1.307-2.925-2.934-2.925h-4.39V65.725z M544.124,20.059l-2.181-0.294
		c-1.564-0.18-2.494,1.645-1.592,2.932c5.615,8.084,8.619,17.688,8.603,27.524c-0.02,25.681-20.093,46.874-45.729,48.269
		c-1.573,0.089-2.45,1.932-1.342,3.059c0.501,0.518,1.011,1.019,1.538,1.519l0.518,0.483l1.915,1.646l1.377,1.091l0.876,0.661
		l1.377,0.949l1.287,0.84c0.877,0.573,1.789,1.091,2.709,1.592l1.94,1.002l1.896,0.875l2.218,0.895l1.788,0.626
		c2.639,0.858,5.339,1.502,8.084,1.896l2.325,0.303l1.949,0.144c26.665,1.555,49.521-18.796,51.077-45.443
		c0.161-2.754,0.089-5.509-0.215-8.236l-0.269-2.029c-0.554-3.542-1.503-7.029-2.825-10.364l-0.823-1.958l-0.554-1.216l-0.555-1.091
		c-1.163-2.254-2.486-4.417-3.988-6.465l-1.127-1.466l-1.448-1.763l-1.198-1.313l-0.877-0.904l-1.18-1.162l-1.627-1.467
		l-2.343-1.905l-1.807-1.296l-2.128-1.394l-2.361-1.36l-2.396-1.208l-0.823-0.383l-1.771-0.734l-2.021-0.769l-1.842-0.59
		l-2.379-0.662l-1.949-0.447L544.124,20.059z M557.753,51.686c0-7.269-1.432-14.467-4.203-21.192
		c20.53,9.604,29.383,34.025,19.78,54.546c-9.604,20.531-34.033,29.367-54.547,19.762c10.562-3.326,19.888-9.729,26.809-18.384
		c1.771,2.45,5.203,3.005,7.654,1.216c1.43-1.037,2.271-2.683,2.271-4.454c0-2.557-1.788-4.792-4.292-5.364
		c0.465-0.841,0.895-1.718,1.306-2.594c4.311,2.665,9.943,1.342,12.608-2.969c2.665-4.292,1.341-9.943-2.968-12.608
		c-1.449-0.894-3.112-1.359-4.83-1.359c0.251-2.164,0.394-4.363,0.394-6.6H557.753z"/>
</g>
</svg>
</template>