<template>
  <!-- MAIN SCORE -->
  <!-- stepToUse: <pre>{{ stepToUse }}</pre>
  brickScore: <pre>{{ brickScore }}</pre> -->
  <!-- roomsScore: <pre>{{ roomsScore }}</pre> -->

  <div class="main-score-wrapper">

    <div class="row">
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
          <div class="main-score-bricks">
            <template
            v-for="(step, stepIndex) in stepToUse"
            :key="`step_${stepIndex}`"
            >
            <div

            :id="`score-brick-${stepIndex + 1}`"
            :style="{'--brick-bgColor': step.bgColor, '--brick-txtColor': step.txtColor}"
            class="main-score-brick brick-score"
            :class="{'brick-active': step.level === brickScore, 'brick-selected': step.level === brickScore}"
            >
              <i class="cursor bi bi-caret-right-fill" v-if="step.level === brickScore"></i>
              <span class="brick-label">{{ step.label }}</span>
              <i class="cursor bi bi-caret-left-fill" v-if="step.level === brickScore"></i>

              <div class="brick-legend">
                <span>{{ step.max }}</span>
                <span>{{ step.min }}</span>
              </div>
            </div>

            <!-- <template v-if="step.level === brickScore && roomsScore.length > 0">
              <div
              v-for="(rs, rsIndex) in roomsScore"
              :key="`rs_${rsIndex}`"
              class="room-score-block"
              >
                <div class="room-score-label">{{ rs.label }} : {{ rs.score }}%</div>
                <div class="room-score-line">
                  <div class="room-score-cursor" :style="{'--room-score': rs.score + '%'}"></div>
                </div>
              </div>
            </template> -->
          </template>
          </div>
          <template v-if="props.isDebug">
            <input type="range" max="100" min="0" v-model.number="scoreTest" />
          </template>
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
        <div>
          <i class="brick-emoji" :class="`bi ${ emojiSelected.emoji}-fill`" :style="{color: emojiSelected.bgColor}"></i>
        </div>
        <div class="theScore">
          <div class="theScore-label">Risque relatif d’inconfort&nbsp;:</div>
          <div class="theScore-value">{{theScore}}&nbsp;/&nbsp;100</div>
        </div>

      </div>
    </div>
  </div>
</template>


<script setup>
import { roundNumber } from '@/functions/numbers';
import { computed, ref } from 'vue';

const props = defineProps({
  mainScore: {
    type: Number,
    required: true,
  },
  roomsScore: {
    type: Array
  },
  isDebug: {
    type: Boolean,
    default: false
  }
});

const scoreTest = ref(props.mainScore);

const theScore = computed(() => {
  return roundNumber(scoreTest.value, 1);
  // return props.score
})

const steps = [
  {level: 5, min: 0, max: 19, label: "Très Bon", bgColor: "#49df23", txtColor: "#333", emoji:"bi-emoji-sunglasses"},
  {level: 4, min: 20, max: 39, label: "Bon", bgColor: "#9ddf23", txtColor: "#333", emoji:"bi-emoji-smile"},
  {level: 3, min: 40, max: 59, label: "Moyen", bgColor: "#f8e004", txtColor: "#333", emoji:"bi-emoji-neutral"},
  {level: 2, min: 60, max: 79, label: "Mauvais", bgColor: "#df6e23", txtColor: "#fff", emoji:"bi-emoji-frown"},
  {level: 1, min: 80, max: 100, label: "Très mauvais", bgColor: "#c70505", txtColor: "#fff", emoji:"bi-emoji-angry"},
];
const nbBricks = steps.length;
const bricksInterval = 100/nbBricks;
// const brickScore = Math.ceil(props.score / bricksInterval);
const brickScore = computed(() => roundNumber(nbBricks - Math.floor(theScore.value / bricksInterval)) || 1);

const isBadToGoodScore = ref(true);
const stepToUse = computed(() => {
  return isBadToGoodScore.value ? [...steps].reverse() : steps;
});

const emojiSelected = computed(() => steps.find(step => step.level === brickScore.value));


</script>

<style lang="scss" scoped>
.main-score-bricks {
  // border: 1px solid #ddd;
  // border-radius: 0.5rem;
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 450px;

  .main-score-brick {
    background-color: var(--brick-bgColor);
    border: 1px solid var(--brick-bgColor);
    color:  var(--brick-txtColor);
    // height: 1.4rem;
    width: 100%;
    margin: 0.2rem 0;
    padding: 0.4rem;
    border-radius: 0.5rem;
    opacity: 0.35;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .brick-legend{
      // border: 1px solid red;
      position:absolute;
      top:0;
      right: 5px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        display: block;
        font-size: 0.8rem;
      }
    }

    .brick-label{
      font-size: 1.3rem;
      margin: 0 0.8rem;
    }

    &.brick-selected {
      font-weight: 900;
    }
    &.brick-active {
      opacity: 1;
      position: relative;
      // background-color: var(--brick-color);
      // .cursor{
      //   // z-index: 10;
      //   // position: absolute;
      //   // top: 0rem;
      //   // right: 0;
      //   // display: block;
      //   // width: 0;
      //   // height: 0;
      //   // border-style: solid;
      //   // border-width: 0.7rem 1.4rem 0.7rem 0;
      //   // border-color: transparent #333 transparent transparent;
      // }
    }
  }
}

.theScore {
  .theScore-value {
    text-align: center;
    font-size: 2.3rem;
    font-weight: 700;
  }
  .theScore-label {
    text-align: center;
    font-size: 1.5rem;
  }
}

.brick-emoji {
  font-size: 5rem;
}


.room-score-block {
  width: 100%;
  margin: 0.5rem 0;

  .room-score-label{
    font-size: 0.7rem;
  }
.room-score-line{
  width: 100%;
  height: 2px;
  background-color: #ccc;
  position: relative;

  .room-score-cursor {
    position: absolute;
    top: -2.5px;
    left: var(--room-score);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #007bff transparent;

  }
}
}

</style>