<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isAllowed: {
      type: Boolean,
      required: true
    },
    // isActive: {
    //   type: Boolean,
    //   required: true
    // }
  },
  data() {
    return {
      // isActive: false
    };
  },
  mounted() {
    this.$parent.addTab(this);
  },
  methods: {
    activateTab() {
      this.isActive = true;
    },
    deactivateTab() {
      this.isActive = false;
    }
  }
};
</script>
