<!-- CustomInput.vue -->
<template>
  <div :class="{error: isError}">
  
    <template v-if="type === 'number' || type === 'range'">
      <div :class="type === 'number' ? 'input-group' : ''">
        <div class="input-group-text" v-if="type === 'number' && prefix != ''">{{prefix}}</div>
        <input
        :name="varname"
        :type="type"
        v-model.number="model"
        :class="type === 'number' ? 'form-control' : 'form-range'"
        :min="min"
        :max="max"
        :step="stepIncrement"
        />
        <div class="input-group-text" v-if="type === 'number'">{{unite}}</div>
      </div>
    </template>
  
    <template v-else-if="type === 'select'">
      <select :name="varname"  v-model.lazy="model" class="form-select">
        <option :value="null">Sélection...</option>
        <template v-for="(choice, choiceIndex) in choicesList" :key="`choice_${choiceIndex}`">
          <option
          v-if="!choice.hidden"
          :value="choice.value"
          :ref="`choicesList_${varname}-${choice.value}`"
          >{{choice.label}}</option>
        </template>
        

      </select>
    </template>
  
    <template v-else-if="type === 'switch'">
      <div class="form-check form-switch">
        <input :name="`${varname}${uniqId}`" class="form-check-input" type="checkbox" :id="`${varname}${uniqId}`" v-model.lazy="model">
        <label class="form-check-label" :for="`${varname}${uniqId}`">{{label}}</label>
      </div>
    </template>
  
    <!-- RADIO -->
    
    <template v-else-if="type === 'radio' || type === 'checkbox'">
      <div class="form-check-wrapper" :class="extraClasses.join(' ')">
      <div
        class="form-check"
        :class="{'selected': choice.value === model}"
        v-for="(choice, choiceIdx) in choicesList"
        :key="varname + '_' + choiceIdx"
      >
      <label class="form-check-label" :for="`${varname}${uniqId}${choiceIdx}`" v-if="!choice.hidden">
        <input
          class="form-check-input"
          :type="type"
          :value="choice.value"
          :id="`${varname}${uniqId}${choiceIdx}`"
          :name="`${varname}${uniqId}${choiceIdx}`"
          v-model="model"
          :ref="`choicesList_${varname}-${choice.value}`"
        />

          <span class="form-check-label-text">{{ choice.label }}</span>
        </label>
        <HelpBtn :helpComponent="choice.help.modalComponent" :helpTitle="choice.help.title" v-if="choice.help" />
      </div>
    </div>
    </template>

    <!-- RADIO-CARD -->
    <template v-else-if="type === 'radio-card'">
      <div class="row type-radio-card">
        <!-- :class="choicesList.length % 2 === 0 ? 'col-lg-6' : 'col-lg-4'" -->
        <div
          class="radio-card-item- col-6 col-lg-3" 
          v-for="(choice, choiceIdx) in choicesList"
          :key="varname + '_' + choiceIdx"
          :style="{'--nb_item': choicesList.length}"
        >
          <div
            v-if="!choice.hidden"
            class="input-card"
            @click="model = choice.value"
            :class="{ selected: model === choice.value }"
          >
            <!-- <img
              :src="`@/assets/img/forms/${imagePrefixName}_${choice.value}.png`"
              :alt="choice.label"
            /> -->
            <!-- <img
              :src="getImgUrl(`${imagePrefixName}_${choice.value}`)"
              :alt="choice.label"
            /> -->
  
            <label>{{ choice.label }}</label>
          </div>
        </div>
      </div>
      <input
        type="hidden"
        :id="varname"
        v-model="model"
      />
    </template>
  
    <!-- IMAGE -->
    <template v-else-if="type === 'image'">
      <div class="row type-image">
        <!-- :class="choicesList.length % 2 === 0 ? 'col-lg-6' : 'col-lg-4'" -->
        <div
          class="col-6 col-lg-3" 
          v-for="(choice, choiceIdx) in choicesList"
          :key="varname + '_' + choiceIdx"
        >
          <div
            v-if="!choice.hidden"
            class="input-card"
            @click="model = choice.value"
            :class="{ selected: model === choice.value }"
          >
            <!-- <img
              :src="`@/assets/img/forms/${imagePrefixName}_${choice.value}.png`"
              :alt="choice.label"
            /> -->
            <img
              :src="getImgUrl(`${imagePrefixName}_${choice.value}`)"
              :alt="choice.label"
            />
  
            <label>{{ choice.label }}</label>
          </div>
        </div>
      </div>
      <input
        type="hidden"
        :id="varname"
        v-model="model"
      />
    </template>

    <!-- ICON -->
    <template v-else-if="type === 'icon'">
      <div class="row">
        <div
          class="col-6 col-lg-3"
          v-for="(choice, choiceIdx) in choicesList"
          :key="varname + '_' + choiceIdx"
        >
          <div
            v-if="!choice.hidden"
            class="input-icon"
            @click="model = choice.value"
            :class="{ selected: model === choice.value }"
          >
            <!-- <img
              :src="`@/assets/img/forms/${imagePrefixName}_${choice.value}.png`"
              :alt="choice.label"
            /> -->
            <component :is="choice.icon" class="icon"></component>
            <!-- <img
              :src="getImgUrl(`${imagePrefixName}_${choice.value}`)"
              :alt="choice.label"
            /> -->
  
            <label>{{ choice.label }}</label>
          </div>
        </div>
      </div>
      <input
        type="hidden"
        :id="varname"
        v-model="model"
      />
    </template>
  
    <template v-else>
      <input :name="`${varname}${uniqId}`" :type="type" v-model.lazy="model" class="form-control" />
    </template>
  
  
  
  </div>
  </template>
  
  <script>
  // import {ref, onMounted} from 'vue';
  export default {
    // setup () {
    //   const itemRefs = ref([])

    //   onMounted(() => console.log(itemRefs.value))
    // },
    name: 'CustomInput',
    emits: ['update:modelValue'],
    props: {
      label: {
        type: [String, Boolean],
        default: false,
        required: false,
      },
      modelValue: {
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      varname: {
        type: String,
        required: true,
      },
      uniqId: {
        type: [String, Number],
        required: false,
        default:''
      },
      isError: {
        type: Boolean,
        default: false
      },
      unite: {
        type: String,
        default: ''
      },
      prefix: {
        type: String,
        default: ''
      },
      min: {
        type: Number,
        default: null
      },
      max: {
        type: Number,
        default: null
      },
      stepIncrement: {
        type: Number,
        default: null
      },
      imagePrefixName: {
        type: String,
        default: null
      },
      extraClasses: {
        type: Array,
        default: () => {return []}
      },
      choicesList: {
        type: Array,
        default: () => {return []}
      }
    },
    methods: {
      // getImgUrl(imgName) {
      //   let img = new URL(`/src/assets/img/forms/${imgName}.png`, import.meta.url);
      //   if (img.pathname === "/undefined") {
      //     img = new URL(`/src/assets/img/forms/no-image.png`, import.meta.url);
      //   }
      //   // console.log('getImgUrl', imgName, img, img.pathname);
      //   return img;
      // }
      getImgUrl(imgName) {
        // console.log('getImgUrl', imgName);
        const images = require.context('@/assets/img/forms', false, /\.png$/);
        // console.log('getImgUrl', images);
        try {
          return images('./' + imgName + ".png");
        } catch (e) {
          // console.error('getImgUrl', e);
          return images("./no-image.png");
        }
      }
    },
    computed: {
      model: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        },
      },
    },
  }
  </script>
