<template>
<div>
<p>Les surfaces vitrées peuvent être facteur d’une élévation significative de la température intérieure si les locaux sont pleinement exposés au soleil. L’orientation des vitrages est donc importante à considérer.</p>
 
<p>D’autant que l’excès de chaleur générée à travers les parois vitrées peut engendrer une surconsommation énergétique liée à un besoin de rafraichissement (comme la climatisation), qui ne sera, ni efficace, ni optimal, si les parois vitrées ne bénéficient d’aucune protection.</p>

<p>Pour aller plus loin : </p>
<ul>
  <li><a href="https://lab.cercle-promodul.inef4.org/tool_type/fiches-pratiques/tool/adaptation-du-batiment-au-changement-climatique-limiter-les-effets-du-rayonnement-et-la-montee-en-temperature-de-lhabitat-via-les-parois-vitrees" target="_blank">Limiter les effets du rayonnement et la montée en température de l’habitat via les parois vitrées</a> - Cercle Promodul / INEF4</li>
</ul>
</div>

</template>