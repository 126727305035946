<template>

<!-- props.inputValidationObj Global: <pre>{{props.inputValidationObj }}</pre> -->
<!-- props.qstParams: <pre>{{ props.qstParams }}</pre> -->
<!-- theValue: {{ theValue }} / props.modelValue: {{ props.modelValue }} -->
    <InputFormRow :inputValidationObj="props.inputValidationObj" v-bind="$attrs" :displayType="props.qstParams.displayType">
      
      <template v-slot:label>
        {{ props.qstParams?.label ? props.qstParams.label.replace(' ?', '&nbsp;?') : ""}}
        <template v-if="props.qstParams.help && props.qstParams.help.modalComponent">
          <HelpBtn :helpComponent="props.qstParams.help.modalComponent" :helpTitle="props.qstParams.help.title" />
        </template>
      </template>
      <template v-slot:description>
        <div v-if="props.qstParams.description" v-html="props.qstParams.description"></div>
      </template>
      <template v-slot:input>
        <template v-if="props.qstParams.isComponent">
          <component
          :is="props.qstParams.componentName"
          v-model="theValue"
          :varname="props.qstParams.varname"
          ></component>
        </template>
        <template v-else>
          <InputCustom
            v-model="theValue"
            :varname="props.qstParams.varname"
            :type="props.qstParams.type"
            :unite="props.qstParams.unite"
            :prefix="props.qstParams.prefix"
            :min="props.qstParams.min"
            :max="props.qstParams.max"
            :stepIncrement="props.qstParams.stepIncrement"
            :extraClasses="props.qstParams.extraClasses"
            :imagePrefixName="props.qstParams.imagePrefixName"
            :choicesList="theChoicesList"
          />
        </template>
      </template>
    </InputFormRow>
</template>

<script setup>
  import {ref, watch, computed} from 'vue';
  import InputCustom from './InputCustom.vue';
  import InputFormRow from './InputFormRow.vue';

  const props = defineProps({
    modelValue: {
      type: [String, Number, Boolean, Array],
      default: null
    },
    inputValidationObj: {
      type: Object,
      required: false
    },
    // formData: {
    //   type: Object,
    //   required: true
    // },
    qstParams:{
      type: Object,
      required: true
    },
    choicesList:{
      type: Array,
      required: false,
      default: () => {[]}
    },

  })

  const emit = defineEmits(['update:modelValue']);

  const theValue = ref(props.modelValue);
  
  // const theVarname = ref(props.qstParams.varname);
  const theChoicesList = computed(() => {
    if (props.choicesList) {
      return props.choicesList
    } else {
      return props.qstParams.choices
    }
  })

  watch (() => props.modelValue, (newVal) => {
    theValue.value = newVal;
  }, {deep: true});

  watch (theValue, (newVal) => {
    emit('update:modelValue', newVal)
  })
</script>
