<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="YES">
	<path id="symbole" class="icon-symbole symbole-good" fill="#009640" d="M480.908,152.147c3.046-1.834,6.997-0.93,8.933,2.04l6.535,9.994
		c1.781,2.687,1.342,6.262-1.008,8.444l-0.025,0.04l-0.104,0.089l-0.362,0.336l-1.497,1.42
		c-8.236,7.992-16.216,16.229-23.938,24.713c-14.514,15.958-31.763,36.874-43.357,57.144c-3.254,5.655-11.155,6.87-15.855,1.99
		l-42.866-44.544c-2.53-2.633-2.453-6.817,0.181-9.347c0.053-0.052,0.103-0.091,0.155-0.129l12.962-11.699
		c2.351-2.117,5.863-2.272,8.393-0.374l21.873,16.398C445.091,174.975,464.458,162.037,480.908,152.147z"/>
	<path id="umbrella" opacity="0.50" fill="currentColor" d="M362.093,150.946l1.996-3.483c21.027-36.403,8.538-82.959-27.866-103.986
		c-36.404-21.016-82.981-8.538-103.986,27.865l-0.68,1.19c-2.251,3.866-0.999,8.825,2.825,11.151l52.863,31.943l-29.288,48.489
		c-25.593,2.251-47.491,15.844-59.257,35.087c4.885-0.467,9.749,1.147,13.402,4.417c10.726-15.419,30.075-26.251,53.438-26.251
		s42.732,10.832,53.438,26.251c3.653-3.271,8.496-4.884,13.402-4.417c-11.661-19.072-33.261-32.581-58.577-35.044l25.082-41.501
		l51.824,31.306c3.887,2.336,8.921,1.083,11.256-2.803C362.008,151.083,362.049,151.02,362.093,150.946L362.093,150.946z
		 M339.897,62.835c21.26,19.02,27.292,50.751,13.104,76.631l-26.506-16.015l0.085-0.148c0.659-1.042,1.529-2.506,2.548-4.386
		c2.02-3.759,4.567-9.165,6.755-15.823C339.367,92.496,341.958,78.531,339.897,62.835z M316.769,49.689
		c-11.683,5.256-21.792,13.943-29.267,21.695c-5.671,5.894-10.875,12.223-15.568,18.924l-0.086,0.127l-25.806-15.589
		C261.568,51.388,290.645,41.533,316.769,49.689z M283.508,97.477c4.163-5.884,8.771-11.448,13.762-16.641
		c8.029-8.325,18.012-16.375,28.525-20.146c2.781,14.772,0.487,28.067-2.825,38.157c-1.529,4.704-3.483,9.26-5.819,13.613
		c-0.723,1.349-1.487,2.666-2.294,3.951L283.508,97.477z"/>
	<path id="sea" fill="#009FE3" opacity="1" d="M334.587,211.158c-1.083-0.743-2.357-1.125-3.673-1.167h-0.256
		c-2.888,0.063-5.394,1.911-6.328,4.65v0.022c-0.235,0.636-7.052,19.795-26.167,19.795c-19.137,0-25.912-19.03-26.188-19.838
		c-1.167-3.547-5.012-5.479-8.58-4.29c-2.04,0.679-3.632,2.272-4.291,4.29l-0.021,0.043c-0.234,0.636-7.03,19.795-26.167,19.795
		c-19.136,0-25.911-19.03-26.145-19.838c-0.446-1.317-1.296-2.464-2.421-3.313c-1.126-0.829-2.485-1.274-3.887-1.316h-0.106
		c-2.846,0-5.395,1.741-6.393,4.375c-0.106,0.255-9.452,24.17-30.351,24.17c-3.738,0-6.796,3.037-6.796,6.797
		c0,3.759,3.059,6.796,6.796,6.796c15.017-0.424,28.8-8.41,36.68-21.196c12.468,18.01,37.19,22.514,55.222,10.024
		c3.929-2.697,7.329-6.096,10.025-10.004c12.488,18.011,37.211,22.493,55.221,10.004c3.928-2.718,7.306-6.116,10.025-10.024
		c7.88,12.786,21.664,20.771,36.7,21.196c3.739,0,6.797-3.037,6.797-6.796c0-3.76-3.059-6.797-6.797-6.797
		c-13.486,0-22.216-10.131-26.719-17.288l0,0c-1.401-2.188-2.612-4.503-3.61-6.882c-0.509-1.317-1.401-2.421-2.57-3.229V211.158z"/>
</g>
</svg>
</template>