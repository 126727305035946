import { reactive, ref, watch, computed } from 'vue';
import { useStore } from "vuex";
import { useVuelidate } from '@vuelidate/core';
// import {getObjectValueFromString} from '../functions/utils.js';
import { /*steps as qstSteps,*/ questions as qstMaster } from '../appConfig/index.js';
import { forms as formsMaster } from '../appConfig/config.forms.js';

/**
 * 
 * @param {string} formName 
 * @returns {object[]}
 */
const setFormQstList = (formName) => {
  const fQ = formsMaster[formName].questions.map((qstName) => {
    return qstMaster.find((qst) => qst.varname === qstName);
  });

  // console.log('fQ', fQ);
  return fQ;
}

export function useFormQstManager({formType, formItem}) {

  //-- STORE
  const store = useStore();
  const steps = computed(() => store.getters.steps);



  //-- QST PARSE
  const simuData = computed(() => store.getters.simuData);
  const formDataRaw = reactive({});
  const rules = reactive({});

  let stepData = reactive([]);
  let formQst = reactive({});
  let formQstBySubStep = reactive({});
  let submitForm = null;



  if (formType === 'step') {

    const stepId = formItem;
    stepData = steps.value.find((step) => step.id === stepId);

    if (stepData.subSteps) {
      stepData.subSteps.forEach((subStep) => {

        formQstBySubStep['subStep_' + subStep.id] = [];

        if (!subStep.formName) return;

        const formQstList = setFormQstList(subStep.formName);

        formQstBySubStep['subStep_' + subStep.id] = formQstList;

        formQstList.forEach((qst) => {
          if (!rules[subStep.formName]) rules[subStep.formName] = {};
          rules[subStep.formName][qst.varname] = qst.validation;

          if (!formQst[subStep.formName]) formQst[subStep.formName] = [];
          formQst[subStep.formName].push(qst);
        });
      });
    }

    //-- FX
    submitForm = async () => {
      const result = await v$.value.$validate();
      const nextStep = store.getters.nextStep;
  
      isFormValide.value = result;
      store.dispatch('setStepAllowedStatus', {id: nextStep.id, status: result});
  
      if (result) {
        store.dispatch('saveSimuData', formData);
        store.commit('setCurrentStep', nextStep.id);
        // setTimeout(() => {
        //   window.scrollTo(0,0);
        // }, 300);
      } else {
        store.dispatch('disallowAllNextSteps');
      }
  
    }
  } else if (formType === 'modalForm') {

    /**
     * Les questions doivent être gérées directement dans le Form dynamique du modal
     */

    submitForm = async () => {
      // alert('TODO');
      const result = await v$.value.$validate();
      isFormValide.value = result;
      if (result) {
        alert('OK');
        
      } else {
        // alert('KO!');
      }
    }
  }


  const setFormData = () => {
    for(const [formName, questions] of Object.entries(formQst)) {
      questions.forEach((qst) => {
        let savedValue = simuData.value[formName] ? simuData.value[formName][qst.varname] : null;

        if (!rules[formName]) rules[formName] = {};
        rules[formName][qst.varname] = qst.validation;

        if (!formDataRaw[formName]) formDataRaw[formName] = {};
        formDataRaw[formName][qst.varname] = savedValue ?? qst.default;// || null

      })
    }
  };
  setFormData();


  //-- FORM DATA
  const formData = reactive(formDataRaw);

  // console.log('rules',rules);
  // console.log('formDataRaw',formDataRaw);
  // console.log('formData',formData);
  // console.log('formQst',formQst);


  //-- VALIDATION
  let v$ = useVuelidate(rules, formData, {$lazy: true, $autoDirty: true});
  
  const isFormValide = ref(null);

  watch(isFormValide, (newVal) => {
    if (newVal === false) {
      setTimeout(() => {isFormValide.value = null}, 600);
    }
  })

  //-- Need to store each data to set input validation reactive
  watch(formData, () => {
    store.dispatch('saveSimuData', formData);
  }, {deep: true});

  watch(simuData, () => {
    setFormData();
  }, {deep: true});


  // console.log(formQst);

  return {
    // stepData,
    formQst,
    formQstBySubStep,
    formData,
    v$,
    submitForm,
    // getQstParam,
    isFormValide
  }
}
