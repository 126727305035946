import {
  materialsInertiaLevel,
  inertiaValues,
  // yearsConstructionLevel,
  getPeriodInsulationInfo,
  // insulationValues,
  getInsulationInfo,
} from "./levelAndValues.config.js";
import {forms, questions, discomforts} from '../appConfig/index.js';
// import {CalculBuilding} from '../class/calculBuilding.class.js';
import {roundNumber} from '../functions/numbers.js';
import {getDateFromNthHourOfYear} from '../functions/datetime.js';
import * as coefCor from './json/coefCorrection.json';
import meteoHours from './json/meteoHeatWave.json';



export class CalculMaster {

  constructor (simuData, options = {}) {

    // console.log('CalculMaster', {options});
    this.debug = {};

    this.simuData = JSON.parse(JSON.stringify(simuData));

    this.resultType = options?.resultType || 'bati';
    this.roomId = options?.roomId || null;

    this.simuData4Calcul = this.resultType === 'room' ? this.setRoomData() : this.setBatiData();
    

    this.inertiaLevel = this.setInertiaLevel();
    this.insulationLevel = this.setInsulationLevel();

    this.buildingType =  this.simuData.buildingConfig.buildingType;

    this.setCalculatedData();

    this.addToDebug('inertia', this.inertiaLevel.detail);
    this.addToDebug('insulation', this.insulationLevel.detail);
    this.addToDebug('simuData', this.simuData);
    this.addToDebug('buildingType', this.buildingType);

    // this.setCorrectionRaw();
    // this.setCorrectionModule();
    // this.getQuantitativeData();
  }

  getSimuData () {
    return this.simuData;
  }

  getDebug () {
    return this.debug;
  }

  addToDebug (key, data) {
    // console.log('addToDebug', {key, data});
    this.debug[key] = data;
  }

  setCalculatedData () {

    const calculatedData = {
        inertiaLevel: this.inertiaLevel.result?.value,
        insulationLevel: this.insulationLevel.result?.value,
    }

    this.simuData['calculated'] = calculatedData;
  }

  getCalculatedData () {
    return this.simuData['calculated'];
  }

  //------------------------------
  // DATA MANAGEMENT
  //------------------------------

  /**
   * 
   * @returns 
   */
  setBatiData () {
    return this.simuData;
  }

  /**
   * 
   * @returns 
   */
  setRoomData () {
    const batiData = this.simuData;
    const rooms = batiData.rooms;

    const theRoom = rooms.find(room => room.roomId === this.roomId);

      //-- Env
      batiData.environment.canOpenWindowsNight = theRoom.canOpenWindowsNight;

      //-- Floor
      batiData.buildingFloor.floorComposition = theRoom.roomFloorComposition;
      if (theRoom.roomFlooring === 'carpet') {
        batiData.buildingFloor.floorComposition = 'carpet';
      }

      //-- Roof
      batiData.buildingRoof.roofComposition = theRoom.roomRoofComposition;

      //-- Walls
      //-- Détection des pires coef pour chaque item (windowsWorstCoef) de chaque mur
      const windowsWorstCoef = {
        windowsGlassType: {value: null, coef: null},
        windowsProtectionType: {value: null, coef: null},
        windowsProtectionControl: {value: null, coef: null}
      }
      const orientationWorstCoef = {value: null, coef: null};
      const wallsCompositionLevel = [];
      // let nbWallsExt = 0;

      for (let wallData of Object.values(theRoom.walls)) {
        // console.log('WALL',{wallData});

        //-- Windows
        for (const [worstKey,worstInfo] of Object.entries(windowsWorstCoef)) {
          const wallDataCoef = this.getQuestionsValueCoef(worstKey, wallData[worstKey]);
          // console.log('TEST-A',{worstKey, worstInfo, wallData: wallData[worstKey], batiData: batiData.buildingWindows[worstKey]});
          if (worstInfo.value === null || wallDataCoef >= worstInfo.coef) {
            windowsWorstCoef[worstKey].value = wallData[worstKey];
            windowsWorstCoef[worstKey].coef = wallDataCoef;
          }
        }

        //-- Orientation
        const wallOrientationCoef = this.getQuestionsValueCoef('wallOrientation', wallData.wallOrientation);
        if (orientationWorstCoef.value == null || wallOrientationCoef >= orientationWorstCoef.coef) {
          orientationWorstCoef.value = wallData.wallOrientation;
          orientationWorstCoef.coef = wallOrientationCoef;
        }
        // console.log('TEST COEF', {wallOrientationCoef});


        //-- Inertie
        //-- On prend par defaut la valeur du bâtiment
        const batiWallLevel = materialsInertiaLevel[batiData.buildingWalls.wallsComposition];
        let wallComposition = {
          value: batiData.buildingWalls.wallsComposition,
          level: batiWallLevel
        };

        if (wallData.info2D.type === 'ext') {
          // nbWallsExt++;
        } else {// int
          
          if (wallData.wallType === 'parget') {
            wallComposition = {
              value: 'parget',
              level: materialsInertiaLevel.parget
            };
          }
          // Sinon si wallData.wallType==='shearWall' (mur de refend) on prend la valeur du mur bâtiment
        }

        wallsCompositionLevel.push(wallComposition);
        
      }
      // console.log('wallsCompositionLevel', {nbWallsExt, wallsCompositionLevel});
      //-- /Détection

      //-- Affection des pires coef pour chaque item (windowsWorstCoef) dans la simu pour la pièce
      //-- Windows
      for (const [worstKey,worstInfo] of Object.entries(windowsWorstCoef)) {
        batiData.buildingWindows[worstKey] = worstInfo.value;
      }

      //-- Orientation
      batiData.buildingWindows.windowsOrientations = orientationWorstCoef.value;

      //--Wall
      const nbWallLight = wallsCompositionLevel.reduce((acc, item) => (acc += (item.level === 0) ? 1 : 0), 0);
      // console.log({nbWallLight, wallsCompositionLevel});

      // Si plus de 3 murs léger, toute la pièce passe en inertie légère (plâtre)
      if (nbWallLight >= 3) {
        batiData.buildingWalls.wallsComposition = 'parget';
      }
      //-- /Affection


      this.addToDebug('nbWallLight', nbWallLight);
      this.addToDebug('wallsCompositionLevel', wallsCompositionLevel);
      this.addToDebug('orientationWorstCoef', orientationWorstCoef);


    return batiData;
  }

  /**
   * 
   * @param {*} varname 
   * @param {*} value 
   * @returns 
   */
  getQuestionsValueCoef (varname, value) {
    // console.log('getQuestionsValueCoef', {varname, value});
    const qst = questions.find(question => question.varname === varname);
    const choice = qst.choices.find(choice => choice.value === value);

    return choice?.coef;
  }


  //------------------------------
  // BUILDING
  //------------------------------


  /**
   * 
   * @returns 
   */
  setInertiaLevel() {
    let result = null;

    const sides = {
      walls: {
        material: this.simuData4Calcul.buildingWalls.wallsComposition,
        materialInertia: materialsInertiaLevel[this.simuData4Calcul.buildingWalls.wallsComposition],
        inertia: materialsInertiaLevel[this.simuData4Calcul.buildingWalls.wallsComposition],
      },
      floor: {
        material: this.simuData4Calcul.buildingFloor.floorComposition,
        materialInertia: materialsInertiaLevel[this.simuData4Calcul.buildingFloor.floorComposition],
        inertia: materialsInertiaLevel[this.simuData4Calcul.buildingFloor.floorComposition],
      },
      roof: {
        material: this.simuData4Calcul.buildingRoof.roofComposition,
        materialInertia: materialsInertiaLevel[this.simuData4Calcul.buildingRoof.roofComposition],
        inertia: materialsInertiaLevel[this.simuData4Calcul.buildingRoof.roofComposition],
      },
    };

    const isWallsITE = this.simuData4Calcul.buildingWalls.wallsInsulationType === "ite";
    const isRoofITE = this.simuData4Calcul.buildingRoof.roofInsulationType === "ite";
    if (!isWallsITE) {
      sides.walls.inertia = 0;
    }

    if (!isRoofITE) {
      sides.roof.inertia = 0;
    }

    // console.log({sides});

    const sumInertia =
      sides.walls.inertia +
      sides.floor.inertia +
      sides.roof.inertia;

    result = inertiaValues.find((item) => item.level === sumInertia);

    // console.log({sumInertia, result});

    return { result, detail: { isWallsITE, isRoofITE, sides } };
  }


  /**
   * 
   * @returns ObjectS
   */
  setInsulationLevel() {
    let level = 0;
    const simuDataYearConstruct =
      this.simuData4Calcul.buildingConfig.buildingConstructionYear;
    const constructInsulationInfo = getPeriodInsulationInfo(
      simuDataYearConstruct
    );

    const buildingSidesInsulation = {
      walls: getPeriodInsulationInfo(
        this.simuData4Calcul.buildingWalls.wallsInsulationImprovement
      ),
      roof: getPeriodInsulationInfo(
        this.simuData4Calcul.buildingRoof.roofInsulationImprovement
      ),
      floor: getPeriodInsulationInfo(
        this.simuData4Calcul.buildingFloor.floorInsulationImprovement
      ),
    };

    // console.log({ buildingSidesInsulation });

    let improvementsCounterSides = 0;
    let improvementsMaxLevel = 0;
    for (let value of Object.values(buildingSidesInsulation)) {
      // console.log({key, value});
      if (value.level) {
        improvementsCounterSides++;
        improvementsMaxLevel =
          value.level > improvementsMaxLevel
            ? value.level
            : improvementsMaxLevel;
      }
    }

    if (improvementsCounterSides === 0) {
      level = constructInsulationInfo.level;
    } else if (improvementsCounterSides > 0 && improvementsCounterSides < 3) {
      level =
        constructInsulationInfo.level >= improvementsMaxLevel
          ? constructInsulationInfo.level
          : getInsulationInfo("medium")?.level;
    } else {
      //3
      level = improvementsMaxLevel;
    }

    const result = getInsulationInfo(level, "level");

    // console.log({ level, result });

    return {
      result,
      detail: {
        level,
        // simuDataYearConstruct,
        constructInsulationInfo,
        buildingSidesInsulation,
        improvementsCounterSides,
        improvementsMaxLevel,
      },
    };
  }

  //------------------------------
  // DISCOMFORT
  //------------------------------

  setDiscomfortsAnaliz () {
    let discomfortsDetails = {};
    let discomfortsResume = {};
    for (const [discomfortFamily, discomfortData] of Object.entries(discomforts)) {

      if (!discomfortsDetails[discomfortFamily]){
        discomfortsDetails[discomfortFamily] = {
          coef: discomfortData.coef,
          label: discomfortData.label,
          questions: {}
        };
      }

      

      for(const [qstVarName, qstData] of Object.entries(discomfortData.questions)) {

        const qstConfig = this.getQstConfig(qstVarName);
        const formName = this.getQstFormName(qstVarName);
        const nbChoicesMax = this.getQstNbChoicesMax(qstVarName);// - 1;
        
        let value = null;
        // let valueCoef = null;

        // console.log('TEST', formName, qstVarName, this.simuData[formName][qstVarName]);
        let simuDataValue = null;
        if (this.simuData[formName] && this.simuData[formName][qstVarName] !== null && this.simuData[formName][qstVarName] !== undefined ) {
          simuDataValue = this.simuData[formName][qstVarName];
          // valueCoef = this.getQstChoiceCoef(qstVarName, value);
          value = this.getQstChoiceCoef(qstVarName, simuDataValue);
        }

        const ratio = roundNumber(value?.coef / nbChoicesMax);
        const pond = roundNumber(qstData.coef * ratio);

        discomfortsDetails[discomfortFamily].questions[qstVarName] = {
          coef: qstData.coef,
          label: qstConfig.label,
          simuDataValue,
          value,
          nbChoicesMax,
          ratio,
          pond
        };
      }
    }

    // console.log("RESULT A", {discomfortsDetails})

    const total = {};
    const chartDataDiscomforts = {labels: [], series: []};
    let finalScore = 0;
    let finalScoreData = {items1: 0, items2: 0};
    for (const [discomfortFamily, discomfortValue] of Object.entries(discomfortsDetails)) {
      // console.log({discomfortFamily, discomfortValue});

      chartDataDiscomforts.labels.push(discomforts[discomfortFamily].label);
      if (!total[discomfortFamily]) {
        total[discomfortFamily] = {coef: discomfortValue.coef, sum1: 0, sum2: 0, result: 0};
      }

      for (const qstData of Object.values(discomfortValue.questions)) {
        total[discomfortFamily].sum1 = roundNumber(total[discomfortFamily].sum1 + qstData.coef);
        total[discomfortFamily].sum2 = roundNumber(total[discomfortFamily].sum2 + qstData.pond);
      }

      const r = roundNumber(total[discomfortFamily].sum2 / total[discomfortFamily].sum1) * 100;
      total[discomfortFamily].result = roundNumber(r);
      chartDataDiscomforts.series.push(r);
      finalScoreData.items1 = roundNumber(finalScoreData.items1 + (r * discomfortValue.coef));
      finalScoreData.items2 = roundNumber(finalScoreData.items2 + discomfortValue.coef);
    }

    finalScore = roundNumber(finalScoreData.items1 / finalScoreData.items2);
    // console.log("RESULT B", {finalScoreData, finalScore, total});


    // Total sorted by using Array
    const totalSorted = Object.entries(total).sort((a,b) => {
      if (a[1].coef > b[1].coef) {
        return -1;
      } else if (a[1].coef === b[1].coef && a[1].result > b[1].result) {
        return -1;
      } else {
        return 1;
      }
    } );
    // convert Array to Object
    totalSorted.forEach((item) => {
      discomfortsResume[item[0]] = item[1];
    })


    return {discomfortsDetails, discomfortsResume, chartDataDiscomforts, finalScore};
  }

  /**
   * 
   * @param {*} qstVarname 
   * @param {*} discomfortName 
   * @returns 
   */
  getQstDiscomfortCoef (qstVarname, discomfortName = null) {
    // console.group('getQstDiscomfortConfig');
    let qstResult = null;
  
    // console.log({qstVarname, discomfortName});
    
    if (!discomfortName) {
  
      for (const discomfortConfig of Object.values(discomforts)) {
          // console.log('A', {discomfortConfig});
          if (discomfortConfig.questions[qstVarname]) {
            qstResult = {
              qst: discomfortConfig.questions[qstVarname].coef,
              discomfort: discomfortConfig.coef
            };
            break;
          }
      }
    } else {
      // console.log('B', {qstResult});
      if (discomfortName.questions[qstVarname]) {
        qstResult = {
          qst: discomfortName.questions[qstVarname].coef,
          discomfort: discomfortName.coef
        };
      }
    }
    // console.groupEnd();
    return qstResult;
  
  }


  getQstConfig (qstVarname) {
    return questions.find(qst => qst.varname === qstVarname);
  }

  getQstChoiceCoef (qstVarname, choiceValue) {
    // console.log({qstVarname, choiceValue});
    const qst = this.getQstConfig(qstVarname);
    return qst.choices.find(choice => choice.value === choiceValue);
  }

  getQstFormName (qstVarname) {
    for(const [formKey, formData] of Object.entries(forms)) {
      // console.log(formKey, {formData});
      if (formData.questions && formData.questions.includes(qstVarname)) {
        return formKey;
      }
    }
    return null;
  }

  getQstNbChoicesMax (qstVarname) {
    // console.log({qstVarname, choiceValue});
    const qst = this.getQstConfig(qstVarname);
    const choices = qst.choices;
    let isCoefZero = false;
    const compteur = choices.reduce((acc, val) => {
      // console.log({acc, val});
      if (val.coef === 0) isCoefZero = true;
      acc[val.coef] = (acc[val.coef] || 0) + 1;
      return acc;
    }, {});

    let nbChoices = Object.keys(compteur).length;
    if (isCoefZero === true) {
      nbChoices -= 1;
    }
    // console.log("T", {qstVarname, compteur, isCoefZero, nbChoices});
    return nbChoices;
  }


  //------------------------------
  // QUANTITATIF
  //------------------------------

  getRegionArea () {
    const zoneClimat = this.simuData.location.zoneClimat;
    return coefCor.regions.data.find(reg => reg.climaticZones.includes(zoneClimat));
  }

  setIsProctectionOrCalm () {
    const isProtection = this.simuData.buildingWindows.windowsProtectionType !== 'none';
    const isCalm = this.simuData.environment.canOpenWindowsNight === true;

    let string = null;
    if (isProtection && isCalm) {
      string = "withProtectionAndCalm";
    } else if (!isProtection && !isCalm) {
      string = "withoutProtectionAndNoCalm";
    } else if (isProtection && !isCalm) {
      string = "withProtectionAndNoCalm";
    } else if (!isProtection && isCalm) {
      string = "withoutProtectionAndCalm";
    }
    this.addToDebug('setIsProctectionOrCalm', string);
    return string;
  }

  setCorrectionRaw () {
    const orientation = this.simuData.buildingWindows.windowsOrientations;
    const regionArea = this.getRegionArea();
    const inertiaLevel = this.simuData.calculated.inertiaLevel;
    const protectionState = this.setIsProctectionOrCalm();
    const orientationBaseData = coefCor.regions.orientationsMatch[orientation];

    const inertia = this.buildingType === 'MI' ? coefCor.inertia.MI[inertiaLevel] : coefCor.inertia.LC[inertiaLevel];
    const protection = regionArea[protectionState][orientationBaseData].s[inertiaLevel];
    const noiseMultiplicateur = this.buildingType === 'MI' ? -1 : 1.5;
    const noise = roundNumber(regionArea[protectionState][orientationBaseData].b[inertiaLevel] * noiseMultiplicateur);

    const total = roundNumber(inertia + protection + noise);

    this.addToDebug('setCorrectionRaw', {inertia, protection, noiseMultiplicateur, noise, total});

    this.setEfficiency();

    return {
      inertia,
      protection,
      noise,
      total
    }
  }

  setCorrectionModule () {
    const isRoomCrossover = this.simuData.buildingWindows.windowsRoomCrossover;
    const {inertia, protection, noise } = this.setCorrectionRaw();
    const protectionState = this.setIsProctectionOrCalm();
    const {aProtection, aBehaviorV, aBehaviorSP, aBehaviorElecEquip} = this.setEfficiency();

    const inertiaMod = inertia;

    const protectionMod = protectionState.substring (0, 17) === 'withoutProtection' ? protection : roundNumber(protection * aProtection * aBehaviorSP);

    let noiseMod = null;
    let coefIsRoomCrossover = 1;
    if (this.buildingType === 'MI') {
      coefIsRoomCrossover = !isRoomCrossover ? 1 : 1;// en cours de reflexion
      noiseMod = protectionState.slice(-6) === 'NoCalm' ? 0 : roundNumber(noise * aBehaviorV * coefIsRoomCrossover);
    } else if (this.buildingType === 'LC'){
      coefIsRoomCrossover = !isRoomCrossover ? 2 : 1;// en cours de reflexion
      noiseMod = protectionState.slice(-6) === 'NoCalm' ?  noise : roundNumber(noise * aBehaviorV * coefIsRoomCrossover);
    }

    const TempToCorrect = roundNumber(inertiaMod + protectionMod + noiseMod + aBehaviorElecEquip);

    this.addToDebug('setCorrectionModule', {inertiaMod, protectionMod, noiseMod, aBehaviorElecEquip,coefIsRoomCrossover, TempToCorrect});

    return {
      inertiaMod,
      protectionMod,
      noiseMod,
      TempToCorrect
    }
  }

  setEfficiency () {
    const protecType = this.simuData.buildingWindows.windowsProtectionType;
    const behaviorV = this.simuData.environment.userBehaviorVentil;
    const behaviorSP = this.simuData.buildingWindows.userBehaviorSolarProtection;
    const behaviorElecEquip = this.simuData.buildingGearsAndBehaviours.userBehaviorElecEquipments;

    const aProtection = coefCor.solarProtection.location[protecType].ep;
    const aBehaviorV = coefCor.behavior[this.buildingType][behaviorV].acv;
    const aBehaviorElecEquip = coefCor.behavior[this.buildingType][behaviorElecEquip].aci;
    let aBehaviorSP = coefCor.behavior[this.buildingType][behaviorSP].acp;

    if (this.simuData.buildingWindows.windowsProtectionControl === 'auto') {
      aBehaviorSP = coefCor.behavior[this.buildingType].good.acp;
    } else if (protecType === 'ext' && behaviorSP === 'good') {
      aBehaviorSP = 0;
    }

    this.addToDebug('setEfficiency', {aProtection, aBehaviorV, aBehaviorSP, aBehaviorElecEquip});

    return {
      aProtection,
      aBehaviorV,
      aBehaviorSP,
      aBehaviorElecEquip
    }
  }

  getQuantitativeData () {
    const Tseuils = {night: 26, day: 28};
    const nbHotDaysMaxSeuil = 3;
    const nbHotMonthsMaxSeuil = 1;

    const {TempToCorrect} = this.setCorrectionModule();
    const matrice = meteoHours;
    const zoneClimat = this.simuData.location.zoneClimat;
    let nbHotHours = 0;
    let nbHotDays = 0;
    let nbHotMonth = 0;
    let degreHourTotal = 0;

    const nMatrice = [];

    const counterHotHours = {};

    const period = {startMonth: 5, endMonth: 9};

    matrice.forEach((itemH) => {

      const TempExt = itemH[zoneClimat];
      const TempIntCorrected = roundNumber(itemH[zoneClimat] + TempToCorrect);

      const {month, day, hour/*, timeOffset, totalHours, hourDifference, hourInMilliseconds*/} = getDateFromNthHourOfYear(itemH.h, 2019);

      if (month < period.startMonth || month > period.endMonth) return;

      const Tseuil = (hour >=6 && hour <= 22) ? Tseuils.day : Tseuils.night;
      const TseuilT = roundNumber(TempIntCorrected - Tseuil);

      const isDiscomfortHour = TseuilT > 0 ? 1 : 0;
      const degreeHour = TseuilT > 0 ? TseuilT : 0;

      nbHotHours = roundNumber(nbHotHours + isDiscomfortHour);
      degreHourTotal = roundNumber(degreHourTotal + degreeHour);

      if (!counterHotHours[`m${month}`]){
          counterHotHours[`m${month}`] = {
            isHot: false,
            nbHotHours: 0,
            days: {}
        };
      }
      if (!counterHotHours[`m${month}`].days[`d${day}`]) {
        counterHotHours[`m${month}`].days[`d${day}`] = {
          nbHours: 0,
          isHot: false
        };
      } 

      counterHotHours[`m${month}`].days[`d${day}`].nbHours += isDiscomfortHour;
      if (counterHotHours[`m${month}`].days[`d${day}`].nbHours >= nbHotDaysMaxSeuil) {

        if (!counterHotHours[`m${month}`].days[`d${day}`].isHot ) {
          nbHotDays += 1;
        }

        counterHotHours[`m${month}`].days[`d${day}`].isHot = true;
        counterHotHours[`m${month}`].nbHotHours += 1;

      }

      if (counterHotHours[`m${month}`].nbHotHours >= (nbHotMonthsMaxSeuil * 24)) {
        if (!counterHotHours[`m${month}`].isHot) {
          nbHotMonth += 1;
        }
        counterHotHours[`m${month}`].isHot = true;
      }
  


      const row = {
        hourIdx: itemH.h,
        /*timeOffset, totalHours, hourDifference,hourInMilliseconds,*/
        month,
        day,
        hour,
        TempExt,
        TempIntCorrected,
        Tseuil,
        TseuilT,
        isDiscomfortHour,
        degreeHour
      }


      nMatrice.push(row);
    })

    this.addToDebug('getQuantitativeData', {Tseuils, nbHotDaysMaxSeuil, nbHotMonthsMaxSeuil});



    return {
      resume: {
        nbHotHours,
        nbHotDays,
        nbHotMonth,
        degreHourTotal
      },
      nMatrice,
      counterHotHours
    }
  }

}