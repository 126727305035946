import { useStore } from "vuex";
import {computed} from 'vue';


export function useCheckSimuDataValueProject() {

  // const store = useStore();

  const checkProjectSimuData = (params = {}) => {
    
    let A = null;
    const B = params.value;
    const ope = params.operand;

    // console.log('BBBB params.formName, params.varname', params.formName, params.varname);
    const store = useStore();
    const simuData = computed(() => store.getters.simuData);
    if (simuData.value[params.formName]) {
      A = simuData.value[params.formName][params.varname];
    }


    const comp = setComparaison(A, ope, B);

    // console.log('currentFormData', currentFormData)
    // console.log('checkProjectSimuData', params)
    // console.log('comp', comp)

    return comp;

  }

  const setComparaison = (A, ope, B) => {
    // console.log('setComparaison', A, ope, B);
    switch (ope) {
      case '===' : return A === B;
      case '==' : return A == B;
      case '<' : return A < B;
      case '<=' : return A <= B;
      case '>' : return A > B;
      case '>=' : return A >= B;
      case '!==' : return A !== B;
      case '!=' : return A != B;
      case 'in' : return B.includes(A);
      case '!in' : return !B.includes(A);
    }
  }


  // const checkProjectSimuDatas = (varList = []) => {
  //   let result = true;
  //   varList.forEach((varItem) => {
  //     if (!result) return ;
  //     // if (!varItem.andor &&  !result) return ;
  //     result = checkProjectSimuData(
  //       {
  //         andor: varItem.andor || null,
  //         formName: varItem.formName,
  //         varname: varItem.varname,
  //         value: varItem.value,
  //         operand: varItem.operand || '===',
  //         // currentFormData: varItem.currentFormData || null,
  //       }
  //     )

  //     console.log('checkProjectSimuDatas', varItem, result);
  //   })

  //   return result;
  // }

  const checkProjectSimuDatas = (varList = []) => {
    let checkParts = [];
    varList.forEach((varItem) => {
      // if (!result) return ;
      // if (!varItem.andor &&  !result) return ;
      const checkVal = checkProjectSimuData(
        {
          andor: varItem.andor || null,
          formName: varItem.formName,
          varname: varItem.varname,
          value: varItem.value,
          operand: varItem.operand || '===',
          // currentFormData: varItem.currentFormData || null,
        }
      );

      // console.log('checkProjectSimuDatas', {checkVal});
      
      if (varItem.andor) checkParts.push(varItem.andor);
      checkParts.push(checkVal);

      // console.log('checkProjectSimuDatas', {checkParts});

      // console.log('checkProjectSimuDatas', {varItem, checkParts, result});
    });

    const execString = checkParts.join(' ');
    const result = eval(execString);

    // console.log('checkProjectSimuDatas', {checkParts, execString, result});

    return result;
  }

  const isShowQst = (varList, varname) => {
    if (varList === undefined || varList.length === 0) return true;
    const isShowed = checkProjectSimuDatas(varList);

    if (!isShowed) {
      const store = useStore();
      store.state.simuData[varname] = null;
    }

    return isShowed;
  }

  const getSimuDataStoredValue = ({formName, varname}) => {
    let val = null;
    if (varname && formName) {
      const store = useStore();
      val = store.state.simuData[formName] ? store.state.simuData[formName][varname] : null;
    }
    // console.log('getSimuDataStoredValue formName, varname', formName, varname);
    // console.log('getSimuDataStoredValue val', val);
    return val;
  }

  //--- VALIDATION RULES
  const maxValueFromDynamicValue = ({formName, varname}) => (value) => {
    // console.log('maxValueFromDynamicValue formName, varname', formName, varname);
    const storedValue = getSimuDataStoredValue({formName, varname});
    const result = value <= storedValue;
    return result;
  }
  //--- /VALIDATION RULES

  //--- TRIGGERS
  const triggerMaster = (triggersList) => {
    if (!triggersList || !Array.isArray(triggersList)) return true;
    let results = [];
    triggersList.forEach((trigger) => {
      const fxName = trigger.fxName;
      const params = trigger.params;

      // console.log('triggerMaster', fxName, params);


      results.push(eval(`${fxName}('${JSON.stringify(params)}')`));
    });



    return !results.includes(false);

  }

  const isLogCollectifLastLevel = () => {
    const buildingType = getSimuDataStoredValue('buildingType');
    const nbLevels = getSimuDataStoredValue('buildingNbLevelTotal');
    const userLevel = getSimuDataStoredValue('buildingLevel');
    const test = buildingType !== 'LC' || (buildingType === 'LC' && userLevel === nbLevels);
    return test;
  }

  const isStroredValueEquals = (p) => {
    const params = JSON.parse(p);
    const lookVar = params.lookVar;
    const needValue = params.needValue;
    const storedValue = getSimuDataStoredValue(lookVar);
    const test = storedValue === needValue;
    // console.log('triggerTest1', params, lookVar, needValue, storedValue, test);
    return test;
  }
  //--- TRIGGERS

  return {
    checkProjectSimuDatas,
    isShowQst,
    getSimuDataStoredValue,
    maxValueFromDynamicValue,
    triggerMaster,
    isStroredValueEquals,
    isLogCollectifLastLevel
  }

}
