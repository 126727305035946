<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="URBAIN">
	<path opacity="0.5" fill="currentColor" d="M254.138,146.064H206.43V87.128c0-4.647-3.769-8.419-8.419-8.419h-50.516
		c-4.646,0-8.419,3.771-8.419,8.419v25.259h-22.45V84.322c0-3.103-2.514-5.613-5.613-5.613H105.4c-3.097,0-5.612,2.511-5.612,5.613
		v28.064H77.336V84.322c0-3.103-2.514-5.613-5.613-5.613h-5.613c-3.097,0-5.613,2.511-5.613,5.613v28.064H46.466
		c-4.647,0-8.418,3.77-8.418,8.417v126.287c0,6.196,5.029,11.226,11.225,11.226h202.06c6.197,0,11.225-5.029,11.225-11.226v-92.61
		C262.557,149.833,258.787,146.064,254.138,146.064z M82.95,220.43c0,2.323-1.885,4.21-4.21,4.21H64.707
		c-2.322,0-4.209-1.887-4.209-4.21v-14.031c0-2.325,1.887-4.21,4.209-4.21h14.032c2.326,0,4.21,1.885,4.21,4.21V220.43z
		 M82.95,186.755c0,2.323-1.885,4.209-4.21,4.209H64.707c-2.322,0-4.209-1.886-4.209-4.209v-14.032c0-2.324,1.887-4.212,4.209-4.212
		h14.032c2.326,0,4.21,1.888,4.21,4.212V186.755z M82.95,153.078c0,2.323-1.885,4.209-4.21,4.209H64.707
		c-2.322,0-4.209-1.886-4.209-4.209v-14.032c0-2.325,1.887-4.208,4.209-4.208h14.032c2.326,0,4.21,1.883,4.21,4.208V153.078z
		 M127.851,220.43c0,2.323-1.885,4.21-4.209,4.21h-14.033c-2.322,0-4.209-1.887-4.209-4.21v-14.031c0-2.325,1.887-4.21,4.209-4.21
		h14.033c2.324,0,4.209,1.885,4.209,4.21V220.43z M127.851,186.755c0,2.323-1.885,4.209-4.209,4.209h-14.033
		c-2.322,0-4.209-1.886-4.209-4.209v-14.032c0-2.324,1.887-4.212,4.209-4.212h14.033c2.324,0,4.209,1.888,4.209,4.212V186.755z
		 M127.851,153.078c0,2.323-1.885,4.209-4.209,4.209h-14.033c-2.322,0-4.209-1.886-4.209-4.209v-14.032
		c0-2.325,1.887-4.208,4.209-4.208h14.033c2.324,0,4.209,1.883,4.209,4.208V153.078z M183.978,153.078
		c0,2.323-1.885,4.209-4.209,4.209h-14.032c-2.321,0-4.21-1.886-4.21-4.209v-14.032c0-2.325,1.889-4.208,4.21-4.208h14.032
		c2.324,0,4.209,1.883,4.209,4.208V153.078z M183.978,119.403c0,2.32-1.885,4.207-4.209,4.207h-14.032
		c-2.321,0-4.21-1.887-4.21-4.207v-14.034c0-2.323,1.889-4.209,4.21-4.209h14.032c2.324,0,4.209,1.886,4.209,4.209V119.403z"/>
	<path fill="currentColor" d="M256.801,243.121c-5.961,0-10.797,3.405-10.797,7.598c0,4.194,4.835,7.599,10.797,7.599h287.915
		c5.96,0,10.797-3.404,10.797-7.599c0-4.192-4.837-7.598-10.797-7.598h-71.978v-53.186c0-19.114,0-28.651-8.437-34.585
		c-8.435-5.936-21.987-5.936-49.147-5.936h-28.791c-27.147,0-40.714,0-49.148,5.936c-8.436,5.934-8.436,15.471-8.436,34.585v53.186
		H256.801z M361.171,179.807c0-4.195,4.836-7.598,10.795-7.598h57.583c5.959,0,10.797,3.402,10.797,7.598
		c0,4.192-4.838,7.598-10.797,7.598h-57.583C366.007,187.404,361.171,183.999,361.171,179.807z M361.171,210.197
		c0-4.193,4.836-7.597,10.795-7.597h57.583c5.959,0,10.797,3.403,10.797,7.597c0,4.195-4.838,7.598-10.797,7.598h-57.583
		C366.007,217.795,361.171,214.393,361.171,210.197z"/>
	<path opacity="0.5" fill="currentColor" enable-background="new    " d="M343.176,73.438c13.58,0,20.354,0,24.574,2.966
		c4.217,2.969,4.217,7.738,4.217,17.294v18.154c2.264,0.781,4.216,1.75,5.96,2.979c5.821,4.092,7.636,9.911,8.197,19.39v15.195
		c-26.979,0-40.503,0.02-48.909,5.936c-8.436,5.934-8.436,15.471-8.436,34.585v53.186h-57.583v-93.706
		c0-19.104,0-28.651,8.436-34.585c1.729-1.229,3.752-2.228,5.96-2.979V93.697c0-9.556,0-14.325,4.216-17.294
		c4.22-2.966,10.996-2.966,24.576-2.966h3.599V58.24c0-4.193,4.837-7.598,10.796-7.598c5.961,0,10.797,3.404,10.797,7.598v15.197
		H343.176z M525.202,81.003c-5.115-5.201-14.17-7.597-32.249-12.354c-35.343-9.34-53-13.99-65.4-7.953
		c-12.399,6.054-12.399,19.329-12.399,45.881v42.839c27.16,0,40.713,0,49.147,5.936c8.437,5.934,8.437,15.471,8.437,34.585v53.186
		h57.582V106.576C530.32,93.005,530.32,86.208,525.202,81.003z"/>
	<path fill="currentColor" d="M258.445,203.486h-23.304v-28.788c0-2.271-1.842-4.112-4.112-4.112h-24.676
		c-2.27,0-4.112,1.842-4.112,4.112v12.338h-10.966v-13.708c0-1.515-1.228-2.742-2.742-2.742h-2.742
		c-1.512,0-2.741,1.228-2.741,2.742v13.708h-10.967v-13.708c0-1.515-1.227-2.742-2.741-2.742h-2.742
		c-1.512,0-2.741,1.228-2.741,2.742v13.708h-6.854c-2.27,0-4.113,1.842-4.113,4.113v61.685c0,3.026,2.457,5.483,5.484,5.483h98.698
		c3.027,0,5.482-2.457,5.482-5.483v-45.237C262.557,205.327,260.716,203.486,258.445,203.486z M174.825,239.813
		c0,1.134-0.92,2.056-2.056,2.056h-6.854c-1.135,0-2.056-0.922-2.056-2.056v-6.854c0-1.137,0.921-2.057,2.056-2.057h6.854
		c1.136,0,2.056,0.92,2.056,2.057V239.813z M174.825,223.362c0,1.135-0.92,2.056-2.056,2.056h-6.854
		c-1.135,0-2.056-0.921-2.056-2.056v-6.854c0-1.135,0.921-2.057,2.056-2.057h6.854c1.136,0,2.056,0.922,2.056,2.057V223.362z
		 M174.825,206.913c0,1.135-0.92,2.056-2.056,2.056h-6.854c-1.135,0-2.056-0.921-2.056-2.056v-6.855
		c0-1.136,0.921-2.055,2.056-2.055h6.854c1.136,0,2.056,0.919,2.056,2.055V206.913z M196.758,239.813
		c0,1.134-0.92,2.056-2.056,2.056h-6.855c-1.134,0-2.056-0.922-2.056-2.056v-6.854c0-1.137,0.922-2.057,2.056-2.057h6.855
		c1.135,0,2.056,0.92,2.056,2.057V239.813z M196.758,223.362c0,1.135-0.92,2.056-2.056,2.056h-6.855
		c-1.134,0-2.056-0.921-2.056-2.056v-6.854c0-1.135,0.922-2.057,2.056-2.057h6.855c1.135,0,2.056,0.922,2.056,2.057V223.362z
		 M196.758,206.913c0,1.135-0.92,2.056-2.056,2.056h-6.855c-1.134,0-2.056-0.921-2.056-2.056v-6.855
		c0-1.136,0.922-2.055,2.056-2.055h6.855c1.135,0,2.056,0.919,2.056,2.055V206.913z M224.175,223.362
		c0,1.135-0.921,2.056-2.057,2.056h-6.854c-1.134,0-2.057-0.921-2.057-2.056v-6.854c0-1.135,0.923-2.057,2.057-2.057h6.854
		c1.136,0,2.057,0.922,2.057,2.057V223.362z M224.175,206.913c0,1.135-0.921,2.056-2.057,2.056h-6.854
		c-1.134,0-2.057-0.921-2.057-2.056v-6.855c0-1.136,0.923-2.055,2.057-2.055h6.854c1.136,0,2.057,0.919,2.057,2.055V206.913z
		 M224.175,190.463c0,1.135-0.921,2.058-2.057,2.058h-6.854c-1.134,0-2.057-0.923-2.057-2.058v-6.854
		c0-1.137,0.923-2.057,2.057-2.057h6.854c1.136,0,2.057,0.92,2.057,2.057V190.463z M251.591,239.813
		c0,1.134-0.922,2.056-2.057,2.056h-6.854c-1.135,0-2.057-0.922-2.057-2.056v-6.854c0-1.137,0.922-2.057,2.057-2.057h6.854
		c1.135,0,2.057,0.92,2.057,2.057V239.813z M251.591,223.362c0,1.135-0.922,2.056-2.057,2.056h-6.854
		c-1.135,0-2.057-0.921-2.057-2.056v-6.854c0-1.135,0.922-2.057,2.057-2.057h6.854c1.135,0,2.057,0.922,2.057,2.057V223.362z"/>
</g>
</svg>
</template>