<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g fill="currentColor">
	<path fill="currentColor" d="M283.091,252.936c0.95,0.582,1.868,1.173,2.752,1.758c0.88,0.592,1.755,1.211,2.637,1.875
		c0.445-2.571,1.141-5.078,2.097-7.544c0.949-2.458,2.089-4.792,3.408-6.992c-1.904-4.847-4.638-9.121-8.201-12.825
		c-3.559-3.709-7.763-6.553-12.606-8.534c3.23,4.11,5.725,8.662,7.49,13.647c1.758,4.997,2.64,10.284,2.64,15.861
		c0,0.433-0.038,0.896-0.114,1.373C283.125,252.034,283.091,252.491,283.091,252.936z"/>
	<path fill="currentColor" d="M303.725,257.283c2.691-2.239,5.648-4.128,8.875-5.667c1.686-4.63,4.073-8.811,7.156-12.556
		c3.077-3.744,6.682-6.898,10.783-9.467c-4.686,0.146-9.104,1.173-13.261,3.078c-4.145,1.914-7.764,4.461-10.85,7.653
		c-3.075,3.194-5.506,6.887-7.267,11.066c-1.761,4.181-2.639,8.654-2.639,13.425C298.652,262.031,301.065,259.521,303.725,257.283z"
		/>
	<path fill="currentColor" d="M583.067,149.521c0-19.258-10.736-37.021-28.75-51.271c-0.024-0.011-0.048-0.011-0.071-0.034
		c-5.137-50.567-53.611-90.239-112.722-90.239c-59.13,0-107.598,39.672-112.726,90.239c-0.009,0.022-0.044,0.022-0.067,0.034
		c-18.027,14.25-28.751,32.012-28.751,51.271c0,44.049,55.864,80.242,127.385,84.501v14.586c0,14.141-25.768,27.042-25.768,27.042
		c-1.427,0.738-2.083,2.422-1.544,3.92c0,0,0.395,1.048,1.185,2.508h-85.917c1.703-6.135,4.745-11.43,9.162-15.885
		c4.482-4.508,9.833-7.568,16.079-9.189c-1.469-0.36-2.926-0.635-4.347-0.825c-1.434-0.179-2.922-0.275-4.461-0.275
		c-6.451,0-12.354,1.586-17.667,4.73c-5.327,3.168-9.534,7.346-12.614,12.564c1.252,2.783,2.206,5.71,2.867,8.753
		c0.011,0.041,0.011,0.082,0.023,0.126h-8.951c-1.496-5.995-4.515-11.319-9.131-15.938c-6.825-6.82-15.156-10.236-24.991-10.236
		c-1.539,0-3.025,0.088-4.458,0.275c-1.431,0.181-2.887,0.465-4.351,0.825c6.239,1.621,11.62,4.681,16.131,9.189
		c4.451,4.464,7.482,9.75,9.111,15.885h-78.378l3.153-3.154c1.106-1.106,0.807-2.466-0.669-3.02c0,0-25.618-9.604-25.618-27.297
		v-15.874c64.6-7.857,113.208-42.115,113.208-83.214c0-19.258-10.737-37.021-28.751-51.271c-0.023-0.011-0.047-0.011-0.069-0.034
		c-5.138-50.567-53.609-90.239-112.724-90.239c-59.125,0-107.597,39.672-112.724,90.239c-0.011,0.022-0.045,0.022-0.069,0.034
		C17.055,112.5,6.331,130.263,6.331,149.521c0,44.049,55.867,80.242,127.388,84.501v14.586c0,14.141-25.769,27.042-25.769,27.042
		c-1.427,0.738-2.084,2.422-1.542,3.92c0,0,0.395,1.048,1.184,2.508H46.178v8.987h206.301v0.073h44.042
		c0-0.024-0.003-0.046-0.003-0.073h8.805c0,0.026,0.002,0.049,0.002,0.073h35.235v-0.073H554.48v-8.987h-61.49l3.154-3.154
		c1.104-1.106,0.804-2.466-0.671-3.02c0,0-25.614-9.604-25.614-27.297v-15.874C534.459,224.878,583.067,190.621,583.067,149.521z
		 M34.645,149.521c0-14.422,10.862-27.529,28.612-37.528c-0.137-1.635-0.31-3.283-0.31-4.929c0-39.095,38.035-70.772,84.928-70.772
		c46.904,0,84.917,31.677,84.917,70.772c0,1.646-0.161,3.294-0.276,4.929c17.738,9.999,28.589,23.106,28.589,37.528
		c0,26.379-36.1,48.472-84.895,54.762v-9.469c0.094-1.773,0.808-3.456,2.027-4.745l25.941-24.442
		c1.245-1.293,1.982-2.972,2.073-4.768v-12.05c0-1.557-1.106-2.188-2.442-1.394l-25.157,14.825
		c-1.382,0.784-2.443,0.161-2.443-1.404v-50.949c0-1.555-1.22-2.431-2.718-1.936l-37.08,12.372
		c-1.555,0.599-2.604,2.062-2.683,3.719v36.806c0,1.553-1.118,2.223-2.501,1.509l-28.624-15.058
		c-1.497-0.702-3.271-0.437-4.492,0.681l-4.85,4.837c-1.036,1.015-1.047,2.671-0.045,3.709c0.057,0.059,0.116,0.102,0.173,0.16
		l38.219,33.429c1.176,1.012,2.121,3.133,2.121,4.699v10.826C77.899,202.165,34.645,178.41,34.645,149.521z M467.139,107.949
		l-37.078,12.372c-1.556,0.599-2.603,2.062-2.684,3.719v36.806c0,1.553-1.119,2.223-2.499,1.509l-28.626-15.058
		c-1.495-0.702-3.271-0.437-4.491,0.681l-4.85,4.837c-1.035,1.015-1.047,2.671-0.046,3.709c0.058,0.059,0.117,0.102,0.172,0.16
		l38.221,33.429c1.176,1.012,2.119,3.133,2.119,4.699v10.826c-55.829-3.475-99.085-27.23-99.085-56.119
		c0-14.422,10.862-27.529,28.612-37.528c-0.135-1.635-0.311-3.283-0.311-4.929c0-39.095,38.037-70.772,84.932-70.772
		c46.902,0,84.914,31.677,84.914,70.772c0,1.646-0.159,3.294-0.277,4.929c17.741,9.999,28.591,23.106,28.591,37.528
		c0,26.379-36.099,48.472-84.893,54.762v-9.469c0.093-1.773,0.808-3.456,2.024-4.745l25.942-24.442
		c1.245-1.293,1.983-2.972,2.071-4.768v-12.05c0-1.557-1.105-2.188-2.44-1.394l-25.158,14.825c-1.381,0.784-2.439,0.161-2.439-1.404
		v-50.949C469.859,108.33,468.637,107.454,467.139,107.949z"/>
</g>
</svg>
</template>