<template>
  <div>
    <p>
      Si l’usage d’un système de climatisation s’avérait indispensable pour
      faire face à des situations caniculaires, le choix de l’équipement est
      très important :
    </p>

    <div class="my-5">
      <p>
        <strong>Les systèmes mobiles ou de types monobloc ou split</strong> sont
        à installer à proximité d’une ouverture pour faire passer la gaine
        d’évacuation (généralement via une fenêtre entrebâillée). L’air chaud de
        l’extérieur entre ainsi dans la pièce, ce qui est contreproductif et
        incohérent avec le fonctionnement d’un climatiseur.
      </p>

      <div class="row">
        <div class="col-12 col-md-6">
          <figure>
            <img
              src="@/assets/img/helps/climType1.png"
              alt=""
              style="max-width: 300px"
            />
          </figure>
        </div>
        <div class="col-12 col-md-6">
          <figure>
            <img
              src="@/assets/img/helps/climType2.png"
              alt=""
              style="max-width: 300px"
            />
          </figure>
        </div>
      </div>
    </div>

    <div class="my-5">
      <p>
        <strong>Les systèmes fixes split, ou centralisé multisplit</strong>,
        sont quant à eux composés de deux unités, l’une s’installant à
        l’extérieur du bâtiment, et la deuxième à l’intérieur de pièces à
        climatiser. Il se sera donc pas nécessaire de laisser une fenêtre
        ouverte, ce qui est moins improductif.
      </p>

      <div class="row">
        <div class="col-12 col-md-4">
          <figure>
            <img
              src="@/assets/img/helps/climType3.png"
              alt=""
              style="max-width: 300px"
            />
          </figure>
        </div>
        <div class="col-12 col-md-8">
          <figure>
            <img
              src="@/assets/img/helps/climType4.png"
              alt=""
              style="max-width: 670px"
            />
          </figure>
        </div>
      </div>
    </div>

    <p style="font-weight: bold">
      A noter que, quel que soit le type de système choisi, la climatisation
      induit une forte consommation d’énergie.
    </p>
    <hr />

    <p>Source :</p>
    <ul>
      <li>
        <a
          href=" https://librairie.ademe.fr/cadic/7139/guide-adapter-logement-fortes-chaleurs.pdf#page=8 
"
          target="_blank"
          >Adapter son logement aux fortes chaleurs</a
        >
        - ADEME
      </li>
    </ul>
  </div>
</template>
