<template>

<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="bad_1_">
	<path id="bad" d="M229.953,80.972c-1.577-1.576-3.423-2.365-5.571-2.365s-4.027,0.789-5.589,2.365
		c-1.562,1.562-2.349,3.424-2.349,5.572s0.787,4.012,2.349,5.59c1.562,1.56,3.44,2.349,5.589,2.349s3.994-0.789,5.571-2.349
		c1.562-1.578,2.35-3.441,2.35-5.59S231.515,82.533,229.953,80.972z M372.423,147.902c-1.779-3.81-3.994-5.74-6.646-5.841
		c1.241-1.393,2.282-3.356,3.104-5.89c0.822-2.519,1.225-4.8,1.225-6.864c0-5.707-2.182-10.624-6.545-14.752
		c1.477-2.652,2.216-5.505,2.216-8.56c0-3.056-0.722-6.075-2.165-9.097c-1.443-3.021-3.406-5.186-5.891-6.511
		c0.42-2.485,0.621-4.801,0.621-6.949c0-13.796-7.938-20.692-23.783-20.692h-15.002c-10.824,0-24.957,3.02-42.395,9.045
		c-0.419,0.168-1.611,0.605-3.591,1.31c-1.981,0.704-3.44,1.208-4.397,1.544s-2.399,0.806-4.346,1.426
		c-1.931,0.622-3.509,1.074-4.699,1.359c-1.209,0.303-2.568,0.571-4.096,0.806c-1.527,0.253-2.82,0.37-3.895,0.37h-3.977v79.332
		h3.977c1.31,0,2.787,0.37,4.397,1.107c1.611,0.74,3.257,1.863,4.952,3.356c1.693,1.478,3.289,2.954,4.782,4.397
		c1.477,1.443,3.122,3.256,4.95,5.438c1.813,2.199,3.24,3.961,4.281,5.271c1.023,1.324,2.314,3.02,3.894,5.084
		c1.577,2.064,2.517,3.306,2.853,3.708c4.548,5.64,7.72,9.398,9.531,11.278c3.392,3.559,5.857,8.09,7.385,13.595
		c1.527,5.471,2.787,10.674,3.777,15.54c0.99,4.867,2.567,8.393,4.717,10.541c7.938,0,13.206-1.947,15.858-5.842
		c2.651-3.859,3.961-9.867,3.961-17.957c0-4.867-1.981-11.512-5.942-19.904c-3.96-8.374-5.939-14.969-5.939-19.754h43.618
		c4.128,0,7.804-1.594,11.026-4.783c3.223-3.171,4.834-6.881,4.834-11.076C375.075,155.052,374.185,151.696,372.423,147.902z
		 M381.519,180.243c-6.275,6.277-13.711,9.414-22.304,9.414h-21.817c3.978,8.19,5.957,16.111,5.957,23.799
		c0,9.667-1.442,17.354-4.346,23.06c-2.887,5.688-7.1,9.886-12.639,12.571c-5.537,2.685-11.781,4.027-18.711,4.027
		c-4.214,0-7.939-1.527-11.162-4.582c-3.541-3.474-6.109-7.938-7.67-13.393c-1.576-5.438-2.836-10.674-3.792-15.676
		c-0.94-5-2.417-8.524-4.397-10.606c-4.045-4.362-8.459-9.667-13.258-15.843c-8.358-10.842-13.998-17.235-16.985-19.217h-33.95
		c-4.382,0-8.124-1.561-11.229-4.648c-3.089-3.088-4.633-6.847-4.633-11.211V78.606c0-4.363,1.544-8.106,4.633-11.211
		c3.104-3.088,6.847-4.648,11.229-4.648h35.695c1.813,0,7.52-1.646,17.086-4.951c10.171-3.56,19.115-6.262,26.852-8.125
		c7.721-1.846,15.542-2.785,23.48-2.785h15.977c11.646,0,21.047,3.322,28.128,9.971c7.116,6.662,10.642,15.725,10.54,27.222
		c4.967,6.343,7.451,13.694,7.451,22.052c0,1.813-0.135,3.592-0.369,5.338c3.121,5.521,4.699,11.479,4.699,17.84
		c0,2.971-0.369,5.823-1.107,8.541c4.027,6.127,6.076,12.856,6.076,20.208C390.952,166.564,387.796,173.949,381.519,180.243z"/>
	<path id="symbole_2_" class="icon-symbole symbole-bad" fill="#E30613" d="M495.505,149.997l27.309,27.296l-16.386,16.397l-27.31-27.309l-27.359,27.385
		l-16.398-16.398l27.373-27.371l-27.373-27.371l16.398-16.393l27.359,27.371l27.386-27.295l16.385,16.392L495.505,149.997z"/>
</g>
</svg>
</template>