<template>
  <div>
    <apexchart
      :type="data4Chart.apexchart.type"
      :height="data4Chart.apexchart.height"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data4Chart: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [
        {
          name: this.data4Chart.series.name,
          data: this.data4Chart.series.data,
        },
      ],
      chartOptions: this.data4Chart.chartOptions
      // chartOptions: {

      //   chart: {
      //     height: 350,
      //     type: "bar",
      //     toolbar: {
      //       show: false
      //     },
      //   },
      //   plotOptions: {
      //     bar: {
      //       // borderRadius: 10,
      //       dataLabels: {
      //         position: "top", // top, center, bottom
      //       },
      //     },
      //   },
      //   dataLabels: {
      //     enabled: false,
      //     formatter: function (val) {
      //       return val;// + "%";
      //     },
      //     // offsetY: -20,
      //     style: {
      //       fontSize: "12px",
      //       colors: ["#304758"],
      //     },
      //   },

      //   xaxis: {
      //     categories: this.data4Chart.chartOptions.xaxis.categories,
      //     position: "bottom",
      //     axisBorder: {
      //       show: false,
      //     },
      //     axisTicks: {
      //       show: false,
      //     },
      //     crosshairs: {
      //       fill: {
      //         type: "gradient",
      //         gradient: {
      //           colorFrom: "#D8E3F0",
      //           colorTo: "#BED1E6",
      //           stops: [0, 100],
      //           opacityFrom: 0.4,
      //           opacityTo: 0.5,
      //         },
      //       },
      //     },
      //     tooltip: {
      //       enabled: false,
      //     },
      //   },
      //   yaxis: {
      //     max: 31,
      //     floating: false,
      //     // logBase: 5,
      //     tickAmount: 3,
      //     // forceNiceScale: true,
      //     axisBorder: {
      //       show: true,
      //     },
      //     axisTicks: {
      //       show: true,
      //     },
      //     labels: {
      //       show: true,
      //       formatter: function (val) {
      //         return Math.floor(val);// + "%";
      //       },
      //     },
      //   },
      //   title: {
      //     text: "",
      //     floating: true,
      //     offsetY: 330,
      //     align: "center",
      //     style: {
      //       color: "#444",
      //     },
      //   },
      // },
    };
  },
};
</script>
