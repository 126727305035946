<template>
	<!-- theValue : <pre>{{ theValue }}</pre>
	props : <pre>{{ props }}</pre>
	<hr> -->
<div id="zoneClimatMap">
<svg version="1.1"
	 id="zones_climat" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:cc="http://creativecommons.org/ns#" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:svg="http://www.w3.org/2000/svg" xmlns:dc="http://purl.org/dc/elements/1.1/" sodipodi:docname="RT_2005_zone_climatique.svg" inkscape:output_extension="org.inkscape.output.svg.inkscape" sodipodi:version="0.32" inkscape:version="0.48.0 r9654"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="587.5px" height="550px"
	 viewBox="0 0 587.5 550" enable-background="new 0 0 587.5 550" xml:space="preserve">
<g id="zones">
	<g id="H3" @click="setZoneClimat('H3')" :class="{canNotSelect: !isCliquable, canSelect: isCliquable ,active: theValue === 'H3'}" class="zone-climat">
		
			<path class="zone-dept dept-2A" id="path9_1_" inkscape:label="Haute-Corse" fill="#FF0000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M562.137,441.703l-2.844,1.906l0.393,1.876l1.514,1.906l-1.695,1.302l0.757,1.513l-1.149,1.332v1.694l1.906,1.725v2.633
			l-1.15,2.451l-1.301,0.575l-1.513-2.088l-2.663,0.212l-0.575-0.394h-2.27l-2.058,1.906l-0.787,3.208l-4.902,0.938l-3.782,3.208
			l-0.757,2.088l-1.876-0.182l-0.969-1.149l-0.545,3.237l-1.331,0.545l-0.394,3.026l0.575,1.331l-2.088,1.514l-0.575,1.513
			l2.118,0.394l0.363,1.028h3.752l1.029,0.666l2.754-0.515l1.18,0.696l-0.514,0.848l1.876,2.572h3.601l0.878,2.571h2.542
			l-0.151,1.544l2.058,2.572l1.029,0.515l1.361,0.847v3.269l1.029,0.847h2.058l0.515,0.848l0.332,4.63l1.211,0.848l-0.515,0.696
			l0.333,2.723l3.934-0.151l3.299-2.179l-0.121-5.598l4.539-6.416v-10.591l-1.876-3.602l-0.575-11.348l-1.331-2.088l-2.451-1.876
			l-0.394-7.021l1.149-3.208l-1.513-5.114l-0.938-4.146l-0.786-1.15L562.137,441.703z"/>
		
			<path class="zone-dept dept-2A" id="path50_1_" inkscape:label="Corse-du-Sud" fill="#FF0000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M530.545,479.529v2.088l1.906,1.332l3.207,1.876l0.212,1.513l-1.906,0.575l-3.026,0.575v1.301l1.12,1.15l0.212,3.782l4.146,1.331
			l1.514,0.363l1.331,2.088l-0.938,1.332l-1.513,0.545l-1.15,2.088l-1.119,1.331l0.545,3.39l2.845-0.182l0.756,0.575l2.663-1.332
			l0.757,0.757l-1.332,2.845l1.332,1.331l-2.27,1.694l-1.514,3.39l4.146,0.969l5.87,0.544l-2.451,2.845c0,0-1.151-0.444-1.664-0.212
			c-0.016,0.008-0.047,0.021-0.061,0.03c-0.004,0.004-0.026,0.027-0.03,0.03c-0.004,0.004-0.026,0.026-0.03,0.03
			s-0.027,0.026-0.03,0.03c-0.007,0.009-0.024,0.021-0.03,0.03c-0.003,0.005,0.003,0.025,0,0.03c-0.005,0.011-0.026,0.05-0.03,0.061
			c-0.002,0.006,0.002,0.024,0,0.03c-0.003,0.013-0.028,0.048-0.03,0.061c-0.001,0.014,0,0.046,0,0.061
			c0,0.945-1.301,3.238-1.301,3.238l1.876,2.058l3.419,2.088l6.416,1.694l1.876,0.757l1.725,0.757l-1.149,2.088l3.025-0.182
			l0.575,1.331h3.026l0.757-3.601l-1.907-0.394l2.663-2.814l-0.938-0.968l0.182-1.695l3.419-1.876l0.182-2.088l-2.27-0.182
			l-1.513,1.301v-1.876l3.025-0.182l0.938-2.27l0.757-6.627l-0.575-2.845l-0.061-2.724l-3.299,2.179l-3.934,0.151l-0.333-2.723
			l0.515-0.696l-1.211-0.848l-0.332-4.63l-0.515-0.848h-2.058l-1.029-0.847v-3.269l-1.361-0.847l-1.029-0.515l-2.058-2.572
			l0.151-1.544h-2.542l-0.878-2.571h-3.601l-1.876-2.572l0.514-0.848l-1.18-0.696l-2.754,0.515l-1.029-0.666h-3.752l-0.363-1.028
			l-2.118-0.394H530.545z"/>
		<path class="zone-dept dept-83" id="path52_1_" inkscape:label="Var" fill="#FF0000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M496.634,400.232l-2.875,0.121l-1.361,1.392l-5.145-0.182l-4.448,3.237l-3.057-2.057l-4.812,1.543l-0.848,1.725l-3.419,2.572
			l-6.174-4.115l-4.992,1.634l-0.242,1.331l0.09-0.061l3.087,2.059l-4.115,2.754l-1.694,3.419l2.058,1.694l-0.182,3.602l3.269,3.087
			l-0.878,0.695l-3.753-0.182v1.362l1.695,0.847v0.515l-1.544,1.393l0.878,1.028h1.361l1.362,1.362l0.182,1.028l-1.726,1.029
			l-1.361,1.543l-0.182,3.753l0.515,0.695l3.39,1.514l0.938,3.782l2.088,0.394l1.906-1.332l3.39-2.088l5.871,0.575l-0.182,1.513
			l-1.907,0.938l4.539,0.212l-1.119-1.15l-0.394-2.451l2.451-1.694l2.845,0.938l1.15,0.363l0.938,1.149l1.332-0.938l0.362-2.48
			l1.514-1.302h3.964l1.15-1.725l2.633,0.757l3.025-1.302v-4.933l-3.964,0.182l3.026-1.876l1.513-2.088l0.394-3.026l5.477-0.757
			l3.057-3.419l-2.118-2.18v-1.21l-1.028-1.028l1.361-1.181l-0.333-1.906l-2.239-0.848h-1.18l-2.059-2.058l-0.362-3.602l-2.21-1.028
			l-2.239-0.151l-0.847-2.058L496.634,400.232z"/>
		
			<path class="zone-dept" id="path54_1_" inkscape:label="Alpes-Maritimes" fill="#FF0000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M502.564,369.91l-0.393,0.151l-2.572,1.362l-0.182,2.391l-1.877,1.543l-1.028,2.421l-0.696,4.448l2.058,3.057l0.182,2.572
			l1.181,0.363l3.449,3.237l3.238,2.905l-1.361,0.877l-2.059-1.392l-2.572,1.392l-0.695,0.848h-2.724l0.333,1.362l-0.848,1.028
			l0.696,1.725l-2.239,1.362l0.847,2.058l2.239,0.151l2.21,1.028l0.362,3.602l2.059,2.058h1.18l2.239,0.848l0.333,1.906
			l-1.361,1.181l1.028,1.028v1.21l2.118,2.18l0.151-0.182l0.182-4.328l3.782,0.758l1.332-1.726l1.906,0.394l0.182-5.871l4.357-0.363
			l3.783-3.419h3.389l0.182-2.088l3.42-2.058l-1.906-4.357l2.844-2.451l-0.574-2.845l4.176-1.332l1.119-4.146l-0.544-2.845
			l-0.969-1.694l-0.757-2.481l-2.814,0.212l-8.896,3.207h-2.845l-4.902-3.964l-4.933-1.331h-2.844v-3.42l-3.965-2.451V369.91z"/>
		
			<path class="zone-dept" id="path66_1_" inkscape:label="Bouches-du-Rhone" fill="#FF0000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M421.042,397.055l-5.296,3.025l-1.362,10.168l-5.598-0.787l-1.604,4.268l1.332,1.876l-6.143,3.753l-1.695,3.934l5.992,0.272
			l7.958,0.575l1.514,1.513h-2.845l-1.876,3.208l8.109,1.694l6.446-1.12l-3.42-3.237l2.27-1.877l3.602,1.514l1.694,3.601
			l10.803,0.182l2.814-1.149l0.575,1.725l-3.026,2.633l4.176,0.182l-0.756,1.906l-1.15,1.331h9.26l4.539,1.514l0.454,0.605
			l0.182-3.753l1.361-1.543l1.726-1.029l-0.182-1.028l-1.362-1.362h-1.361l-0.878-1.028l1.544-1.393v-0.515l-1.695-0.847v-1.362
			l3.753,0.182l0.878-0.695l-3.269-3.087l0.182-3.602l-2.058-1.694l1.694-3.419l4.115-2.754l-3.087-2.059l-2.209,1.726l-5.144,1.181
			l-4.116-0.516l-7.354-3.056l-4.448,0.151l-3.752-1.695l-1.392-1.906l-2.905-3.238l-6.839-2.904L421.042,397.055z"/>
		<path class="zone-dept" id="path72_1_" inkscape:label="Gard" fill="#FF0000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M388.571,369.094l-0.938,1.815l-1.181,1.028l-2.239,0.363l2.724,2.905v3.935l1.21,0.332l-1.361,1.543v2.906l-2.239,3.419
			l-4.963-0.515l-2.905-2.723l-2.058,0.514l0.696,1.695l-0.848,1.21l-4.993,1.028l-5.84-3.903l-1.12,0.999v2.693l-2.148,0.514
			l0.545,2.148l2.663,0.545h2.936l0.816,3.723l-3.479,1.604v1.694l2.724,0.938v1.543l1.21,0.696l1.028-0.878h1.362l0.696,1.543
			h2.058l1.028-3.601h1.695l2.572-3.237l3.086,0.332l0.515,4.449l1.181,1.392l1.906-1.028l3.237,1.693l1.211,2.059l5.629,3.42
			l2.058,4.811v2.542l-3.602,2.058l-2.33,2.088l2.905,0.213v3.782l4.327-0.212l2.33,0.121l1.695-3.934l6.143-3.753l-1.332-1.876
			l1.604-4.268l5.598,0.787l1.362-10.168l7.475-4.266v-2.422l-5.629-5.598v-4.297l-3.208-5.326l-6.688-3.752l-0.515,2.935
			l-2.692,0.272l-0.787-2.936l-2.693,0.515l-0.515,3.753l-2.148-0.787l-4.539-2.966l-2.148,1.089v-5.355L388.571,369.094z"/>
		<path class="zone-dept" id="path74_1_" inkscape:label="Aude" fill="#FF0000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M318.91,424.713l-0.271,3.48l-3.48-1.09h-3.752l0.271-1.331l-1.876,0.272l-3.994,1.331l-1.331-2.421l-2.693,2.421l0.816,1.876
			l-2.965,1.332l-0.515,2.936l-2.421,1.089l2.148,2.391l-0.545,1.604l9.381,4.54l0.787,6.445v3.479l0.545,4.539h-4.812l-1.332,1.877
			l6.144,5.084l3.479-1.877l4.267,5.084l-0.635,0.061l0.816,0.484l7.687-3.752l-1.876-2.754l-0.151-3.238h17.944l-0.332-2.421
			l4.115-2.209l4.781,3.752l2.481,1.15l-0.151-5.417l0.212-6.233l-2.27,0.182l-1.907-2.814l1.514-2.481l3.207,3.026l2.845-2.27
			l1.907-1.877l0.241-1.997l-2.421-0.091l-0.847-2.724l-2.391-0.181l-2.239-3.269l-1.725,0.182l-2.028-1.211l-0.362-2.904
			l-1.029,0.514l0.515,2.058h-2.391l-0.182,3.42l-3.571,1.211l-1.725-3.602l-2.391,1.543l-2.058-1.543l-1.029-2.391l1.726-2.058
			l-0.817-2.209l-0.212,0.061h-5.629l-5.87-1.06H318.91z"/>
		
			<path class="zone-dept" id="path76_1_" inkscape:label="Herault" fill="#FF0000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M378.223,395.572l-2.572,3.237h-1.695l-1.028,3.601h-2.058l-0.696-1.543h-1.362l-1.028,0.878l-1.21-0.696v-1.543l-2.724-0.938
			v0.999l-3.208,0.514l-1.604,1.362l0.514,3.208h-2.935l-2.936-1.604h-1.604v1.875l0.272,5.599h-3.208h-1.604l-1.089,2.149
			l-6.96,2.391l-2.663-1.847l-1.604,2.391l-0.787,2.663l2.936,2.693l-1.09,3.479l-4.055,1.271l0.817,2.209l-1.726,2.058l1.029,2.391
			l2.058,1.543l2.391-1.543l1.725,3.602l3.571-1.211l0.182-3.42h2.391l-0.515-2.058l1.029-0.514l0.362,2.904l2.028,1.211
			l1.725-0.182l2.239,3.269l2.391,0.181l0.847,2.724l2.421,0.091l0.121-1.028l6.81-2.088l0.756-1.695l5.296-0.182l1.694-2.088
			l10.229-8.14l6.415-4.54l2.603,0.182l2.33-2.088l3.602-2.058v-2.542l-2.058-4.811l-5.629-3.42l-1.211-2.059l-3.237-1.693
			l-1.906,1.028l-1.181-1.392l-0.515-4.449L378.223,395.572z"/>
		
			<path class="zone-dept" id="path78_1_" inkscape:label="Pyrenees-Orientales" fill="#FF0000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M344.33,453.945l-4.115,2.209l0.332,2.421h-17.944l0.151,3.238l1.876,2.754l-7.687,3.752l-0.816-0.484l-6.325,0.454l-0.786,1.604
			l-3.208,0.817l-2.148,1.876l-5.871,1.362l0.333,1.997l2.845,2.663l5.659,1.513l0.182,3.39l3.025,2.662l2.27-0.393l3.238-3.965
			l3.964-0.756l6.234,2.088l5.296,4.539l1.513-1.906h1.331l1.332,0.938l1.119-0.545l0.182-2.662l5.689-1.332l1.876-2.451
			l2.845-0.938h3.964l2.481,2.632l3.026,0.212v-3.025l-1.513-2.089l-2.663-1.149l-0.424-16.522l-2.481-1.15L344.33,453.945z"/>
		<g class="zone-label" id="label_H3" transform="translate(274,-26)">
			
				<path class="zone-cartouche" id="cartouche_H3" sodipodi:ry="21.296297" sodipodi:rx="21.296297" sodipodi:cy="270.37036" sodipodi:cx="75" sodipodi:type="arc" fill="#FFFFFF" fill-opacity="0.502" stroke="#000000" d="
				M149.487,430c0,10.763-10.964,19.487-24.487,19.487s-24.487-8.725-24.487-19.487s10.964-19.487,24.487-19.487
				S149.487,419.237,149.487,430z"/>
			<text transform="matrix(1 0 0 1 111.8682 437.0654)" font-family="'Arial'" font-size="20">H3</text>
		</g>
		<g class="zone-area" id="area_H3">
			<path class="zone-area" fill="none" stroke="#FF00FF" stroke-miterlimit="10" d="M572.547,482.192l-1.876-3.602l-0.575-11.348l-1.331-2.088
				l-2.451-1.876l-0.394-7.021l1.149-3.208l-1.513-5.114l-0.938-4.146l-0.786-1.15l-1.695-0.938l-2.844,1.906l0.393,1.876
				l1.514,1.906l-1.695,1.302l0.757,1.513l-1.149,1.332v1.694l1.906,1.725v2.633l-1.15,2.451l-1.301,0.575l-1.513-2.088
				l-2.663,0.212l-0.575-0.394h-2.27l-2.058,1.906l-0.787,3.208l-4.902,0.938l-3.782,3.208l-0.757,2.088l-1.876-0.182l-0.969-1.149
				l-0.545,3.237l-1.331,0.545l-0.394,3.026l0.575,1.331l-2.088,1.514l-0.575,1.513h-1.513v2.088l1.906,1.332l3.207,1.876
				l0.212,1.513l-1.906,0.575l-3.026,0.575v1.301l1.12,1.15l0.212,3.782l4.146,1.331l1.514,0.363l1.331,2.088l-0.938,1.332
				l-1.513,0.545l-1.15,2.088l-1.119,1.331l0.545,3.39l2.845-0.182l0.756,0.575l2.663-1.332l0.757,0.757l-1.332,2.845l1.332,1.331
				l-2.27,1.694l-1.514,3.39l4.146,0.969l5.87,0.544l-2.451,2.845c0,0-1.151-0.444-1.664-0.212c-0.016,0.008-0.047,0.021-0.061,0.03
				c-0.004,0.004-0.026,0.027-0.03,0.03c-0.004,0.004-0.026,0.026-0.03,0.03s-0.027,0.026-0.03,0.03
				c-0.007,0.009-0.024,0.021-0.03,0.03c-0.003,0.005,0.003,0.025,0,0.03c-0.005,0.011-0.026,0.05-0.03,0.061
				c-0.002,0.006,0.002,0.024,0,0.03c-0.003,0.013-0.028,0.048-0.03,0.061c-0.001,0.014,0,0.046,0,0.061
				c0,0.945-1.301,3.238-1.301,3.238l1.876,2.058l3.419,2.088l6.416,1.694l1.876,0.757l1.725,0.757l-1.149,2.088l3.025-0.182
				l0.575,1.331h3.026l0.757-3.601l-1.907-0.394l2.663-2.814l-0.938-0.968l0.182-1.695l3.419-1.876l0.182-2.088l-2.27-0.182
				l-1.513,1.301v-1.876l3.025-0.182l0.938-2.27l0.757-6.627l-0.575-2.845l-0.061-2.724l-0.121-5.598l4.539-6.416V482.192z"/>
			<polygon class="zone-area" fill="none" stroke="#FF00FF" stroke-miterlimit="10" points="531.313,393.121 530.738,390.276 534.914,388.944 
				536.033,384.799 535.489,381.954 534.521,380.26 533.764,377.778 530.949,377.99 522.053,381.197 519.208,381.197 
				514.306,377.233 509.373,375.902 506.529,375.902 506.529,372.482 502.564,370.031 502.564,369.91 502.172,370.062 
				499.6,371.424 499.418,373.814 497.541,375.357 496.513,377.778 495.816,382.227 497.874,385.283 498.056,387.855 
				499.236,388.219 502.686,391.456 505.924,394.361 504.563,395.238 502.504,393.847 499.932,395.238 499.236,396.086 
				496.513,396.086 496.846,397.448 495.998,398.477 496.694,400.201 494.455,401.563 496.634,400.232 493.759,400.354 
				492.397,401.745 487.253,401.563 482.805,404.801 479.748,402.744 474.937,404.287 474.089,406.012 470.67,408.584 
				464.496,404.469 459.504,406.103 459.262,407.434 459.352,407.373 457.143,409.099 451.999,410.279 447.883,409.764 
				440.529,406.708 436.081,406.859 432.329,405.164 430.938,403.258 428.032,400.02 421.193,397.115 421.045,397.056 
				423.221,395.814 423.221,393.393 417.592,387.795 417.592,383.498 414.384,378.172 407.696,374.42 407.182,377.354 
				404.489,377.627 403.702,374.691 401.009,375.206 400.494,378.959 398.346,378.172 393.807,375.206 391.658,376.295 
				391.658,370.939 388.571,369.094 387.634,370.909 386.453,371.938 384.214,372.301 386.938,375.206 386.938,379.141 
				388.147,379.473 386.786,381.016 386.786,383.922 384.547,387.341 379.584,386.826 376.679,384.104 374.621,384.617 
				375.317,386.313 374.47,387.522 369.477,388.551 363.637,384.647 362.517,385.646 362.517,388.34 360.368,388.854 
				360.913,391.002 363.576,391.547 366.512,391.547 367.328,395.27 363.849,396.873 363.849,398.567 363.849,399.566 
				360.641,400.08 359.037,401.442 359.551,404.65 356.616,404.65 353.681,403.047 352.077,403.047 352.077,404.922 352.35,410.521 
				349.142,410.521 347.537,410.521 346.448,412.67 339.488,415.061 336.825,413.214 335.222,415.604 334.435,418.268 
				337.37,420.961 336.28,424.44 332.226,425.712 332.014,425.772 326.385,425.772 320.515,424.713 318.91,424.713 318.639,428.193 
				315.158,427.104 311.406,427.104 311.678,425.772 309.802,426.045 305.808,427.376 304.477,424.955 301.783,427.376 
				302.6,429.252 299.635,430.584 299.12,433.52 296.699,434.608 298.848,436.999 298.303,438.603 307.684,443.143 308.471,449.588 
				308.471,453.067 309.016,457.606 304.204,457.606 302.872,459.483 309.016,464.567 312.495,462.69 316.762,467.774 
				316.127,467.835 309.802,468.289 309.016,469.893 305.808,470.71 303.659,472.586 297.788,473.948 298.121,475.945 
				300.966,478.608 306.625,480.121 306.807,483.511 309.832,486.173 312.102,485.78 315.34,481.815 319.304,481.06 
				325.538,483.147 330.834,487.687 332.347,485.78 333.678,485.78 335.01,486.718 336.129,486.173 336.311,483.511 342,482.179 
				343.876,479.728 346.721,478.79 350.685,478.79 353.166,481.422 356.192,481.634 356.192,478.608 354.68,476.52 352.017,475.37 
				351.593,458.848 351.441,453.431 351.653,447.197 349.384,447.379 347.477,444.564 348.99,442.083 352.197,445.109 
				355.042,442.84 356.949,440.963 357.19,438.966 357.312,437.938 364.121,435.85 364.877,434.154 370.173,433.973 
				371.867,431.885 382.096,423.745 388.511,419.205 391.113,419.387 394.019,419.6 394.019,423.382 398.346,423.17 
				400.676,423.291 406.668,423.563 414.626,424.139 416.14,425.651 413.295,425.651 411.419,428.859 419.528,430.554 
				425.975,429.434 422.555,426.196 424.824,424.319 428.426,425.833 430.12,429.434 440.923,429.615 443.737,428.466 
				444.313,430.19 441.286,432.823 445.462,433.005 444.706,434.911 443.556,436.242 452.815,436.242 457.354,437.756 
				457.809,438.361 458.323,439.057 461.713,440.57 462.65,444.353 464.738,444.746 466.645,443.414 470.034,441.326 
				475.905,441.901 475.724,443.414 473.816,444.353 478.355,444.564 477.236,443.414 476.843,440.963 479.294,439.269 
				482.139,440.207 483.289,440.57 484.227,441.72 485.559,440.781 485.921,438.301 487.435,436.999 491.398,436.999 
				492.549,435.274 495.182,436.031 498.207,434.729 498.207,429.797 494.243,429.979 497.27,428.103 498.782,426.015 
				499.176,422.988 504.652,422.231 507.709,418.813 507.86,418.631 508.042,414.303 511.824,415.061 513.156,413.335 
				515.063,413.729 515.244,407.857 519.602,407.494 523.385,404.075 526.773,404.075 526.955,401.987 530.375,399.93 
				528.469,395.572 			"/>
		</g>
	</g>
	<g id="H2c" @click="setZoneClimat('H2c')" :class="{canNotSelect: !isCliquable, canSelect: isCliquable ,active: theValue === 'H2c'}" class="zone-climat">
		
			<path class="zone-dept" id="path11_1_" inkscape:label="Tarn-et-Garonne" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M266.559,372.634l-1.332,4.327l2.088,2.27l-0.938,3.602l-1.15,1.331l0.787,2.271l-3.601,0.756l-2.844,0.757l0.756,2.632
			l-1.422,0.424l0.848,0.787h2.753l1.362-0.666l1.21,0.666l-2.239,1.029v1.725l-2.058,1.543v2.209l3.783,0.182l1.18,1.211v2.391
			l1.392,1.361l-1.21,1.877l0.514,1.028l2.391-1.028h4.963l1.392-0.848h1.694l1.876,2.239l4.297-1.21l1.18-1.877l-1.694-0.877
			l1.18-0.484l0.363-1.725l1.876,0.182l1.029,1.18l0.847-1.361l2.754-1.029l1.241,0.574l0.968-1.604l-1.695-1.876h3.238l1.028-1.876
			l2.239-2.058h-2.239l0.696-2.905l5.811-0.696l2.239-1.361l2.724-1.029l0.877-0.878l-1.059-2.723l1.543-3.087l-2.724-0.182
			l-0.333-4.267l-4.115,1.876l-2.754-0.665l-1.694,2.057l-2.905-0.363l-0.514,3.42h-1.392l-0.848-1.876l-0.514-0.847l-1.543,0.15
			l-2.905,3.087l-3.601-0.151l-0.515-2.239v-1.694l-0.333-0.363l-1.21,1.361h-1.543l-1.695-1.876h-1.543l-1.029-2.058l1.362-1.543
			l-0.151-0.999l-2.421,0.091l0.303,0.666l-4.176,0.756L266.559,372.634z"/>
		
			<path class="zone-dept" id="path62_1_" inkscape:label="Pyrenees-Atlantiques" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M220.048,415.423l-2.724,1.544l-5.477-0.182l-0.515-0.848l-3.601,1.211l-2.753,0.847l-2.058-1.543l-2.209,0.696l-0.696-0.878
			h-2.724l-1.725,0.878l-4.449-0.182l-2.391,1.875l-4.297-0.332l-0.847,1.211l-1.029-0.363l1.361-1.362l-2.391-1.876l-2.905,2.572
			l-4.963,0.332l-5.447-2.723l-0.787,1.392l-4.357,5.296l-3.389,1.331l-2.451,0.363v2.088l2.27,2.089l3.389,0.181l0.182,2.451
			l2.663,0.212l0.756-1.725l3.601,1.513l2.27,0.575l0.545,2.27l-1.301,1.15v3.57l-2.663,1.332l-0.182,1.694l1.695,1.906l3.026,0.938
			l0.575-2.845l1.694-1.876l-0.182,2.451l1.332,1.906h3.389l1.513,2.058l4.539,0.757l4.358,2.663h7.172l0.393,3.964l4.902,3.782
			l1.907,2.27l2.088-1.119l1.876-0.394l0.938,0.938l1.725-0.938l3.298-1.815l0.303-3.904l1.513-1.149l0.787-6.233l2.814,0.574
			l1.15-0.756l-1.332-2.663l5.114-4.357l3.026-6.81l1.876-2.451l-2.27-3.389l-1.513-2.27l2.088-1.906l-3.208-5.296l-5.114-0.363
			L220.048,415.423z"/>
		
			<path class="zone-dept" id="path64_1_" inkscape:label="Aveyron" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M338.701,342.313l-5.356,4.569v4.539h-1.331l-0.545,4.267l-1.331,0.545l-1.06,2.391h-5.9l-0.515-0.787h-2.693l-1.604,2.663
			l-0.575-0.061l-0.333,1.06l-1.21,1.361h-3.42l-0.696,0.878l-1.694-0.182l-3.087,3.419l-1.028-0.332l-2.209,1.876l0.665,3.419
			l1.726,2.572l-1.332,1.393l0.303,3.903l2.724,0.182l-1.543,3.087l1.21,3.086l1.543-0.877l0.696,1.543l2.209-2.209l3.269-0.182
			l3.238,2.391l5.658,1.029l2.058,3.601l2.905,1.362l1.543,3.934l-0.182,1.543l1.877,3.45v1.876l3.419,4.448l3.269,1.694
			l2.058-0.514l1.029-1.361l1.543,0.362l3.54,2.27h0.031h1.573h3.208l-0.272-5.599v-1.875h1.604l2.936,1.604h2.935l-0.514-3.208
			l1.604-1.362l3.208-0.514v-2.693l3.479-1.604l-0.816-3.723h-2.936l-2.663-0.545l-0.545-2.148l2.148-0.514v-2.693l2.118-1.877
			l-1.604-0.786l-4.539,0.786l0.272-2.117l-3.208-1.362l-0.817-6.415v-4.539l-2.662-1.877l0.272-3.752l-5.629-6.93l-0.545-5.356
			h-1.846l-0.817-5.356l-3.752,0.545l-0.515-3.48L338.701,342.313z"/>
		
			<path class="zone-dept" id="path82_1_" inkscape:label="Hautes-Pyrenees" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M226.887,415.09l-1.876,0.999l0.333,0.03l3.208,5.296l-2.088,1.906l1.513,2.27l2.27,3.389l-1.876,2.451l-3.026,6.81l-5.114,4.357
			l1.332,2.663l-1.15,0.756l-2.814-0.574l-0.787,6.233l-1.513,1.149l-0.303,3.904l0.484-0.272l3.208,1.906l3.783,2.845l0.363,2.27
			l3.026,2.451h2.481l6.234-2.663l2.633,3.026l3.601,0.968l1.332-2.27l1.694,0.757l3.662,0.242l-0.242-10.168h1.907l1.694,0.848
			l1.21-1.181l-0.182-1.876l2.391-1.392l-0.847-3.571l-1.029-0.877l-2.058,0.695l1.029-1.725l-0.515-2.209l-3.086-2.239l0.182-1.543
			l1.725-2.905l2.209-0.848v-1.21l1.362-2.059l0.908-1.271l-3.45-1.785h-4.63l-0.696-1.393h-2.391l-0.696-1.543h-2.209l-0.696,0.696
			h-2.572l-0.151-1.544l-2.058-1.361l0.696-0.696l0.333-1.694l-0.515-0.515l-1.029-2.754l-2.239-0.332l-2.209-1.211l0.151-3.238
			H226.887z"/>
		<path class="zone-dept" id="path84_1_" inkscape:label="Landes" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M188.728,362.709l-5.992,3.086l-1.543,0.061l-3.359,17.975l-4.358,16.645l-1.332,6.445l-1.12,4.539l-2.814,4.842l5.447,2.723
			l4.963-0.332l2.905-2.572l2.391,1.876l-1.361,1.362l1.029,0.363l0.847-1.211l4.297,0.332l2.391-1.875l4.449,0.182l1.725-0.878
			h2.724l0.696,0.878l2.209-0.696l2.058,1.543l2.753-0.847l3.601-1.211l0.515,0.848l5.477,0.182l2.724-1.544l-1.332-2.33
			l1.332-3.601l1.876-2.451l-0.575-3.208l1.513-1.513l-2.239-3.783l1.876-2.27l2.088-0.393l1.876,0.756l2.663-2.27l0.938,2.845
			l0.938,1.331l2.088-0.574l-0.182-2.451l0.605-1.332L233.06,393l0.515-3.753l2.058-2.058l-1.029-1.211l-2.239-0.182l-2.572-0.998
			l-3.752,0.333l-0.696-4.116l-2.391-2.904l-1.029-0.333l0.363,3.419v1.029l-3.268,0.151l-3.419-1.181l-0.696-4.297l-2.391-2.572
			l-1.694-0.15l-0.182-1.544l-1.876-1.21l-3.087-0.848l0.847-1.029v-1.028l-1.029-0.848l-1.18-1.028l-3.45,0.514l-2.027,1.695
			l-1.392,0.182l-2.209-1.544l-3.268,1.362l-1.543-1.029l1.392-1.694l0.151-2.239L188.728,362.709z"/>
		
			<path class="zone-dept" id="path86_1_" inkscape:label="Gironde" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M190.12,305.363l-3.026,4.539l-0.938,15.888l-2.451,16.068l-1.725,12.468l-0.182,3.238l1.332-4.358l2.633-3.419l3.783,3.419
			l0.393,1.12l1.12,1.513l-4.721,0.212l-0.756-1.15l-1.876,0.757l-0.394,2.845l-2.088,2.845v4.327l-0.03,0.182l1.543-0.061
			l5.992-3.086l3.238,1.18l-0.151,2.239l-1.392,1.694l1.543,1.029l3.268-1.362l2.209,1.544l1.392-0.182l2.027-1.695l3.45-0.514
			l1.18,1.028l1.029,0.848v1.028l-0.847,1.029l3.087,0.848l1.876,1.21l0.182,1.544l1.694,0.15l2.391,2.572l0.696,4.297l3.419,1.181
			l3.268-0.151v-1.029l-0.363-3.419l1.029,0.333l1.937,2.36l3.026-0.484l1.392-1.362l-0.182-1.875l-1.21-1.029l0.363-1.906h1.876
			l1.876-1.181l-0.847-1.725l-0.515-2.572l1.362-2.391l2.905-4.448l1.725-2.059l1.543-0.514l0.333-1.695l-2.058-0.182l-0.847-1.875
			l0.666-1.877l2.421-0.514l1.695-0.516l1.997-0.271l-0.121-0.091l-0.151-3.753l1.876-1.361l-2.391-1.543l-2.391,2.904l-5.84,0.182
			l-0.515-1.392l-1.694-0.848l1.362-1.725v-1.876l-0.696-1.029v-1.029l1.725-1.028l0.514-3.056l1.029-2.754l-1.029-1.544h-1.876
			l-0.999-1.181l-0.968,2.028l-1.876-1.331l-2.663,1.331l-2.27-0.363l-4.327-4.357l-2.663-0.182l-0.756-6.052l-4.933-0.576
			l-0.182-2.844l-0.938,0.938h-5.598l0.272,1.211l1.332,5.477l0.363,5.479l-0.938,1.513l-0.938-4.54l-2.663-10.379l-9.653-8.715
			l0.212-3.965L190.12,305.363z"/>
		
			<path class="zone-dept" id="path88_1_" inkscape:label="Dordogne" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M256.542,300.764l-1.694,3.026l-2.663,0.363l-0.182,4.539l-8.896,6.052l-0.182,6.627l-3.419,3.39l-1.876,1.726l-3.783-0.395
			l-2.088,3.602l-0.544,1.18l0.999,1.181h1.876l1.029,1.544l-1.029,2.754l-0.514,3.056l-1.725,1.028v1.029l0.696,1.029v1.876
			l-1.362,1.725l1.694,0.848l0.515,1.392l5.84-0.182l2.391-2.904l2.391,1.543l-1.876,1.361l0.151,3.753l2.754,1.906l0.333,3.571
			l2.905,1.028l1.725-1.543h3.752l2.058-1.694l1.21,0.182l0.333,1.361h3.783l0.847-1.029l1.362,0.182l1.543,1.695v1.21l-1.361,0.847
			l0.514,1.211l1.876,0.151l2.391-2.209h2.058l1.362,1.544l2.965,1.24l0.182-0.484l1.725-1.695l0.182-2.844l4.146-0.363l2.663-3.783
			l-1.15-0.393l-0.182-2.059l3.208-0.393l0.212-1.877l1.513-0.968l1.694-3.026l-1.694-1.876v-2.088l1.301-1.12l-1.695-2.662
			l0.182-4.146l-4.146,0.182l-1.694-1.15l1.513-1.875l-2.088-1.695l1.513-1.906l-1.513-0.756v-2.633l3.783-3.42l-2.088-1.695
			l-1.12-2.844l-3.995-0.575l-1.332-0.938l2.845-1.331l-0.938-1.302l-4.146-0.574l-0.968-3.783l-6.052-0.574l-1.332,1.906
			l-1.301,0.363l-1.725-2.27l0.787-2.088l-0.968-1.877L256.542,300.764z"/>
		
			<path class="zone-dept" id="path90_1_" inkscape:label="Lot-et-Garonne" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M242.411,351.996l-1.997,0.271l-1.695,0.516l-2.421,0.514l-0.666,1.877l0.847,1.875l2.058,0.182l-0.333,1.695l-1.543,0.514
			l-1.725,2.059l-2.905,4.448l-1.362,2.391l0.515,2.572l0.847,1.725l-1.876,1.181h-1.876l-0.363,1.906l1.21,1.029l0.182,1.875
			l-1.392,1.362l-3.026,0.484l0.454,0.544l0.696,4.116l3.752-0.333l2.572,0.998l2.239,0.182l1.029,1.211l-2.058,2.058L233.06,393
			l0.454,1.18l0.726-1.513l2.239,1.906l3.026-3.026l1.332,1.877l3.208-0.576l3.419-0.362l1.513-2.663l5.659-0.545l2.845,2.814
			l0.968-0.938l1.876-0.576l-0.756-2.632l2.844-0.757l3.601-0.756l-0.787-2.271l1.15-1.331l0.938-3.602l-2.088-2.27l1.332-4.327
			l2.845,1.694l4.176-0.756l-1.906-4.176l-1.513-5.659l3.783-0.182l0.756-1.997l-2.965-1.24l-1.362-1.544h-2.058l-2.391,2.209
			l-1.876-0.151l-0.514-1.211l1.361-0.847v-1.21l-1.543-1.695l-1.362-0.182l-0.847,1.029h-3.783l-0.333-1.361l-1.21-0.182
			l-2.058,1.694h-3.752l-1.725,1.543l-2.905-1.028l-0.333-3.571L242.411,351.996z"/>
		<path class="zone-dept" id="path92_1_" inkscape:label="Lot" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M292.977,336.441l-4.025,1.877l-0.575,0.09l0.121,0.182l-1.301,1.12v2.088l1.694,1.876l-1.694,3.026l-1.513,0.968l-0.212,1.877
			l-3.208,0.393l0.182,2.059l1.15,0.393l-2.663,3.783l-4.146,0.363l-0.182,2.844l-1.725,1.695l-0.938,2.481l-3.783,0.182
			l1.513,5.659l1.604,3.51l2.421-0.091l0.151,0.999l-1.362,1.543l1.029,2.058h1.543l1.695,1.876h1.543l1.21-1.361l0.333,0.363v1.694
			l0.515,2.239l3.601,0.151l2.905-3.087l1.543-0.15l0.514,0.847l0.848,1.876h1.392l0.514-3.42l2.905,0.363l1.694-2.057l2.754,0.665
			l4.115-1.876l0.03,0.363l1.332-1.393l-1.726-2.572l-0.665-3.419l2.209-1.876l1.028,0.332l3.087-3.419l1.694,0.182l0.696-0.878
			h3.42l1.21-1.361l0.333-1.06l-1.543-0.212l1.059-3.207l-2.421-1.06l2.421-5.628l-3.207-2.391l-1.09-6.961l-2.936-0.786
			l-2.148,2.118l-1.059-1.877l-3.208,3.209l-2.148,0.271l-3.723-5.326L292.977,336.441z"/>
		<path class="zone-dept" id="path94_1_" inkscape:label="Ariege" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M283.051,432.369l-1.21,0.848l-0.514,1.028l2.391,1.726l0.696,1.21l-0.515,0.999l-4.115,0.362l-1.18,1.695l0.151,0.515
			l1.543,0.514l0.878,1.211l-1.029,1.694l-1.21-0.151l-1.876-1.725l-2.239-0.666l-2.209,0.151l-3.934,2.391l0.151,3.269l1.029,0.695
			l-0.666,2.542l-4.479,1.211l-1.694,2.058v3.42l0.696,1.028l-1.604,1.483l1.029,0.574l5.84,1.12h2.481l3.208,4.176l8.14-0.394
			l3.208,5.114l2.844-1.149l8.322,1.149l0.604,3.48l5.871-1.362l2.148-1.876l3.208-0.817l0.786-1.604l6.96-0.515l-4.267-5.084
			l-3.479,1.877l-6.144-5.084l1.332-1.877h4.812l-0.545-4.539v-3.479l-0.787-6.445l-9.381-4.54l0.545-1.604l-1.785-1.997
			l-1.514,0.636l-2.058,0.333l-3.782-1.726l-1.029-0.333l1.543,1.877l-0.666,1.543l-3.269-0.333l-0.151-1.725l-1.906-2.572
			L283.051,432.369z"/>
		<path class="zone-dept" id="path96_1_" inkscape:label="Gers" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M254.636,389.277l-5.659,0.545l-1.513,2.663l-3.419,0.362l-3.208,0.576l-1.332-1.877l-3.026,3.026l-2.239-1.906l-1.332,2.845
			l0.182,2.451l-2.088,0.574l-0.938-1.331l-0.938-2.845l-2.663,2.27l-1.876-0.756l-2.088,0.393l-1.876,2.27l2.239,3.783
			l-1.513,1.513l0.575,3.208l-1.876,2.451l-1.332,3.601l1.513,2.663l4.781,0.333l1.876-0.999h2.905l-0.151,3.238l2.209,1.211
			l2.239,0.332l1.029,2.754l0.515,0.515l-0.333,1.694l-0.696,0.696l2.058,1.361l0.151,1.544h2.572l0.696-0.696h2.209l0.696,1.543
			h2.391l0.696,1.393h4.63l3.45,1.785l0.303-0.424l1.876-1.028l3.419-4.449l6.354,0.515l2.724,1.876l0.877-1.21l1.362-4.085
			l1.695-3.934l3.601-1.544l1.725-0.696l-0.514-1.543l-1.725-0.182l-0.666-1.695h-1.392l-2.209-2.238l0.151-1.543l-2.905-2.906
			l-0.514-1.846l-1.876,0.817l-0.514-1.028l1.21-1.877l-1.392-1.361v-2.391l-1.18-1.211l-3.783-0.182v-2.209l2.058-1.543v-1.725
			l2.239-1.029l-1.21-0.666l-1.362,0.666h-2.753l-0.848-0.787l-0.454,0.152l-0.968,0.938L254.636,389.277z"/>
		
			<path class="zone-dept" id="path98_1_" inkscape:label="Haute-Garonne" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M290.586,399.506l-2.754,1.029l-0.847,1.361l-1.029-1.18l-1.876-0.182l-0.363,1.725l-1.18,0.484l1.694,0.877l-1.18,1.877
			l-4.297,1.21l-1.876-2.239h-1.694l-1.392,0.848h-4.963l-0.514,0.211l0.514,1.846l2.905,2.906l-0.151,1.543l2.209,2.238h1.392
			l0.666,1.695l1.725,0.182l0.514,1.543l-1.725,0.696l-3.601,1.544l-1.695,3.934l-1.362,4.085l-0.877,1.21l-2.724-1.876
			l-6.354-0.515l-3.419,4.449l-1.876,1.028l-1.21,1.694l-1.362,2.059v1.21l-2.209,0.848l-1.725,2.905l-0.182,1.543l3.086,2.239
			l0.515,2.209l-1.029,1.725l2.058-0.695l1.029,0.877l0.847,3.571l-2.391,1.392l0.182,1.876l-1.21,1.181l-1.694-0.848h-1.907
			l0.242,10.168l7.686,0.515l0.394-9.29l2.633,0.393l3.904,2.271l1.604-1.483l-0.696-1.028v-3.42l1.694-2.058l4.479-1.211
			l0.666-2.542l-1.029-0.695l-0.151-3.269l3.934-2.391l2.209-0.151l2.239,0.666l1.876,1.725l1.21,0.151l1.029-1.694l-0.878-1.211
			l-1.543-0.514l-0.151-0.515l1.18-1.695l4.115-0.362l0.515-0.999l-0.696-1.21l-2.391-1.726l0.514-1.028l1.21-0.848l1.18,0.333
			l1.906,2.572l0.151,1.725l3.269,0.333l0.666-1.543l-1.543-1.877l1.029,0.333l3.782,1.726l2.058-0.333l1.514-0.636l-0.363-0.394
			l2.421-1.089l0.515-2.936l2.965-1.332l-0.816-1.876l2.693-2.421l1.331,2.421l3.994-1.331l0.757-0.121l0.061-1.241v-2.421
			l-2.058,0.363l-2.572-0.696l-2.239-2.724l-0.848-1.21l-4.448-1.876l-1.028-1.543l1.361-0.516v-1.875l-1.361-1.544l-1.726-2.753
			l-0.151-2.391l-0.514-0.334l-2.058-2.42l-0.847-2.543L290.586,399.506z"/>
		<path class="zone-dept" id="path100_1_" inkscape:label="Tarn" fill="#FFFF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M315.582,384.436l-3.269,0.182l-2.209,2.209l-0.696-1.543l-1.543,0.877l-0.151-0.363l-0.877,0.878l-2.724,1.029l-2.239,1.361
			l-5.811,0.696l-0.696,2.905h2.239l-2.239,2.058l-1.028,1.876h-3.238l1.695,1.876l-0.968,1.604l0.303,0.121l0.847,2.543l2.058,2.42
			l0.514,0.334l0.151,2.391l1.726,2.753l1.361,1.544v1.875l-1.361,0.516l1.028,1.543l4.448,1.876l0.848,1.21l2.239,2.724
			l2.572,0.696l2.058-0.363v2.421l-0.061,1.241l1.119-0.151l-0.271,1.331h3.752l3.48,1.09l0.271-3.48h1.604l5.87,1.06h5.629
			l4.267-1.332l1.09-3.479l-2.936-2.693l0.787-2.663l1.604-2.391l2.663,1.847l6.96-2.391l1.089-2.149l-3.54-2.27l-1.543-0.362
			l-1.029,1.361l-2.058,0.514l-3.269-1.694l-3.419-4.448v-1.876l-1.877-3.45l0.182-1.543l-1.543-3.934l-2.905-1.362l-2.058-3.601
			l-5.658-1.029L315.582,384.436z"/>
		<g class="zone-label" id="label_H2c" transform="translate(130,-38)">
			
				<path class="zone-cartouche" id="cartouche_H2c" sodipodi:ry="21.296297" sodipodi:rx="21.296297" sodipodi:cy="270.37036" sodipodi:cx="75" sodipodi:type="arc" fill="#FFFFFF" fill-opacity="0.502" stroke="#000000" d="
				M149.487,430c0,10.763-10.963,19.487-24.487,19.487s-24.487-8.725-24.487-19.487s10.963-19.487,24.487-19.487
				S149.487,419.237,149.487,430z"/>
			<text id="text_H2c" transform="matrix(1 0 0 1 106.4985 437.0654)" font-family="'Arial'" font-size="20">H2c</text>
		</g>
		<polygon class="zone-area" id="area_H2c" fill="none" stroke="#FF00FF" stroke-miterlimit="10" points="366.512,391.547 363.576,391.547 
			360.913,391.002 360.368,388.854 362.517,388.34 362.517,385.646 364.635,383.77 363.031,382.983 358.492,383.77 358.765,381.652 
			355.557,380.29 354.739,373.875 354.739,369.336 352.077,367.459 352.35,363.707 346.721,356.777 346.176,351.421 344.33,351.421 
			343.513,346.064 339.761,346.609 339.246,343.129 338.701,342.313 333.345,346.882 333.345,351.421 332.014,351.421 
			331.469,355.688 330.138,356.232 329.078,358.623 323.178,358.623 322.663,357.836 319.97,357.836 318.366,360.499 
			317.791,360.438 316.248,360.227 317.307,357.02 314.886,355.96 317.307,350.332 314.1,347.941 313.01,340.98 310.074,340.194 
			307.926,342.313 306.867,340.436 303.659,343.645 301.511,343.916 297.788,338.59 292.977,336.441 288.952,338.318 
			288.382,338.407 286.804,335.928 286.985,331.781 282.839,331.963 281.145,330.813 282.658,328.938 280.57,327.242 
			282.083,325.336 280.57,324.58 280.57,321.947 284.353,318.527 282.265,316.832 281.145,313.988 277.15,313.413 275.819,312.475 
			278.664,311.144 277.726,309.842 273.58,309.268 272.611,305.484 266.559,304.91 265.228,306.816 263.926,307.18 262.202,304.91 
			262.988,302.822 262.02,300.945 256.542,300.764 254.848,303.79 252.185,304.153 252.003,308.692 243.107,314.744 
			242.925,321.371 239.506,324.761 237.629,326.486 233.847,326.092 231.759,329.693 231.214,330.873 230.246,332.901 
			228.37,331.57 225.707,332.901 223.437,332.538 219.11,328.181 216.447,327.999 215.69,321.947 210.758,321.371 210.576,318.527 
			209.638,319.465 204.04,319.465 204.312,320.676 205.644,326.152 206.007,331.631 205.069,333.144 204.13,328.604 
			201.468,318.225 191.814,309.51 192.026,305.545 190.12,305.363 187.094,309.902 186.155,325.79 183.705,341.858 181.979,354.326 
			181.798,357.564 183.129,353.206 185.762,349.787 189.545,353.206 189.938,354.326 191.058,355.839 186.337,356.051 
			185.581,354.9 183.705,355.657 183.311,358.502 181.223,361.347 181.223,365.674 181.193,365.855 177.834,383.83 173.476,400.475 
			172.145,406.92 171.025,411.459 168.211,416.301 167.424,417.692 163.066,422.988 159.677,424.319 157.226,424.683 
			157.226,426.771 159.496,428.859 162.885,429.04 163.066,431.491 165.729,431.703 166.486,429.979 170.087,431.491 
			172.356,432.066 172.901,434.336 171.6,435.486 171.6,439.057 168.937,440.389 168.755,442.083 170.45,443.989 173.476,444.928 
			174.051,442.083 175.746,440.207 175.564,442.658 176.896,444.564 180.285,444.564 181.798,446.622 186.337,447.379 
			190.695,450.042 197.867,450.042 198.26,454.006 203.162,457.788 205.069,460.058 207.157,458.938 209.033,458.545 
			209.971,459.483 211.696,458.545 214.994,456.729 215.479,456.457 218.686,458.363 222.469,461.208 222.832,463.478 
			225.858,465.929 228.339,465.929 234.573,463.266 237.206,466.292 240.807,467.26 242.139,464.99 243.833,465.747 
			247.495,465.989 255.181,466.504 255.574,457.214 258.207,457.606 262.111,459.877 263.14,460.451 268.98,461.571 
			271.461,461.571 274.669,465.747 282.809,465.354 286.017,470.468 288.861,469.318 297.184,470.468 297.788,473.948 
			303.659,472.586 305.808,470.71 309.016,469.893 309.802,468.289 316.762,467.774 312.495,462.69 309.016,464.567 
			302.872,459.483 304.204,457.606 309.016,457.606 308.471,453.067 308.471,449.588 307.684,443.143 298.303,438.603 
			298.848,436.999 297.063,435.002 296.699,434.608 299.12,433.52 299.635,430.584 302.6,429.252 301.783,427.376 304.477,424.955 
			305.808,427.376 309.802,426.045 310.559,425.924 311.678,425.772 311.406,427.104 315.158,427.104 318.639,428.193 
			318.91,424.713 320.515,424.713 326.385,425.772 332.014,425.772 336.28,424.44 337.37,420.961 334.435,418.268 335.222,415.604 
			336.825,413.214 339.488,415.061 346.448,412.67 347.537,410.521 347.568,410.521 349.142,410.521 352.35,410.521 
			352.077,404.922 352.077,403.047 353.681,403.047 356.616,404.65 359.551,404.65 359.037,401.442 360.641,400.08 363.849,399.566 
			363.849,396.873 367.328,395.27 		"/>
	</g>
	<g id="H2d" @click="setZoneClimat('H2d')" :class="{canNotSelect: !isCliquable, canSelect: isCliquable ,active: theValue === 'H2d'}" class="zone-climat">
		<path class="zone-dept" id="path58_1_" inkscape:label="Lozere" fill="#808000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M363.97,341.223l-5.296,1.906l-1.514,3.39l-3.389-2.27l-2.663,8.321l-2.724,6.265l3.965,4.872l-0.272,3.752l2.662,1.877v4.539
			l0.817,6.415l3.208,1.362l-0.272,2.117l4.539-0.786l1.604,0.786l-0.998,0.878l5.84,3.903l4.993-1.028l0.848-1.21l-0.696-1.695
			l2.058-0.514l2.905,2.723l4.963,0.515l2.239-3.419v-2.906l1.361-1.543l-1.21-0.332v-3.935l-2.724-2.905l2.239-0.363l1.181-1.028
			l0.938-1.815l-0.938-0.575l0.544-3.995l-3.207-3.479l-1.332-6.96l-4.812-6.143l-3.51,0.847l-0.757-2.633h-1.906l-0.363,2.27
			l-4.902,1.513L363.97,341.223z"/>
		
			<path class="zone-dept" id="path68_1_" inkscape:label="Vaucluse" fill="#808000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M428.093,369.396l-2.663,0.211l-2.058,3.208l0.544,3.39l3.208,0.393l-0.545,1.514l-2.481,0.182l-2.814,2.844l-0.756-0.938
			l0.545-3.783l-1.12-1.331l-5.114,0.757l-0.998,2.027l0.544,0.303l3.208,5.326v4.297l5.629,5.598v2.422l-2.179,1.24l0.151,0.061
			l6.839,2.904l2.905,3.238l1.392,1.906l3.752,1.695l4.448-0.151l7.354,3.056l4.116,0.516l5.144-1.181l2.119-1.665l0.271-1.422
			l-3.934-4.448h-4.297v-1.544l1.543-1.725v-1.876l-3.419-1.694l-0.333-2.754l1.876-0.848v-2.391l-2.058-0.363l-0.151-2.572
			l-0.03-0.182l-1.725-0.12l-2.845-2.089l-0.757-2.451l-5.296-0.393l-3.964-0.363l-0.394-2.27l1.332-2.845l-2.451,2.088
			l-3.783-0.394l-0.756-1.331l2.632-3.571L428.093,369.396z"/>
		
			<path class="zone-dept" id="path70_1_" inkscape:label="Alpes-de-Haute-Provence" fill="#808000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M502.504,352.934l-2.058,3.087l-2.905,1.726l-1.028,2.057l-2.572,0.152v1.875l-0.696,1.029l-1.028,2.572l-6.144-0.151
			l-2.904-1.543l-1.907,1.361l-3.57-0.181l-0.878,1.21h0.878l0.515,3.237l-0.878,0.363l-3.238-2.058v-1.21l-1.876-1.543h-1.028
			v1.725l-1.544,0.332l-3.268,1.877l-2.059,3.449l-0.514,1.695l1.21,0.332l0.182,2.754h-1.21l-1.877-1.725l-1.028,0.182l0.515,1.543
			l2.904,3.238l-1.876,0.695l-1.361-0.847h-3.45l-2.905,2.724l-0.03-0.03l-0.121,1.12l-1.119-1.332l-1.513-1.332l-0.969,2.845
			l-1.694,1.513l-0.727-0.061l0.03,0.182l0.151,2.572l2.058,0.363v2.391l-1.876,0.848l0.333,2.754l3.419,1.694v1.876l-1.543,1.725
			v1.544h4.297l3.934,4.448l-0.029,0.091l4.992-1.634l6.174,4.115l3.419-2.572l0.848-1.725l4.812-1.543l3.057,2.057l4.448-3.237
			l5.145,0.182l1.361-1.392l2.875-0.121l0.061-0.031l-0.696-1.725l0.848-1.028l-0.333-1.362h2.724l0.695-0.848l2.572-1.392
			l2.059,1.392l1.361-0.877l-3.238-2.905l-3.449-3.237l-1.181-0.363l-0.182-2.572l-2.058-3.057l0.696-4.448l1.028-2.421l1.877-1.543
			l0.182-2.391l2.572-1.362l0.393-0.151v-3.661l2.663-0.363l-1.513-1.331l-1.906-0.575l-0.938-2.451l0.757-1.694l3.389-3.602
			l-0.544-2.663l0.483-0.515L502.504,352.934z"/>
		
			<path class="zone-dept" id="path104_1_" inkscape:label="Ardeche" fill="#808000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M416.714,318.225l-4.388,2.603l-0.847,3.722l-3.269,0.605l-1.755,0.484l0.061,0.091l-1.12,4.539l-2.663,1.12l-1.119,1.906
			l0.757,2.451l0.544,1.331h-2.814l-0.212,3.571l-3.025,0.211l-1.514,4.721h-4.539l-4.902,3.602l-2.723,4.327l0.453,0.575
			l1.332,6.96l3.207,3.479l-0.544,3.995l4.024,2.421v5.355l2.148-1.089l4.539,2.966l2.148,0.787l0.515-3.753l2.693-0.515
			l0.787,2.936l2.692-0.272l0.515-2.935l6.144,3.449l0.998-2.027l2.603-0.394l0.212-3.995l-0.605-0.847l-0.816-0.182v-1.452
			l0.604-1.453l-1.028-1.634l0.605-3.692l2.48-2.904v-4.086l-1.028-4.75l1.846-0.395l0.424-2.057l1.846-3.511l1.029-2.663
			l-1.634-4.115l-1.029-3.299l-1.453-5.75v-7.716l-1.028-0.333L416.714,318.225z"/>
		<path class="zone-dept" id="path106_1_" inkscape:label="Drome" fill="#808000" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M426.096,318.98l-2.663,1.847l-2.27,0.817l-2.271-0.696v7.716l1.453,5.75l1.029,3.299l1.634,4.115l-1.029,2.663l-1.846,3.511
			l-0.424,2.057l-1.846,0.395l1.028,4.75v4.086l-2.48,2.904l-0.605,3.692l1.028,1.634l-0.604,1.453v1.452l0.816,0.182l0.605,0.847
			l-0.212,3.995l2.512-0.363l1.12,1.331l-0.545,3.783l0.756,0.938l2.814-2.844l2.481-0.182l0.545-1.514l-3.208-0.393l-0.544-3.39
			l2.058-3.208l2.663-0.211l2.632,2.48l-2.632,3.571l0.756,1.331l3.783,0.394l2.451-2.088l-1.332,2.845l0.394,2.27l3.964,0.363
			l5.296,0.393l0.757,2.451l2.845,2.089l2.451,0.181l1.694-1.513l0.969-2.845l1.513,1.332l1.119,1.332l0.757-7.021l-1.876-0.363
			l-1.15-2.451l-6.415-1.725l-0.787-3.207l2.3-1.514l-1.906-1.331l0.394-1.694l3.026,0.182l2.633,1.331l2.088-2.451l-2.088-1.694
			l0.182-2.27l1.149-3.602l3.964-0.394l3.208-1.301l0.333-0.757l-3.42-0.394l-1.21-1.028l-1.876-0.182l-1.846-1.453l-2.451-1.452
			h-2.693l-1.029-1.028l-0.182-15.403h-0.635l-0.817,1.422l-1.846,1.241l-4.328-1.241l-3.298-1.028l-1.423,0.424l-0.423-1.029
			l1.028-2.875l-0.424-2.904l0.848-2.059l-2.058-2.875h-2.875L426.096,318.98z"/>
		<g class="zone-label" id="label_H2d" transform="translate(287,-74)">
			
				<path class="zone-cartouche" id="cartouche_H2d" sodipodi:ry="21.296297" sodipodi:rx="21.296297" sodipodi:cy="270.37036" sodipodi:cx="75" sodipodi:type="arc" fill="#FFFFFF" fill-opacity="0.502" stroke="#000000" d="
				M149.487,430c0,10.763-10.964,19.487-24.487,19.487s-24.487-8.725-24.487-19.487s10.964-19.487,24.487-19.487
				S149.487,419.237,149.487,430z"/>
			<text transform="matrix(1 0 0 1 106.5684 437.0654)" font-family="'Arial'" font-size="20">H2d</text>
		</g>
		<polygon class="zone-area" id="area_H2d" fill="none" stroke="#FF00FF" stroke-miterlimit="10" points="502.686,391.456 499.236,388.219 
			498.056,387.855 497.874,385.283 495.816,382.227 496.513,377.778 497.541,375.357 499.418,373.814 499.6,371.424 
			502.172,370.062 502.564,369.91 502.564,366.249 505.228,365.886 503.715,364.555 501.809,363.979 500.87,361.528 
			501.627,359.834 505.016,356.232 504.472,353.569 504.955,353.055 502.504,352.934 500.446,356.021 497.541,357.746 
			496.513,359.803 493.94,359.955 493.94,361.83 493.244,362.859 492.216,365.432 486.072,365.28 483.168,363.737 481.261,365.099 
			477.69,364.918 476.813,366.128 477.69,366.128 478.205,369.365 477.327,369.729 474.089,367.671 474.089,366.461 
			472.213,364.918 471.185,364.918 471.185,366.643 469.641,366.975 466.373,368.852 464.314,372.301 463.801,373.996 
			465.011,374.328 465.192,377.082 463.982,377.082 462.105,375.357 461.077,375.539 461.592,377.082 464.496,380.32 
			462.62,381.016 461.259,380.169 457.809,380.169 454.903,382.893 454.873,382.862 454.752,383.982 455.509,376.961 
			453.633,376.598 452.482,374.146 446.067,372.422 445.28,369.215 447.58,367.701 445.674,366.37 446.067,364.676 449.094,364.857 
			451.727,366.188 453.814,363.737 451.727,362.043 451.908,359.773 453.058,356.172 457.021,355.778 460.229,354.478 
			460.563,353.721 457.143,353.327 455.933,352.299 454.057,352.117 452.211,350.664 449.76,349.212 447.066,349.212 
			446.037,348.184 445.855,332.78 445.221,332.78 444.403,334.202 442.558,335.443 438.229,334.202 434.932,333.174 
			433.509,333.598 433.086,332.568 434.114,329.693 433.69,326.789 434.538,324.73 432.48,321.855 429.605,321.855 426.096,318.98 
			423.433,320.827 421.163,321.645 418.893,320.948 417.864,320.615 416.714,318.225 412.326,320.827 411.479,324.549 
			408.211,325.154 406.456,325.639 406.517,325.729 405.396,330.269 402.733,331.389 401.614,333.295 402.371,335.746 
			402.915,337.077 400.101,337.077 399.889,340.648 396.863,340.859 395.35,345.58 390.811,345.58 385.908,349.182 383.187,353.507 
			378.827,347.941 375.317,348.788 374.561,346.155 372.654,346.155 372.291,348.425 367.389,349.938 363.97,341.223 
			358.674,343.129 357.16,346.519 353.771,344.249 351.108,352.57 348.385,358.835 352.35,363.707 352.077,367.459 354.739,369.336 
			354.739,373.875 355.557,380.29 358.765,381.652 358.492,383.77 363.031,382.983 364.635,383.77 363.637,384.647 369.477,388.551 
			374.47,387.522 375.317,386.313 374.621,384.617 376.679,384.104 379.584,386.826 384.547,387.341 386.786,383.922 
			386.786,381.016 388.147,379.473 386.938,379.141 386.938,375.206 384.214,372.301 386.453,371.938 387.634,370.909 
			388.571,369.094 387.634,368.519 391.658,370.939 391.658,376.295 393.807,375.206 398.346,378.172 400.494,378.959 
			401.009,375.206 403.702,374.691 404.489,377.627 407.182,377.354 407.696,374.42 413.84,377.869 414.384,378.172 
			417.592,383.498 417.592,387.795 423.221,393.393 423.221,395.814 421.042,397.055 421.193,397.115 428.032,400.02 
			430.938,403.258 432.329,405.164 436.081,406.859 440.529,406.708 447.883,409.764 451.999,410.279 457.143,409.099 
			459.262,407.434 459.517,406.099 464.496,404.469 470.67,408.584 474.089,406.012 474.937,404.287 479.748,402.744 
			482.805,404.801 487.253,401.563 492.397,401.745 493.759,400.354 496.634,400.232 496.694,400.201 495.998,398.477 
			496.846,397.448 496.513,396.086 499.236,396.086 499.932,395.238 502.504,393.847 504.563,395.238 505.924,394.361 		"/>
	</g>
	<g id="H1c" @click="setZoneClimat('H1c')" :class="{canNotSelect: !isCliquable, canSelect: isCliquable ,active: theValue === 'H1c'}" class="zone-climat">
		<path class="zone-dept" id="path34_1_" inkscape:label="Allier" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M345.419,240.242l-2.632,3.389l-1.514,0.181l-1.694,1.725l-1.906-2.088l-5.084,5.114v3.026l0.938,0.756l0.182,1.513l-2.633,2.058
			l-2.481-0.756l-4.721,0.968l-2.451,2.814l-0.907,1.937l0.151-0.03l2.27,3.208v2.27l1.301,1.694l1.332-1.694l1.513,2.663
			l2.088,0.757l2.088,4.902l0.091,1.422l2.875,2.24l1.543-0.696l1.211-2.905l1.18-0.333v-1.543l2.059-0.182l0.182,1.029l2.571-2.905
			h2.905l0.515,1.029l-1.362,1.876l2.028,2.24l0.362,1.361l4.781,2.754l5.811,0.848l1.725-0.182l2.572,0.514l2.209-1.361
			l1.726,0.848l0.333,2.391l2.238,0.514l2.905-0.15l0.848,2.058l2.663,1.059l0.091-0.938l4.539-0.211l-0.363-10.773l-1.331-2.632
			l0.544-2.088l3.147-0.544l0.091-0.212l3.964-3.026l0.182-7.384l-1.331-1.876h-3.026l-1.119-1.513h-3.208l-0.938-1.15v-2.845
			l-3.813-7.172l-1.876-1.332l-3.602,4.933l-1.513,0.363l-0.575-2.451l-1.694-0.756l-0.757,1.513h-2.814l-0.394-1.694l-1.906,1.12
			l-2.058,1.15l-2.27-2.481l-3.237-1.513l-0.182-2.451L345.419,240.242z"/>
		
			<path class="zone-dept" id="path80_1_" inkscape:label="Puy-de-Dome" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M342.968,270.775l-2.571,2.905l-0.182-1.029l-2.059,0.182v1.543l-1.18,0.333l-1.211,2.905l-1.543,0.696l-2.875-2.24l0.272,4.449
			l1.513,1.876l0.757,3.601l-2.27,1.695l-0.545,2.662l-2.088,1.12l-3.601,2.089l0.363,1.693l4.357,4.358l0.394,2.663l-1.726,2.814
			v2.662l1.15,1.332l0.575,3.207l-0.363,1.302l5.81,1.694l1.544,3.238h3.056l1.211-1.18l2.058,1.028l1.029,0.151l2.571,3.934
			l2.724,0.182l0.696-1.361l0.757,0.09l0.786-1.119l3.42-0.514l1.876-1.544l2.058-0.182v-1.181l1.362-0.695l0.363,0.848l0.847-0.515
			l1.362,0.878l2.391-0.182l2.058-0.848l1.361,1.18h1.544l1.21,2.24l1.876,0.847l1.029-2.391l1.361,1.21l2.936,0.152l1.029-2.059
			l1.694,1.725l2.391,0.696l0.757-0.726l-1.483-0.484l-0.574-2.27l3.601-3.39l-1.694-6.233l-4.933-3.238l-2.058-4.902l-2.27-3.025
			l0.574-4.177l1.695-1.694l-3.026-2.451l0.091-0.757l-2.663-1.059l-0.848-2.058l-2.905,0.15l-2.238-0.514l-0.333-2.391
			l-1.726-0.848l-2.209,1.361l-2.572-0.514l-1.725,0.182l-5.811-0.848l-4.781-2.754l-0.362-1.361l-2.028-2.24l1.362-1.876
			l-0.515-1.029H342.968z"/>
		<path class="zone-dept" id="path170_1_" inkscape:label="Loire" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M382.126,269.353l-3.42,0.575l-0.544,2.088l1.331,2.632l0.363,10.773l-4.539,0.211l-0.182,1.695l3.026,2.451l-1.695,1.694
			l-0.574,4.177l2.27,3.025l2.058,4.902l4.933,3.238l1.694,6.233l-3.601,3.39l0.574,2.27l5.296,1.725l4.176-3.42l2.058-0.182
			l6.053,2.633l-0.363,3.602l3.026-0.182l2.391,2.754l1.755-0.484l3.269-0.605l0.847-3.722l4.509-2.663l0.424-5.961l0.151-0.092
			l-2.209-0.332l-2.058,0.816l-1.664-1.028l2.058-2.451l-0.605-1.847l-6.385-1.028l-5.356-4.933v-1.664l1.24-1.029v-1.422
			l-1.422-0.848l1.21-1.846v-2.663l-2.451-2.27v-2.27l-1.664-1.634v-1.846l-0.816-3.087l1.24-1.241l0.212-3.691h3.903l1.029-1.241
			l-1.241-2.058v-1.846l-1.028-0.817l-0.727,3.874h-2.088l-1.513,1.513l-1.15-1.15l-6.052-0.938l-2.27,1.332h-1.514l-0.363-1.332
			l-2.844-0.575l-0.182-3.026L382.126,269.353z"/>
		
			<path class="zone-dept" id="path20_1_" inkscape:label="Cote-d&apos;Or" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M405.215,171.761l-0.394,2.451l-2.45,1.332l-6.234,0.182l0.272,1.361l0.151,1.362l-1.906,1.362v2.3l0.393,0.424h1.514l0.938,1.604
			l-0.544,2.724l-2.179,1.634v1.634l0.695,0.544l-0.151,0.394l-1.361,0.544l-0.394,2.027l-2.179,4.63l-1.482,2.179v2.179
			l0.544,0.938l-0.816,1.089l-1.906,0.938l0.121,1.907l1.785,1.089l0.666,1.483l-0.272,2.058l-0.394,1.483l0.938,1.634l2.724,0.545
			l1.211,1.906v0.817l-0.817,0.272v1.967l0.151,0.061l3.661,3.782l3.813-0.121l3.39,2.572l2.45,1.786l0.121,2.3l2.572,0.545
			l2.179,1.755l5.72-2.027l3.934-1.241l1.756-0.272l0.544-0.787l1.907,0.121l1.482,0.938l2.179-0.545l2.179-1.482l1.634,0.182
			l0.03-0.182l1.302-0.757l-0.182-0.968l-0.363-1.12l0.938-1.513l3.207-1.513v-1.513l1.15-1.513l1.119-1.513l-0.362-1.331
			l0.544-2.088l0.394-3.026h0.757l-0.182-1.12l-0.757-0.757l-0.212-3.783l-1.694-0.181l-0.363-3.026l-2.088-0.968l0.757-0.938
			l1.149-0.363l2.451-2.844l-0.212-1.513l-1.513-3.026l-2.239-0.393l-0.787,1.906l-4.146,0.938l-0.394-0.938l-3.026-3.783
			l-1.694,0.938l-2.27-0.181l-0.757-1.513l-3.025,0.182l-0.182-3.208l-1.695-1.15l2.451-2.632l-4.357-5.871l-3.389-3.601
			l-3.026-1.695H405.215z"/>
		
			<path class="zone-dept" id="path22_1_" inkscape:label="Haute-Loire" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M361.095,316.137l-1.362,0.695v1.181l-2.058,0.182l-1.876,1.544l-3.42,0.514l-0.786,1.119l0.604,0.061l0.848,2.422h4.297
			l0.848,3.057l1.211,1.21l0.332,3.601l1.029,1.362l2.058,0.181l-1.21,1.029l-1.029,0.333l0.878,0.848l-1.029,1.543l2.058,1.21
			l1.029,1.544v1.604l0.454-0.151l3.419,8.715l4.902-1.513l0.363-2.27h1.906l0.757,2.633l3.51-0.847l4.358,5.567l2.723-4.327
			l4.902-3.602h4.539l1.514-4.721l3.025-0.211l0.212-3.571h2.814l-0.544-1.331l-0.757-2.451l1.119-1.906l2.663-1.12l1.12-4.539
			l-2.451-2.845l-3.026,0.182l0.363-3.602l-6.053-2.633l-2.058,0.182l-4.176,3.42l-3.813-1.24l-0.757,0.726l-2.391-0.696
			l-1.694-1.725l-1.029,2.059l-2.936-0.152l-1.361-1.21l-1.029,2.391l-1.876-0.847l-1.21-2.24h-1.544l-1.361-1.18l-2.058,0.848
			l-2.391,0.182l-1.362-0.878l-0.847,0.515L361.095,316.137z"/>
		<path class="zone-dept" id="path24_1_" inkscape:label="Rhone" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M413.325,266.72l-2.058,0.182l-1.726,1.695l-1.119-1.513l-1.694,1.513l-2.3-1.513h-1.877l-0.756,0.575l-0.394,2.179l1.028,0.817
			v1.846l1.241,2.058l-1.029,1.241h-3.903l-0.212,3.691l-1.24,1.241l0.816,3.087v1.846l1.664,1.634v2.27l2.451,2.27v2.663
			l-1.21,1.846l1.422,0.848v1.422l-1.24,1.029v1.664l5.356,4.933l6.385,1.028l0.605,1.847l-2.058,2.451l1.664,1.028l2.058-0.816
			l2.209,0.332l3.965-1.967l-0.636-1.24l-2.663-2.663l4.115-1.452l5.356-1.241l4.115-5.325l-2.693-2.27l0.151-1.968l-3.843-1.12
			l-2.481,0.817l-0.394-3.298l-2.27-2.059l-1.452-0.211l-3.692-2.24l0.605-1.452v-6.385l1.028-1.423l0.363-4.417l-0.635,1.513
			l-1.332-0.181l-0.727-3.783L413.325,266.72z"/>
		<path class="zone-dept" id="path28_1_" inkscape:label="Jura" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M441.801,210.768l0.091,0.515h-0.757l-0.394,3.026l-0.544,2.088l0.362,1.331l-1.119,1.513l-1.15,1.513v1.513l-3.207,1.513
			l-0.938,1.513l0.363,1.12l0.182,0.968l-1.302,0.757l-0.212,2.451l1.514,0.181l1.725,2.845h2.088l1.12,1.332h1.331l-0.394,1.513
			l-4.357,0.544l0.394,1.513l1.513,0.968v1.876l-0.574,0.575l1.149,1.513l1.695,3.026l-0.757,3.208l-1.906,1.331l0.212,2.633
			l2.27,0.757l0.756,1.15l-1.906,1.513l-4.963,0.605l2.33,0.908l3.783,5.296l2.451,1.12v2.663l2.844-0.393l3.602-3.964l3.026,1.513
			v2.27h5.477l7.868-8.655l-0.333-0.182l0.363-3.964l2.845-3.39l-1.906-0.756l0.182-1.15l-2.301-0.212l-0.15-1.362l1.482-1.482
			l-0.394-1.483l-0.817-1.906l3.39-1.09l1.24-1.755l0.272-2.179l-2.723-2.603l-1.907-0.515l-4.206-1.361v-3.813l-0.272-2.845
			l-3.389,0.272l-5.296-1.786l0.816-1.876l1.211-2.875l0.424-1.876l-1.362-1.785l-2.451-1.634l-0.272-2.028l0.031-1.149
			l-1.665-0.061l-0.938,0.938h-2.451l-1.635-1.362L441.801,210.768z"/>
		
			<path class="zone-dept" id="path56_1_" inkscape:label="Hautes-Alpes" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M486.526,328.543l-1.694,0.758l-0.394,2.844l-3.39,0.394l-0.574-2.663l-1.12-1.119l-3.419,0.363l-1.332,1.149l-0.757,3.964
			l0.575,0.938l3.965,0.394l0.756,2.451l1.514,0.756v4.146l-3.602-0.182l-1.513,1.694l-4.327-0.757l-2.451,2.088l-1.726-0.756
			l-2.451,1.906l0.938,1.694l-1.513,1.513h-4.721v2.27l1.513,0.758l-0.575,1.331l-3.208,1.301l-3.964,0.394l-1.149,3.602
			l-0.182,2.27l2.088,1.694l-2.088,2.451l-2.633-1.331l-3.026-0.182l-0.394,1.694l1.906,1.331l-2.3,1.514l0.787,3.207l6.415,1.725
			l1.15,2.451l1.876,0.363l-0.636,5.901l0.03,0.03l2.905-2.724h3.45l1.361,0.847l1.876-0.695l-2.904-3.238l-0.515-1.543l1.028-0.182
			l1.877,1.725h1.21l-0.182-2.754l-1.21-0.332l0.514-1.695l2.059-3.449l3.268-1.877l1.544-0.332v-1.725h1.028l1.876,1.543v1.21
			l3.238,2.058l0.878-0.363l-0.515-3.237h-0.878l0.878-1.21l3.57,0.181l1.907-1.361l2.904,1.543l6.144,0.151l1.028-2.572
			l0.696-1.029v-1.875l2.572-0.152l1.028-2.057l2.905-1.726l2.058-3.087l2.451,0.121l1.786-1.937l2.058,0.182v-1.694l-2.633-1.332
			l-0.575-5.477l-2.088-0.757l-2.633,0.394l-4.933-2.481l-0.756-5.659l-2.814-0.938l-0.969-1.906l-1.24-2.725L486.526,328.543z"/>
		<path class="zone-dept" id="path60_1_" inkscape:label="Cantal" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M330.138,313.595l-0.575,2.088l0.938,2.27l-1.15,1.331h-1.876l-1.906-2.088l-1.695-0.938l-0.182,5.295l-3.419,2.088l-2.451,3.39
			l0.575,3.42l-0.757,1.513l-0.969,3.026h-1.513l-1.513,1.876l1.149,1.15l0.757,1.876l-2.451,1.725l0.999,6.325l3.207,2.391
			l-2.421,5.628l2.421,1.06l-1.059,3.207l2.118,0.272l1.604-2.663h2.693l0.515,0.787h5.9l1.06-2.391l1.331-0.545l0.545-4.267h1.331
			v-4.539l5.356-4.569l0.545,0.816l0.515,3.48l3.752-0.545l0.817,5.356h1.846l0.545,5.356l1.664,2.058l2.724-6.265l2.663-8.321
			l3.389,2.27l1.514-3.39l4.842-1.755v-1.604l-1.029-1.544l-2.058-1.21l1.029-1.543l-0.878-0.848l1.029-0.333l1.21-1.029
			l-2.058-0.181l-1.029-1.362l-0.332-3.601l-1.211-1.21l-0.848-3.057h-4.297l-0.848-2.422l-1.361-0.15l-0.696,1.361l-2.724-0.182
			l-2.571-3.934l-1.029-0.151l-2.058-1.028l-1.211,1.18h-3.056l-1.544-3.238L330.138,313.595z"/>
		<path class="zone-dept" id="path102_1_" inkscape:label="Isere" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M438.653,289.355l-1.24,1.453l-1.241,3.691l-2.481,1.24l-2.663-2.48h-1.846l-0.212,2.693l2.693,2.27l-4.115,5.325l-5.356,1.241
			l-4.115,1.452l2.663,2.663l0.636,1.24l-4.116,2.059l-0.424,5.961l-0.121,0.061l1.15,2.391l3.299,1.029l2.27-0.817l2.663-1.847
			l3.51,2.875h2.875l2.058,2.875l-0.848,2.059l0.424,2.904l-1.028,2.875l0.423,1.029l1.423-0.424l3.298,1.028l4.328,1.241
			l1.846-1.241l0.817-1.422h0.635l0.182,15.403l1.029,1.028h2.693l2.451,1.452l1.846,1.453l1.876,0.182l1.21,1.028l3.42,0.394
			l0.242-0.574l-1.513-0.758v-2.27h4.721l1.513-1.513l-0.938-1.694l2.451-1.906l1.726,0.756l2.451-2.088l4.327,0.757l1.513-1.694
			l3.602,0.182v-4.146l-1.514-0.756l-0.756-2.451l-3.965-0.394l-0.575-0.938l0.757-3.964l1.332-1.149l-1.09-1.483l-2.058-1.241
			l-1.241,1.241l0.424-1.664v-1.634l-1.664-1.665l0.848-3.903L473,317.74l-0.212-2.662l-3.903-3.904h-1.453l-1.028,1.422
			l-2.451-3.268l-1.453,0.182l-1.24,2.693l0.847,1.634l-0.635,0.636l-1.635-1.241l-4.751-1.028l-2.239-4.116v-1.664l-2.27-2.451
			l-0.242-1.06l-7.564-9.229l-0.848-2.663l-1.635-1.452L438.653,289.355z"/>
		
			<path class="zone-dept" id="path108_1_" inkscape:label="Correze" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M310.68,298.01l-0.817,2.059l-3.086,0.605l-1.211,2.057h-1.452l-2.058-0.604l-1.453,2.451l-2.239,0.212l-1.452,2.662h-1.847
			l-1.452,1.453l-3.692-0.424l-1.241,2.058l-1.422-0.182l-2.481,3.087l-2.27-0.848l-1.06,2.148l0.817,2.088l2.088,1.695l-3.783,3.42
			v2.633l1.513,0.756l-1.513,1.906l2.088,1.695l-1.513,1.875l1.694,1.15l4.146-0.182l-0.182,4.146l1.574,2.48l0.575-0.09
			l4.025-1.877l4.811,2.148l3.723,5.326l2.148-0.271l3.208-3.209l1.059,1.877l2.148-2.118l2.936,0.786l0.091,0.636l2.451-1.725
			l-0.757-1.876l-1.149-1.15l1.513-1.876h1.513l0.969-3.026l0.757-1.513l-0.575-3.42l2.451-3.39l3.419-2.088l0.182-5.295
			l1.695,0.938l1.906,2.088h1.876l1.15-1.331l-0.938-2.27l0.938-3.39l-0.575-3.207l-1.15-1.332v-2.662l1.726-2.814l-0.394-2.663
			l-0.424-0.424l-1.906,1.483h-3.723l-1.21,1.846h-2.27l-1.846-1.846l-0.848-1.241h-4.721l-1.029-1.423H310.68z"/>
		
			<path class="zone-dept" id="path110_1_" inkscape:label="Creuse" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M301.359,259.518l-1.12,3.026l-3.419-0.182l-0.757-0.394l-2.27,0.212l-1.694-1.15l-3.269,3.752l0.061,2.784l-2.27,4.509
			l0.424,2.27l2.451,0.605l1.664,4.115l1.634,1.665l-0.605,7.808l3.48-1.029l1.453,1.846l-2.27,1.846v1.877l1.846,0.182l3.298-0.182
			l1.029-1.453h0.817l-0.394,2.481l2.663,1.21l2.481,1.665v1.028h-1.453l0.424,2.451l0.969,0.605l0.242-0.393l3.086-0.605
			l0.817-2.059h1.452l1.029,1.423h4.721l0.848,1.241l1.846,1.846h2.27l1.21-1.846h3.723l1.906-1.483l-3.934-3.935l-0.363-1.693
			l3.601-2.089l2.088-1.12l0.545-2.662l2.27-1.695l-0.757-3.601l-1.513-1.876l-0.363-5.871l-2.088-4.902l-2.088-0.757l-1.513-2.663
			l-1.332,1.694l-1.301-1.694v-2.27l-2.27-3.208l-6.234,0.757l-3.601-0.938L301.359,259.518z"/>
		
			<path class="zone-dept" id="path112_1_" inkscape:label="Haute-Vienne" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M285.291,262.363l-1.694,1.695l-4.751-0.363l-0.545-0.121l-3.601,0.666l-2.088,1.725v2.27l-4.146,0.182l-2.451,2.844l-1.513,1.12
			l1.301,1.513l-0.182,5.114l-0.938,1.695l1.513,1.694l2.633,0.212l0.575,2.633l0.181,1.694l-3.389,0.757l-1.694,0.574l0.363,4.721
			l-2.27,1.514l-1.906,0.574l-0.938,2.664l-1.513,0.182l-0.636,2.874l4.418,0.151l0.968,1.877l-0.787,2.088l1.725,2.27l1.301-0.363
			l1.332-1.906l6.052,0.574l0.968,3.783l4.146,0.574l0.938,1.302l-2.845,1.331l1.332,0.938l3.995,0.575l0.302,0.756l1.06-2.148
			l2.27,0.848l2.481-3.087l1.422,0.182l1.241-2.058l3.692,0.424l1.452-1.453h1.847l1.452-2.662l2.239-0.212l1.453-2.451l2.058,0.604
			h1.452l0.969-1.664l-0.969-0.605l-0.424-2.451h1.453v-1.028l-2.481-1.665l-2.663-1.21l0.394-2.481h-0.817l-1.029,1.453
			l-3.298,0.182l-1.846-0.182v-1.877l2.27-1.846l-1.453-1.846l-3.48,1.029l0.605-7.808l-1.634-1.665l-1.664-4.115l-2.451-0.605
			l-0.424-2.27l2.27-4.509l-0.061-2.784l-0.514,0.605L285.291,262.363z"/>
		
			<path class="zone-dept" id="path138_1_" inkscape:label="Saone-et-Loire" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M393.686,220.754v0.182h-2.845l-2.3,1.361l-1.361,1.907l1.089,1.634l0.938,0.544l-0.666,1.634l-0.968,0.544l-1.06,1.483
			l1.211,0.817l-0.151,1.907l0.272,2.027l1.785,1.089v0.817l-1.906,1.09v1.21l1.361,1.211l-0.151,0.696l-2.966-0.151l-0.272,0.968
			l-1.361,0.272l-1.361,1.755h-1.756l-1.361,1.362l-1.785,0.272l-0.938-2.3l-1.21-0.151l-2.966,1.846l3.51,6.597v2.845l0.938,1.15
			h3.208l1.119,1.513h3.026l1.331,1.876l-0.182,7.384l-3.964,3.026l-0.091,0.212l0.272-0.03l0.575,0.182l0.182,3.026l2.844,0.575
			l0.363,1.332h1.514l2.27-1.332l6.052,0.938l1.15,1.15l1.513-1.513h2.088l1.12-6.052l0.756-0.575h1.877l2.3,1.513l1.694-1.513
			l1.119,1.513l1.726-1.695l2.058-0.182l0.968,3.026l0.727,3.783l1.332,0.181l1.149-2.663l3.571-13.981l1.331-2.451l2.088-0.211
			l2.088,1.725l1.514-0.394l1.876-1.331l1.906,0.393l1.12,2.451l1.089,0.424l4.963-0.605l1.906-1.513l-0.756-1.15l-2.27-0.757
			l-0.212-2.633l1.906-1.331l0.757-3.208l-1.695-3.026l-1.149-1.513l0.574-0.575v-1.876l-1.513-0.968l-0.394-1.513l4.357-0.544
			l0.394-1.513h-1.331l-1.12-1.332h-2.088l-1.725-2.845l-1.514-0.181l0.182-2.27l-1.634-0.182l-2.179,1.482l-2.179,0.545
			l-1.482-0.938l-1.907-0.121l-0.544,0.787l-1.756,0.272l-3.934,1.241l-5.72,2.027l-2.179-1.755l-2.572-0.545l-0.121-2.3
			l-2.45-1.786l-3.39-2.572l-3.813,0.121l-3.661-3.782L393.686,220.754z"/>
		<path class="zone-dept" id="path162_1_" inkscape:label="Doubs" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M486.587,193.519l-0.182,0.211h-2.179l-1.119,1.543l-1.423,0.817v1.907l-3.54,0.423l-1.907-1.361l-2.571,0.393l-2.451,1.907
			l-1.756,2.996l-1.755,0.545l-0.817,2.027l-2.179,0.394l-2.995,2.451l-3.935-0.121l-1.21,0.938h-1.09l-3.813,3.117l-1.725-0.06
			l-0.031,1.149l0.272,2.028l2.451,1.634l1.362,1.785l-0.424,1.876l-1.211,2.875l-0.816,1.876l5.296,1.786l3.389-0.272l0.272,2.845
			v3.813l4.206,1.361l1.907,0.515l2.723,2.603l-0.272,2.179l-1.24,1.755l-3.39,1.09l0.817,1.906l0.394,1.483l-1.482,1.482
			l0.15,1.362l2.301,0.212l0.029-0.182l11.711-10.984l-0.362-9.079l4.146-2.058l2.845-1.332l2.633-2.451l0.212-3.601l2.633-1.332
			l6.052-6.99l-0.938-2.27l2.088-0.938l2.451-3.026l-1.331-1.332l-4.539,0.938l-0.182-0.757l4.176-4.812L486.587,193.519z"/>
		<path class="zone-dept" id="path166_1_" inkscape:label="Ain" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M424.491,254.404l-2.088,0.211l-1.331,2.451l-3.571,13.981l-0.515,1.15l-0.363,4.417l-1.028,1.423v6.385l-0.605,1.452l3.692,2.24
			l1.452,0.211l2.27,2.059l0.394,3.298l2.481-0.817l3.843,1.12l0.061-0.726h1.846l2.663,2.48l2.481-1.24l1.241-3.691l1.24-1.453
			l1.634,0.212l1.635,1.452l0.848,2.663l7.595,9.261l2.27-1.635l0.394-3.51l2.481-0.424v-6.174l1.241-1.028l0.393-5.538l0.484,0.394
			l-0.061-2.027l-1.028-1.876l0.423-5.326l1.847,1.029l1.028-1.877l1.847-0.605l1.906-1.513h-2.058v-3.601l2.27-1.332l3.389-0.363
			l0.212-1.906l-1.149-0.756l2.844-3.601l-0.393-1.12l-3.238-1.725l-7.868,8.655h-5.477v-2.27l-3.026-1.513l-3.602,3.964
			l-2.844,0.393v-2.663l-2.451-1.12l-3.783-5.296l-3.419-1.332l-1.12-2.451l-1.906-0.393l-1.876,1.331l-1.514,0.394L424.491,254.404
			z"/>
		
			<path class="zone-dept" id="path168_1_" inkscape:label="Haute-Savoie" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M485.347,257.884l-4.328,0.756l-4.176,3.389l-1.119-1.695l-2.089,0.182l-1.906,4.176l0.212,1.695l2.058,1.694l-3.782,2.481
			l-2.451,2.27h-4.176l-1.906,1.513l-1.847,0.605l-1.028,1.877l-1.847-1.029l-0.423,5.326l1.028,1.876l0.061,2.027l1.574,1.241
			v5.355l3.722,0.605l1.422,2.693l3.299,0.393l0.605-1.21h1.664l2.875,3.087l1.028,1.029l3.299-0.637l0.817-1.633l0.816-3.299
			l1.847-1.453l1.452-4.297l1.846-1.24l1.453,0.393l0.393,1.241l-1.028,1.241l1.846,2.27h3.087l1.876,3.086l-0.424,1.029
			l1.846-1.241l1.453-1.634l1.271,0.151l0.091-3.299l6.445-2.663l0.757-1.876l-0.394-4.176l-4.146-4.327l-1.331,0.756v-1.725v-2.814
			l-3.602-1.725l-0.182-1.513l2.088-2.27v-2.632l-3.419-3.602l-0.182-2.451H485.347z"/>
		
			<path class="zone-dept" id="path172_1_" inkscape:label="Savoie" fill="#00FFFF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M457.143,284.635l-0.393,5.538l-1.241,1.028v6.174l-2.481,0.424l-0.394,3.51l-2.27,1.635l-0.03-0.031l0.242,1.06l2.27,2.451v1.664
			l2.239,4.116l4.751,1.028l1.635,1.241l0.635-0.636l-0.847-1.634l1.24-2.693l1.453-0.182l2.451,3.268l1.028-1.422h1.453
			l3.903,3.904L473,317.74l-1.846,1.029l-0.848,3.903l1.664,1.665v1.634l-0.424,1.664l1.241-1.241l2.058,1.241l1.09,1.483
			l3.419-0.363l1.12,1.119l0.574,2.663l3.39-0.394l0.394-2.844l1.694-0.758l3.632,0.182l-0.061-0.121l5.658-2.27l2.088,1.332h2.088
			l0.182-2.27l2.451-1.332l0.938-1.119l4.933-1.906l0.575-3.208l-0.969-1.513l2.663-4.539l-2.451-0.938l-0.757-2.663l-5.114-3.025
			c0,0,0.306-5.799-0.182-6.84c-0.015-0.025-0.048-0.074-0.06-0.09c-0.005-0.006-0.025-0.026-0.03-0.031c-0.004,0-0.028,0-0.03,0
			c-0.001-0.006-0.001-0.028,0-0.029c-0.004,0-0.028,0-0.03,0c-0.003-0.001-0.027,0-0.03,0c-0.004,0-0.028,0-0.03,0
			c-0.757,0.188-3.571,0.393-3.571,0.393l-2.845-3.238l0.091-3.116l-1.271-0.151l-1.453,1.634l-1.846,1.241l0.424-1.029
			l-1.876-3.086h-3.087l-1.846-2.27l1.028-1.241l-0.393-1.241l-1.453-0.393l-1.846,1.24l-1.452,4.297l-1.847,1.453l-0.816,3.299
			l-0.817,1.633l-3.299,0.637l-1.028-1.029l-2.875-3.087h-1.664l-0.605,1.21l-3.299-0.393l-1.422-2.693l-3.722-0.605v-5.355
			L457.143,284.635z"/>
		<g class="zone-label" id="label_H1c" transform="translate(274.32341,-146.4103)">
			
				<path class="zone-cartouche" id="cartouche_H1c" sodipodi:ry="21.296297" sodipodi:rx="21.296297" sodipodi:cy="270.37036" sodipodi:cx="75" sodipodi:type="arc" fill="#FFFFFF" fill-opacity="0.502" stroke="#000000" d="
				M149.487,430c0,10.762-10.963,19.487-24.487,19.487c-13.523,0-24.487-8.726-24.487-19.487c0-10.763,10.964-19.487,24.487-19.487
				C138.524,410.513,149.487,419.237,149.487,430z"/>
			<text transform="matrix(1 0 0 1 106.4989 437.0656)" font-family="'Arial'" font-size="20">H1c</text>
		</g>
		<path class="zone-area" id="area_H1c" fill="none" stroke="#FF00FF" stroke-miterlimit="10" d="M497.844,327.666h2.088l0.182-2.27l2.451-1.332
			l0.938-1.119l4.933-1.906l0.575-3.208l-0.969-1.513l2.663-4.539l-2.451-0.938l-0.757-2.663l-5.114-3.025
			c0,0,0.306-5.799-0.182-6.84c-0.015-0.025-0.048-0.074-0.06-0.09c-0.005-0.006-0.025-0.026-0.03-0.031c-0.004,0-0.028,0-0.03,0
			c-0.001-0.006-0.001-0.028,0-0.029c-0.004,0-0.028,0-0.03,0c-0.003-0.001-0.027,0-0.03,0c-0.004,0-0.028,0-0.03,0
			c-0.757,0.188-3.571,0.393-3.571,0.393l-2.845-3.238l0.091-3.116l0.091-3.299l6.445-2.663l0.757-1.876l-0.394-4.176l-4.146-4.327
			l-1.331,0.756v-1.725v-2.814l-3.602-1.725l-0.182-1.513l2.088-2.27v-2.632l-3.419-3.602l-0.182-2.451h-6.445l-4.328,0.756
			l-4.176,3.389l-1.119-1.695l-2.089,0.182l-1.906,4.176l0.212,1.695l2.058,1.694l-3.782,2.481l-2.451,2.27h-4.176h-2.058v-3.601
			l2.27-1.332l3.389-0.363l0.212-1.906l-1.149-0.756l2.844-3.601l-0.393-1.12l-3.238-1.725l-0.333-0.182l0.363-3.964l2.845-3.39
			l-1.906-0.756l0.182-1.15l0.029-0.182l11.711-10.984l-0.362-9.079l4.146-2.058l2.845-1.332l2.633-2.451l0.212-3.601l2.633-1.332
			l6.052-6.99l-0.938-2.27l2.088-0.938l2.451-3.026l-1.331-1.332l-4.539,0.938l-0.182-0.757l4.176-4.812l-11.65-5.265l-0.182,0.211
			h-2.179l-1.119,1.543l-1.423,0.817v1.907l-3.54,0.423l-1.907-1.361l-2.571,0.393l-2.451,1.907l-1.756,2.996l-1.755,0.545
			l-0.817,2.027l-2.179,0.394l-2.995,2.451l-3.935-0.121l-1.21,0.938h-1.09l-3.813,3.117l-1.725-0.06l-1.665-0.061l-0.938,0.938
			h-2.451l-1.635-1.362l-1.444-0.392l-0.099-0.607l-0.757-0.757l-0.212-3.783l-1.694-0.181l-0.363-3.026l-2.088-0.968l0.757-0.938
			l1.149-0.363l2.451-2.844l-0.212-1.513l-1.513-3.026l-2.239-0.393l-0.787,1.906l-4.146,0.938l-0.394-0.938l-3.026-3.783
			l-1.694,0.938l-2.27-0.181l-0.757-1.513l-3.025,0.182l-0.182-3.208l-1.695-1.15l2.451-2.632l-4.357-5.871l-3.389-3.601
			l-3.026-1.695h-5.478l-0.394,2.451l-2.45,1.332l-6.234,0.182l0.272,1.361l0.151,1.362l-1.906,1.362v2.3l0.393,0.424h1.514
			l0.938,1.604l-0.544,2.724l-2.179,1.634v1.634l0.695,0.544l-0.151,0.394l-1.361,0.544l-0.394,2.027l-2.179,4.63l-1.482,2.179
			v2.179l0.544,0.938l-0.816,1.089l-1.906,0.938l0.121,1.907l1.785,1.089l0.666,1.483l-0.272,2.058l-0.394,1.483l0.938,1.634
			l2.724,0.545l1.211,1.906v0.817l-0.817,0.272v1.967v0.182h-2.845l-2.3,1.361l-1.361,1.907l1.089,1.634l0.938,0.544l-0.666,1.634
			l-0.968,0.544l-1.06,1.483l1.211,0.817l-0.151,1.907l0.272,2.027l1.785,1.089v0.817l-1.906,1.09v1.21l1.361,1.211l-0.151,0.696
			l-2.966-0.151l-0.272,0.968l-1.361,0.272l-1.361,1.755h-1.756l-1.361,1.362l-1.785,0.272l-0.938-2.3l-1.21-0.151l-2.964,1.845
			l-0.305-0.574l-1.876-1.332l-3.602,4.933l-1.513,0.363l-0.575-2.451l-1.694-0.756l-0.757,1.513h-2.814l-0.394-1.694l-1.906,1.12
			l-2.058,1.15l-2.27-2.481l-3.237-1.513l-0.182-2.451l-4.358-0.363l-2.632,3.389l-1.514,0.181l-1.694,1.725l-1.906-2.088
			l-5.084,5.114v3.026l0.938,0.756l0.182,1.513l-2.633,2.058l-2.481-0.756l-4.721,0.968l-2.451,2.814l-0.901,1.924l-6.089,0.739
			l-3.601-0.938l-9.472-1.15l-1.12,3.026l-3.419-0.182l-0.757-0.394l-2.27,0.212l-1.694-1.15l-3.269,3.752l-0.514,0.605
			l-3.026-3.026l-1.694,1.695l-4.751-0.363l-0.545-0.121l-3.601,0.666l-2.088,1.725v2.27l-4.146,0.182l-2.451,2.844l-1.513,1.12
			l1.301,1.513l-0.182,5.114l-0.938,1.695l1.513,1.694l2.633,0.212l0.575,2.633l0.181,1.694l-3.389,0.757l-1.694,0.574l0.363,4.721
			l-2.27,1.514l-1.906,0.574l-0.938,2.664l-1.513,0.182l-0.636,2.874l4.418,0.151l0.968,1.877l-0.787,2.088l1.725,2.27l1.301-0.363
			l1.332-1.906l6.052,0.574l0.968,3.783l4.146,0.574l0.938,1.302l-2.845,1.331l1.332,0.938l3.995,0.575l0.302,0.756l0.817,2.088
			l2.088,1.695l-3.783,3.42v2.633l1.513,0.756l-1.513,1.906l2.088,1.695l-1.513,1.875l1.694,1.15l4.146-0.182l-0.182,4.146
			l1.574,2.48l0.575-0.09l4.025-1.877l4.811,2.148l3.723,5.326l2.148-0.271l3.208-3.209l1.059,1.877l2.148-2.118l2.936,0.786
			l0.091,0.636l0.999,6.325l3.207,2.391l-2.421,5.628l2.421,1.06l-1.059,3.207l2.118,0.272l1.604-2.663h2.693l0.515,0.787h5.9
			l1.06-2.391l1.331-0.545l0.545-4.267h1.331v-4.539l5.356-4.569l0.545,0.816l0.515,3.48l3.752-0.545l0.817,5.356h1.846l0.545,5.356
			l1.664,2.058l2.724-6.265l2.663-8.321l3.389,2.27l1.514-3.39l4.842-1.755l0.454-0.151l3.419,8.715l4.902-1.513l0.363-2.27h1.906
			l0.757,2.633l3.51-0.847l4.358,5.567l2.723-4.327l4.902-3.602h4.539l1.514-4.721l3.025-0.211l0.212-3.571h2.814l-0.544-1.331
			l-0.757-2.451l1.119-1.906l2.663-1.12l1.12-4.539l-2.451-2.845l2.391,2.754l1.755-0.484l3.269-0.605l0.847-3.722l4.392-2.594
			l1.146,2.382l3.299,1.029l2.27-0.817l2.663-1.847l3.51,2.875h2.875l2.058,2.875l-0.848,2.059l0.424,2.904l-1.028,2.875
			l0.423,1.029l1.423-0.424l3.298,1.028l4.328,1.241l1.846-1.241l0.817-1.422h0.635l0.182,15.403l1.029,1.028h2.693l2.451,1.452
			l1.846,1.453l1.876,0.182l1.21,1.028l3.414,0.393l-0.327,0.758l-3.208,1.301l-3.964,0.394l-1.149,3.602l-0.182,2.27l2.088,1.694
			l-2.088,2.451l-2.633-1.331l-3.026-0.182l-0.394,1.694l1.906,1.331l-2.3,1.514l0.787,3.207l6.415,1.725l1.15,2.451l1.876,0.363
			l-0.636,5.901l0.03,0.03l2.905-2.724h3.45l1.361,0.847l1.876-0.695l-2.904-3.238l-0.515-1.543l1.028-0.182l1.877,1.725h1.21
			l-0.182-2.754l-1.21-0.332l0.514-1.695l2.059-3.449l3.268-1.877l1.544-0.332v-1.725h1.028l1.876,1.543v1.21l3.238,2.058
			l0.878-0.363l-0.515-3.237h-0.878l0.878-1.21l3.57,0.181l1.907-1.361l2.904,1.543l6.144,0.151l1.028-2.572l0.696-1.029v-1.875
			l2.572-0.152l1.028-2.057l2.905-1.726l2.058-3.087l2.451,0.121l1.786-1.937l2.058,0.182v-1.694l-2.633-1.332l-0.575-5.477
			l-2.088-0.757l-2.633,0.394l-4.933-2.481l-0.756-5.659l-2.814-0.938l-0.969-1.906l-1.24-2.725l-0.061-0.121l5.658-2.27
			L497.844,327.666z"/>
	</g>
	<g id="H2a" @click="setZoneClimat('H2a')" :class="{canNotSelect: !isCliquable, canSelect: isCliquable ,active: theValue === 'H2a'}" class="zone-climat">
		
			<path class="zone-dept" id="departement56_3_" inkscape:label="Morbihan" fill="#008080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M107.567,155.632l-3.147,1.513h-2.209l-2.33,1.876l0.151,1.392l1.392,3.602l0.787,2.814l4.842,0.787l2.36,1.876l0.938-1.12
			l1.543,2.058l-0.787,0.938l-0.151,2.784h-1.392l-1.089,1.725h-2.209l-0.908,3.722l2.119,3.39l3.026,0.756l1.12-1.695l-0.544,2.058
			l2.632,1.15l3.419,3.389l1.12,2.088l-0.363,2.451l-0.394,2.481l2.27,1.695l1.15-1.332l-1.15-1.513v-3.389l2.27,0.544l0.757-2.27
			l0.575,1.332l2.451,2.088l1.15-1.906l-1.15-2.633l2.088,2.845l2.633-0.394l-0.544-1.332l2.451,0.575l1.876,2.27l-0.938,1.513
			l-2.451-0.756l-2.845-1.332l-1.513,1.907l2.27,0.756l1.694,2.633l10.229-0.938l2.632,0.575l-1.301,1.12l0.181,1.725l0.363,0.272
			l0.817-0.151l1.695-1.695l1.12,1.301h3.026l3.601-1.876l5.296-2.088l0.182-5.295l1.12-0.605l-1.997-3.752l1.725-1.392
			l-0.303-0.938l-1.089-0.635l1.695-0.787l1.574-1.876l-0.151-1.876h-2.028l-0.484-1.846l1.422-1.876l-1.574-2.814l-2.33-1.422
			h-2.663l-0.938-0.302v-1.241l1.392-1.271l0.787-3.117l-0.454-2.027l-0.635,0.787l-3.752-0.302l-1.725-3.147h-1.392l-2.179,0.787
			v2.027l-2.966,1.876l-2.36-0.938l-0.938-2.027l-0.757,1.089h-2.209l-1.089-1.392h-1.089l-2.33-2.36l-2.663,0.636l-2.814-2.512
			l-2.512,0.182l-0.757,1.543l-2.965,1.574l-3.601-1.574l-0.938-0.938l-1.574,1.089l-1.876-0.151l-0.303-1.543l-2.966-0.787
			L107.567,155.632z"/>
		
			<path class="zone-dept" id="path118_3_" inkscape:label="Cotes-d&apos;Armor" fill="#008080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M120.913,119.44l-1.725,1.332l-4.328,0.544l-0.968,1.332l-3.026-2.27l-3.964,2.663l1.513,2.058l-2.633,3.601l-0.121-0.061
			l-1.089,5.084l2.33,0.151l-0.151,2.027l1.725,1.089l-1.574,1.574l-1.089,0.787l0.151,1.846l2.36,0.787l-2.209,0.636v2.33
			l1.422,1.876l0.303,5.477l-0.938,0.938l0.787,2.814l2.966,0.787l0.303,1.543l1.876,0.151l1.574-1.089l0.938,0.938l3.601,1.574
			l2.965-1.574l0.757-1.543l2.512-0.182l2.814,2.512l2.663-0.636l2.33,2.36h1.089l1.089,1.392h2.209l0.757-1.089l0.938,2.027
			l2.36,0.938l2.966-1.876v-2.027l2.179-0.787h1.392l1.725,3.147l3.752,0.302l1.876-2.36l2.028-4.357l2.663-0.938l1.392-2.028
			l1.422,1.392l2.966-0.605l0.938-8.594l0.938-3.45l-0.938-1.876l-1.574-0.605l-1.059-5.719l-1.21,1.362l-3.601-0.394l-0.363,2.088
			l-2.27,0.182l-0.182-2.633l-1.906-0.575l-1.332,1.513v-3.783l-2.27,1.694l-3.389-0.575l-1.15,2.27l-6.99,3.783v1.906h-1.513
			v-3.419l-3.964-1.876l0.363-3.42l-3.571-2.632v-3.208l-2.663-0.575l0.182-3.026l-2.058-0.182l0.182-2.088h-3.783l-0.575,1.876
			L120.913,119.44z"/>
		
			<path class="zone-dept" id="path120_3_" inkscape:label="Finistere" fill="#008080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M92.709,125.099l-2.058,2.27l-2.27-0.938l-4.176,0.394L83.45,128.7l-2.451,0.575l-0.394-2.088l-4.327,0.575v1.332l-3.026,0.182
			l-1.332-0.938l-1.513,0.757l-0.394,2.27l-5.084,0.182l-2.663,3.208l2.27,1.695l-3.026,2.481l0.938,1.695l-0.756,4.146l3.026,0.393
			l1.15-1.15l0.575,0.757l7.172-0.938l4.721-3.389l-4.146,3.964l0.363,1.876l3.783-1.694l-0.756,2.663l4.176,0.181l-0.182,1.12
			l-4.539-0.181l-3.601-0.938l-4.357-2.088l-2.633,3.026l3.389,1.15l-0.182,5.083l0.938-0.756l2.088-3.208l3.964,2.27l1.907,0.393
			l0.756,3.026l-1.15,2.058l-2.451-0.182h-2.27l-3.783,0.575l-6.445,0.363l-1.301,1.725l1.876,1.12l2.088-0.182l1.694,1.513
			l2.451-0.182l3.995,4.539l0.938,4.902l-1.332,2.663l3.964,0.756l4.357-0.211l0.938-1.695l-1.694-2.27l1.694,0.756l1.725-0.182
			l3.026,1.695l1.876-0.363v-3.238l0.756,3.238l2.451,3.964l5.295,0.363l0.212-1.12l1.301,1.876l3.238,0.575h2.451l0.151,0.212
			l0.908-3.722h2.209l1.089-1.725h1.392l0.151-2.784l0.787-0.938l-1.543-2.058l-0.938,1.12l-2.36-1.876l-4.842-0.787l-0.787-2.814
			l-1.392-3.602l-0.151-1.392l2.33-1.876h2.209l3.147-1.513l-0.666-2.391l0.938-0.938l-0.303-5.477l-1.422-1.876v-2.33l2.209-0.636
			l-2.36-0.787l-0.151-1.846l1.089-0.787l1.574-1.574l-1.725-1.089l0.151-2.027l-2.33-0.151l1.089-5.084l-3.117-1.815l-5.296,0.181
			v3.783h-1.513l-0.363-1.694l-2.27,0.363L92.709,125.099z"/>
		
			<path class="zone-dept" id="path122_3_" inkscape:label="Ille-et-Villaine" fill="#008080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M165.911,131.726l-1.816,2.058l1.059,5.719l1.574,0.605l0.938,1.876l-0.938,3.45l-0.938,8.594l-2.966,0.605l-1.422-1.392
			l-1.392,2.028l-2.663,0.938l-2.028,4.357l-1.241,1.574l0.454,2.027l-0.787,3.117l-1.392,1.271v1.241l0.938,0.302h2.663l2.33,1.422
			l1.574,2.814l-1.422,1.876l0.484,1.846h2.028l0.151,1.876l-1.574,1.876l-1.695,0.787l1.089,0.635l0.303,0.938l-1.725,1.392
			l1.997,3.752l3.813-2.027l11.711-0.575l0.756-2.088l1.907-1.876l4.146-0.575l0.182-2.088l2.845,0.394l1.694,2.27l3.813,0.938
			l0.726-1.513l0.969-3.419l2.451-6.052l1.331-0.756l3.208,0.393v-5.114l-1.332-1.332v-5.478l-0.575-1.876v-3.026l1.907-1.907
			v-3.783l-0.938-0.756l0.182-5.296l-1.513-0.756h-2.27l-1.907-1.513l-2.058,2.451l-1.695,0.212l-1.513,2.058l-1.513-0.363
			l-3.238-2.845l-1.12-3.601l-0.575-2.391h-9.29l-3.419-2.088l2.27-3.026L165.911,131.726z"/>
		
			<path class="zone-dept" id="path177_3_" inkscape:label="Manche" fill="#008080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M169.119,75.168l-0.757,1.906l3.995,3.208v4.176l-1.513,1.876l0.938,0.938l0.575,0.394l-0.394,3.601l1.332,3.026l4.358,4.902
			l0.938,4.357l0.938,1.332v6.809l2.27,4.54v5.295l-2.451,4.902l2.633,6.809l4.176,0.938l0.363,1.907l-2.058,0.938h-3.571
			l0.575,2.391l1.12,3.601l3.238,2.845l1.513,0.363l1.513-2.058l1.695-0.212l2.058-2.451l1.907,1.513h2.27l1.513,0.756v0.363
			l3.208,0.394l1.906-1.513l2.814,1.15l0.061,0.121l3.238-2.754l1.089-3.601l-0.303-1.573l0.454-1.846l-1.876-1.876l-4.841-3.147
			l-3.571-0.302l-3.601-4.54l2.965-1.089l1.241-2.331l-1.543-1.422l1.392-1.241l1.392,1.089l2.512-1.543l1.574-2.512l0.605-2.481
			l-1.089-2.209l0.635-0.787l-1.422-2.33l1.573-2.028l-1.241-1.573l-1.573,2.027l-2.179-1.241l-3.601-3.601l-0.151-1.543
			l1.089-1.089l-0.333-2.088l-1.786,0.454l-0.181-4.539l-4.933-5.841l1.513-3.782h2.088l-1.876-5.114l-8.14-0.394l-4.357,3.026
			l-4.902-3.208L169.119,75.168z"/>
		<g class="zone-label" id="label_H2a" transform="translate(8,-168)">
			
				<path class="zone-cartouche" id="cartouche_H2a" sodipodi:ry="21.296297" sodipodi:rx="21.296297" sodipodi:cy="270.37036" sodipodi:cx="75" sodipodi:type="arc" fill="#FFFFFF" fill-opacity="0.502" stroke="#000000" d="
				M149.487,330c0,10.763-10.963,19.487-24.487,19.487s-24.487-8.725-24.487-19.487s10.963-19.487,24.487-19.487
				S149.487,319.237,149.487,330z"/>
			<text transform="matrix(1 0 0 1 106.2686 337.0654)" font-family="'Arial'" font-size="20">H2a</text>
		</g>
		<polygon class="zone-area" id="area_H2a" fill="none" stroke="#FC03F0" stroke-miterlimit="10" points="210.758,134.51 208.881,132.634 
			204.04,129.486 200.469,129.184 196.868,124.645 199.833,123.556 201.074,121.225 199.531,119.803 200.923,118.563 
			202.315,119.652 204.827,118.108 206.4,115.597 207.005,113.115 205.916,110.906 206.551,110.12 205.129,107.79 206.703,105.762 
			205.462,104.188 203.889,106.216 201.709,104.975 198.108,101.374 197.958,99.831 199.047,98.741 198.714,96.653 196.928,97.107 
			196.747,92.568 191.814,86.728 193.327,82.945 195.416,82.945 193.539,77.831 185.399,77.438 181.042,80.464 176.139,77.256 
			169.119,75.168 168.362,77.074 172.356,80.282 172.356,84.458 170.843,86.334 171.782,87.272 172.356,87.666 171.963,91.267 
			173.294,94.293 177.652,99.195 178.59,103.553 179.528,104.884 179.528,111.693 181.798,116.232 181.798,121.528 179.347,126.43 
			181.979,133.239 186.155,134.177 186.519,136.083 184.461,137.021 180.89,137.021 171.6,137.021 168.181,134.934 170.45,131.907 
			165.911,131.726 164.095,133.784 162.885,135.146 159.284,134.752 158.92,136.84 156.651,137.021 156.469,134.389 
			154.563,133.814 153.231,135.327 153.231,131.544 150.962,133.239 147.573,132.664 146.423,134.934 139.433,138.716 
			139.433,140.623 137.919,140.623 137.919,137.203 133.955,135.327 134.318,131.907 130.748,129.275 130.748,126.067 
			128.084,125.492 128.266,122.466 126.208,122.284 126.39,120.196 122.607,120.196 122.032,122.073 120.913,119.44 
			119.188,120.771 114.86,121.316 113.892,122.647 110.866,120.378 106.902,123.041 108.415,125.099 105.782,128.7 105.661,128.639 
			102.544,126.824 97.249,127.005 97.249,130.788 95.735,130.788 95.373,129.093 93.103,129.456 92.709,125.099 90.652,127.368 
			88.382,126.43 84.206,126.824 83.45,128.7 80.999,129.275 80.605,127.187 76.278,127.762 76.278,129.093 73.251,129.275 
			71.92,128.336 70.407,129.093 70.014,131.363 64.93,131.544 62.267,134.752 64.537,136.447 61.51,138.928 62.448,140.623 
			61.692,144.769 64.718,145.162 65.868,144.012 66.443,144.769 73.615,143.83 78.335,140.441 74.189,144.405 74.553,146.281 
			78.335,144.587 77.579,147.25 81.755,147.431 81.573,148.551 77.034,148.37 73.433,147.431 69.076,145.343 66.443,148.37 
			69.832,149.52 69.65,154.603 70.589,153.847 72.677,150.639 76.641,152.909 78.547,153.302 79.304,156.328 78.154,158.386 
			75.703,158.204 73.433,158.204 69.65,158.779 63.205,159.143 61.904,160.867 63.78,161.987 65.868,161.805 67.563,163.318 
			70.014,163.137 74.008,167.676 74.946,172.578 73.615,175.241 77.579,175.998 81.937,175.786 82.875,174.091 81.18,171.822 
			82.875,172.578 84.6,172.396 87.625,174.091 89.502,173.728 89.502,170.49 90.258,173.728 92.709,177.692 98.005,178.056 
			98.217,176.936 99.518,178.812 102.756,179.387 105.207,179.387 105.358,179.599 107.477,182.988 110.503,183.745 111.623,182.05 
			111.078,184.108 113.71,185.258 117.13,188.647 118.25,190.735 117.887,193.186 117.493,195.667 119.763,197.362 120.913,196.03 
			119.763,194.518 119.763,191.128 122.032,191.673 122.789,189.403 123.364,190.735 125.815,192.823 126.965,190.917 
			125.815,188.284 127.903,191.128 130.536,190.735 129.991,189.403 132.442,189.979 134.318,192.248 133.38,193.761 
			130.929,193.004 128.084,191.673 126.571,193.58 128.841,194.336 130.536,196.969 140.764,196.03 143.396,196.605 
			142.096,197.725 142.277,199.45 142.64,199.723 143.457,199.571 145.152,197.876 146.271,199.178 149.297,199.178 
			152.898,197.302 158.194,195.213 158.376,189.918 159.496,189.313 163.309,187.285 175.02,186.71 175.776,184.622 
			177.683,182.746 181.828,182.171 182.01,180.083 184.854,180.477 186.549,182.746 190.362,183.684 191.088,182.171 
			192.057,178.751 194.508,172.699 195.839,171.943 199.047,172.336 199.047,167.222 197.715,165.891 197.715,160.413 
			197.14,158.537 197.14,155.511 199.047,153.604 199.047,149.822 198.108,149.065 198.29,143.77 198.29,144.133 201.498,144.526 
			203.404,143.013 206.219,144.163 206.279,144.284 209.517,141.53 210.606,137.929 210.304,136.356 		"/>
	</g>
	<g id="H2b" @click="setZoneClimat('H2b')" :class="{canNotSelect: !isCliquable, canSelect: isCliquable ,active: theValue === 'H2b'}" class="zone-climat">
		<path class="zone-dept" id="path13_1_" inkscape:label="Vendee" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M187.487,222.418l-1.089,2.028h-3.117l1.241,1.271l-0.938,3.117l-2.814,0.938l-1.089-0.787l0.484-3.117l-0.787-1.725h-1.725
			l-1.241,1.392l0.605,4.388l1.422,2.027l-1.422,1.574l-2.633-0.484l-3.903-0.938l-1.089-2.965l-2.512-0.303l-3.269-1.422
			l-0.787-1.876l-3.571-2.33l-5.628,7.263l-0.182,4.539l5.84,5.659l-0.182,1.695h1.695l3.601,10.803l3.783,1.876l3.782,3.783h4.358
			l1.694,3.783h4.176l1.876,2.844l4.176,2.088l0.182-2.663l1.059,0.999l5.78-3.51l2.663-0.182l1.12,3.026l3.601-1.513l3.026,2.27
			l2.451-1.15l2.088-0.575l0.938-1.12l2.632-1.694l-1.876-1.907l-1.513,1.15l-0.182-1.513l1.332-2.481l-1.332-2.27l1.12-1.513
			l-0.575-5.296l-1.876-2.814l1.332-1.725l-3.026-3.389l1.12-2.451l-4.539-3.783v-2.27l-1.876-2.663l0.363-0.151l-2.572-2.179
			h-4.842l-1.574-0.938l-2.179-0.303l-2.512-2.36L187.487,222.418z"/>
		
			<path class="zone-dept" id="path16_1_" inkscape:label="Mayenne" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M230.064,141.894l-1.876,0.182l-0.787,1.876l-2.814,1.15l-5.114-0.756l-5.114,3.026l-1.876-1.332l-2.844,1.907l-2.088-1.513
			l-1.331-2.27l-2.814-1.15l-1.906,1.513l-3.208-0.394l-0.182,4.933l0.938,0.756v3.783l-1.907,1.907v3.026l0.575,1.876v5.478
			l1.332,1.332v5.114l-3.208-0.393l-1.331,0.756l-2.451,6.052l-0.969,3.419l-0.302,0.666l3.419,0.696h2.814l3.117,2.027l1.271-1.543
			l5.598,1.694l2.209,1.271l4.993-0.182l2.179-2.179l3.45,1.089h1.846l0.03,0.03l0.605-1.422v-5.628l-0.635-0.938l0.333-0.938
			l2.663-0.151l1.241-1.089l0.151-0.938l-1.392-3.449l0.454-1.392l3.117-0.303l-0.303-0.787l0.787-1.876l-0.636-1.422l0.787-1.543
			l2.814-2.36l-0.333-2.481l0.484-4.055l1.089-1.876l3.541-1.15l-0.544-0.03l-0.968-3.42l-2.451-0.938l-0.757-4.146L230.064,141.894
			z"/>
		
			<path class="zone-dept" id="path18_1_" inkscape:label="Loir-et-Cher" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M268.798,166.919l-1.271,1.634l-1.513,2.633l1.876,1.725l-0.182,3.964l-0.756,2.632h-1.907v3.601l-2.633,3.419l-2.844,1.12
			l-1.332,1.694l0.575,1.422l9.169,0.303l0.787,0.696l-0.121,0.666l-1.362,0.696l0.272,1.876l1.21,0.817l1.907-1.362h0.544
			l2.179,1.634l1.907,0.151l0.272,4.872l1.483,3.934v6.809l1.089,1.634h3.662l2.845,4.206l1.059,0.908l0.03-0.091l1.906-0.272
			l1.634-1.362l3.268-0.151l0.272,0.545l0.666-1.483l2.845-0.968l2.451,0.151l1.21-0.424l1.635,1.513l2.875,1.634l2.421-0.151
			l-0.121-2.451l1.089-1.21l1.09-0.121l0.938,1.059l3.813-0.394l2.421-1.362l-0.272-0.938l-0.666-0.817l0.121-1.786l1.785-3.238
			l2.3-0.968v-2.028l0.545-1.21l-1.482-0.545l-0.969-2.058l-2.451-0.666l-0.121-0.817l2.451-2.027l2.754-1.362l-1.543-2.3
			l-6.931-0.272l-0.938,1.089h-1.906l-0.666-0.696l-2.996-0.394l-0.816,1.755l-1.907,0.545l-1.755-2.027l-0.424-2.33l-1.604-1.362
			l-2.451-0.272l-1.785-1.21v-1.21l-1.06-2.603l2.149-2.148l-0.273-1.089l-0.938-1.089h-1.089v-0.968l1.089-1.876l0.121-1.089
			h-1.604l-2.451-0.696l-2.3,2.179l-2.603,0.272l-4.206-1.089l-1.21-2.724l-1.634-0.938l-0.938-2.179h-2.724l-1.089-0.817
			l1.634-0.938l0.121-0.817h-1.755l-2.996,0.544L268.798,166.919z"/>
		
			<path class="zone-dept" id="path114_1_" inkscape:label="Charente" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M253.093,275.98l-1.634,1.24l0.212,2.693l-1.029,0.817l-2.905-1.665l-2.875,1.029l-3.298,0.212l-2.481-3.026l-0.787,0.151
			l-3.298,1.634l-2.481,0.636v1.423l-1.422,1.664l0.393,1.029l-1.846,1.028l-1.634,5.962l-0.423,3.903l-1.422,0.604l-2.058-0.182
			l-0.424-1.028h-2.27l-1.21,1.028h-2.693l-2.27,1.211l1.665,0.636l0.211,4.327l0.817,0.182l-1.453,1.664l1.665,1.029l2.239,2.058
			l1.241,2.058l1.453,1.422l-0.424,2.059l-0.817,0.816l1.241,1.241v1.452l-2.481,2.059l1.029,0.816l1.453,0.424v0.393l-2.058,0.818
			l0.182,1.028l1.241,0.636l3.904-0.636l1.664,1.846l1.241,1.665l4.025,2.723l0.575-0.969l3.783,0.395l1.876-1.726l3.419-3.39
			l0.182-6.627l8.896-6.052l0.182-4.539l2.663-0.363l1.694-3.026l1.059,0.03l0.636-2.874l1.513-0.182l0.938-2.664l1.906-0.574
			l2.27-1.514l-0.363-4.721l1.694-0.574l3.389-0.757l-0.181-1.694l-0.575-2.633l-2.633-0.212l-1.513-1.694l0.938-1.695l0.061-1.543
			l-1.695-0.848h-3.268l-1.453,1.635l-3.51,1.452l-1.21-1.241L253.093,275.98z"/>
		
			<path class="zone-dept" id="path116_1_" inkscape:label="Deux-Sevres" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M229.308,222.267l-5.114,0.182l-4.721,0.938l-5.114,0.393v2.633l-2.633,1.725l-5.871-1.332l-3.964,1.694l1.876,2.663v2.27
			l4.539,3.783l-1.12,2.451l3.026,3.389l-1.332,1.725l1.876,2.814l0.575,5.296l-1.12,1.513l1.332,2.27l-1.332,2.481l0.182,1.513
			l1.513-1.15l1.876,1.907l-2.632,1.694l-0.938,1.12l-2.088,0.575l-2.451,1.15l-0.182-0.151l0.091,2.784l2.058,2.239v1.241
			l3.087,2.27l3.904,0.212l2.663,3.48l4.328-0.394l3.51,2.451l2.663,1.24l0.423,2.059l1.483,1.422l1.785-0.998l-0.393-1.029
			l1.422-1.664v-1.423l2.481-0.636l3.298-1.634l3.48-0.604l0.635-1.877l-2.905-0.817l-1.422-2.27l1.029-2.451l1.241-1.453v-3.086
			l-1.241-0.817l-1.634,0.817v1.029l-0.847,1.241l-1.634-1.846l-0.212-1.24l0.635-1.422l-1.453-1.029v-4.328l-0.817-0.212v-1.453
			l1.241-2.058l0.182-1.029l0.847-2.239l-0.635-1.453l-2.058-0.817l2.058-3.086l0.635-1.634v-1.029l-1.453-1.665V240l0.817-1.453
			l-0.605-1.029l1.241-1.422l-1.029-2.27l-0.636-3.298l-0.211-2.058l-1.634,0.212l-0.303-4.66l-0.393,0.696l-1.332-1.12
			L229.308,222.267z"/>
		
			<path class="zone-dept" id="path124_1_" inkscape:label="Loire-Atlantique" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M182.01,180.083l-0.182,2.088l-4.146,0.575l-1.907,1.876l-0.756,2.088l-11.711,0.575l-4.933,2.633l-0.182,5.295l-5.296,2.088
			l-3.601,1.876h-3.026l-1.12-1.301l-1.695,1.695l-0.817,0.151l0.968,0.666l-3.601,3.208l0.757,0.757l0.756,1.513l-1.906,2.663
			l2.088,1.12l3.601,0.756l0.363-1.513l2.088,2.663h3.419l2.451-2.663h3.208l-3.389,1.694l0.182,1.907l0.756,1.694l-2.088,2.088
			h-2.27l0.393,2.844l4.146-0.756l4.933,4.539l-0.242,0.303l3.571,2.33l0.787,1.876l3.269,1.422l2.512,0.303l1.089,2.965
			l3.903,0.938l2.633,0.484l1.422-1.574l-1.422-2.027l-0.605-4.388l1.241-1.392h1.725l0.787,1.725l-0.484,3.117l1.089,0.787
			l2.814-0.938l0.938-3.117l-1.241-1.271h3.117l1.089-2.028l1.089,0.151l2.512,2.36l1.816,0.242l0.061-1.967l-1.574-2.027h-1.392
			l-0.484,0.151l-0.938-0.454l0.787-0.787v-1.422l1.573-0.454l0.938-2.179l-0.787-0.787l-0.151-2.663h-2.027l-2.027-2.512v-1.846
			l2.179-1.12l3.904-0.756l6.083,0.151l1.876-1.271l-0.635-3.904l-2.814-2.633l-3.419,0.454l-0.938-0.787l-0.151-2.784l2.481-2.209
			l-1.876-2.33l-1.241-3.298l-2.027-1.241v-2.179l-0.212-0.696l-3.541-0.877l-1.694-2.27L182.01,180.083z"/>
		
			<path class="zone-dept" id="path132_1_" inkscape:label="Indre-et-Loire" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M258.812,190.765l0.363,0.847l-5.477,1.513l-1.301,1.906l-2.3-1.513l1.15,3.783h-2.088l-3.783-2.663l-1.876,3.782l0.938,1.15v1.12
			l-1.907,2.481l0.212,4.146l-3.026,3.601l-2.149,7.747h0.242l0.756,3.026l3.601,0.756v2.27l4.54,1.332v3.601l-0.182,2.27h5.659
			l4.933-1.15l-0.394-2.088l1.513-0.938l1.513,2.088l1.332,0.575l1.12,4.539l3.238,3.389l0.363,2.663l2.935,2.905l1.665-0.151
			l2.058-1.906l1.483-7.716l0.938-2.724l0.696-3.389l2.996-1.089l2.027,0.393l1.362,1.241l1.483-2.451l1.362-1.241v-1.482
			l1.755-0.121l0.424-1.786l-1.513-2.027l0.242-0.847l-1.059-0.908l-2.845-4.206h-3.662l-1.089-1.634v-6.809l-1.483-3.934
			l-0.272-4.872l-1.907-0.151l-2.179-1.634h-0.544l-1.907,1.362l-1.21-0.817l-0.272-1.876l1.362-0.696l0.121-0.666l-0.787-0.696
			L258.812,190.765z"/>
		<path class="zone-dept" id="path134_1_" inkscape:label="Cher" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M320.151,194.608l-2.754,1.362l-2.451,2.027l0.121,0.817l2.451,0.666l0.969,2.058l1.482,0.545l-0.545,1.21v2.028l-2.3,0.968
			l-1.785,3.238l-0.121,1.786l0.666,0.817l0.272,0.938l-2.421,1.362l-3.813,0.394l-0.938-1.059l-1.09,0.121l-1.089,1.21l0.121,2.451
			l-2.421,0.151l-0.151,2.148l-2.179,1.786l0.271,0.817l1.362,1.09l2.724,0.393l2.027-0.817l2.572-0.121l1.24,0.938l-0.424,2.572
			l2.179,2.875v1.211l-0.938,1.634v0.666l1.089,1.241h1.756l0.121,0.787l-2.148,1.785l0.12,1.21l-1.361,0.696l0.545,0.938
			l1.755,1.483v1.089l-1.876,1.089v1.634l2.572,1.361l0.545,1.907l1.634,1.483l-0.151,0.544l-0.666,0.817l-0.151,2.451l-0.394,0.817
			l1.09,2.148l0.394,1.634l-1.906,1.907l-0.091,1.513l4.448-0.545l0.907-1.937l2.451-2.814l4.721-0.968l2.481,0.756l2.633-2.058
			l-0.182-1.513l-0.938-0.756v-3.026l5.084-5.114l1.906,2.088l1.694-1.725l1.514-0.181l2.632-3.389l4.358,0.363l0.061,1.029
			l1.452-4.63l-0.938-1.694l0.182-2.451l0.575-4.933l-2.088-2.058l0.394-4.54l-1.906-3.994l-0.182-2.633l-3.42-2.663l-0.545-2.27
			l1.695-2.451v-3.601l-1.968-2.3l-1.937,0.787l-0.938-0.393l-1.362-1.786l-0.817-0.121l-0.544,0.272l-0.151,2.724l-1.211,0.121
			l-0.938-0.817l-2.179-2.996l-0.817-1.059l-3.117-0.151l-1.513-1.907h-1.211l-0.544,0.696l-2.027,0.272l-1.635-1.513l-1.906-0.817
			L320.151,194.608z"/>
		
			<path class="zone-dept" id="path174_1_" inkscape:label="Charente-Maritime" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M197.927,262.181l-2.663,0.182l-5.78,3.51l1.392,1.271l-3.026,2.451l-0.363,1.906l-2.844,0.363l-1.513-1.695l-3.783-0.363
			l-0.394-1.907l-2.27-1.513l-3.208,1.149l2.088,3.026h2.633l2.663,1.694l2.088,1.695l3.964-0.182l0.756,1.695l2.633,0.575
			l0.968,2.633l1.695,0.756l-0.182,2.088l-2.27-0.363l-0.756,1.12l1.694,2.451l-0.938,4.176l-2.27-0.181l0.182,2.632l0.575,0.938
			h-2.663l-0.363-1.514l1.694-2.27l-0.575-1.301l-0.938-0.756l-0.393-4.54l-3.208-0.394l-2.633-3.207l-0.394,6.627l4.357,3.207
			l0.363,3.602l0.756,4.146l0.394,4.176l2.27-0.213l3.964,3.238l2.663,1.514l0.182,1.876l2.088,0.394l6.052,6.052l1.422,6.536h5.598
			l0.938-0.938l0.182,2.844l4.933,0.576l0.756,6.052l2.663,0.182l4.327,4.357l2.27,0.363l2.663-1.331l1.876,1.331l1.513-3.208
			l1.513-2.633l-4.025-2.723l-1.241-1.665l-1.664-1.846l-3.904,0.636l-1.241-0.636l-0.182-1.028l2.058-0.818v-0.393l-1.453-0.424
			l-1.029-0.816l2.481-2.059v-1.452l-1.241-1.241l0.817-0.816l0.424-2.059l-1.453-1.422l-1.241-2.058l-2.239-2.058l-1.665-1.029
			l1.453-1.664l-0.817-0.182l-0.211-4.327l-1.665-0.636l2.27-1.211h2.693l1.21-1.028h2.27l0.424,1.028l2.058,0.182l1.422-0.604
			l0.423-3.903l1.634-5.962l0.061-0.03l-1.483-1.422l-0.423-2.059l-2.663-1.24l-3.51-2.451l-4.328,0.394l-2.663-3.48l-3.904-0.212
			l-3.087-2.27v-1.241l-2.058-2.239l-0.091-2.784l-2.844-2.118l-3.601,1.513L197.927,262.181z"/>
		<path class="zone-dept" id="d_1_" inkscape:label="Vienne" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M236.116,218.666l-3.601,3.782l-0.908,1.574l0.303,4.66l1.634-0.212l0.211,2.058l0.636,3.298l1.029,2.27l-1.241,1.422l0.605,1.029
			L233.968,240v0.605l1.453,1.665v1.029l-0.635,1.634l-2.058,3.086l2.058,0.817l0.635,1.453l-0.847,2.239l-0.182,1.029l-1.241,2.058
			v1.453l0.817,0.212v4.328l1.453,1.029l-0.635,1.422l0.212,1.24l1.634,1.846l0.847-1.241v-1.029l1.634-0.817l1.241,0.817v3.086
			l-1.241,1.453l-1.029,2.451l1.422,2.27l2.905,0.817l-0.635,1.877l-2.693,0.453l2.481,3.026l3.298-0.212l2.875-1.029l2.905,1.665
			l1.029-0.817l-0.212-2.693l1.634-1.24l1.453,2.48l1.21,1.241l3.51-1.452l1.453-1.635h3.268l1.695,0.848l0.121-3.571l-1.301-1.513
			l1.513-1.12l2.451-2.844l4.146-0.182v-2.27l2.088-1.725l5.114-0.938l0.363-2.845l-2.058-1.119l-1.15-3.964l-3.026-0.394
			l-1.876-1.876l-3.601-2.845l0.757-2.27v-3.601l-3.419-3.389l-0.363-2.663l-3.238-3.389l-1.12-4.539l-1.332-0.575l-1.513-2.088
			l-1.513,0.938l0.394,2.088l-4.933,1.15h-5.659l0.182-2.27v-3.601l-4.54-1.332v-2.27l-3.601-0.756l-0.756-3.026H236.116z"/>
		
			<path class="zone-dept" id="path179_1_" inkscape:label="Maine-et-Loire" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M190.786,182.837l-0.424,0.847l-0.272-0.061l0.212,0.696v2.179l2.027,1.241l1.241,3.298l1.876,2.33l-2.481,2.209l0.151,2.784
			l0.938,0.787l3.419-0.454l2.814,2.633l0.635,3.904l-1.876,1.271l-6.083-0.151l-3.904,0.756l-2.179,1.12v1.846l2.027,2.512h2.027
			l0.151,2.663l0.787,0.787l-0.938,2.179l-1.573,0.454v1.422l-0.787,0.787l0.938,0.454l0.484-0.151h1.392l1.574,2.027l-0.061,1.967
			l0.363,0.061l1.574,0.938h4.842l2.572,2.179l3.601-1.543l5.871,1.332l2.633-1.725v-2.633l5.114-0.393l4.721-0.938l5.114-0.182
			l0.575,1.332l1.332,1.12l1.301-2.27l3.601-3.782h1.453l2.149-7.747l3.026-3.601l-0.212-4.146l1.907-2.481v-1.12l-0.938-1.15
			l0.605-1.24l-2.572-0.999l-7.958-4.842l-7.354-2.179l-2.814-0.151v-1.876l-1.725-1.422h-1.846l-3.45-1.089l-2.179,2.179
			l-4.993,0.182l-2.209-1.271l-5.598-1.694l-1.271,1.543l-3.117-2.027h-2.814L190.786,182.837z"/>
		
			<path class="zone-dept" id="path181_1_" inkscape:label="Sarthe" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M249.371,147.189l-5.114,0.182l-5.114,4.721l-2.663-0.151l-3.541,1.15l-1.089,1.876l-0.484,4.055l0.333,2.481l-2.814,2.36
			l-0.787,1.543l0.636,1.422l-0.787,1.876l0.303,0.787l-3.117,0.303l-0.454,1.392l1.392,3.449l-0.151,0.938l-1.241,1.089
			l-2.663,0.151l-0.333,0.938l0.635,0.938v5.628l-0.605,1.422l1.694,1.392v1.876l2.814,0.151l7.354,2.179l7.958,4.842l2.572,0.999
			l1.271-2.542l3.783,2.663h2.088l-1.15-3.783l2.3,1.513l1.301-1.906l5.477-1.513l-0.938-2.27l1.332-1.694l2.844-1.12l2.633-3.419
			v-3.601h1.907l0.756-2.632l0.182-3.964l-1.876-1.725l1.513-2.633l2.239-2.845l-2.633-1.876l-2.451-0.394l-2.663-3.964h-0.757
			l-0.181,1.876l-0.182-1.301h-3.964l-1.906-2.845l-3.208-1.15l-0.938-6.809L249.371,147.189z"/>
		<path class="zone-dept" id="path191_1_" inkscape:label="Indre" fill="#00FF00" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M299.997,214.55l-1.21,0.424l-2.451-0.151l-2.845,0.968l-0.666,1.483l-0.272-0.545l-3.268,0.151l-1.634,1.362l-1.906,0.272
			l-0.272,0.938l1.513,2.027l-0.424,1.786l-1.755,0.121v1.482l-1.362,1.241l-1.483,2.451l-1.362-1.241l-2.027-0.393l-2.996,1.089
			l-0.696,3.389l-0.938,2.724l-1.483,7.716l-2.058,1.906l-1.665,0.151l0.484,0.484v3.601l-0.757,2.27l3.601,2.845l1.876,1.876
			l3.026,0.394l1.15,3.964l2.058,1.119l-0.363,2.845l-1.513,0.272l0.545,0.121l4.751,0.363l1.694-1.695l3.026,3.026l3.783-4.357
			l1.694,1.15l2.27-0.212l0.757,0.394l3.419,0.182l1.12-3.026l9.472,1.15l3.601,0.938l1.635-0.182l0.091-1.513l1.906-1.907
			l-0.394-1.634l-1.09-2.148l0.394-0.817l0.151-2.451l0.666-0.817l0.151-0.544l-1.634-1.483l-0.545-1.907l-2.572-1.361v-1.634
			l1.876-1.089v-1.089l-1.755-1.483l-0.545-0.938l1.361-0.696l-0.12-1.21l2.148-1.785l-0.121-0.787h-1.756l-1.089-1.241v-0.666
			l0.938-1.634v-1.211l-2.179-2.875l0.424-2.572l-1.24-0.938l-2.572,0.121l-2.027,0.817l-2.724-0.393l-1.362-1.09l-0.271-0.817
			l2.179-1.786l0.151-2.148l-2.875-1.634L299.997,214.55z"/>
		<g class="zone-label" id="label_H2b">
			
				<path class="zone-cartouche" id="cartouche_H2b" sodipodi:ry="21.296297" sodipodi:rx="21.296297" sodipodi:cy="270.37036" sodipodi:cx="75" sodipodi:type="arc" fill="#FFFFFF" fill-opacity="0.502" stroke="#000000" d="
				M259.487,222c0.005,10.763-10.954,19.491-24.478,19.495c-13.524,0.004-24.492-8.717-24.497-19.479c0-0.005,0-0.01,0-0.015
				c-0.005-10.763,10.954-19.491,24.478-19.495c13.524-0.004,24.492,8.717,24.497,19.479
				C259.487,221.99,259.487,221.995,259.487,222z"/>
			<text transform="matrix(1 0 0 1 216.2583 229.0654)" font-family="'Arial'" font-size="20">H2b</text>
		</g>
		<polygon class="zone-area" id="area_H2b" fill="none" stroke="#FF00FF" stroke-miterlimit="10" points="350.352,235.31 350.533,232.858 
			351.108,227.926 349.021,225.868 349.414,221.329 347.508,217.334 347.326,214.702 343.906,212.039 343.361,209.769 
			345.057,207.318 345.057,203.717 343.089,201.417 341.152,202.204 340.215,201.811 338.853,200.025 338.035,199.904 
			337.491,200.176 337.34,202.9 336.129,203.021 335.191,202.204 333.013,199.208 332.195,198.149 329.078,197.998 327.565,196.091 
			326.354,196.091 325.811,196.787 323.783,197.06 322.148,195.546 320.242,194.729 320.151,194.608 318.608,192.309 
			311.678,192.036 310.74,193.125 308.834,193.125 308.168,192.43 305.172,192.036 304.355,193.791 302.448,194.336 
			300.693,192.309 300.27,189.979 298.666,188.617 296.215,188.344 294.43,187.134 294.43,185.923 293.37,183.321 295.52,181.172 
			295.246,180.083 294.309,178.994 293.219,178.994 293.219,178.025 294.309,176.149 294.43,175.06 292.826,175.06 290.375,174.364 
			288.075,176.542 285.472,176.815 281.266,175.726 280.056,173.002 278.421,172.064 277.483,169.885 274.76,169.885 
			273.67,169.068 275.305,168.13 275.426,167.313 273.67,167.313 270.675,167.857 268.81,166.925 269.767,165.709 267.134,163.833 
			264.683,163.439 262.02,159.475 261.263,159.475 261.082,161.352 260.9,160.05 256.936,160.05 255.03,157.206 251.822,156.056 
			250.884,149.247 249.371,147.189 244.257,147.371 239.143,152.092 236.479,151.94 235.935,151.91 234.967,148.49 232.516,147.552 
			231.759,143.407 230.064,141.894 228.188,142.075 227.401,143.951 224.587,145.101 219.473,144.345 214.359,147.371 
			212.482,146.039 209.638,147.946 207.55,146.433 206.219,144.163 203.404,143.013 201.498,144.526 198.29,144.133 
			198.108,149.065 199.047,149.822 199.047,153.604 197.14,155.511 197.14,158.537 197.715,160.413 197.715,165.891 
			199.047,167.222 199.047,172.336 195.839,171.943 194.508,172.699 192.057,178.751 191.088,182.171 190.786,182.837 
			190.362,183.684 190.089,183.624 186.549,182.746 184.854,180.477 182.01,180.083 181.828,182.171 177.683,182.746 
			175.776,184.622 175.02,186.71 163.309,187.285 158.376,189.918 158.194,195.213 152.898,197.302 149.297,199.178 
			146.271,199.178 145.152,197.876 143.457,199.571 142.64,199.723 143.608,200.388 140.007,203.596 140.764,204.353 
			141.521,205.865 139.614,208.528 141.702,209.648 145.303,210.404 145.666,208.892 147.754,211.555 151.174,211.555 
			153.625,208.892 156.833,208.892 153.443,210.586 153.625,212.493 154.381,214.187 152.293,216.275 150.024,216.275 
			150.417,219.12 154.563,218.363 159.496,222.902 159.253,223.205 153.625,230.468 153.443,235.007 159.284,240.666 
			159.102,242.36 160.797,242.36 164.398,253.164 168.181,255.04 171.963,258.822 176.321,258.822 178.015,262.605 182.191,262.605 
			184.067,265.449 188.244,267.537 188.425,264.875 189.484,265.873 190.876,267.144 187.85,269.595 187.487,271.501 
			184.643,271.865 183.129,270.17 179.347,269.807 178.953,267.9 176.684,266.388 173.476,267.537 175.564,270.563 178.197,270.563 
			180.86,272.258 182.948,273.953 186.912,273.771 187.668,275.466 190.301,276.041 191.27,278.674 192.964,279.43 192.783,281.518 
			190.513,281.154 189.757,282.274 191.451,284.726 190.513,288.901 188.244,288.721 188.425,291.353 189,292.291 186.337,292.291 
			185.974,290.777 187.668,288.508 187.094,287.207 186.155,286.451 185.762,281.911 182.555,281.518 179.922,278.311 
			179.528,284.938 183.886,288.145 184.249,291.746 185.005,295.893 185.399,300.068 187.668,299.855 191.633,303.094 
			194.296,304.607 194.478,306.483 196.565,306.877 202.618,312.929 204.04,319.465 209.638,319.465 210.576,318.527 
			210.758,321.371 215.69,321.947 216.447,327.999 219.11,328.181 223.437,332.538 225.707,332.901 228.37,331.57 230.246,332.901 
			231.759,329.693 233.272,327.061 233.847,326.092 237.629,326.486 239.506,324.761 242.925,321.371 243.107,314.744 
			252.003,308.692 252.185,304.153 254.848,303.79 256.542,300.764 257.602,300.794 258.237,297.92 259.75,297.738 260.688,295.074 
			262.595,294.5 264.864,292.986 264.501,288.266 266.196,287.691 269.585,286.935 269.404,285.24 268.829,282.607 266.196,282.396 
			264.683,280.701 265.621,279.006 265.682,277.463 265.803,273.892 264.501,272.379 266.014,271.259 268.465,268.415 
			272.611,268.233 272.611,265.964 274.699,264.239 278.313,263.576 278.845,263.694 283.596,264.058 285.291,262.363 
			288.317,265.389 292.1,261.031 293.794,262.181 296.063,261.969 296.82,262.363 300.239,262.544 301.359,259.518 310.831,260.668 
			314.432,261.606 316.066,261.425 320.515,260.88 321.422,258.943 323.873,256.129 328.594,255.161 331.075,255.917 
			333.708,253.859 333.526,252.346 332.589,251.59 332.589,248.563 337.673,243.45 339.579,245.538 341.273,243.813 
			342.787,243.631 345.419,240.242 349.777,240.605 349.838,241.634 351.29,237.004 		"/>
	</g>
	<g id="H1b" @click="setZoneClimat('H1b')" :class="{canNotSelect: !isCliquable, canSelect: isCliquable ,active: theValue === 'H1b'}" class="zone-climat">
		<path class="zone-dept" id="path30_1_" inkscape:label="Aube" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M391.598,134.419l-3.843,2.118l-3.269,2.3h-3.238l-5.386,3.662l-4.601,0.756l-3.268-3.45l-0.817,0.182v0.575l-2.633,1.12
			l-0.182,2.27l-1.331,1.725l-0.757,3.783l-0.423,3.177l1.18,0.968h2.27l4.902,5.114l-0.575,5.114l3.42,2.632l1.906-1.332
			l3.026,3.602l0.363,3.419l2.088,2.814l0.938,2.088l7.565,0.394l3.782-1.906l1.12,2.088l1.331,0.182l0.969-2.088l6.234-0.182
			l2.45-1.332l0.394-2.451h5.478l0.757,0.424l-0.636-2.421l-1.543-0.968l2.118-1.907l3.268-0.212l1.15-1.725l-0.212-6.9
			l-0.757-4.024l-3.268-1.15l-3.45-4.812l0.182-2.875l0.999-2.088l-1.756-0.605l-5.387,1.149h-3.843l-3.449-5.174l-0.394-3.843
			L391.598,134.419z"/>
		<path class="zone-dept" id="path32_1_" inkscape:label="Meuse" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M431.118,83.914l-1.331,2.179l-1.694,1.12l-1.332-1.301h-2.845l-0.544-0.756l-1.907,3.571l-0.756,1.332l2.27,2.844l-0.757,3.783
			l-2.088,1.695l0.212,1.332l0.727,1.876l-1.12,1.15l-2.27,1.12l0.363,2.088l0.968,0.182l-1.694,1.513l1.302,2.844l1.331,4.176
			l-1.694,1.876l3.025-0.181l-1.513,4.721l-2.088,0.938l-1.149,2.844l1.149,0.938l-1.331,2.27l0.393,1.513l3.602,2.845l0.182,6.627
			l3.964,0.575l2.451,2.633l4.539,2.27l1.694,0.575l3.995,4.358l-0.424,0.423l3.329-0.423v-1.665l3.722-0.756v-1.271h0.908v1.089
			l2.965-0.908l1.15-1.543l-0.212,0.061v-2.603l-1.846-1.664v-1.12l1.482-0.726h1.664l-0.574-0.938h-1.09l-0.756-1.665v-3.329
			l-0.727-2.058l1.482-3.329l1.271-3.51l-1.483-1.483l-0.182-0.938l0.938-1.301H451v-0.938l-1.846-0.908l-0.363-1.301l1.846-2.027
			l-2.027-1.876l0.727-1.482l-1.665-0.727l-1.119-0.938l-0.182-2.391l-0.182-1.483l-0.727-1.12l0.182-2.421l0.908-1.12V99.8
			l-0.908-0.756v-1.665l-0.575-1.664l-0.726-1.483l-1.665-1.301l-3.146,0.938l-1.876,0.182l-0.908,1.665l-0.938,0.726l-0.182-0.726
			l0.363-1.301l0.394-1.12l-1.119-1.846l0.121-2.27h-0.908l-0.757-3.601l-1.513-1.513L431.118,83.914z"/>
		
			<path class="zone-dept" id="path126_1_" inkscape:label="Haute-Saone" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M463.438,170.066l-3.571,0.575l-0.574,1.876l-1.907,1.332l-1.301-1.513l-0.938,0.575l0.727,1.12l-1.876,1.15l0.574,1.513
			l-2.088,0.756v2.451h-2.662l-0.182,1.513l-2.633,0.575l0.182,2.27l1.694,0.182l-0.938,1.12l-0.574,3.783h-1.695l-2.844,1.15
			l-3.026-1.15l-2.633,1.15v1.907l2.058,0.363l1.513,3.026l0.212,1.513l-2.451,2.844l-1.149,0.363l-0.757,0.938l2.088,0.968
			l0.363,3.026l1.694,0.181l0.212,3.783l0.757,0.757l0.091,0.605l1.452,0.394l1.635,1.362h2.451l0.938-0.938l1.665,0.061l1.725,0.06
			l3.813-3.117h1.09l1.21-0.938l3.935,0.121l2.995-2.451l2.179-0.394l0.817-2.027l1.755-0.545l1.756-2.996l2.451-1.907l2.571-0.393
			l1.907,1.361l3.54-0.423v-1.907l1.423-0.817l1.119-1.543h2.179l1.211-1.271l0.454-3.026v-1.876l-0.817-2.875v-2.421l1.482-1.089
			l1.755-0.908l-0.182-0.363l-6.052-3.208l-1.694-1.907l-1.695-1.12l-1.513,0.757l-0.212,1.12l-1.513,0.938h-0.938l-2.845-3.208
			h-3.964l-1.694,1.331l-1.513,0.182l-2.451-1.876l0.181-2.088L463.438,170.066z"/>
		
			<path class="zone-dept" id="path136_1_" inkscape:label="Nievre" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M350.382,197.453l-0.938,1.483h-1.786l-2.391-0.544l-2.662,0.787l0.182,1.906l2.27,2.633v3.601l-1.695,2.451l0.545,2.27
			l3.42,2.663l0.182,2.633l1.906,3.994l-0.394,4.54l2.088,2.058l-0.575,4.933l-0.182,2.451l0.938,1.694l-1.452,4.63l0.121,1.422
			l3.237,1.513l2.27,2.481l2.058-1.15l1.906-1.12l0.394,1.694h2.814l0.757-1.513l1.694,0.756l0.575,2.451l1.513-0.363l3.602-4.933
			l1.876,1.332l0.303,0.575l2.966-1.846l1.21,0.151l0.938,2.3l1.785-0.272l1.361-1.362h1.756l1.361-1.755l1.361-0.272l0.272-0.968
			l2.966,0.151l0.151-0.696l-1.361-1.211v-1.21l1.906-1.09v-0.817l-1.785-1.089l-0.272-2.027l0.151-1.907l-1.211-0.817l1.06-1.483
			l0.968-0.544l0.666-1.634l-0.938-0.544l-1.089-1.634l1.361-1.907l2.3-1.361h2.845v-2.148l0.817-0.272v-0.817l-1.211-1.906
			l-2.724-0.545l-0.938-1.634l0.394-1.483l0.272-2.058l-0.484-1.089l-3.026,2.058l-1.241,0.545l-1.634-1.09l0.151-2.723h-1.785
			l-1.482,0.968l-0.394-1.241l0.816-1.362l-0.968-1.21l-1.09,1.634l0.151,1.21l-2.572-0.121l-4.085-3.813l-3.117-0.121v-2.058
			l-2.179-1.362l-0.424-1.755l-0.665-0.121v3.39l-1.211,0.272l-1.785-0.545l-1.876,1.21l-1.09,0.272l-1.089-0.938l-1.09,0.544
			l-2.451-1.634h-1.755l-1.241-0.817l0.272-1.513l-1.361-1.21H350.382z"/>
		
			<path class="zone-dept" id="path150_1_" inkscape:label="Meurthe-et-Moselle" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M442.225,85.759l-2.27,2.088l-3.208,0.182l-1.12,1.15h-0.241l-0.121,2.27l1.119,1.846l-0.394,1.12l-0.363,1.301l0.182,0.726
			l0.938-0.726l0.908-1.665l1.876-0.182l3.146-0.938l1.665,1.301l0.726,1.483l0.575,1.664v1.665l0.908,0.756v1.271l-0.908,1.12
			l-0.182,2.421l0.727,1.12l0.182,1.483l0.182,2.391l1.119,0.938l1.665,0.727l-0.727,1.482l2.027,1.876l-1.846,2.027l0.363,1.301
			l1.846,0.908v0.938h-2.209l-0.938,1.301l0.182,0.938l1.483,1.483l-1.271,3.51l-1.482,3.329l0.727,2.058v3.329l0.756,1.665h1.09
			l0.574,0.938h-1.664l-1.482,0.726v1.12l1.846,1.664v2.603l1.846-0.575l2.784,0.182l0.182,2.966l1.12,0.393l-1.302,0.908
			l-0.182,0.938l2.027,0.363l1.302,1.664l6.112-0.363l1.302-2.421h2.783l1.12-0.908l1.846,1.12l1.665-0.575l2.42,0.182l2.028-0.727
			l2.027-1.483l1.119,1.12l0.182-2.603l1.483-0.575l0.756,2.421l2.209,0.182l2.24,0.575l0.907,0.181l3.178-1.482l1.664-1.12
			l1.482-1.846l2.966-1.12l1.937-0.424l-1.089-1.059l2.088,0.182l0.394-0.393l-2.239-0.727l-3.147-2.209l-2.784-2.058h-3.328
			l-3.511-2.028l-2.784-0.181v-0.757l-4.267-2.572l-4.812-2.058h-2.421l-0.907-2.572l-3.723-4.66h-3.691l-1.483-2.027h-2.966
			l0.182-2.966l-3.903-2.421l0.182-2.391h2.058v-2.058l0.727-1.483l-1.664-1.664l1.482-2.572l-1.12-2.966l-0.907-0.756l-2.421-5.175
			l0.938-1.482c0,0-0.076-1.218-0.151-2.845h-2.541l-3.42-3.783H442.225z"/>
		
			<path class="zone-dept" id="path164_1_" inkscape:label="Territoire de Belfort" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M490.49,180.265l-1.755,0.908l-1.482,1.089v2.421l0.817,2.875v1.876l-0.454,3.026l-1.029,1.059l11.65,5.265l0.727-0.847
			l2.512-0.394l-0.938-4.024l-0.574-2.088l-2.663,0.394l-0.363-2.27l0.938-1.725l0.182-2.27l-0.544-1.301l-3.42-2.663l-3.026-0.182
			L490.49,180.265z"/>
		<path class="zone-dept" id="path187_1_" inkscape:label="Yonne" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M361.699,152.485l-1.694,1.301l-7.384-0.363l-3.389,1.695l-1.332,2.844l1.514,1.695l-2.27,2.663l-1.726,2.058l3.42,3.238
			l0.938,3.026l2.48,2.633v3.419l-4.932,4.146l1.694,1.907l-0.363,2.814l-2.663,1.907h-3.782l0.575,2.088l2.45,3.389l0.575,3.026
			l0.575,2.088l-1.12,0.333l2.391,0.544h1.786l0.938-1.483h1.211l1.361,1.21l-0.272,1.513l1.241,0.817h1.755l2.451,1.634l1.09-0.544
			l1.089,0.938l1.09-0.272l1.876-1.21l1.785,0.545l1.211-0.272v-3.39l0.665,0.121l0.424,1.755l2.179,1.362v2.058l3.117,0.121
			l4.085,3.813l2.572,0.121l-0.151-1.21l1.09-1.634l0.968,1.21l-0.816,1.362l0.394,1.241l1.482-0.968h1.785l-0.151,2.723l1.634,1.09
			l1.241-0.545l3.026-2.058l-0.182-0.394l-1.785-1.089l-0.121-1.907l1.906-0.938l0.816-1.089l-0.544-0.938v-2.179l1.482-2.179
			l2.179-4.63l0.394-2.027l1.361-0.544l0.151-0.394l-0.695-0.544v-1.634l2.179-1.634l0.544-2.724l-0.938-1.604h-1.514l-0.393-0.424
			v-2.3l1.906-1.362l-0.151-1.362l-0.272-1.361l-0.969,2.088l-1.331-0.182l-1.12-2.088l-3.782,1.906l-7.565-0.394l-0.938-2.088
			l-2.088-2.814l-0.363-3.419l-3.026-3.602l-1.906,1.332l-3.42-2.632l0.575-5.114l-4.902-5.114h-2.27L361.699,152.485z"/>
		
			<path class="zone-dept" id="path189_1_" inkscape:label="Loiret" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M318.396,155.511l-2.451,2.27l-5.538,0.484l-0.212,0.363l0.545,1.089l-1.755,1.483l0.394,3.541l-1.483,0.272l-0.968,3.51
			l-1.635,1.785h-2.3l-2.179,1.21l-1.21-1.362l-1.362-0.544l-1.482,0.968v1.483l-1.09,0.817l-1.755-1.089l-1.089,0.817l0.121,1.21
			l-0.515,1.12l0.394,0.121h1.604l-0.121,1.089l-1.089,1.876v0.968h1.089l0.938,1.089l0.273,1.089l-2.149,2.148l1.06,2.603v1.21
			l1.785,1.21l2.451,0.272l1.604,1.362l0.424,2.33l1.755,2.027l1.907-0.545l0.816-1.755l2.996,0.394l0.666,0.696h1.906l0.938-1.089
			l6.931,0.272l1.634,2.421l1.906,0.817l1.635,1.513l2.027-0.272l0.544-0.696h1.211l1.513,1.907l3.117,0.151l0.817,1.059
			l2.179,2.996l0.938,0.817l1.211-0.121l0.151-2.724l0.544-0.272l0.817,0.121l1.362,1.786l0.938,0.393l1.937-0.787l-0.302-0.333
			l-0.182-1.906l3.782-1.12l-0.575-2.088l-0.575-3.026l-2.45-3.389l-0.575-2.088h3.782l2.663-1.907l0.363-2.814l-1.694-1.907
			l4.932-4.146v-3.419l-2.48-2.633l-0.938-3.026l-3.42-3.238l-4.721,2.663l-0.362-1.513l-2.089-0.182l-0.574,1.513l-1.877,0.363
			l-5.113-0.182l-2.089,1.332l-1.694-1.513l3.026-2.088l-0.182-3.208l-2.27-1.15l-1.906-2.844l-5.084-0.363L318.396,155.511z"/>
		<path class="zone-dept" id="path193_1_" inkscape:label="Marne" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M380.038,96.29l-2.27,0.969l0.394,1.876h-3.995l-3.57,2.663v5.084l2.633,1.725l0.756,1.695l-4.357,0.363l-0.545,1.725l1.695,1.12
			l-0.757,1.15l-1.694,0.756l0.363,1.301h2.451l0.968,1.332l-1.694,1.15l-1.513,3.964l-2.845,1.332l-0.969,2.058l-0.938,1.15
			l0.212,1.12l-1.513,0.968l-0.394,2.633l1.514,0.938l0.756,2.845l-0.938,1.694l0.574,1.332l2.814-0.182v0.938l0.817-0.182
			l3.268,3.45l4.601-0.756l5.386-3.662h3.238l3.269-2.3l3.843-2.118l2.875,0.212l0.394,3.843l3.449,5.174h3.843l5.387-1.149
			l3.844,1.331l4.024-2.875l0.575-4.781l4.448-0.756l-0.091-2.996l-3.602-2.845l-0.393-1.513l1.331-2.27l-1.149-0.938l1.149-2.844
			l2.088-0.938l1.513-4.721l-3.025,0.181l1.694-1.876l-1.331-4.176l-1.302-2.844l1.694-1.513l-0.968-0.182l-0.242-1.301
			c-0.039,0.006-0.272,0.03-0.272,0.03l-1.725-1.543l-1.937,1.937h-0.575l-0.757-0.968l-4.6-0.182l-0.787,1.15h-1.331l-1.15-2.512
			h-2.512l-0.574,0.575l-2.481-0.182l-3.087-2.3l-2.118-0.575l-0.757-1.15l-4.024-2.512l-4.6-0.121l0.061,1.725l-1.331,0.363
			L380.038,96.29z"/>
		
			<path class="zone-dept" id="path195_1_" inkscape:label="Ardennes" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M409.33,54.197l-1.906,2.814l-1.694,1.725v1.694v2.27l-2.27,1.513l-4.146,1.332l-2.27,0.938l-2.663-2.088h-3.602l-0.515,3.359
			l1.695,3.208l-1.695,1.513l-0.181,2.845l0.938,1.513l-0.757,2.27l-2.844,1.513v2.451l-3.602,0.756l-0.574,1.513l2.48,1.331
			l-0.756,2.633l-0.575,2.27l0.121,4.721l4.6,0.121l4.024,2.512l0.757,1.15l2.118,0.575l3.087,2.3l2.481,0.182l0.574-0.575h2.512
			l1.15,2.512h1.331l0.787-1.15l4.6,0.182l0.757,0.968h0.575l1.937-1.937l1.725,1.543c0,0,0.233-0.024,0.272-0.03l-0.121-0.787
			l2.27-1.12l1.12-1.15l-0.727-1.876l-0.212-1.332l2.088-1.695l0.757-3.783l-2.27-2.844l0.756-1.332l1.907-3.571l0.544,0.756h2.845
			l1.332,1.301l1.694-1.12l1.331-2.179l-1.361-0.212L429,79.919l-1.513-1.15l-5.296-0.575l-0.938-2.451l-1.726-1.12l-6.052-0.756
			l-0.363-4.357l0.757-0.756v-1.695l-3.026-1.907l0.575-2.088l0.756-1.876l-1.331-1.15l2.088-1.876v-3.419l-0.757-0.544H409.33z"/>
		
			<path class="zone-dept" id="path197_1_" inkscape:label="Haute-Marne" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M420.437,135.418l-4.448,0.756l-0.575,4.781l-4.024,2.875l-2.088-0.726l-0.999,2.088l-0.182,2.875l3.45,4.812l3.268,1.15
			l0.757,4.024l0.212,6.9l-1.15,1.725l-3.268,0.212l-2.118,1.907l1.543,0.968l0.636,2.421l2.27,1.271l3.389,3.601l4.357,5.871
			l-2.451,2.632l1.695,1.15l0.182,3.208l3.025-0.182l0.757,1.513l2.27,0.181l1.694-0.938l3.026,3.783l0.394,0.938l4.146-0.938
			l0.787-1.906l0.182,0.03v-1.907l2.633-1.15l3.026,1.15l2.844-1.15h1.695l0.574-3.783l0.938-1.12l-1.694-0.182l-0.182-2.27
			l2.633-0.575l0.182-1.513h2.662v-2.451l2.088-0.756l-0.574-1.513l0.574-0.363l-1.725-1.361l-2.058,0.756v-3.964l-5.296-2.633
			l1.12-5.114l1.694-1.15l-0.545-1.695l-2.481-0.363l-0.544-2.481h-2.27l-2.663-3.571l-3.026-0.211l-1.301-1.876l1.694-1.694
			l-3.995-4.358l-1.694-0.575l-4.539-2.27l-2.451-2.633l-3.964-0.575L420.437,135.418z"/>
		
			<path class="zone-dept" id="path199_1_" inkscape:label="Bas-Rhin" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M518.846,108.849l-3.602,0.999l-1.664,2.905v2.845l-1.514,1.332h-1.331l-2.451-1.725l-1.906,1.332h-2.27l-1.876-1.876
			l-3.602-0.575l-2.088-0.938l-0.757-2.844l-1.694,1.876l-0.938,4.357l-2.481,0.757v2.451l2.481,1.15l1.876,1.331l-0.756,1.695
			l1.725,1.12l3.026-2.27l5.266,3.026l-2.239,4.176l0.182,1.332l1.513,1.513l-1.15,3.964l-3.782,3.783l-2.088-0.182l1.331,1.301
			l-0.756,3.419l0.756,5.114l3.602,0.938l-0.303,0.696l2.845-0.151l1.664,2.027l1.482,1.846l3.723-0.181l1.664,4.812l2.905,1.271
			l-0.03-0.605l4.933-9.653l-0.575-5.477l2.27-7.384l0.575-6.446l4.902-3.571v-2.27l1.906-2.481h1.513l1.695-1.694l-0.363-3.208
			l1.694-4.54l2.633-0.575l-2.633-2.088l-4.721-0.545l-4.176-2.088l-2.845,1.694l-1.513-1.694H518.846z"/>
		
			<path class="zone-dept" id="path201_1_" inkscape:label="Haut-Rhin" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M504.199,151.698l-2.845,0.151l-1.785,4.024l-2.27,4.539l0.574,2.845l-1.876,4.357l-3.238,2.814l-0.181,7.384l-2.36,2.027
			l0.09,0.061l0.757,1.513l3.026,0.182l3.42,2.663l0.544,1.301l-0.182,2.27l-0.938,1.725l0.363,2.27l2.663-0.394l0.574,2.088
			l0.938,4.024l2.239-0.363l-0.394,2.058l1.331,1.15l6.991-0.182l3.601-2.844l0.182-4.176l1.906-2.451l-2.481-2.845l-1.301-3.026
			l1.513-2.058v-4.751l0.938-2.27v-3.783l1.694-2.451l-1.876-2.633l-0.182-5.447l-2.905-1.271l-1.664-4.812l-3.723,0.181
			l-1.482-1.846L504.199,151.698z"/>
		
			<path class="zone-dept" id="path203_1_" inkscape:label="Moselle" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M463.044,87.666l-2.845,0.181l-2.27,1.907l-0.575,0.938h-3.207l-1.12-1.15h-0.484c0.075,1.627,0.151,2.845,0.151,2.845
			l-0.938,1.482l2.421,5.175l0.907,0.756l1.12,2.966l-1.482,2.572l1.664,1.664l-0.727,1.483v2.058h-2.058l-0.182,2.391l3.903,2.421
			l-0.182,2.966h2.966l1.483,2.027h3.691l3.723,4.66l0.907,2.572h2.421l4.812,2.058l4.267,2.572v0.757l2.784,0.181l3.511,2.028
			h3.328l2.784,2.058l3.147,2.209l2.239,0.727l3.389-3.39l1.15-3.964l-1.513-1.513l-0.182-1.332l2.239-4.176l-5.266-3.026
			l-3.026,2.27l-1.725-1.12l0.756-1.695l-1.876-1.331l-2.481-1.15v-2.451l2.481-0.757l0.938-4.357l1.694-1.876l0.757,2.844
			l2.088,0.938l3.602,0.575l1.876,1.876h2.27l1.906-1.332l2.451,1.725h1.331l1.514-1.332v-2.845l1.664-2.905l-0.394,0.121
			l-1.301-1.876l-3.783-2.27l-1.331-2.088l-4.539,0.394l-2.663,2.451l-6.415,0.182l-1.907-1.332
			c-0.127-0.232-1.058-1.875-1.876-2.33c-0.031-0.017-0.084-0.044-0.121-0.061c-0.027-0.011-0.067-0.024-0.091-0.03
			c-0.01-0.005-0.05-0.025-0.061-0.03c-0.003,0-0.026,0-0.03,0c-0.023-0.001-0.071,0-0.091,0c-0.886,0-2.603-0.995-2.813-1.12
			l-2.663,1.12l-0.182,2.27l-3.208,0.393l-1.906-3.601l-1.12-0.394v-2.633l-2.663-1.15l-0.181-4.539l-1.907-1.876l-3.964-1.906
			h-1.876l-0.575,0.394h-1.906L463.044,87.666z"/>
		
			<path class="zone-dept" id="path205_1_" inkscape:label="Vosges" fill="#0000FF" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M497.813,141.439l-1.937,0.424l-2.966,1.12l-1.482,1.846l-1.664,1.12l-3.178,1.482l-0.907-0.181l-2.24-0.575l-2.209-0.182
			l-0.756-2.421l-1.483,0.575l-0.182,2.603l-1.119-1.12l-2.027,1.483l-2.028,0.727l-2.42-0.182l-1.665,0.575l-1.846-1.12
			l-1.12,0.908h-2.783l-1.302,2.421l-6.112,0.363l-1.302-1.664l-2.027-0.363l0.182-0.938l1.302-0.908l-1.12-0.393l-0.182-2.966
			l-2.784-0.182l-1.634,0.515l-1.15,1.543l-2.965,0.908v-1.089h-0.908v1.271l-3.722,0.756v1.665l-3.329,0.423l-1.271,1.271
			l1.301,1.876l3.026,0.211l2.663,3.571h2.27l0.544,2.481l2.481,0.363l0.545,1.695l-1.694,1.15l-1.12,5.114l5.296,2.633v3.964
			l2.058-0.756l1.725,1.361l1.302-0.787l-0.727-1.12l0.938-0.575l1.301,1.513l1.907-1.332l0.574-1.876l3.571-0.575l0.968,0.757
			l-0.181,2.088l2.451,1.876l1.513-0.182l1.694-1.331h3.964l2.845,3.208h0.938l1.513-0.938l0.212-1.12l1.513-0.757l1.695,1.12
			l1.694,1.907l5.962,3.147l2.36-2.027l0.181-7.384l3.238-2.814l1.876-4.357l-0.574-2.845l2.27-4.539l2.088-4.721l-3.602-0.938
			l-0.756-5.114l0.756-3.419L497.813,141.439z"/>
		<g class="zone-label" id="label_H1b" transform="translate(310.65758,-297.9344)">
			
				<path class="zone-cartouche" id="cartouche_H1b" sodipodi:ry="21.296297" sodipodi:rx="21.296297" sodipodi:cy="270.37036" sodipodi:cx="75" sodipodi:type="arc" fill="#FFFFFF" fill-opacity="0.502" stroke="#000000" d="
				M149.487,430c0,10.763-10.963,19.487-24.487,19.487c-13.523,0-24.487-8.725-24.487-19.487c0-10.762,10.964-19.487,24.487-19.487
				C138.524,410.513,149.487,419.238,149.487,430z"/>
			<text transform="matrix(1 0 0 1 106.2584 437.0652)" font-family="'Arial'" font-size="20">H1b</text>
		</g>
		<path class="zone-area" id="area_h1b" fill="none" stroke="#FF00FF" stroke-miterlimit="10" d="M536.79,113.569l-2.633-2.088l-4.721-0.545
			l-4.176-2.088l-2.845,1.694l-1.513-1.694h-2.058l-3.602,0.999l-0.394,0.121l-1.301-1.876l-3.783-2.27l-1.331-2.088l-4.539,0.394
			l-2.663,2.451l-6.415,0.182l-1.907-1.332c-0.127-0.232-1.058-1.875-1.876-2.33c-0.031-0.017-0.084-0.044-0.121-0.061
			c-0.027-0.011-0.067-0.024-0.091-0.03c-0.01-0.005-0.05-0.025-0.061-0.03c-0.003,0-0.026,0-0.03,0c-0.023-0.001-0.071,0-0.091,0
			c-0.886,0-2.603-0.995-2.813-1.12l-2.663,1.12l-0.182,2.27l-3.208,0.393l-1.906-3.601l-1.12-0.394v-2.633l-2.663-1.15
			l-0.181-4.539l-1.907-1.876l-3.964-1.906h-1.876l-0.575,0.394h-1.906l-2.633-2.27l-2.845,0.181l-2.27,1.907l-0.575,0.938h-3.207
			l-1.12-1.15h-0.484h-2.541l-3.42-3.783h-4.357l-2.27,2.088l-3.208,0.182l-1.12,1.15h-0.241h-0.908l-0.757-3.601l-1.513-1.513
			l-1.09-0.151l-1.361-0.212L429,79.919l-1.513-1.15l-5.296-0.575l-0.938-2.451l-1.726-1.12l-6.052-0.756l-0.363-4.357l0.757-0.756
			v-1.695l-3.026-1.907l0.575-2.088l0.756-1.876l-1.331-1.15l2.088-1.876v-3.419l-0.757-0.544h-2.845l-1.906,2.814l-1.694,1.725
			v1.694v2.27l-2.27,1.513l-4.146,1.332l-2.27,0.938l-2.663-2.088h-3.602l-0.515,3.359l1.695,3.208l-1.695,1.513l-0.181,2.845
			l0.938,1.513l-0.757,2.27l-2.844,1.513v2.451l-3.602,0.756l-0.574,1.513l2.48,1.331l-0.756,2.633l-0.575,2.27l0.121,4.721
			l0.061,1.725l-1.331,0.363l-3.208-2.088l-2.27,0.969l0.394,1.876h-3.995l-3.57,2.663v5.084l2.633,1.725l0.756,1.695l-4.357,0.363
			l-0.545,1.725l1.695,1.12l-0.757,1.15l-1.694,0.756l0.363,1.301h2.451l0.968,1.332l-1.694,1.15l-1.513,3.964l-2.845,1.332
			l-0.969,2.058l-0.938,1.15l0.212,1.12l-1.513,0.968l-0.394,2.633l1.514,0.938l0.756,2.845l-0.938,1.694l0.574,1.332l2.814-0.182
			v0.938v0.575l-2.633,1.12l-0.182,2.27l-1.331,1.725l-0.757,3.783l-0.42,3.156l-0.155-0.13l-1.694,1.301l-7.384-0.363l-3.389,1.695
			l-1.332,2.844l1.514,1.695l-2.27,2.663l-1.726,2.058l-4.721,2.663l-0.362-1.513l-2.089-0.182l-0.574,1.513l-1.877,0.363
			l-5.113-0.182l-2.089,1.332l-1.694-1.513l3.026-2.088l-0.182-3.208l-2.27-1.15l-1.906-2.844l-5.084-0.363l-2.088-1.694
			l-2.451,2.27l-5.538,0.484l-0.212,0.363l0.545,1.089l-1.755,1.483l0.394,3.541l-1.483,0.272l-0.968,3.51l-1.635,1.785h-2.3
			l-2.179,1.21l-1.21-1.362l-1.362-0.544l-1.482,0.968v1.483l-1.09,0.817l-1.755-1.089l-1.089,0.817l0.121,1.21l-0.515,1.12
			l0.394,0.121h1.604l-0.121,1.089l-1.089,1.876v0.968h1.089l0.938,1.089l0.273,1.089l-2.149,2.148l1.06,2.603v1.21l1.785,1.21
			l2.451,0.272l1.604,1.362l0.424,2.33l1.755,2.027l1.907-0.545l0.816-1.755l2.996,0.394l0.666,0.696h1.906l0.938-1.089l6.931,0.272
			l1.634,2.421l1.906,0.817l1.635,1.513l2.027-0.272l0.544-0.696h1.211l1.513,1.907l3.117,0.151l0.817,1.059l2.179,2.996
			l0.938,0.817l1.211-0.121l0.151-2.724l0.544-0.272l0.817,0.121l1.362,1.786l0.938,0.393l1.926-0.782l1.979,2.295v3.601
			l-1.695,2.451l0.545,2.27l3.42,2.663l0.182,2.633l1.906,3.994l-0.394,4.54l2.088,2.058l-0.575,4.933l-0.182,2.451l0.938,1.694
			l-1.452,4.63l0.121,1.422l3.237,1.513l2.27,2.481l2.058-1.15l1.906-1.12l0.394,1.694h2.814l0.757-1.513l1.694,0.756l0.575,2.451
			l1.513-0.363l3.602-4.933l1.876,1.332l0.303,0.575l2.966-1.846l1.21,0.151l0.938,2.3l1.785-0.272l1.361-1.362h1.756l1.361-1.755
			l1.361-0.272l0.272-0.968l2.966,0.151l0.151-0.696l-1.361-1.211v-1.21l1.906-1.09v-0.817l-1.785-1.089l-0.272-2.027l0.151-1.907
			l-1.211-0.817l1.06-1.483l0.968-0.544l0.666-1.634l-0.938-0.544l-1.089-1.634l1.361-1.907l2.3-1.361h2.845v-2.148l0.817-0.272
			v-0.817l-1.211-1.906l-2.724-0.545l-0.938-1.634l0.394-1.483l0.272-2.058l-0.484-1.089l-0.182-0.394l-1.785-1.089l-0.121-1.907
			l1.906-0.938l0.816-1.089l-0.544-0.938v-2.179l1.482-2.179l2.179-4.63l0.394-2.027l1.361-0.544l0.151-0.394l-0.695-0.544v-1.634
			l2.179-1.634l0.544-2.724l-0.938-1.604h-1.514l-0.393-0.424v-2.3l1.906-1.362l-0.151-1.362l-0.272-1.361l6.234-0.182l2.45-1.332
			l0.394-2.451h5.478l0.757,0.424l2.27,1.271l3.389,3.601l4.357,5.871l-2.451,2.632l1.695,1.15l0.182,3.208l3.025-0.182l0.757,1.513
			l2.27,0.181l1.694-0.938l3.026,3.783l0.394,0.938l4.146-0.938l0.787-1.906l0.182,0.03l2.058,0.363l1.513,3.026l0.212,1.513
			l-2.451,2.844l-1.149,0.363l-0.757,0.938l2.088,0.968l0.363,3.026l1.694,0.181l0.212,3.783l0.757,0.757l0.091,0.605l1.452,0.394
			l1.635,1.362h2.451l0.938-0.938l1.665,0.061l1.725,0.06l3.813-3.117h1.09l1.21-0.938l3.935,0.121l2.995-2.451l2.179-0.394
			l0.817-2.027l1.755-0.545l1.756-2.996l2.451-1.907l2.571-0.393l1.907,1.361l3.54-0.423v-1.907l1.423-0.817l1.119-1.543h2.179
			l0.195-0.205l11.637,5.259l0.727-0.847l2.512-0.394l2.239-0.363l-0.394,2.058l1.331,1.15l6.991-0.182l3.601-2.844l0.182-4.176
			l1.906-2.451l-2.481-2.845l-1.301-3.026l1.513-2.058v-4.751l0.938-2.27v-3.783l1.694-2.451l-1.876-2.633l-0.182-5.447l-0.03-0.605
			l4.933-9.653l-0.575-5.477l2.27-7.384l0.575-6.446l4.902-3.571v-2.27l1.906-2.481h1.513l1.695-1.694l-0.363-3.208l1.694-4.54
			L536.79,113.569z"/>
	</g>
	<g id="H1a" @click="setZoneClimat('H1a')" :class="{canNotSelect: !isCliquable, canSelect: isCliquable ,active: theValue === 'H1a'}" class="zone-climat">
		
			<path class="zone-dept" id="path26_1_" inkscape:label="Calvados" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M249.583,94.808l-4.418,0.787l-7.202,4.176l-8.11,3.208l-6.446-3.601l-15.494-2.27l-3.601-1.876l-5.598,1.422l0.333,2.088
			l-1.089,1.089l0.151,1.543l3.601,3.601l2.179,1.241l1.573-2.027l1.241,1.573l-1.573,2.028l1.422,2.33l-0.635,0.787l1.089,2.209
			l-0.605,2.481l-1.574,2.512l-2.512,1.543l-1.392-1.089l-1.392,1.241l1.543,1.422l-1.241,2.331l-2.965,1.089l3.601,4.54
			l3.571,0.302l2.753,1.786l3.813-1.15l2.814-3.268l3.903,1.089l3.419-2.36l2.058-0.757l2.179,2.179l3.601-0.635l3.117,1.725
			l3.904-1.241l3.571-2.663l2.36-2.663l1.574-0.303l0.454,2.028l1.241-0.303l0.151-1.422l3.601-0.605l1.241,0.756l3.843-0.847
			l0.635-1.846l-0.182-1.694l-1.906-0.757l-0.182-1.332l1.695-1.12l0.212-1.906l-1.15-4.539l-2.27-3.208l1.907-1.12v-0.756
			l-1.907-0.575L249.583,94.808z"/>
		
			<path class="zone-dept" id="path128_1_" inkscape:label="Seine-Maritime" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M287.621,59.674l-1.241,1.513l-8.11,6.234l-14.374,3.601l-9.472,3.389l-7.747,4.176l-4.539,6.809l-0.938,5.295l3.783,2.845
			l5.477,1.12l-0.877,0.151l0.03,0.151l4.237-0.545l2.179-2.33l1.634-0.394l1.876,3.269l2.603-0.272l1.089,1.876l4.6-0.272
			l4.63,3.269l-2.996,0.938l2.179,1.634h1.362l1.21,2.603h2.179l0.666-1.634l-1.634-1.089l4.63-1.362l4.872-0.545l1.24-3.541
			l2.3-2.027l4.357-0.121l5.023,2.572l2.996,0.333l0.515-1.574l1.331-2.481l0.757-1.301h-1.906v-3.238l-1.12-1.876l0.757-3.782
			l0.757-1.907h-1.514l0.757-1.876l1.876-2.27l-1.876-3.419l-0.575-3.389l-8.322-8.14l-0.938-1.876l-2.088,0.181L287.621,59.674z"/>
		<path class="zone-dept" id="path130_1_" inkscape:label="Eure" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M257.662,91.69l-1.634,0.394l-2.179,2.33l-4.237,0.545l0.877,7.021l1.907,0.575v0.756l-1.907,1.12l2.27,3.208l1.15,4.539
			l-0.212,1.906l-1.695,1.12l0.182,1.332l1.906,0.757l0.182,1.694l-1.513,4.358l1.513,1.906l5.296,0.182l1.12,2.633l1.906,1.906
			l2.633,0.938l0.787,2.845l-1.331,1.876l2.753,1.846l2.724-2.603h2.633l6.839-3.571l2.239,0.938h4.751l1.12-1.332v-2.844
			l3.783-1.876v-3.026l1.029-0.848l-0.091-0.847l0.968-0.968l-1.694-0.363v-1.513l-0.968-1.513l0.756-0.938l5.296-1.513l1.331-2.27
			l1.15-4.176l1.392-1.725l0.303-2.239l1.694,0.938l1.332-0.363l-0.969-1.513l-0.545-4.357l-1.694-1.513l0.03-0.121l-2.996-0.333
			l-5.023-2.572l-4.357,0.121l-2.3,2.027l-1.24,3.541l-4.872,0.545l-4.63,1.362l1.634,1.089l-0.666,1.634h-2.179l-1.21-2.603h-1.362
			l-2.179-1.634l2.996-0.938l-4.63-3.269l-4.6,0.272l-1.089-1.876l-2.603,0.272L257.662,91.69z"/>
		<path class="zone-dept" id="path140_1_" inkscape:label="Oise" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M302.418,77.801l-1.24,1.483l-0.757,1.876h1.514l-0.757,1.907l-0.757,3.782l1.12,1.876v3.238h1.906l-0.757,1.301l-1.331,2.481
			l-0.545,1.694l1.694,1.513l0.545,4.357l0.969,1.513l-1.332,0.363l-1.694-0.938l-0.303,2.239l0.121-0.151l0.727,1.694l1.149,1.876
			l5.114,0.394l3.602-0.394l2.451-1.876l3.025,1.876l1.514,1.15l2.27-0.575l2.058-0.938l3.994,2.088l4.146,2.451l1.332,1.332
			l2.27-1.513l1.876,1.12l1.15,0.938l1.694-0.182l1.15-1.513l2.632,1.513l3.238-1.332l1.876,0.575l1.877-1.513l1.149-0.575
			l0.363,0.272l0.394-2.572l-1.362-1.543l-2.3-1.543l-0.968,1.543l-0.575,0.181l-0.182-2.875l1.725-0.394l-0.394-2.663l-2.3-0.393
			l1.15-1.937l3.268-0.756l1.15-4.6l1.725-0.787l-2.3-1.725l0.787-1.725l0.363-5.75l-0.787-4.448l-3.994,0.394l-2.693-0.363
			l-4.993,1.332l-4.236,4.024l-3.45-1.149l-3.449-0.363l-2.693-2.693l-4.812-1.362l-6.506,0.575l-1.726-1.332h-3.479l-2.481,0.968
			l-1.15-0.787v-2.088l-0.394-0.575H302.418z"/>
		
			<path class="zone-dept" id="path142_1_" inkscape:label="Pas-de-Calais" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M314.068,8.503l-10.47,1.997l-8.321,6.416v25.541l-0.061,0.756l2.935,0.696l0.938,2.088l2.27-0.575l1.331-1.695l1.695,0.575
			l3.601,2.814l1.332-0.545l0.938,2.27l3.39,1.513v1.876l2.481,0.938l2.451-0.938l4.721-0.575l1.149,0.968l2.27-0.968l1.12,1.906
			l-2.814,1.876v2.663l0.938,0.938l0.757-0.182l0.545-1.513l1.725-1.15l1.695,1.332l3.964,1.332h1.694v-1.907l2.481,1.725
			l0.182,1.513l-1.149,1.694l2.088-1.15l1.725-0.756l0.727,1.332v1.332l2.844-1.513h4.54l0.182,0.182l1.119-2.148l-0.575-0.969
			l-1.725-0.363h-1.906l-1.15-0.394l1.906-1.15l1.726,0.182l1.725-0.182l0.212-3.087l1.149-0.756l0.182-1.725l-2.118-1.362
			l-2.481-0.182l-0.574-0.394l1.543-1.15l0.363-1.15l-1.332-0.938l-1.937-2.33l0.212-1.15l2.481-1.15l0.394-1.332l-1.725-0.787
			l-0.969-2.481l-3.45-0.394l-3.449-0.938l-0.394-3.843l2.481-1.543l-0.938-1.937h-1.937l-1.331,2.118l-5.962-0.363l-4.812-1.18
			l-2.663-2.875v-2.3l2.089-0.968l-1.726-1.332l-4.206-0.182l-2.512-6.355L314.068,8.503z"/>
		<path class="zone-dept" id="path144_1_" inkscape:label="Nord" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M330.077,4.055l-6.053,2.844l-9.441,1.513l-0.515,0.091l3.602,5.719l2.512,6.355l4.206,0.182l1.726,1.332l-2.089,0.968v2.3
			l2.663,2.875l4.812,1.18l5.962,0.363l1.331-2.118h1.937l0.938,1.937l-2.481,1.543l0.394,3.843l3.449,0.938l3.45,0.394l0.969,2.481
			l1.725,0.787l-0.394,1.332l-2.481,1.15l-0.212,1.15l1.937,2.33l1.332,0.938l-0.363,1.15l-1.543,1.15l0.574,0.394l2.481,0.182
			l2.118,1.362l-0.182,1.725l-1.149,0.756l-0.212,3.087l-1.725,0.182l-1.726-0.182l-1.906,1.15l1.15,0.394h1.906l1.725,0.363
			l0.575,0.969l-1.119,2.148l1.513,1.513l1.513,0.394l1.514-0.968h2.088l0.574,1.15l0.757-0.182l2.27-1.332l2.27,1.332l3.026-2.088
			h1.331l1.514,1.332l3.026-2.088l1.301,0.182l1.149,0.938l4.177,0.393l0.363,1.695l2.088-1.876h1.119l0.757,2.451l3.601,0.938
			l1.029-0.696h-0.303l-0.182-1.876l3.783-2.27l-0.575-3.601l-3.602-0.938l0.938-0.968v-2.632l2.845-2.088l-0.757-1.513
			l-6.052-4.721l-10.592,0.575l-1.12,1.876h-1.331l0.182-6.627l-3.026-3.571l-2.27,0.363l-1.331-1.513l-3.783,1.694l-1.301-1.301
			l-2.663-0.394l-0.757-2.451l-0.182-7.565l-1.694-0.756l-0.212-1.15h-1.119l-0.394-2.27l-2.451,0.212l-4.721,1.513L342,25.237
			h-2.27l-1.514-1.876l-0.574-2.088l-1.877-2.088h-2.662l-1.12-2.058V13.89l1.302-2.058l-0.757-2.844L330.077,4.055z"/>
		<path class="zone-dept" id="path146_1_" inkscape:label="Aisne" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M371.354,60.37l-3.026,2.088l-1.514-1.332h-1.331l-3.026,2.088l-2.27-1.332l-2.27,1.332l-0.757,0.182l-0.574-1.15h-2.088
			l-1.514,0.968h-0.061l0.454,2.663l-2.481,2.693v2.481l-1.725,1.937l0.394,2.3l0.938,3.843l1.15,6.536l-0.363,5.75l-0.787,1.725
			l2.3,1.725l-1.725,0.787l-1.15,4.6l-3.268,0.756l-1.15,1.937l2.3,0.393l0.394,2.663l-1.725,0.394l0.182,2.875l0.575-0.181
			l0.968-1.543l2.3,1.543l1.362,1.543l-0.394,2.572l1.725,1.241l0.575,4.176l5.084,4.902l1.694,0.575l0.969,2.27l3.177,0.666
			l0.394-0.484l0.969-2.058l2.845-1.332l1.513-3.964l1.694-1.15l-0.968-1.332h-2.451l-0.363-1.301l1.694-0.756l0.757-1.15
			l-1.695-1.12l0.545-1.725l4.357-0.363l-0.756-1.695l-2.633-1.725v-5.084l3.57-2.663h3.995l-0.394-1.876l2.27-0.969l3.208,2.088
			l1.331-0.363l-0.182-6.446l0.575-2.27l0.756-2.633l-2.48-1.331l0.574-1.513l3.602-0.756v-2.451l2.844-1.513l0.757-2.27
			l-0.938-1.513l0.181-2.845l1.695-1.513l-1.695-3.208l0.515-3.359h-3.843l-1.029,0.696l-3.601-0.938l-0.757-2.451h-1.119
			l-2.088,1.876l-0.363-1.695l-4.177-0.393l-1.149-0.938L371.354,60.37z"/>
		<path class="zone-dept" id="path148_1_" inkscape:label="Somme" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M295.217,43.212l-0.516,6.234l4.177,3.782v1.907l-5.114-3.026l-6.143,7.565l1.634,0.696l2.088-0.181l0.938,1.876l8.322,8.14
			l0.575,3.389l1.876,3.419l-0.636,0.787h2.391l0.394,0.575v2.088l1.15,0.787l2.481-0.968h3.479l1.726,1.332l6.506-0.575
			l4.812,1.362l2.693,2.693l3.449,0.363l3.45,1.149l4.236-4.024l4.993-1.332l2.693,0.363l3.994-0.394l-0.363-2.088l-0.938-3.843
			l-0.394-2.3l1.725-1.937v-2.481l2.481-2.693l-0.454-2.663l-1.452-0.394l-1.694-1.694h-4.54l-2.844,1.513v-1.332l-0.727-1.332
			l-1.725,0.756l-2.088,1.15l1.149-1.694l-0.182-1.513l-2.481-1.725v1.907h-1.694l-3.964-1.332l-1.695-1.332l-1.725,1.15
			l-0.545,1.513l-0.757,0.182l-0.938-0.938v-2.663l2.814-1.876l-1.12-1.906l-2.27,0.968l-1.149-0.968l-4.721,0.575l-2.451,0.938
			l-2.481-0.938v-1.876l-3.39-1.513l-0.938-2.27l-1.332,0.545l-3.601-2.814l-1.695-0.575l-1.331,1.695l-2.27,0.575l-0.938-2.088
			L295.217,43.212z"/>
		
			<path class="zone-dept" id="path152_1_" inkscape:label="Seine-et-Marne" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M351.108,112.934l-1.149,0.575l-1.877,1.513l-1.876-0.575l-3.238,1.332l-2.632-1.513l-1.15,1.513l-1.694,0.182l-1.15-0.938
			l-1.876-1.12l-2.27,1.513l-0.091-0.091l-0.817,5.235l1.12,6.657v4.449l-1.483,3.722l0.363,2.572l-1.664,1.301l0.908,5.023
			l-0.727,1.089l-0.545,5.023l1.271,1.665l-4.237,2.784v3.813l1.181,1.755l2.27,1.15l0.182,3.208l-3.026,2.088l1.694,1.513
			l2.089-1.332l5.113,0.182l1.877-0.363l0.574-1.513l2.089,0.182l0.362,1.513l4.721-2.663l1.726-2.058l2.27-2.663l-1.514-1.695
			l1.332-2.844l3.389-1.695l7.384,0.363l1.694-1.301l0.152,0.151l0.423-3.177l0.757-3.783l1.331-1.725l0.182-2.27l2.633-1.12v-1.513
			l-2.814,0.182l-0.574-1.332l0.938-1.694l-0.756-2.845l-1.514-0.938l0.394-2.633l1.513-0.968l-0.212-1.12l0.545-0.666l-3.177-0.666
			l-0.969-2.27l-1.694-0.575l-5.084-4.902l-0.575-4.176L351.108,112.934z"/>
		
			<path class="zone-dept" id="path154_1_" inkscape:label="Essonne" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M318.881,132.21l-1.695,0.757l-1.846,0.726l-0.363,2.058l-2.784,1.301l-0.362,2.027l1.301,2.239l-1.876,2.572h-2.754l1.089,1.665
			l-1.301,1.483l-0.454,2.996l0.908,0.182l0.363,2.451l0.393,0.575l0.394,5.084l6.053-0.544l2.451-2.27l2.088,1.694l5.084,0.363
			l0.726,1.089v-3.813l4.237-2.784l-1.271-1.665l0.545-5.023l0.727-1.089l-0.908-5.023l1.664-1.301l-0.333-2.36l-2.088-0.968h-3.51
			l-2.027-1.12l-1.483,0.756L318.881,132.21z"/>
		<path class="zone-dept" id="path183_1_" inkscape:label="Orne" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M253.638,120.832l-3.843,0.847l-1.241-0.756l-3.601,0.605l-0.151,1.422l-1.241,0.303l-0.454-2.028l-1.574,0.303l-2.36,2.663
			l-3.571,2.663l-3.904,1.241l-3.117-1.725l-3.601,0.635l-2.179-2.179l-2.058,0.757l-3.419,2.36l-3.903-1.089l-2.814,3.268
			l-3.813,1.15l2.088,1.362l1.876,1.876l-0.454,1.846l0.303,1.573l-1.089,3.601l-3.238,2.754l1.271,2.148l2.088,1.513l2.844-1.907
			l1.876,1.332l5.114-3.026l5.114,0.756l2.814-1.15l0.787-1.876l1.876-0.182l1.694,1.513l0.757,4.146l2.451,0.938l0.968,3.42
			l3.208,0.182l5.114-4.721l5.114-0.182l1.513,2.058l0.938,6.809l3.208,1.15l1.906,2.845h3.964l0.182,1.301l0.181-1.876h0.757
			l2.663,3.964l2.058,0.333v-4.479l-1.301-1.725l-0.394-1.513l2.845-1.694l2.844-0.575l1.876-2.27l-0.363-6.99l-3.994-3.389
			l-0.182-3.238l-3.389-2.27l1.331-1.876l-0.787-2.845l-2.633-0.938l-1.906-1.906l-1.12-2.633l-5.296-0.182l-1.513-1.906
			L253.638,120.832z"/>
		
			<path class="zone-dept" id="path185_1_" inkscape:label="Eure-et-Loir" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M292.674,122.224l-1.15,0.938v3.026l-3.783,1.876v2.844l-1.12,1.332h-4.751l-2.239-0.938l-6.839,3.571h-2.633l-2.724,2.603
			l0.636,0.423l0.182,3.238l3.994,3.389l0.363,6.99l-1.876,2.27l-2.844,0.575l-2.845,1.694l0.394,1.513l1.301,1.725v4.479
			l0.393,0.061l2.633,1.876l-0.968,1.21l1.876,0.938l2.996-0.544h1.755l-0.121,0.817l-1.634,0.938l1.089,0.817h2.724l0.938,2.179
			l1.634,0.938l1.21,2.724l4.206,1.089l2.603-0.272l2.3-2.179l2.058,0.575l0.515-1.12l-0.121-1.21l1.089-0.817l1.755,1.089
			l1.09-0.817v-1.483l1.482-0.968l1.362,0.544l1.21,1.362l2.179-1.21h2.3l1.635-1.785l0.968-3.51l1.483-0.272l-0.394-3.541
			l1.755-1.483l-0.545-1.089l0.212-0.363l-0.515,0.061l-0.394-5.084l-0.393-0.575l-0.363-2.451l-3.965-0.756l-1.725-2.088
			l-0.545-4.176l-2.27-0.363l-0.394-2.088l-2.633-1.876l-1.331-3.238l1.331-2.27l-1.331-1.513v-1.876l0.757-2.088l-1.514-1.513
			l-0.574-2.27L292.674,122.224z"/>
		
			<path class="zone-dept" id="path207_1_" inkscape:label="Yvelines" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M296.881,114.538l-0.061,0.121l-5.296,1.513l-0.756,0.938l0.968,1.513v1.513l1.694,0.363l-0.968,0.968l0.091,0.847l0.121-0.091
			l1.876,1.876l0.574,2.27l1.514,1.513l-0.757,2.088v1.876l1.331,1.513l-1.331,2.27l1.331,3.238l2.633,1.876l0.394,2.088l2.27,0.363
			l0.545,4.176l1.725,2.088l3.057,0.575l0.454-2.996l1.301-1.483l-1.089-1.665h2.754l1.876-2.572l-1.301-2.239l0.362-2.027
			l2.784-1.301l0.363-2.058l1.846-0.726l1.695-0.757l0.332,0.212v-0.212l-1.694-1.906l-1.06-2.875l1.695-3.692l-0.848-2.784
			l-3.298-1.998l-4.66-0.211l-4.358-2.784l-3.51,0.635L296.881,114.538z"/>
		
			<path class="zone-dept" id="path2505_1_" inkscape:label="Val-de-Marne" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M327.163,127.375l-0.773,0.624l-0.772,0.119l-0.06,0.416l-0.148,0.387h0.386l0.208-0.179l-0.089-0.297l1.189-0.06l0.297,0.119
			v0.624l-0.297,0.625l-1.725-0.238l-0.268-0.238l-0.803,0.565l-0.772,0.208l-1.101-0.06l-0.327,1.159l0.298,0.505l-0.476,1.397
			l0.476,0.892l0.832-0.417l1.992,1.1h3.448l2.052,0.951l-0.029-0.208l1.456-3.657v-1.665l-0.922-0.238l-0.237-0.446l-0.387-0.178
			l-0.832-0.922l-1.457-0.832L327.163,127.375z"/>
		
			<path class="zone-dept" id="path2507_1_" inkscape:label="Seine-Saint-Denis" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M331.295,119.318l-1.754,1.249l-2.735,1.249l-4.994,0.327l0.148,0.684l0.416,0.089l0.387,0.594l-0.387,0.833l-0.505,0.089
			l0.356,0.862l2.408-0.03l0.773,1.189l0.148,1.665l0.832-0.119l0.773-0.624l1.159,0.06l1.457,0.832l0.832,0.922l0.387,0.178
			l0.237,0.446l0.922,0.238v-2.706l-1.1-6.541L331.295,119.318z"/>
		<path class="zone-dept" id="path137_1_" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="M321.812,122.143
			l-3.33,1.784l-0.356,0.06l-1.635,3.567l1.04,2.824l1.665,1.873v0.208l2.586,1.813l0.625-0.327l-0.476-0.892l0.476-1.397
			l-0.298-0.505l0.327-1.159h-0.06l-1.813-0.922l-1.486-0.416l-0.208-0.654l-0.029-0.564l0.892-1.011l0.981-0.059l0.445-0.595
			l1.07-0.476l-0.356-0.862l0.505-0.089l0.387-0.833l-0.387-0.594l-0.416-0.089L321.812,122.143z"/>
		
			<path class="zone-dept" id="path156_1_" inkscape:label="Val-d&apos;Oise" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M300.814,106.337l-1.513,1.876l-1.15,4.176l-1.271,2.148l4.6,2.058l3.51-0.635l4.358,2.784l4.66,0.211l3.298,1.998l0.848,2.784
			l-0.03,0.061l0.363-0.061l3.389-1.815l5.084-0.333l2.784-1.271l1.785-1.271l0.575-3.722l-1.241-1.241l-4.146-2.451l-3.994-2.088
			l-2.058,0.938l-2.27,0.575l-1.514-1.15l-3.025-1.876l-2.451,1.876l-3.602,0.394l-5.114-0.394l-1.149-1.876L300.814,106.337z"/>
		<path class="zone-dept" id="path160_2_" inkscape:label="Paris" fill="#000080" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="3.9745" d="
			M324.562,125.458l-2.222,0.027l-0.987,0.439l-0.411,0.548l-0.904,0.055l-0.824,0.932l0.027,0.521l0.191,0.604l1.372,0.384
			l1.673,0.851l1.069,0.054l0.715-0.192l0.739-0.521l0.247,0.22l1.592,0.219l0.273-0.576v-0.575l-0.273-0.11l-1.097,0.055
			l0.082,0.274l-0.193,0.165h-0.355l0.136-0.357l0.056-0.384h-0.056l-0.136-1.536L324.562,125.458z"/>
		<g class="zone-label" id="label_H1a" transform="translate(180.82315,-332.92314)">
			
				<path class="zone-cartouche" id="cartouche_H1a" sodipodi:ry="21.296297" sodipodi:rx="21.296297" sodipodi:cy="270.37036" sodipodi:cx="75" sodipodi:type="arc" fill="#FFFFFF" fill-opacity="0.502" stroke="#000000" d="
				M149.487,430c0,10.762-10.964,19.487-24.487,19.487c-13.524,0-24.487-8.725-24.487-19.487c0-10.763,10.963-19.487,24.487-19.487
				C138.524,410.513,149.487,419.238,149.487,430z"/>
			<text transform="matrix(1 0 0 1 106.2686 437.0657)" font-family="'Arial'" font-size="20">H1a</text>
		</g>
		<polygon class="zone-area" id="area_H1a" fill="none" stroke="#F906ED" stroke-miterlimit="3.9745" points="390.266,72.475 391.961,70.962 
			390.266,67.754 390.78,64.395 386.938,64.395 386.635,64.395 386.453,62.519 390.236,60.25 389.661,56.648 386.06,55.71 
			386.998,54.742 386.998,52.109 389.843,50.021 389.086,48.508 383.034,43.787 372.442,44.362 371.322,46.238 369.991,46.238 
			370.173,39.611 367.146,36.041 364.877,36.404 363.546,34.891 359.763,36.585 358.462,35.284 355.799,34.891 355.042,32.439 
			354.86,24.874 353.166,24.118 352.954,22.968 351.835,22.968 351.441,20.698 348.99,20.91 344.27,22.423 342,25.237 
			339.73,25.237 338.217,23.361 337.643,21.273 335.766,19.185 333.104,19.185 331.983,17.127 331.983,13.89 333.285,11.832 
			332.528,8.987 330.077,4.055 324.024,6.899 314.583,8.412 314.068,8.503 303.599,10.5 295.277,16.916 295.277,42.456 
			295.217,43.212 294.701,49.446 298.878,53.229 298.878,55.135 293.764,52.109 287.621,59.674 286.38,61.188 278.27,67.421 
			263.896,71.022 254.424,74.412 246.678,78.587 242.139,85.396 241.2,90.692 244.983,93.537 250.46,94.656 249.583,94.808 
			245.165,95.594 237.962,99.77 229.853,102.978 223.407,99.377 207.913,97.107 204.312,95.231 198.714,96.653 199.047,98.741 
			197.958,99.831 198.108,101.374 201.709,104.975 203.889,106.216 205.462,104.188 206.703,105.762 205.129,107.79 206.551,110.12 
			205.916,110.906 207.005,113.115 206.4,115.597 204.827,118.108 202.315,119.652 200.923,118.563 199.531,119.803 
			201.074,121.225 199.833,123.556 196.868,124.645 200.469,129.184 204.04,129.486 206.793,131.272 208.881,132.634 
			210.758,134.51 210.304,136.356 210.606,137.929 209.517,141.53 206.279,144.284 207.55,146.433 209.638,147.946 212.482,146.039 
			214.359,147.371 219.473,144.345 224.587,145.101 227.401,143.951 228.188,142.075 230.064,141.894 231.759,143.407 
			232.516,147.552 234.967,148.49 235.935,151.91 239.143,152.092 244.257,147.371 249.371,147.189 250.884,149.247 
			251.822,156.056 255.03,157.206 256.936,160.05 260.9,160.05 261.082,161.352 261.263,159.475 262.02,159.475 264.683,163.439 
			266.741,163.772 267.134,163.833 269.767,165.709 268.798,166.919 270.675,167.857 273.67,167.313 275.426,167.313 
			275.305,168.13 273.67,169.068 274.76,169.885 277.483,169.885 278.421,172.064 280.056,173.002 281.266,175.726 285.472,176.815 
			288.075,176.542 290.375,174.364 292.432,174.938 292.947,173.819 292.826,172.608 293.915,171.792 295.67,172.881 
			296.76,172.064 296.76,170.581 298.242,169.613 299.604,170.157 300.814,171.519 302.993,170.309 305.293,170.309 
			306.928,168.523 307.896,165.013 309.379,164.741 308.985,161.2 310.74,159.717 310.195,158.628 310.398,158.28 315.945,157.781 
			318.396,155.511 320.484,157.206 325.568,157.569 326.294,158.658 327.475,160.413 329.744,161.563 329.926,164.771 
			326.899,166.859 328.594,168.372 330.683,167.041 335.796,167.222 337.673,166.859 338.247,165.346 340.336,165.527 
			340.698,167.041 345.419,164.377 347.145,162.32 349.414,159.657 347.9,157.962 349.232,155.118 352.621,153.423 360.005,153.786 
			361.699,152.485 361.852,152.636 362.274,149.459 363.031,145.676 364.362,143.951 364.544,141.682 367.177,140.562 
			367.177,139.049 364.362,139.23 363.788,137.899 364.726,136.205 363.97,133.36 362.456,132.422 362.85,129.789 364.362,128.821 
			364.15,127.701 364.695,127.035 365.089,126.551 366.058,124.494 368.902,123.162 370.415,119.198 372.109,118.048 
			371.142,116.716 368.69,116.716 368.327,115.415 370.021,114.659 370.778,113.509 369.083,112.389 369.628,110.664 
			373.985,110.301 373.229,108.606 370.597,106.882 370.597,101.798 374.167,99.135 378.162,99.135 377.769,97.259 380.038,96.29 
			383.246,98.378 384.577,98.015 384.396,91.569 384.971,89.3 385.727,86.667 383.246,85.336 383.82,83.823 387.422,83.066 
			387.422,80.615 390.266,79.102 391.022,76.833 390.085,75.319 		"/>
	</g>
</g>
<g id="meteo_x5F_stations" display="none">
	
		<path class="zone-dept" id="path3905" sodipodi:ry="3.1003475" sodipodi:rx="3.1003475" sodipodi:cy="108.13847" sodipodi:cx="-46.257187" sodipodi:type="arc" display="inline" d="
		M179.572,160.224c0,1.712-1.388,3.101-3.1,3.101c-1.712,0-3.101-1.388-3.101-3.1c0,0,0,0,0-0.001c0-1.712,1.387-3.101,3.1-3.101
		C178.184,157.123,179.572,158.511,179.572,160.224C179.572,160.224,179.572,160.224,179.572,160.224z"/>
	
		<path class="zone-dept" id="path3907" sodipodi:ry="3.1003475" sodipodi:rx="3.1003475" sodipodi:cy="108.13847" sodipodi:cx="-46.257187" sodipodi:type="arc" display="inline" fill="#FFFFFF" d="
		M304.578,124.508c0,1.712-1.388,3.101-3.1,3.101c-1.713,0-3.101-1.388-3.102-3.1c0,0,0,0,0-0.001c0-1.712,1.388-3.101,3.101-3.101
		C303.189,121.407,304.578,122.795,304.578,124.508C304.578,124.508,304.578,124.508,304.578,124.508z"/>
	
		<path class="zone-dept" id="path3909" sodipodi:ry="3.1003475" sodipodi:rx="3.1003475" sodipodi:cy="108.13847" sodipodi:cx="-46.257187" sodipodi:type="arc" display="inline" d="
		M475.222,132.941c0,1.712-1.388,3.101-3.101,3.101c-1.712,0-3.101-1.388-3.101-3.1c0,0,0-0.001,0-0.001
		c0-1.712,1.388-3.101,3.1-3.101C473.833,129.84,475.221,131.228,475.222,132.941C475.222,132.94,475.222,132.941,475.222,132.941z"
		/>
	
		<path class="zone-dept" id="path3911" sodipodi:ry="3.1003475" sodipodi:rx="3.1003475" sodipodi:cy="108.13847" sodipodi:cx="-46.257187" sodipodi:type="arc" display="inline" d="
		M396.845,241.082c0,1.712-1.388,3.1-3.101,3.101c-1.712,0-3.1-1.388-3.101-3.1v0c0-1.712,1.388-3.101,3.101-3.101
		C395.456,237.98,396.844,239.368,396.845,241.082C396.845,241.081,396.845,241.081,396.845,241.082z"/>
	
		<path class="zone-dept" id="path3911-1" sodipodi:ry="3.1003475" sodipodi:rx="3.1003475" sodipodi:cy="108.13847" sodipodi:cx="-46.257187" sodipodi:type="arc" display="inline" d="
		M431.692,389.277c0,1.713-1.388,3.102-3.1,3.102c-1.713,0-3.101-1.388-3.101-3.1c0,0,0-0.001,0-0.002
		c-0.001-1.711,1.387-3.1,3.1-3.1C430.304,386.177,431.692,387.564,431.692,389.277L431.692,389.277z"/>
	
		<path class="zone-dept" id="path3911-7" sodipodi:ry="3.1003475" sodipodi:rx="3.1003475" sodipodi:cy="108.13847" sodipodi:cx="-46.257187" sodipodi:type="arc" display="inline" d="
		M514.533,410.112c0.001,1.712-1.387,3.101-3.1,3.101c-1.712,0-3.101-1.387-3.101-3.1c0,0,0,0,0-0.001
		c0-1.712,1.388-3.101,3.1-3.101C513.146,407.012,514.533,408.398,514.533,410.112C514.533,410.111,514.533,410.111,514.533,410.112
		z"/>
	
		<path class="zone-dept" id="path3911-4" sodipodi:ry="3.1003475" sodipodi:rx="3.1003475" sodipodi:cy="108.13847" sodipodi:cx="-46.257187" sodipodi:type="arc" display="inline" d="
		M245.671,365.467c0,1.713-1.387,3.102-3.1,3.102s-3.101-1.388-3.101-3.1c0-0.001,0-0.001,0-0.002c0-1.712,1.388-3.1,3.1-3.1
		C244.283,362.366,245.671,363.754,245.671,365.467L245.671,365.467z"/>
	
		<path class="zone-dept" id="path3911-0" sodipodi:ry="3.1003475" sodipodi:rx="3.1003475" sodipodi:cy="108.13847" sodipodi:cx="-46.257187" sodipodi:type="arc" display="inline" d="
		M197.554,269.729c0,1.712-1.388,3.101-3.1,3.101c-1.712,0-3.101-1.388-3.101-3.1c0,0,0,0,0-0.001c0-1.712,1.388-3.101,3.1-3.101
		C196.166,266.627,197.554,268.015,197.554,269.729C197.554,269.728,197.554,269.729,197.554,269.729z"/>
	<text transform="matrix(1 0 0 1 476.709 136.7852)" display="inline" font-family="'Arial'" font-size="16">Nancy</text>
	<text transform="matrix(1 0 0 1 237.1235 128.4434)" display="inline" fill="#EEEEEE" font-family="'Arial'" font-size="16">Trappes</text>
	<text transform="matrix(1 0 0 1 399.1221 245.9922)" display="inline" font-family="'Arial'" font-size="16">Mâcon</text>
	<text transform="matrix(1 0 0 1 198.7314 274.7637)" display="inline" font-family="'Arial'" font-size="16">La Rochelle</text>
	<text transform="matrix(1 0 0 1 181.8652 165.1357)" display="inline" font-family="'Arial'" font-size="16">Rennes</text>
	<text transform="matrix(1 0 0 1 434.3574 392.8252)" display="inline" font-family="'Arial'" font-size="16">Carpentras</text>
	<text transform="matrix(1 0 0 1 516.1914 415.1475)" display="inline" font-family="'Arial'" font-size="16">Nice</text>
	<text transform="matrix(1 0 0 1 249.3291 369.0146)" display="inline" font-family="'Arial'" font-size="16">Agen</text>
</g>
</svg>
</div>
</template>

<script setup>
import {ref, watch, computed} from 'vue';
const props = defineProps({
    modelValue: {
      type: String,
      required: true
    },
    varname: {
      type: String
    },
		isCliquable: {
			type: Boolean,
			default: false,
		}
  });

const theValue = ref(props.modelValue);
const emit = defineEmits(['update:modelValue']);

const setZoneClimat = (zone) => {
	if (!isCliquable.value) return;
	theValue.value = zone;
};

const isCliquable = computed(() => props.isCliquable);

const modelVal = computed(() => props.modelValue);
watch(modelVal, (newVal) => {
  theValue.value = newVal;
}, {deep: true})

watch(theValue, (newVal) => {
  emit('update:modelValue', newVal);
})

</script>

<style lang="scss" scoped>
@import '@/scss/_var_app.scss';

#zones_climat{
	width: 100%;
	max-width: 430px;
	height: auto;
	max-height: 430px;
}

#zoneClimatMap{
	display: flex;
	justify-content: center;
	align-items: center;
}

.zone-climat {
  cursor: pointer;

	&.canNotSelect {
		cursor: not-allowed;
	}
	
	&.active,
  &.canSelect:hover{
      // fill: yellow;
      .zone-dept {
        fill: $app-secondary-color;
      }
			.zone-label{
				text {
					fill: #fff;
					text-shadow: 3px 3px 0 #333;
					// font-size: 1.6rem;
				}
			}
    }
  .zone-area {
    fill: none;
    stroke: $app-primary-color;
    stroke-width: 1;


  }

  .zone-label{
		text {
			fill: $app-primary-color;
    	fill-opacity: 1;
			font-weight: 900;
		}
  }

  .zone-cartouche{
    fill: white;
    fill-opacity: 0.8;
		display: none;
  }

  .zone-dept {
    fill: rgba(249, 178, 51, 0.10);
  }
}

</style>