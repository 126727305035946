<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g fill="currentColor">
	<path fill="currentColor" d="M282.89,253.376c0.95,0.582,1.868,1.174,2.752,1.758c0.88,0.592,1.755,1.213,2.638,1.877
		c0.444-2.572,1.141-5.078,2.097-7.545c0.948-2.457,2.089-4.793,3.408-6.992c-1.903-4.848-4.638-9.121-8.201-12.824
		c-3.559-3.711-7.763-6.555-12.606-8.535c3.23,4.111,5.725,8.662,7.49,13.648c1.758,4.996,2.64,10.283,2.64,15.861
		c0,0.432-0.038,0.895-0.114,1.373C282.923,252.476,282.89,252.933,282.89,253.376z"/>
	<path fill="currentColor" d="M303.523,257.724c2.691-2.238,5.648-4.127,8.875-5.666c1.686-4.631,4.073-8.813,7.156-12.557
		c3.077-3.744,6.682-6.898,10.782-9.467c-4.686,0.146-9.104,1.174-13.26,3.078c-4.146,1.914-7.765,4.461-10.851,7.652
		c-3.075,3.195-5.506,6.887-7.267,11.066c-1.762,4.182-2.639,8.654-2.639,13.426C298.45,262.472,300.864,259.962,303.523,257.724z"
		/>
	<path fill="currentColor" d="M315.12,282.521c1.703-6.135,4.744-11.43,9.161-15.885c4.481-4.51,9.833-7.568,16.079-9.189
		c-1.469-0.361-2.926-0.635-4.348-0.826c-1.434-0.178-2.922-0.273-4.461-0.273c-6.451,0-12.354,1.586-17.667,4.73
		c-5.327,3.168-9.534,7.346-12.614,12.564c1.252,2.781,2.205,5.709,2.867,8.752c0.012,0.041,0.012,0.082,0.023,0.127h-8.951
		c-1.496-5.994-4.516-11.318-9.132-15.938c-6.825-6.82-15.156-10.236-24.991-10.236c-1.539,0-3.025,0.088-4.458,0.273
		c-1.431,0.182-2.887,0.465-4.351,0.826c6.239,1.621,11.62,4.68,16.131,9.189c4.451,4.463,7.482,9.75,9.111,15.885h-78.378
		l3.154-3.154c1.106-1.105,0.807-2.467-0.669-3.02c0,0-25.618-9.604-25.618-27.297v-15.873
		c64.6-7.857,113.208-42.115,113.208-83.215c0-19.258-10.737-37.021-28.751-51.27c-0.023-0.011-0.047-0.011-0.069-0.034
		C255.26,48.091,206.788,8.419,147.674,8.419c-59.125,0-107.597,39.672-112.724,90.239c-0.011,0.022-0.045,0.022-0.069,0.034
		c-18.027,14.25-28.751,32.012-28.751,51.27c0,44.049,55.867,80.243,127.388,84.502v14.586c0,14.141-25.769,27.043-25.769,27.043
		c-1.427,0.738-2.084,2.422-1.542,3.92c0,0,0.395,1.048,1.184,2.508H45.977v8.986h206.301v0.072h44.043
		c0-0.023-0.004-0.045-0.004-0.072h8.806c0,0.027,0.003,0.049,0.003,0.072h35.234v-0.072h213.919v-8.986H315.12z M34.443,149.962
		c0-14.421,10.862-27.529,28.612-37.528c-0.137-1.635-0.31-3.283-0.31-4.929c0-39.095,38.035-70.772,84.928-70.772
		c46.904,0,84.917,31.677,84.917,70.772c0,1.646-0.161,3.294-0.276,4.929c17.738,9.999,28.589,23.106,28.589,37.528
		c0,26.379-36.1,48.473-84.895,54.762v-9.469c0.094-1.773,0.808-3.455,2.027-4.746l25.941-24.442
		c1.245-1.293,1.982-2.972,2.073-4.768v-12.051c0-1.557-1.106-2.188-2.442-1.395l-25.157,14.825
		c-1.382,0.784-2.443,0.161-2.443-1.404v-50.949c0-1.555-1.22-2.431-2.718-1.936l-37.08,12.372
		c-1.555,0.599-2.604,2.062-2.683,3.719v36.806c0,1.553-1.118,2.223-2.501,1.509l-28.624-15.058
		c-1.497-0.701-3.271-0.438-4.492,0.681l-4.85,4.837c-1.036,1.016-1.047,2.672-0.045,3.71c0.057,0.059,0.116,0.102,0.173,0.16
		l38.219,33.429c1.176,1.012,2.121,3.133,2.121,4.699v10.826C77.698,202.607,34.443,178.851,34.443,149.962z"/>
</g>
</svg>
</template>