<template>
  <Transition
    name="trans-qst"
    @enter="onEnter"
    @leave="onLeave">
    <slot></slot> <!-- passer le contenu du slot -->
  </Transition>
</template>

<script setup>
const onEnter = (el) => {
  el.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
}

const onLeave = () => {}
</script>

<style lang="scss">
.trans-qst-enter-active,
.trans-qst-leave-active 
{
  transition: all 0.65s ease;
  max-height: 500px;
}
.trans-qst-enter-from,
.trans-qst-leave-to
{
  opacity: 0;
  max-height: 0;
}
</style>