<template>
  <div class="form-modal-overlay" v-if="isOpen"></div>

  <div class="form-modal-wrapper " v-if="isOpen">
    <div class="form-modal-container container">
      <div class="top-bar">
        <h1 class="form-modal-title">
          {{ helpTitle }}
        </h1>
        <!-- <button class="btn btn-danger btn-sm" @click.prevent="closeHelp">
          X
        </button> -->
      </div>

      <div class="form-modal-content">
          <component
            :is="data.componentName"
            :id="data.componentId"
            @closeModal="closeHelp"
          />
      </div>
    </div>
  </div>
</template>

<script>
import FormWrapper from "./FormWrapper.vue";
export default {
  name: "formModalBox",
  components: {
    FormWrapper,
  },
  data() {
    return {
      isOpen: false,
      data: {
        componentName: null,
        title: null,
        formType: null,
        formItem: null,
      },
    };
  },
  computed: {
    helpComponenentName() {
      return this.data.componentName;
    },
    helpTitle() {
      return this.data.title;
    },
  },
  mounted() {
    this.emitter.on("form-modal-open", (isOpen) => {
      // alert('ttt :' + isOpen);
      this.isOpen = isOpen;
    });
    this.emitter.on("form-modal-data", (data) => {
      // alert('ttt :' + isOpen);
      this.data = data;
    });
  },
  methods: {
    closeHelp() {
      // this.isOpen = false;
      this.emitter.emit("form-modal-open", false);
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        // alert('Block scoll');
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.form-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  pointer-events: none;

  .top-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    margin: 0 0 0.8rem 0;

    h1 {
      font-size: 1.5rem;
    }
  }

  .form-modal-container {
    pointer-events: auto;
    background-color: #fff;
    padding: 1rem;
    width: 100%;
    height: 100%;
    min-width: 320px;
    width: 98vw;
    height: 98vh;
    // height: auto;
    // height: 100%;
    min-height: 30vh;

    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);

    // .help-modal-title {
    //   // font-size: 1.8rem;
    // }

    .form-modal-content {
      // border: 1px solid red;
      overflow-x: hidden;
      height: auto;
      // min-height: 30vh;
      // max-height: 65vh;
      width: 100%;
      height: 90%;
      padding: 1rem;
      overflow-y: auto;

      h2 {
        font-size: 1.1rem !important;
      }
    }
  }
}
</style>
