<template>
  <div v-bind="$attrs">
    <div class="recoTvx-liste">

      <dl>
        <template
          v-for="(recoTvxData, recoTvxTheme) in recoTvxSorted"
          :key="`recoTvxTestA-${recoTvxTheme}`"
        >
          <dt>{{ recoTvxTheme }}</dt>
          <dd>
            <div
              v-for="(recoTvxMsg, recoTvxMsgIndex) in recoTvxData"
              :key="`recoTvxTestB-${recoTvxMsgIndex}`"
              class="recoTvx-bloc my-3"
              :class="{tvxhightLighted: props.focusTvxPoste === recoTvxMsg.poste}"
            >
            
              <div class="recoTvx-icon-wrapper"><IconPosteTvx class="recoTvx-icon-item" :iconType="recoTvxMsg.poste" :focusTvxPoste="recoTvxMsg.poste" /></div>
              <div class="recoTvx-body">
                <h6 class="recoTvx-title">
                  <span v-if="isDebug">[{{ recoTvxMsg.id }}] - #{{ recoTvxMsg.order }} - </span>
                  {{ recoTvxMsg.title }}
                </h6>
                <div class="recoTvx-description" v-html="recoTvxMsg.description"></div>
                <!-- [{{ recoTvxMsg.poste }}] -->
              </div>
            </div>
          </dd>
        </template>
      </dl>
    

    </div>

    <div class="debug-bloc" v-if="isDebug">
      <div class="row">
        <div class="col">
          <h4>recoTvxItems.messages</h4>
          <pre>{{ recoTvxItems.messages }}</pre>
        </div>
        <div class="col">
          <h4>recoTvxItems.debug</h4>
          <pre>{{ recoTvxItems.debug }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useConditionsManager } from "../composable/useConditionsManager.js";
import { recoTvx } from "@/appConfig";
import {scrollIntoParent} from "@/functions/scroll.js";

const props = defineProps({
  focusTvxPoste: {
    type: String,
    required: false,
    default: null
  }
});

const store = useStore();
const isDebug = computed(() => store.getters.isDebug);

const recoTvxItems = useConditionsManager(recoTvx);

const itemBaseGroup = ref("theme");

const recoTvxGrouped = computed(() => {
  const newReco = {};
  recoTvxItems.messages.forEach((dum) => {
    if (!newReco[dum[itemBaseGroup.value]]) {
      newReco[dum[itemBaseGroup.value]] = [];
    }
    newReco[dum[itemBaseGroup.value]].push({
      id: dum.id,
      order: dum.order,
      theme: dum.theme,
      title: dum.title,
      poste: dum.poste,
      description: dum.description,
    });

    newReco[dum[itemBaseGroup.value]].sort((a, b) => (a.order > b.order) ? 1 : -1);
    // newReco[dum[itemBaseGroup.value]].sort((a, b) => (a.order > b.poste) ? 1 : -1);
  });

  return newReco;
});

const recoTvxSorted = computed(() => {
  // const themesOrder = [
  //   "Environnement",
  //   "Enveloppe",
  //   "Fenêtre",
  //   "Ventilation",
  //   "Equipements",
  // ];

  const themesOrder = [
    {
      id: "ENVIR",
      order: 1,
      label: "Tenir compte de l’environnement proche du bâtiment"
    },
    {
      id: "ENVEL",
      order: 1,
      label: "Réduire les apports solaires sur l’enveloppe (parois opaques)"
    },
    {
      id: "WINDO",
      order: 1,
      label: "Limiter les apports solaires sur les parois transparentes (parois vitrées)"
    },
    {
      id: "VENTIL",
      order: 1,
      label: "Favoriser un rafraichissement naturel"
    },
    {
      id: "EQUIP",
      order: 1,
      label: "Se tourner vers l’usage de certains équipements"
    },
  ];

  const newReco = {};
  themesOrder.forEach((theme) => {
    if (recoTvxGrouped.value[theme.id]) {
      if (!newReco[theme.label]) newReco[theme.label] = [];
      newReco[theme.label] = recoTvxGrouped.value[theme.id];
    }
  });

  return newReco;
});



const showHightlightedOnView = () => {
  const elms = document.getElementsByClassName('tvxhightLighted');
  // console.log(elms[0]);
  if(elms[0]){
    // console.log("IN");
    const parentElem = document.getElementById('listeTvx');
    const targetElem = elms[0];
    scrollIntoParent(parentElem, targetElem, -100);

    parentElem.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }
}

const focusTvxPoste = computed(() => props.focusTvxPoste);
watch (focusTvxPoste, () => {
  setTimeout(() => {
    showHightlightedOnView();
  }, 200)
  
});
</script>
