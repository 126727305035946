<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="590px"
    height="300px"
    viewBox="0 0 590 300"
    enable-background="new 0 0 590 300"
    xml:space="preserve"
  >
    <g>
      <polygon
        id="verre"
        opacity="0.3"
        fill="#009FE3"
        points="298.854,52.502 160.609,52.502 160.609,247.498 298.854,247.498 
		437.099,247.498 437.099,52.502 	"
      />
      <path
        id="cadre"
        d="M306.537,44.818H291.17H152.926v210.363H291.17h15.367h138.244V44.818H306.537z M291.17,239.814H168.292V60.186
		H291.17V239.814z M429.415,239.814H306.537V60.186h122.878V239.814z"
      />
      <path
        id="symbole-bad_1_"
        class="icon-symbole symbole-bad"
        opacity="0.7"
        fill="#E30613"
        d="M295,80.001c-38.109,0-69.045,30.906-69.045,69.045
		c0,38.138,30.936,69.045,69.045,69.045c38.138,0,69.045-30.907,69.045-69.045C364.045,110.907,333.138,80.001,295,80.001z
		 M295,96.796c10.351,0,20.001,3.068,28.137,8.287l-72.092,72.085c-5.409-8.383-8.295-18.15-8.295-28.122
		C242.75,120.238,266.192,96.796,295,96.796z M295,201.296c-9.972,0-19.74-2.887-28.108-8.281l72.078-72.092
		c5.394,8.383,8.281,18.144,8.281,28.123C347.25,177.854,323.807,201.296,295,201.296z"
      />
    </g>
  </svg>
</template>
