<template>
  <svg version="1.1" id="Calque_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<path id="handOnly" fill="currentColor" d="M361.188,107.47c-5.123,0-10.16,1.464-14.465,4.241V76.473
	c0-14.831-12.011-26.864-26.864-26.854c-5.382,0-10.634,1.614-15.068,4.628c-3.143-14.498-17.436-23.711-31.944-20.568
	c-12.356,2.681-21.182,13.616-21.182,26.262v10.44c-12.485-7.986-29.104-4.327-37.09,8.18c-2.755,4.316-4.24,9.32-4.24,14.443
	v78.527c0,49.079,39.78,88.859,88.859,88.859s88.859-39.78,88.859-88.859v-37.197C388.053,119.504,376.041,107.47,361.188,107.47z
	 M375.654,171.531c0,42.234-34.227,76.46-76.46,76.46c-42.213,0-76.46-34.226-76.46-76.46V93.004
	c0-7.985,6.501-14.465,14.465-14.465c8.008,0,14.466,6.479,14.466,14.465v45.464c0,3.423,2.798,6.199,6.199,6.199
	c3.444,0,6.2-2.776,6.2-6.199V59.94c0-7.985,6.5-14.466,14.465-14.466c8.008,0,14.465,6.48,14.465,14.466v70.261
	c0,3.424,2.798,6.199,6.2,6.199c3.444,0,6.199-2.775,6.199-6.199V76.473c0-7.986,6.501-14.466,14.466-14.466
	c8.007,0,14.465,6.479,14.465,14.466v72.736c-23.636,3.133-41.287,23.27-41.33,47.12c0,3.423,2.798,6.199,6.2,6.199
	c3.444,0,6.199-2.776,6.199-6.199c0-19.416,15.714-35.13,35.131-35.13c3.444,0,6.199-2.776,6.199-6.2v-20.665
	c0-7.985,6.501-14.465,14.465-14.465c8.008,0,14.466,6.479,14.466,14.465l0,0V171.531z"/>
</svg>
</template>