import { helpers, required, requiredIf, minValue } from '@vuelidate/validators';console.log
import DepartmentsList from "../json/departements.json";
import {useCheckSimuDataValueProject} from '../composable/useCheckSimuDataValueProject.js';
import {yearsConstructionLevel, insulationValues, inertiaValues,} from '../class/levelAndValues.config.js'
const {checkProjectSimuDatas, maxValueFromDynamicValue} = useCheckSimuDataValueProject();

const mustBeTrue =  (value) => value === true;
// const mustBeString = (value) => typeof value === 'string';


export const questions = [
  {
    varname: "userBehaviorVentil",
    discomfortFamily: "usersBehaviour",
    label: "Êtes-vous attentif à ouvrir les fenêtres la nuit pour favoriser une meilleure ventilation ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "icon",
    extraClasses: ['check-like-card'],
    validation: { required},
    choices: [
      {value: 'good', label: "Oui, toujours", icon:'IconThumbGood', coef: 0},
      {value: 'medium', label: "Oui, parfois", icon:'IconThumbMedium', coef: 1},
      {value: 'bad', label: "Non, pas du tout", icon:'IconThumbBad', coef: 2},
    ],
    help: {
      title: "Ouverture des fenêtres",
      modalComponent: "HelpUserBehaviorVentil",
    },
  },
  {
    varname: "userBehaviorSolarProtection",
    discomfortFamily: "usersBehaviour",
    label: "Êtes-vous attentif à utiliser les protections solaires pour éviter une surchauffe de la pièce ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "icon",
    extraClasses: ['check-like-card'],
    validation: { required},
    choices: [
      {value: 'good', label: "Oui, toujours", icon:'IconThumbGood', coef: 0},
      {value: 'medium', label: "Oui, parfois", icon:'IconThumbMedium', coef: 1},
      {value: 'bad', label: "Non, pas du tout", icon:'IconThumbBad', coef: 2},
    ],
    help: {
      title: "Protections solaires",
      modalComponent: "HelpUserBehaviorSolarProtection",
    },
  },
  {
    varname: "userBehaviorElecEquipments",
    discomfortFamily: "usersBehaviour",
    label: "Êtes-vous attentif à limiter le recours aux appareils électriques ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "icon",
    extraClasses: ['check-like-card'],
    validation: { required},
    choices: [
      {value: 'good', label: "Oui, toujours", icon:'IconThumbGood', coef: 0},
      {value: 'medium', label: "Oui, parfois", icon:'IconThumbMedium', coef: 1},
      {value: 'bad', label: "Non, pas du tout", icon:'IconThumbBad', coef: 2},
    ],
    help: {
      title: "Appareils électriques",
      modalComponent: "HelpUserBehaviorElecEquipments",
    },
  },

  /**
   * BUILDING TYPE
   */
  {
    varname: "buildingType",
    label: "Quel est le type de bâtiment à évaluer ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "icon",
    // imagePrefixName:'buildingType',
    validation: { required},
    choices: [
      {value: 'MI', label: "Maison individelle", icon:"IconBuildingTypeMI", coef: null},
      {value: 'LC', label: "Logement collectif", icon:"IconBuildingTypeLC", coef: null},
      // {value: 'BT', label: "Bâtiment tertiaire", coef: null},
    ],
  },
  {
    varname: "buildingProjectState",
    label: "L'évaluation concerne :",
    // displayType: "nbcol-1",
    description: null,
    default: null,
    type: "icon",
    extraClasses: ['check-like-card'],
    validation: { required},
    choices: [
      {value: "new", label: "Un projet pour une future construction", icon:"IconProjectTypeNew", coef: null},
      {value: "existing", label: "Un bâtiment existant", icon:"IconProjectTypeOld", coef: null},
    ],
  },
  {
    varname: "buildingConstructionYear",
    label: "Quelle est l'année de construction ?",
    // displayType: "nbcol-1",
    description: null,
    default: null,
    type: "select",
    conditionsToShow: [
      {formName: 'buildingConfig', varname: 'buildingProjectState', operand: '===', value: 'existing'}
    ],
    validation: {
      required: requiredIf(() => checkProjectSimuDatas([{formName: 'buildingConfig', varname: 'buildingProjectState', operand: '===', value: 'existing'}])),
      // mustBeString
    },
    // triggersToShow: [
    //   {fxName: 'isStroredValueEquals', params: {lookVar: 'buildingProjectState', operand: '===', value: 'existing'}},
    // ],
    // validation: {
    //   required: requiredIf(() => checkProjectSimuDatas([{varname: 'buildingProjectState', operand: '===', value: 'existing'}]))
    // },
    // choices: [
    //   {value: '1974-', label: "avant 1974", coef: null},
    //   {value: '1974-1982', label: "entre 1974 et 1982", coef: null},
    //   {value: '1983-1989', label: "entre 1983 et 1989", coef: null},
    //   {value: '1990-2000', label: "entre 1990 et 2000", coef: null},
    //   {value: '2001-2006', label: "entre 2001 et 2006", coef: null},
    //   {value: '2007-2012', label: "entre 2007 et 2012", coef: null},
    //   {value: '2012+', label: "après 2012", coef: null},
    // ],
    choices: yearsConstructionLevel
  },
  {
    varname: "buildingNbLevelTotal",
    label: "Combien d'étages comporte le bâtiment ?",
    // displayType: "nbcol-1",
    description: "Pour les bâtiments de plain-pied, veuillez indiquer R+0.",
    default: null,
    type: "number",
    prefix: "R+",
    min: 0,
    unite: "étage(s)",
    validation: { required}
  },
  {
    varname: "buildingLevel",
    label: "A quel étage est situé le logement ?",
    // displayType: "nbcol-1",
    description: null,
    default: null,
    type: "number",
    min: 0,
    unite: "étage",
    validation: {
      required,
      minValue:minValue(0),
      maxValue: helpers.withMessage("L'étage ne peut pas être supérieur au nombre d'étages total !", maxValueFromDynamicValue({formName: 'buildingConfig', varname: 'buildingNbLevelTotal'}))
    }
  },


  /**
   * SITUATION
   */
  //-- ENVIRONNEMENT
  // {
  //   varname: 'externalSurroundings',
  //   discomfortFamily: "environment",
  //   type: "radio-card",
  //   extraClasses: ['check-like-card'],
  //   label: "Dans quel environnement est situé le bâtiment ?",
  //   description: null,
  //   default: null,
  //   validation: { required},
  //   choices: [
  //     {value: "rural", label: 'Rural', coef: 0},
  //     {value: "periurban", label: 'Periurbain', coef: 0},
  //     {value: "urban", label: 'Urbain', coef: 1},
  //   ],
  //   help: {
  //     title: "L'environnement du bâtiment",
  //     modalComponent: "HelpExternalSurroundings",
  //   },
  // },
  {
    varname: 'externalSurroundings',
    discomfortFamily: "environment",
    type: "icon",
    extraClasses: ['check-like-card'],
    label: "Dans quel environnement est situé le bâtiment ?",
    description: null,
    default: null,
    validation: { required},
    choices: [
      {value: "rural", label: 'Rural', icon:"IconExternalSurroundingRural", coef: 0},
      {value: "periurban", label: 'Periurbain', icon:"IconExternalSurroundingPeriurbain", coef: 0},
      {value: "urban", label: 'Urbain', icon:"IconExternalSurroundingUrbain", coef: 1},
    ],
    help: {
      title: "L'environnement du bâtiment",
      modalComponent: "HelpExternalSurroundings",
    },
  },
  {
    varname: 'wallsColor',
    discomfortFamily: "environment",
    type: "image",
    imagePrefixName:'wallsColor',
    extraClasses: ['check-like-card'],
    label: 'De quelle couleur sont les murs extérieurs ?',
    description: null,
    default: null,
    validation: { required},
    choices: [
      {value: "light", label: 'Couleur claire', coef: 0},
      {value: "medium", label: 'Couleur intermédiaire', coef: 1},
      {value: "dark", label: 'Couleur foncée', coef: 2},
    ],
    // help: {
    //   title: "Les couleurs des parois",
    //   modalComponent: "HelpColor",
    // },
  },
  {
    varname: 'roofColor',
    discomfortFamily: "environment",
    type: "image",
    imagePrefixName:'roofColor',
    extraClasses: ['check-like-card'],
    label: 'De quelle couleur est la toiture ?',
    description: null,
    default: null,
    validation: { required},
    choices: [
      {value: "light", label: 'Couleur claire', coef: 0},
      {value: "medium", label: 'Couleur intermédiaire', coef: 1},
      {value: "dark", label: 'Couleur foncée', coef: 2},
      {value: "unknow", label: 'Je ne sais pas', coef: 2},
    ],
    // help: {
    //   title: "Les couleurs des parois",
    //   modalComponent: "HelpColor",
    // },
  },
  {
    varname: 'externalGreenSpace',
    discomfortFamily: "environment",
    type: "icon",
    extraClasses: ['check-like-card'],
    label: 'Comment décririez-vous les espaces verts à proximité ?',
    description: 'par exemple : présence de parcs, d’espaces boisés',
    default: null,
    validation: { required},
    choices: [
      {value: "dense", label: 'Denses', icon:"IconExternalGreenSpaceDense", coef: 0},
      {value: "light", label: 'Légers', icon:"IconExternalGreenSpaceLight", coef: 1},
      {value: "none", label: 'Inéxistants', icon:"IconExternalGreenSpaceNone", coef: 2},
    ],
    help: {
      title: "Les espaces verts",
      modalComponent: "HelpExternalGreenSpace",
    },
  },
  {
    varname: 'externalGroundType',
    discomfortFamily: "environment",
    type: "radio-card",
    extraClasses: ['check-like-card'],
    label: "De quelle nature est principalement la surface aux abords du bâtiment ?",
    description: null,
    default: null,
    // conditionsToShow: [
    //   {formName: 'buildingConfig', varname: 'buildingType', operand: 'in', value: ['LC', 'BT']}
    // ],
    // validation: {
    //   required: requiredIf(() => checkProjectSimuDatas([
    //     {formName: 'buildingConfig', varname: 'buildingType', operand: 'in', value: ['LC', 'BT']}
    //   ]))
    // },
    validation: {
      required
    },
    choices: [
      {value: "revegetation", label: 'Surface végétalisée', coef: 0},
      {value: "woodenTerrace", label: 'Terrasse bois', coef: 1},
      {value: "concreteTerrace", label: 'Surface béton ou bitume', coef: 1},
      {value: "pavingSlab", label: 'Dalle de sol', coef: 2},
    ],
    help: {
      title: "Surface extérieure",
      modalComponent: "HelpExternalGroundType",
    },
  },
  {
    varname: 'canOpenWindowsNight',
    discomfortFamily: "ventilation",
    type: "icon",
    extraClasses: ['check-like-card'],
    label: "L’environnement direct de votre bâtiment vous permet-il d’ouvrir les fenêtres la nuit ?",
    description: "par exemple : sources de bruit gênantes, problèmes de sécurité",
    default: null,
    validation: { required},
    choices: [
      // {value: 'none', label: 'Non, pas du tout', coef: 0},
      // {value: 'light', label: 'Oui, un peu', coef: 1},
      // {value: 'heavy', label: 'Oui, beaucoup', coef: 2},
      {value: true, label: 'Oui', icon:'IconCanOpenWindowsNightYes', coef: 0},
      {value: false, label: 'Non', icon:'IconCanOpenWindowsNightNo', coef: 1},
    ],
  },
  {
    varname: 'externalGreenSpace',
    discomfortFamily: "environment",
    type: "radio-card",
    extraClasses: ['check-like-card'],
    label: 'Comment d\'écrirez-vous les espaces verts à proximite ?',
    description: null,
    default: null,
    validation: { required},
    choices: [
      {value: "light", label: 'Dense', coef: 0},
      {value: "dense", label: 'Léger', coef: 1},
      {value: "none", label: 'Inexistant', coef: 2},
    ],
  },

  //-- GEO
  {
    varname: "department",
    label: "Dans quel département se situe (ou se situera) le bâtiment ?",
    description: `Le département détermine la zone climatique du bâtiment`,
    default: null,
    type: "select",
    choices: DepartmentsList.departements,
    validation: { required },
  },
  {
    varname: "zoneClimat",
    discomfortFamily: "geography",
    label: 'Zone climatique du bâtiment',
    description: `La zone climatique a une incidence sur les apports en chaleur, et donc sur les inconforts d’été générés, dans un endroit donné.`,
    default: null,
    type: "readonly",
    isComponent: true,
    componentName: 'MapFranceZoneClimat',
    validation: { required },
    choices: [
      {value: 'H1a' ,label: 'H1a', coef: 1},
      {value: 'H2a' ,label: 'H2a', coef: 1},
      {value: 'H1b' ,label: 'H1b', coef: 2},
      {value: 'H1c' ,label: 'H1c', coef: 2},
      {value: 'H2b' ,label: 'H2b', coef: 2},
      {value: 'H2c' ,label: 'H2c', coef: 3},
      {value: 'H2d' ,label: 'H2d', coef: 3},
      {value: 'H3' ,label: 'H3', coef: 3},
    ],
    help: {
      title: "Zone climat",
      modalComponent: "HelpZoneClimat",
    },
  },
  // {
  //   varname: "zoneClimat",
  //   discomfortFamily: "geography",
  //   label: 'Dans quelle zone climatique se situe (ou se situera) le bâtiment ?',
  //   description: null,
  //   default: null,
  //   // displayType: "nbcol-1",
  //   type: "text",
  //   isComponent: true,
  //   componentName: 'MapFranceZoneClimat',
  //   validation: { required },
  //   choices: [
  //     {value: 'H1a' ,label: 'H1a', coef: 1},
  //     {value: 'H2a' ,label: 'H2a', coef: 1},
  //     {value: 'H1b' ,label: 'H1b', coef: 2},
  //     {value: 'H1c' ,label: 'H1c', coef: 2},
  //     {value: 'H2b' ,label: 'H2b', coef: 2},
  //     {value: 'H2c' ,label: 'H2c', coef: 3},
  //     {value: 'H2d' ,label: 'H2d', coef: 3},
  //     {value: 'H3' ,label: 'H3', coef: 3},
  //   ],
  //   help: {
  //     title: "Zone climat",
  //     modalComponent: "HelpZoneClimat",
  //   },
  // },
  {
    varname: "isLittoral",
    discomfortFamily: "geography",
    label: "Est-il en zone littoral ?",
    // description: `Pour savoir si votre bâtiment est situé en zone littoral, consultez la <a href="https://www.observatoire-des-territoires.gouv.fr/outils/cartographie-interactive/#c=indicator&i=typo_loilitt.zonage_loilitt&view=map53" target="_blank">carte interactive de l'Observatoire des territoires – Agence nationale de la cohésion des territoires</a>`,
    description: null,
    default: false,
    type: "icon",
    extraClasses: ['check-like-card'],
    validation: { required },
    choices: [
      {value: true ,label: 'Oui', icon:"IconIsLittoralYes", coef: 2},
      {value: false ,label: 'Non', icon:"IconIsLittoralNo", coef: 1}
    ],
    help: {
      title: "Zone littoral",
      modalComponent: "HelpIsLittoral",
    },
  },
  {
    varname: "altitude",
    discomfortFamily: "geography",
    label: "A quelle altitude est-il situé ?",
    // description: `La température de l’air diminue avec l’altitude. Selon le niveau d’altitude du bâtiment, la sensation d’inconfort l’été peut donc varier.`,
    description: null,
    default: 4,
    type: "radio-card",
    extraClasses: ['check-like-card'],
    validation: { required },
    choices: [
      {value: 1, label: "< 400 m", coef: 3},
      {value: 2, label: "entre 400 et 800 m", coef: 2},
      {value: 3, label: "> 800 m", coef: 1},
      {value: 4, label: "no-value", coef: 1, hidden: true},// valeur spéciale si la question n'est pas posée
    ],
    help: {
      title: "Altitude",
      modalComponent: "HelpAltitude",
    },
  },
  //-- /GEO


  /**
   * PAROIS OPAQUES
   */
  //-- Inertie
  // {
  //   varname: "inertiaLevel",
  //   label: "Niveau d'inertie ?",
  //   description: null,
  //   default: null,
  //   type: "radio-card",
  //   validation: { required },
  //   choices: [
  //     {value: 1, label: "Très lourde", coef: 0},
  //     {value: 2, label: "Lourde", coef: 1},
  //     {value: 3, label: "Moyenne", coef: 2},
  //     {value: 4, label: "Légère", coef: 2},
  //   ],
  // },
  {
    varname: "wallsComposition",
    label: "Quelle est la composition de la structure des murs ?",
    displayType: "nbcol-2",
    description: `Si la structure des murs est en béton mais que vous n’en connaissez pas la composition exacte, sélectionnez la réponse «&nbsp;Béton&nbsp;».`,
    default: null,
    type: "image",
    imagePrefixName:'wallsComposition',
    validation: { required },
    choices: [
      {value: "wood", label: "Ossature bois", coef: null},
      {value: "brickWall", label: "Mur en brique", coef: null},
      {value: "solidBrick", label: "Brique pleine", coef: null},
      {value: "stone", label: "Pierre", coef: null},
      {value: "aeratedConcrete", label: "Béton cellulaire", coef: null},
      {value: "cinderBlock", label: "Bloc béton", coef: null},
      {value: "concret", label: "Béton", coef: null},
      {value: "unknow", label: "Je ne sais pas", coef: null},
    ],
    help: {
      title: "Composition de la structure",
      modalComponent: "HelpWallsComposition",
    },
  },
  {
    varname: "floorComposition",
    label: "Quelle est la composition du plancher bas du bâtiment ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "image",
    imagePrefixName:'floorComposition',
    validation: { required },
    choices: [
      {value: "wood", label: "Bois", coef: null},
      // {value: "parget", label: "Plâtre", coef: null},
      // {value: "monomurBrick", label: "Brique monomur", coef: null},
      // {value: "aeratedConcrete", label: "Béton cellulaire", coef: null},
      {value: "solidBrick", label: "Entrevous terre cuite ou poutrelles béton", coef: null},
      {value: "concret", label: "Béton", coef: null},
      // {value: "stone", label: "Pierre", coef: null},
      {value: "unknow", label: 'Je ne sais pas', coef: null},
    ],
    // help: {
    //   title: "Composition de la structure",
    //   modalComponent: "HelpStructureComposition",
    // },
  },
  {
    varname: "floorType",
    label: "Quel est le type du plancher bas ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "image",
    imagePrefixName:'floorType',
    validation: { required },
    choices: [
      {value: "crawlspace", label: "Vide sanitaire", coef: 2},
      {value: "basement", label: "Sous-sol", coef: 1},
      {value: "ground", label: "Terre plein", coef: 0}
    ],
  },
  {
    varname: "roofComposition",
    label: "Quelle est la composition du plancher haut du bâtiment ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "image",
    imagePrefixName:'roofComposition',
    // validation: { required },
    conditionsToShow: [
      {formName: 'buildingRoof', varname: 'roofType', operand: 'in', value: ['lost', 'flat']}
    ],
    validation: {
      required: requiredIf(() => checkProjectSimuDatas([
        {formName: 'buildingRoof', varname: 'roofType', operand: 'in', value: ['lost', 'flat']}
      ]))
    },
    choices: [
      {value: "wood", label: "Plafond sous solive bois", coef: null},
      // {value: "parget", label: "Plâtre", coef: null},
      // {value: "monomurBrick", label: "Brique monomur", coef: null},
      // {value: "aeratedConcrete", label: "Béton cellulaire", coef: null},
      {value: "solidBrick", label: "Entrevous terre cuite ou poutrelles béton", coef: null},
      {value: "concret", label: "Béton", coef: null},
      {value: "parget", label: "Plaques de plâtre", coef: null},
      {value: "unknow", label: "Je ne sais pas", coef: null},
      // {value: "stone", label: "Pierre", coef: null},
    ],
    // help: {
    //   title: "Composition de la structure",
    //   modalComponent: "HelpStructureComposition",
    // },
  },
  {
    varname: "roofType",
    label: "Quel est le type de toiture du bâtiment ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "image",
    imagePrefixName:'roofType',
    validation: { required },
    choices: [
      {value: "lost", label: "Combles perdus", coef: null},
      {value: "converted", label: "Combles aménagés", coef: null},
      {value: "flat", label: "Toiture plate", coef: null}
    ],
  },
  {
    varname: "isAirBlower",
    discomfortFamily: "none",
    label: "Le bâtiment est-il équipé de brasseurs d'air fixes ?",
    description: null,
    default: null,
    type: "icon",
    validation: { required },
    choices: [
      {value: true, label: "Oui", icon: "IconIsFanYes", coef: null},
      {value: false, label: "Non", icon: "IconIsFanNo", coef: null}
    ],
    help: {
      title: "Brasseur d'air",
      modalComponent: "HelpIsAirBlower",
    },
  },

  
  // {
  //   varname: "vmcType",
  //   discomfortFamily: "ventilation",
  //   label: "Quel est le type de ventilation ?",
  //   description: null,
  //   default: null,
  //   type: "select",
  //   validation: { required },
  //   choices: [
  //     {value: "nat", label: "Naturelle", coef: 2},
  //     {value: "sf", label: "Simple flux", coef: 1},
  //     {value: "extact", label: "A extraction", coef: 1},
  //     {value: "df", label: "Double flux", coef: 0},
  //     {value: "insuf", label: "Insufflation", coef: 0},
  //     {value: "openPilot", label: "Ouverture pilote", coef: 0},
  //     {value: "unknow", label: "Je ne sais pas", coef: 2},
  //   ],
  // },
  {
    varname: "overventilationType",
    discomfortFamily: "ventilation",
    label: "Le bâtiment est-il équipé d'un système de surventilation ?",
    description: null,
    default: null,
    type: "radio-card",
    validation: { required },
    choices: [
      {value: 'none', label: "Non", coef: 2},
      {value: 'vmInsuff', label: "Oui, VM Insufflation", coef: 1},
      {value: 'vmcDF', label: "Oui, VMC Double Flux", coef: 0},
    ],
    help: {
      title: "Surventilation",
      modalComponent: "HelpOverventilationType",
    },
    
  },
  //-- CLIM
  {
    varname: "isClim",
    discomfortFamily: "none",
    label: "Le bâtiment est-il équipé d'un système de climatisation ?",
    description: null,
    default: null,
    type: "icon",
    validation: { required },
    choices: [
      {value: true, label: "Oui", icon: "IconIsClimYes", coef: null},
      {value: false, label: "Non", icon: "IconIsClimNo", coef: null}
    ],
    help: {
      title: "Sytème de climatisation",
      modalComponent: "HelpIsClim",
    },
  },
  {
    varname: "climType",
    discomfortFamily: "none",
    label: "Quel est le type de climatisation ?",
    description: null,
    default: null,
    type: "radio-card",
    validation: { required },
    choices: [
      {value: "mobile", label: "Mobile, monobloc ou split", coef: null},
      {value: "fixe", label: "Fixe Split ou centralisé multipslit", coef: null}
    ],
    help: {
      title: "Type de climatisation",
      modalComponent: "HelpClimType",
    },
  },
  {
    varname: "climNbRoom",
    discomfortFamily: "none",
    label: "Combien de pièces du bâtiment sont climatisées ?",
    description: null,
    default: null,
    type: "radio-card",
    validation: { required },
    choices: [
      {value: "2-", label: "1 ou 2 pièces", coef: null},
      {value: "2+", label: "Plus de 2 pièces", coef: null},
      {value: "all", label: "Toutes les pièces", coef: null},
      {value: 'none', label: "Aucune", coef: null, hidden: true},
    ],
    help: {
      title: "Nombre de pièces climatisées",
      modalComponent: "HelpClimNbRoom",
    },
    
  },
  //-- /CLIM
  {
    varname: "inertiaLevel",
    discomfortFamily: "buildingInertia",
    label: "Quel est l'inertie du bâtiment' ?",
    description: null,
    default: null,
    type: "__calculated",
    // validation: { required },
    choices: inertiaValues
  },
  //-- /Inertie
  {
    varname: "insulationLevel",
    discomfortFamily: "buildingInsulation",
    label: "Quel est le niveau d'isolation du bâtiment' ?",
    description: null,
    default: null,
    type: "__calculated",
    // validation: { required },
    choices: insulationValues
  },
  {
    varname: "wallsInsulationType",
    label: "Quel est le type d'isolation des murs ?",
    description: null,
    default: null,
    type: "select",
    conditionsToShow: [
      {formName: 'buildingConfig', varname: 'buildingConstructionYear', operand: '!==', value: '1974-'},
      {andor: '||', formName: 'buildingWalls', varname: 'wallsInsulationImprovement', operand: '!==', value: 'none'},
    ],
    validation: {
      required: requiredIf(() => checkProjectSimuDatas([
        {formName: 'buildingConfig', varname: 'buildingConstructionYear', operand: '!==', value: '1974-'},
        {andor: '||', formName: 'buildingWalls', varname: 'wallsInsulationImprovement', operand: '!==', value: 'none'},
      ]))
    },
    choices: [
      {value: "ite", label: "Isolation par l'extérieur", coef: null},
      {value: "iti", label: "Isolation par l'intérieur", coef: null},
      {value: "unknow", label: "Je ne sais pas", coef: null},
    ],
  },
  {
    varname: "roofInsulationType",
    label: "Quel est le type d'isolation de la toiture ?",
    description: null,
    default: null,
    type: "select",
    validation: { required },
    choices: [
      {value: "ite", label: "Isolation par l'extérieur", coef: null},
      {value: "iti", label: "Isolation par l'intérieur", coef: null},
      {value: "unknow", label: "Je ne sais pas", coef: null},
    ],
  },
  {
    varname: "wallsInsulationImprovement",
    label: "Y a-t-il eu des travaux d'isolation ?",
    description: null,
    default: null,
    type: "select",
    isComponent: true,
    componentName: 'QstInsulationImprovement',
    conditionsToShow: [
      {formName: 'buildingConfig', varname: 'buildingProjectState', operand: '!==', value: 'new'}
    ],
    validation: {
      // mustBeString,
      required: requiredIf(() => checkProjectSimuDatas([
        {formName: 'buildingConfig', varname: 'buildingProjectState', operand: '!==', value: 'new'}
      ]))
    },
  },
  {
    varname: "roofInsulationImprovement",
    label: "Y a-t-il eu des travaux d'isolation ?",
    description: null,
    default: null,
    type: "radio-card",
    extraClasses: ['check-like-card'],
    isComponent: true,
    componentName: 'QstInsulationImprovement',
    conditionsToShow: [
      {formName: 'buildingConfig', varname: 'buildingProjectState', operand: '!==', value: 'new'}
    ],
    validation: {
      // mustBeString,
      required: requiredIf(() => checkProjectSimuDatas([
        {formName: 'buildingConfig', varname: 'buildingProjectState', operand: '!==', value: 'new'}
      ]))
    },
  },
  {
    varname: "floorInsulationImprovement",
    label: "Y a-t-il eu des travaux d'isolation ?",
    description: null,
    default: null,
    type: "radio-card",
    extraClasses: ['check-like-card'],
    isComponent: true,
    componentName: 'QstInsulationImprovement',
    conditionsToShow: [
      {formName: 'buildingConfig', varname: 'buildingProjectState', operand: '!==', value: 'new'}
    ],
    validation: {
      // mustBeString,
      required: requiredIf(() => checkProjectSimuDatas([
        {formName: 'buildingConfig', varname: 'buildingProjectState', operand: '!==', value: 'new'}
      ]))
    },
  },
  {
    varname: "windowsIsShadow",
    discomfortFamily: "buildingWindows",
    label: "Y a-t-il de l'ombre portée sur le batiment ?",
    description: `dû à des obstacles à proximité (autres bâtiments, arbres, etc.)`,
    default: null,
    type: "icon",
    extraClasses: ['check-like-card'],
    // validation: { required, mustBeString},
    validation: { required},
    choices: [
      {value: true, label: 'Oui', icon:"IconIsShadowYes", coef: 0},
      {value: false, label: 'Non', icon:"IconIsShadowNo", coef: 1}
    ],
  },
  {
    varname: "windowsGlassType",
    discomfortFamily: "buildingWindows",
    label: "De quels types sont majoritairement les vitrages ?",
    description: null,
    default: null,
    type: "icon",
    extraClasses: ['check-like-card'],
    validation: { required },
    choices: [
      {value: "DV", label: "Double vitrage", icon:'IconWindowsGlassTypeDouble', coef: 0},
      {value: "SV", label: "Simple vitrage", icon:'IconWindowsGlassTypeSimple', coef: 1}
    ],
  },
  {
    varname: "windowsProtectionType",
    label: "Le bâtiment possède-il des protections solaires ?",
    description: `par exemple : stores intérieurs ou extérieurs, volets, brises soleil extérieurs, masques, stores, stores-banne`,
    default: null,
    type: "icon",
    extraClasses: ['check-like-card'],
    validation: {
      required,
    },
    choices: [
      {value: 'ext', label: "Oui, en extérieur", icon:"IconSolarProtectionExt", coef: 0},
      {value: 'int', label: "Oui, en intérieur", icon:"IconSolarProtectionInt", coef: 1},
      {value: 'none', label: "Non, aucune", icon:"IconSolarProtectionNone", coef: 2},
    ],
  },
  // {
  //   varname: "windowsProtectionPosition",
  //   discomfortFamily: "buildingWindows",
  //   label: "Quel est le type de protection solaire ?",
  //   description: null,
  //   default: null,
  //   type: "radio-card",
  //   validation: { required },
  //   choices: [
  //     {value: "int", label: "Intérieur", coef: 1},
  //     {value: "ext", label: "Extérieur", coef: 0},
  //     {value: "none", label: "Aucune", coef: 2},
  //   ],
  // },
  {
    varname: "windowsOrientations",
    discomfortFamily: "buildingWindows",
    label: "Quelles sont les orientations majoritaires des vitrages ?",
    description: "",
    default: null,
    type: "select",
    validation: { required },
    choices: [
      // {value: "N", label: "Nord", coef: 0},
      // {value: "E", label: "Est", coef: 2},
      // {value: "W", label: "Ouest", coef: 3},
      // {value: "S", label: "Sud", coef: 1},
      {value: "N", label: "Nord", coef: 0},
      {value: "NE", label: "Nord Est", coef: 1},
      {value: "E", label: "Est", coef: 2},
      {value: "SE", label: "Sud Est", coef: 1},
      {value: "S", label: "Sud", coef: 1},
      {value: "SW", label: "Sud Ouest", coef: 2},
      {value: "W", label: "Ouest", coef: 3},
      {value: "NW", label: "Nord Ouest", coef: 1},
    ],
    help: {
      title: "Orientation des vitrages",
      modalComponent: "HelpWindowsOrientations",
    },
  },
  {
    varname: "windowsRoomCrossover",
    discomfortFamily: "ventilation",
    label: "Le bâtiment est-il traversant ?",
    description: null,
    default: null,
    type: "icon",
    extraClasses: ['check-like-card'],
    validation: { required },
    choices: [
      {value: true, label: "Oui", icon:"IconWindowsRoomCrossoverYes", coef: 0},
      {value: false, label: "Non", icon:"IconWindowsRoomCrossoverNo", coef: 1}
    ],
    help: {
      title: "Bâtiment traversant",
      modalComponent: "HelpWindowsRoomCrossover",
    },
    
  },

  //-- ROOM
  {
    varname: "roomId",
    label: "roomId",
    description: null,
    default: null,
    type: 'hidden',
    validation: {required},
  },
  {
    varname: "roomType",
    label: "Quel est l'usage de la pièce à évaluer ?",
    description: null,
    default: null,
    type: "icon",
    help: {
      title: "Help Test !",
      modalComponent: "HelpTest",
    },
    validation: {
      required
    },
    choices: [
      {value: 'bedroom', label: "Chambre", icon:"IconBedroom", coef: null},
      {value: 'livingroom', label: "Séjour", icon:"IconLivingRoom", coef: null},
      {value: 'kitchen', label: "Cuisine", icon:"IconKitchen", coef: null},
    ],
  },
  {
    varname: "roomLevel",
    label: "A quel niveau se situe la pièce ?",
    description: null,
    default: null,
    type: "icon",
    help: {
      title: "Help Test !",
      modalComponent: "HelpTest",
    },
    validation: {
      required
    },
    choices: [
      {value: 'bottom', label: "Rez de chaussée", icon:"IconLevelBottom", coef: null},
      {value: 'middle', label: "Intermédiaire", icon:"IconLevelIntermed", coef: null},
      {value: 'top', label: "Dernier étage", icon:"IconLevelTop", coef: null},
    ],
  },
  {
    varname: "roomFloorComposition",
    label: "Quelle est la composition du sol ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "image",
    imagePrefixName:'floorComposition',
    validation: { required },
    choices: [
      {value: "wood", label: "Bois", coef: 2},
      // {value: "parget", label: "Plâtre", coef: 2},
      // {value: "monomurBrick", label: "Brique monomur", coef: 1},
      {value: "solidBrick", label: "Brique pleine", coef: 0},
      {value: "concret", label: "Béton", coef: 0},
      // {value: "stone", label: "Pierre", coef: 0},
    ],
  },
  {
    varname: "roomFlooring",
    label: "Quelle est le type de revêtement au sol ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "radio-card",
    extraClasses: ['check-like-card'],
    validation: { required },
    choices: [
      {value: "wood", label: "Bois", coef: null},
      {value: "carpet", label: "Moquette", coef: null},
      {value: "floorTile", label: "Carrelage", coef: null},
    ],
  },
  {
    varname: "roomRoofComposition",
    label: "Quelle est la composition du plafond ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "image",
    imagePrefixName:'roofComposition',
    validation: { required },
    choices: [
      {value: "wood", label: "Bois", coef: 2},
      // {value: "monomurBrick", label: "Brique monomur", coef: 1},
      {value: "solidBrick", label: "Brique pleine", coef: 0},
      {value: "concret", label: "Béton", coef: 0},
      // {value: "stone", label: "Pierre", coef: 0},
      {value: "parget", label: "Plâtre", coef: 2},
      {value: "unknow", label: "Je ne sais pas", coef: 2},
    ],
  },
  {
    varname: "roomShape",
    label: "Combien de murs donnent sur l'extérieur ?",
    displayType: "nbcol-2",
    description: null,
    default: null,
    type: "image",
    imagePrefixName:'roomShape',
    validation: { required },
    choices: [
      {value: 1, label: "4 murs extérieurs", coef: null},
      {value: 2, label: "3 murs extérieurs", coef: null},
      {value: 3, label: "2 murs extérieurs en angle (1)", coef: null},
      {value: 4, label: "2 murs extérieurs en angle (2)", coef: null},
      {value: 5, label: "2 murs extérieurs opposés", coef: null},
      {value: 6, label: "1 mur extérieur", coef: null},
    ],
  },
  {
    varname: "roomOrientationDeg",
    label: "Quelle est l'orientation de la pièce ?",
    description: null,
    default: 45,
    type: "number",
    validation: { required }
  },
  {
    varname: "isRoomFormValid",
    label: "Lorem blabla blabla... ?",
    description: "(bulle d'explication)",
    default: null,
    type: "radio-card",
    extraClasses: ['check-like-card'],
    validation: {
      required,
      mustBeTrue
    },
    choices: [
      {value: true, label: "Oui", coef: null},
      {value: false, label: "Non", coef: null}
    ],
  },
  //-- /ROOM

  //-- WALLS
  {
    varname: "wallId",
    label: "wallId",
    description: null,
    default: null,
    type: 'hidden',
    validation: {required},
  },
  {
    varname: "wallOrientation",
    label: "wallOrientation",
    description: null,
    default: null,
    type: "radio-card",
    validation: {required},
    choices: [
      {value: "N", label: "Nord", coef: 0},
      {value: "NE", label: "Nord Est", coef: 1},
      {value: "E", label: "Est", coef: 2},
      {value: "SE", label: "Sud Est", coef: 1},
      {value: "S", label: "Sud", coef: 1},
      {value: "SW", label: "Sud Ouest", coef: 2},
      {value: "W", label: "Ouest", coef: 3},
      {value: "NW", label: "Nord Ouest", coef: 1},
    ],
  },
  {
    varname: "wallType",
    label: "Type de mur ?",
    description: null,
    default: null,
    type: "radio-card",
    extraClasses: ['check-like-card'],
    help: {
      title: "Help Test !",
      modalComponent: "HelpTest",
    },
    validation: {
      // required,
    },
    choices: [
      {value: 'parget', label: "Plâtre", coef: null},
      {value: 'shearWall', label: "Mur de refend", coef: null}
    ],
  },
  {
    varname: "windowsProtectionControl",
    discomfortFamily: "buildingWindows",
    label: "Quel est le type de gestion des protections solaires ?",
    description: null,
    default: null,
    type: "icon",
    extraClasses: ['check-like-card'],
    help: {
      title: "La gestion des protections solaires",
      modalComponent: "HelpWindowsProtectionControl",
    },
    validation: {
      required,
    },
    choices: [
      {value: 'auto', label: "Automatisée", icon:"IconSolarProtectionControlAuto", coef: 0},
      {value: 'moto', label: "Motorisée", icon:"IconSolarProtectionControlMoto", coef: 1},
      {value: 'manual', label: "Manuelle", icon:"IconSolarProtectionControlManual", coef: 2},
      // {value: 'none', label: "Aucune gestion", coef: 2},
    ],
  },
  //-- /WALLS
]