<template>
  <div>
    <p>Recourir à un appareil de climatisation requiert un bon usage du système en&nbsp;:</p>
    <ul>
      <li>climatisant uniquement la ou les pièces qui sont occupées&nbsp;;</li>
      <li>pensant à bien éteindre les systèmes s’il n’y a pas d’occupant dans la pièce ou dans le bâtiment&nbsp;;</li>
      <li>opérant une température de consigne égale ou supérieure à 26°C (d’autant que le corps humain tolère difficilement des variations de températures supérieures à 10°C entre l’air intérieur et extérieur, pouvant également engendrer des chocs thermiques pour l’organisme).</li>
    </ul>
  </div>
</template>
