// utils/trackEvent.js
export function matomoTrackEvent(category, action, name, value) {
  if (window._paq) {
    window._paq.push(["trackEvent", category, action, name, value]);
  }
}

export function matomoTrackPageView() {
  if (window._paq) {
    window._paq.push(["trackPageView"]);
  }
}
