<template>
  <div>
    <p>
      Les matériaux qui composent la structure de l’enveloppe jouent un rôle
      important dans la régulation de la chaleur à l’intérieur du bâtiment,
      grâce à l’inertie thermique.
    </p>
    <p>
      Identifier le type de structure des murs d’un bâtiment est donc important
      à considérer en matière de confort d’été. Voici quelques indications pour
      vous aider :
    </p>
    <p>
      Pour les logements collectifs, et selon l’année de construction du
      bâtiment :
    </p>
    <ul>
      <li>avant les années 1900&nbsp;: pierre&nbsp;;</li>
      <li>de 1900 à 1950 environ&nbsp;: béton ou brique pleine&nbsp;;</li>
      <li>de 1950 aux années 2000&nbsp;: béton ou mur en brique.</li>
    </ul>
    <p>
      Les maisons individuelles sont quant à elles principalement construites en
      béton/bloc béton/béton cellulaire, pierre ou ossature bois.
    </p>
    <p>
      Si vous êtes dans l’impossibilité de connaître cette information, veuillez
      cocher «&nbsp;Je ne sais pas&nbsp;» (ce qui appliquera par défaut un critère
      défavorable en matière d’inertie de la structure des murs).
    </p>
    <hr>
    <p>Pour aller plus loin : <a href="https://lab.cercle-promodul.inef4.org/knowledge/post/inertie-thermique" target="_blank">Inertie thermique</a>, Cercle Promodul / INEF4</p>
    

  </div>
</template>
