<template>
  <div>
    <p>L'ensemble des données précédemment renseignées dans l'outil vont permettre d'évaluer le confort d'été à l'échelle du bâtiment dans un premier temps.</p>
    <p>Cette évaluation tient compte :</p>
    <ul>
      <li>de la situation géographique du bâtiment</li>
      <li>de son orientation et de son environnement ;</li>
      <li>des éléments composants l'enveloppe du bâtiment ;</li>
      <li>des équipements et comportements des occupants.</li>
    </ul>
  </div>
</template>