<template>

  <!-- <pre>{{ simuData }}</pre> -->
  <!-- <pre>{{ wallsComposition }}</pre> -->

    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['roofType']"
    v-model="theValue['roofType']"
    :qstParams="getQstParam('roofType')"
    />

    <!-- <template v-if="theValue['roofType'] === 'converted'"> -->
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['roofColor']"
      v-model="theValue['roofColor']"
      :qstParams="getQstParam('roofColor')"
      />
    <!-- </template> -->
    <TransitionQst class="transition">
    <template v-if="theValue['roofType'] !== 'converted'">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[formName]['roofComposition']"
      v-model="theValue['roofComposition']"
      :qstParams="getQstParam('roofComposition')"
      />
    </template>
    </TransitionQst>

    <TransitionQst class="transition">
    <template v-if="!isNew">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['roofInsulationImprovement']"
      v-model="theValue['roofInsulationImprovement']"
      :qstParams="getQstParam('roofInsulationImprovement')"
      />
    </template>
    </TransitionQst>

    <div v-if="isDebug" class="debug-bloc">
      roofInsulationType: {{ theValue['roofInsulationType'] }}
    </div>

    <TransitionQst class="transition">
    <template v-if="theValue['roofInsulationImprovement'] !== 'none' && theValue['roofInsulationImprovement'] !== 'unknow'">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['roofInsulationType']"
      v-model="theValue['roofInsulationType']"
      :qstParams="getQstParam('roofInsulationType')"
      />
    </template>
  </TransitionQst>



  <!-- formName: <pre>{{ formName }}</pre> -->
  <!-- inputValidationObj: <pre>{{ props.validationObj[formName] }}</pre> -->

<!-- theValue['roofType']: {{ theValue['roofType'] }}<br />
theValue['roofComposition']: {{ theValue['roofComposition'] }}<br />
theValue['roofColor']: {{ theValue['roofColor'] }}<br /> -->

</template>

<script setup>
  import { ref, watch, computed } from 'vue';
  import { useStore } from 'vuex';
  import InputFormRowGlobal from './InputFormRowGlobal.vue';

  const props = defineProps({
  stepId: {
    type: Number,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  },
  formName: {
      type: String,
      required: true
    },
  formQst: {
    type: Array,
    required: true
  },
  validationObj: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);

const theValue = ref(props.modelValue[[props.formName]]);
watch (theValue, (newVal) => {
    emit('update:modelValue', newVal)
  });

const getQstParam = (varname) => {
  const param = props.formQst.find((qst) => qst.varname === varname);
  return param;
}

  //-- FOR THE SPECIFIC FORM
const store = useStore();
const simuData = computed(() => store.getters.simuData);
const isNew = computed(() => simuData.value.buildingConfig.buildingProjectState === 'new');

const isDebug = computed(() => store.getters.isDebug);
const roofType = computed(() => theValue.value['roofType']);
watch(roofType, (newVal) => {
  theValue.value['roofComposition'] = newVal === 'converted' ? 'wood' : null;
  // theValue.value['roofColor'] = newVal !== 'converted' ? 'light' : null;
}, {deep: true});

const roofInsulationImprovement = computed(() => theValue.value['roofInsulationImprovement']);
watch (roofInsulationImprovement, (newVal) => {
  if (newVal === 'none') {
    theValue.value['roofInsulationType'] = 'ite';
  } else if (newVal === 'unknow') {
    theValue.value['roofInsulationType'] = 'iti'
  }
}, {deep: true});


// const wallsComposition = computed(() => simuData.value.buildingWalls?.wallsComposition);
// watch(wallsComposition, (newVal) => {
//   if (newVal === 'concret' && theValue.value['roofComposition'] === null) {
//     theValue.value['roofComposition'] = newVal;
//   }
// });
</script>
