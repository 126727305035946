<template>
  <!-- score: {{ props.score }}
  cursorPosition: {{ cursorPosition }}

  colorWay: <pre>{{ colorWay }}</pre>
  steps: <pre>{{ steps }}</pre>
  stepToUse: <pre>{{ stepToUse }}</pre>
  stepsInfos: <pre>{{ stepsInfos }}</pre>
   -->
   <!-- gaugeActiveStep: <pre>{{ gaugeActiveStep }}</pre> -->
  <div class="chart-gauge-wrapper">
    <div class="chart-gauge">
      <!--  -->
      <!-- <div class="scoreCursor" :style="{left: cursorPosition + '%' }"></div> -->
      <div class="final-score-steps">
        <div
          class="final-score-step"
          v-for="(step, stepIndex) in stepToUse"
          :key="`step_${stepIndex}`"
          :class="{'final-score-active': stepIndex === gaugeActiveStep}"
        >
          <div class="final-score-step-label">{{ step.label }}</div>
          <div class="final-score-indic"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
  score: {
    type: Number,
    required: true,
  },
});

const isBadToGoodScore = ref(true);

// const cursorPosition = computed(() => {
//   return Math.round(100 - props.score);
// })

// const scoreStep = computed(() => {
//   const nbSteps = steps.length - 1;
//   // const stepBase = 100 / nbSteps;
//   const scoreBase = Math.round(props.score / nbSteps);

//   return scoreBase
// })

const steps = [
  // { label: "Très mauvais" },
  // { label: "Mauvais" },
  // { label: "Moyen" },
  // { label: "Bon" },
  // { label: "Très Bon" }
  { label: "Très Bon" },
  { label: "Bon" },
  { label: "Moyen" },
  { label: "Mauvais" },
  { label: "Très mauvais" }
];

const stepToUse = computed(() => {
  return isBadToGoodScore.value ? [...steps].reverse() : steps.value;
})

const colorWay = computed(() => {
  return isBadToGoodScore.value ? '-90deg' : '90deg';
})



const stepsInfos = computed(() => {
  const nbSteps = stepToUse.value.length;
  const stepBase = 100 / nbSteps;

  let stepsValues = [];
  let min = 0;
  let max = 0;
  for(let i = 1; i <= nbSteps; i++) {
    max =  Math.round(i * stepBase);
    stepsValues.push({min, max});
    min = max;
  }

  stepsValues = isBadToGoodScore.value ? stepsValues.reverse() : stepsValues
  // stepsValues.reverse();

  return {nbSteps, stepBase, stepsValues}

})

const gaugeActiveStep = computed(() => {
  let activeStep = null;
  stepsInfos.value.stepsValues.forEach((step, stepIndex) => {
    if(props.score >= step.min && props.score <= step.max) {
      activeStep = stepIndex;
      return;
    }
  })
  return activeStep;
})

// const stepsWidth = 100 / steps.length;
</script>

<style lang="scss">
.chart-gauge-wrapper {
  --colors-way: v-bind(colorWay);
  width: 100%;
  height: 50px;
  // border: 2px solid red;
  .chart-gauge {
    position: relative;
    background: rgb(5, 1, 255);
    background: linear-gradient(
      var(--colors-way),
      rgba(5, 1, 255, 1) 0%,
      rgba(1, 134, 255, 1) 25%,
      rgba(246, 239, 0, 1) 50%,
      rgba(255, 159, 3, 1) 75%,
      rgba(255, 3, 3, 1) 100%
    );
    height: 100%;

    border-radius: 1rem;

    .scoreCursor {
      position: absolute;
      top:0;
      width: 10px;
      height: 100%;
      background-color: red
    }

    .final-score-steps {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;

      .final-score-step {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        border-left: 2px solid white;
        // border-right: 1px solid green;
        text-align: center;
        color: #333;
        font-weight: 400;
        background-color: rgba(255,255,255,0.6);

        &:first-child{
          // border-radius: 1rem 0 0 1rem;
          border-left: 0;
        }
        // &:last-child{
        //   // border-radius: 0 1rem 1rem 0;
        //   border-right: 0;
        // }

        .final-score-indic {
          display: none;
        }

        &.final-score-active{
          background-color: transparent;
          color: #fff;

          .final-score-step-label {
            padding: 0.3rem;
            border-radius: 0.5rem;
            background-color: rgba(0,0,0,0.3);
          }

          .final-score-indic {
            position: absolute;
            top: 2.2rem;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 15px 30px 15px;
            border-color: transparent transparent #333 transparent;
          }
        }
      }
    }
  }
}
</style>
