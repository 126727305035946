<template>

  <p> L'atténuation de <strong>la chaleur ressentie dans les bâtiments ne dépend pas uniquement des améliorations à apporter sur les structures ou sur les équipements : nos comportements jouent un rôle essentiel dans la recherche du confort</strong> et doivent évoluer dans le bon sens. <a class="btn btn-link" data-bs-toggle="collapse" href="#IntroBuildingDesc" role="button" aria-expanded="false" aria-controls="IntroBuildingDesc">
    Lire la suite
  </a></p>



  <div class="collapse" id="IntroBuildingDesc">
  <div class="card card-body">
    <p>Des gestes simples peuvent être mis en place par l'usager pour atténuer le ressentit des fortes chaleurs dans l'habitat, et ainsi modifier notre rapport aux solutions technologiques, par exemple :</p>

<ul>
  <li>Anticiper la fermeture des fenêtres et volets</li>
  <li>Faire circuler l'air en deuxième partie de nuit jusqu'à l'aube (température les plus fraiches).</li>
  <li>Limiter le recours aux appareils qui produisent de la chaleur</li>
</ul>
  </div>
</div>



</template>