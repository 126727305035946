<template>

  <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['externalSurroundings']"
      v-model="theValue['externalSurroundings']"
      :qstParams="getQstParam('externalSurroundings')"
      />

  <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['externalGreenSpace']"
    v-model="theValue['externalGreenSpace']"
    :qstParams="getQstParam('externalGreenSpace')"
    />

      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['externalGroundType']"
      v-model="theValue['externalGroundType']"
      :qstParams="getQstParam('externalGroundType')"
      />

    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['canOpenWindowsNight']"
    v-model="theValue['canOpenWindowsNight']"
    :qstParams="getQstParam('canOpenWindowsNight')"
    />

    <TransitionQst class="transition">
    <div v-if="theValue['canOpenWindowsNight']">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[formName]['userBehaviorVentil']"
      v-model="theValue['userBehaviorVentil']"
      :qstParams="getQstParam('userBehaviorVentil')"
      />
    </div>
    </TransitionQst>

</template>

<script setup>
  import { ref, watch, computed} from 'vue';
  // import { useStore } from 'vuex';
  import InputFormRowGlobal from './InputFormRowGlobal.vue';

  const props = defineProps({
  stepId: {
    type: Number,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  },
  formName: {
      type: String,
      required: true
    },
  formQst: {
    type: Array,
    required: true
  },
  validationObj: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);

const theValue = ref(props.modelValue[[props.formName]]);
watch (theValue, (newVal) => {
    emit('update:modelValue', newVal)
  });

const getQstParam = (varname) => {
  const param = props.formQst.find((qst) => qst.varname === varname);
  return param;
}

  //-- FOR THE SPECIFIC FORM
const canOpenWindowsNight = computed(() => theValue.value['canOpenWindowsNight']);
watch(canOpenWindowsNight, (newVal) => {

  if (newVal === true) {
    theValue.value['userBehaviorVentil'] = null;
  } else {
    theValue.value['userBehaviorVentil'] = 'bad';
  }
}, {deep: true});

</script>
