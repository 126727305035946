<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="DOUBLE">
	<polygon  id="D_x5F_Horiz" fill="#676767" points="226.367,269.223 196.173,252.043 393.486,209.274 422.493,220.489 	"/>
	<polygon  id="D_x5F_Verti" fill="#676767" points="422.083,220.331 393.004,209.087 393.004,44.094 422.083,40.576 	"/>
	<polygon class="item-to-focus" id="D_x5F_Verre2" opacity="0.6" fill="#009FE3" stroke="#27348B" stroke-width="4" stroke-miterlimit="10" points="
		196.173,252.043 393.004,209.378 393.004,44.094 196.173,30.776 	"/>
	<polygon class="item-to-focus" id="D_x5F_Verre1" opacity="0.6" fill="#009FE3" stroke="#27348B" stroke-width="4" stroke-miterlimit="10" points="
		226.367,110.748 226.367,117.414 226.367,269.223 422.083,220.59 422.083,40.576 326.617,33.168 	"/>
</g>
</svg>

</template>