<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="590px"
	 height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">

<g>
	<polygon id="verre" opacity="0.3" fill="#009FE3" points="298.854,52.502 160.609,52.502 160.609,247.498 298.854,247.498 
		437.099,247.498 437.099,52.502 	"/>
	<path id="cadre" d="M306.537,44.818H291.17H152.926v210.363H291.17h15.367h138.244V44.818H306.537z M291.17,239.814H168.292V60.186
		H291.17V239.814z M429.415,239.814H306.537V60.186h122.878V239.814z"/>
	
		<rect id="EXT_1_" x="152.926" y="44.818" opacity="0.85" fill="currentColor" stroke="#333333" stroke-width="5" stroke-miterlimit="10" width="291.855" height="140.228"/>
</g>
</svg>

</template>