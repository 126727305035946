<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="PERIURBAIN">
	<path fill="none" d="M321.49,173.574h-14.032c-2.322,0-4.209,1.887-4.209,4.211v14.033c0,2.322,1.887,4.209,4.209,4.209h14.032
		c2.326,0,4.21-1.887,4.21-4.209v-14.033C325.701,175.46,323.816,173.574,321.49,173.574z"/>
	<path fill="none" d="M366.393,207.251H352.36c-2.322,0-4.209,1.885-4.209,4.209v14.031c0,2.324,1.887,4.211,4.209,4.211h14.033
		c2.324,0,4.209-1.887,4.209-4.211V211.46C370.602,209.136,368.717,207.251,366.393,207.251z"/>
	<path fill="none" d="M321.49,139.9h-14.032c-2.322,0-4.209,1.883-4.209,4.208v14.032c0,2.323,1.887,4.209,4.209,4.209h14.032
		c2.326,0,4.21-1.886,4.21-4.209v-14.032C325.701,141.783,323.816,139.9,321.49,139.9z"/>
	<path fill="none" d="M155.207,189.949c0,0.883,0.605,1.236,1.393,0.793l14.327-8.365c0.76-0.447,1.391-0.092,1.391,0.787v6.799
		c-0.052,1.014-0.472,1.961-1.181,2.691l-14.774,13.793c-0.694,0.727-1.101,1.676-1.155,2.678v5.342
		c27.791-3.549,48.348-16.018,48.348-30.9c0-8.139-6.179-15.536-16.282-21.178c0.065-0.923,0.159-1.853,0.159-2.782
		c0-22.061-21.649-39.936-48.36-39.936c-26.707,0-48.366,17.875-48.366,39.936c0,0.929,0.098,1.859,0.177,2.782
		c-10.108,5.642-16.295,13.039-16.295,21.178c0,16.301,24.633,29.705,56.429,31.666v-6.107c0-0.885-0.538-2.082-1.207-2.652
		l-21.767-18.863c-0.033-0.033-0.066-0.059-0.099-0.092c-0.571-0.584-0.563-1.52,0.027-2.094l2.762-2.729
		c0.695-0.631,1.705-0.779,2.558-0.383l16.301,8.496c0.787,0.402,1.424,0.023,1.424-0.852v-20.77
		c0.044-0.935,0.642-1.761,1.528-2.099l21.117-6.981c0.852-0.28,1.546,0.215,1.546,1.092V189.949z"/>
	<path fill="none" d="M321.49,207.251h-14.032c-2.322,0-4.209,1.885-4.209,4.209v14.031c0,2.324,1.887,4.211,4.209,4.211h14.032
		c2.326,0,4.21-1.887,4.21-4.211V211.46C325.701,209.136,323.816,207.251,321.49,207.251z"/>
	<path fill="none" d="M422.52,106.223h-14.032c-2.321,0-4.21,1.886-4.21,4.209v14.034c0,2.32,1.889,4.207,4.21,4.207h14.032
		c2.324,0,4.209-1.887,4.209-4.207v-14.034C426.729,108.108,424.844,106.223,422.52,106.223z"/>
	<path fill="none" d="M422.52,139.9h-14.032c-2.321,0-4.21,1.883-4.21,4.208v14.032c0,2.323,1.889,4.209,4.21,4.209h14.032
		c2.324,0,4.209-1.886,4.209-4.209v-14.032C426.729,141.783,424.844,139.9,422.52,139.9z"/>
	<path fill="none" d="M366.393,173.574H352.36c-2.322,0-4.209,1.887-4.209,4.211v14.033c0,2.322,1.887,4.209,4.209,4.209h14.033
		c2.324,0,4.209-1.887,4.209-4.209v-14.033C370.602,175.46,368.717,173.574,366.393,173.574z"/>
	<path fill="none" d="M366.393,139.9H352.36c-2.322,0-4.209,1.883-4.209,4.208v14.032c0,2.323,1.887,4.209,4.209,4.209h14.033
		c2.324,0,4.209-1.886,4.209-4.209v-14.032C370.602,141.783,368.717,139.9,366.393,139.9z"/>
	<path opacity="0.5" fill="currentColor" d="M415.277,202.993h-6.854c-1.135,0-2.057,0.918-2.057,2.055v6.855
		c0,1.135,0.922,2.055,2.057,2.055h6.854c1.136,0,2.056-0.92,2.056-2.055v-6.855C417.333,203.911,416.413,202.993,415.277,202.993z"
		/>
	<path opacity="0.5" fill="currentColor" d="M437.21,235.891h-6.854c-1.134,0-2.056,0.92-2.056,2.057v6.854
		c0,1.135,0.922,2.057,2.056,2.057h6.854c1.136,0,2.057-0.922,2.057-2.057v-6.854C439.267,236.811,438.346,235.891,437.21,235.891z"
		/>
	<path opacity="0.5" fill="currentColor" d="M437.21,219.44h-6.854c-1.134,0-2.056,0.922-2.056,2.057v6.855
		c0,1.135,0.922,2.055,2.056,2.055h6.854c1.136,0,2.057-0.92,2.057-2.055v-6.855C439.267,220.362,438.346,219.44,437.21,219.44z"/>
	<path opacity="0.5" fill="currentColor" d="M494.083,263.378c0.418,0,0.828-0.026,1.234-0.071h-1.234V263.378z"/>
	<path opacity="0.5" fill="currentColor" d="M437.21,202.993h-6.854c-1.134,0-2.056,0.918-2.056,2.055v6.855
		c0,1.135,0.922,2.055,2.056,2.055h6.854c1.136,0,2.057-0.92,2.057-2.055v-6.855C439.267,203.911,438.346,202.993,437.21,202.993z"
		/>
	<path opacity="0.5" fill="currentColor" d="M415.277,235.891h-6.854c-1.135,0-2.057,0.92-2.057,2.057v6.854
		c0,1.135,0.922,2.057,2.057,2.057h6.854c1.136,0,2.056-0.922,2.056-2.057v-6.854C417.333,236.811,416.413,235.891,415.277,235.891z
		"/>
	<path opacity="0.5" fill="currentColor" d="M415.277,219.44h-6.854c-1.135,0-2.057,0.922-2.057,2.057v6.855
		c0,1.135,0.922,2.055,2.057,2.055h6.854c1.136,0,2.056-0.92,2.056-2.055v-6.855C417.333,220.362,416.413,219.44,415.277,219.44z"/>
	<path opacity="0.5" fill="currentColor" d="M492.042,235.891h-6.854c-1.135,0-2.057,0.92-2.057,2.057v6.854
		c0,1.135,0.922,2.057,2.057,2.057h6.854c1.135,0,2.057-0.922,2.057-2.057v-6.854C494.099,236.811,493.177,235.891,492.042,235.891z
		"/>
	<path opacity="0.5" fill="currentColor" d="M464.626,219.44h-6.854c-1.135,0-2.057,0.922-2.057,2.057v6.855
		c0,1.135,0.922,2.055,2.057,2.055h6.854c1.136,0,2.057-0.92,2.057-2.055v-6.855C466.683,220.362,465.762,219.44,464.626,219.44z"/>
	<path opacity="0.5" fill="currentColor" d="M505.308,159.543c0-4.647-3.771-8.416-8.419-8.416h-47.708V92.19
		c0-4.647-3.769-8.419-8.419-8.419h-50.516c-4.646,0-8.419,3.771-8.419,8.419v25.259h-22.45V89.385c0-3.103-2.514-5.613-5.613-5.613
		h-5.613c-3.097,0-5.612,2.511-5.612,5.613v28.064h-22.452V89.385c0-3.103-2.514-5.613-5.613-5.613h-5.613
		c-3.097,0-5.613,2.511-5.613,5.613v28.064h-14.032c-4.647,0-8.418,3.77-8.418,8.417v126.288c0,2.298,0.694,4.434,1.881,6.213
		h112.749c-0.018-0.179-0.027-0.36-0.027-0.544v-61.684c0-2.271,1.843-4.113,4.113-4.113h6.854v-13.709
		c0-1.514,1.229-2.742,2.741-2.742h2.742c1.514,0,2.741,1.229,2.741,2.742v13.709h10.967v-13.709c0-1.514,1.229-2.742,2.741-2.742
		h2.742c1.514,0,2.741,1.229,2.741,2.742v13.709h10.966v-12.338c0-2.271,1.843-4.113,4.112-4.113h24.676
		c2.271,0,4.112,1.842,4.112,4.113v28.787h23.303c2.271,0,4.112,1.842,4.112,4.111v41.887c0.157-0.749,0.243-1.524,0.243-2.319
		V159.543z M325.701,225.492c0,2.324-1.885,4.211-4.21,4.211h-14.032c-2.322,0-4.209-1.887-4.209-4.211V211.46
		c0-2.324,1.887-4.209,4.209-4.209h14.032c2.326,0,4.21,1.885,4.21,4.209V225.492z M325.701,191.818c0,2.322-1.885,4.209-4.21,4.209
		h-14.032c-2.322,0-4.209-1.887-4.209-4.209v-14.033c0-2.324,1.887-4.211,4.209-4.211h14.032c2.326,0,4.21,1.887,4.21,4.211V191.818
		z M325.701,158.141c0,2.323-1.885,4.209-4.21,4.209h-14.032c-2.322,0-4.209-1.886-4.209-4.209v-14.032
		c0-2.325,1.887-4.208,4.209-4.208h14.032c2.326,0,4.21,1.883,4.21,4.208V158.141z M370.602,225.492
		c0,2.324-1.885,4.211-4.209,4.211H352.36c-2.322,0-4.209-1.887-4.209-4.211V211.46c0-2.324,1.887-4.209,4.209-4.209h14.033
		c2.324,0,4.209,1.885,4.209,4.209V225.492z M370.602,191.818c0,2.322-1.885,4.209-4.209,4.209H352.36
		c-2.322,0-4.209-1.887-4.209-4.209v-14.033c0-2.324,1.887-4.211,4.209-4.211h14.033c2.324,0,4.209,1.887,4.209,4.211V191.818z
		 M370.602,158.141c0,2.323-1.885,4.209-4.209,4.209H352.36c-2.322,0-4.209-1.886-4.209-4.209v-14.032
		c0-2.325,1.887-4.208,4.209-4.208h14.033c2.324,0,4.209,1.883,4.209,4.208V158.141z M426.729,158.141
		c0,2.323-1.885,4.209-4.209,4.209h-14.032c-2.321,0-4.21-1.886-4.21-4.209v-14.032c0-2.325,1.889-4.208,4.21-4.208h14.032
		c2.324,0,4.209,1.883,4.209,4.208V158.141z M426.729,124.466c0,2.32-1.885,4.207-4.209,4.207h-14.032
		c-2.321,0-4.21-1.887-4.21-4.207v-14.034c0-2.323,1.889-4.209,4.21-4.209h14.032c2.324,0,4.209,1.886,4.209,4.209V124.466z"/>
	<path opacity="0.5" fill="currentColor" d="M492.042,219.44h-6.854c-1.135,0-2.057,0.922-2.057,2.057v6.855
		c0,1.135,0.922,2.055,2.057,2.055h6.854c1.135,0,2.057-0.92,2.057-2.055v-6.855C494.099,220.362,493.177,219.44,492.042,219.44z"/>
	<path opacity="0.5" fill="currentColor" d="M464.626,202.993h-6.854c-1.135,0-2.057,0.918-2.057,2.055v6.855
		c0,1.135,0.922,2.055,2.057,2.055h6.854c1.136,0,2.057-0.92,2.057-2.055v-6.855C466.683,203.911,465.762,202.993,464.626,202.993z"
		/>
	<path opacity="0.5" fill="currentColor" d="M464.626,186.542h-6.854c-1.135,0-2.057,0.92-2.057,2.057v6.854
		c0,1.135,0.922,2.059,2.057,2.059h6.854c1.136,0,2.057-0.924,2.057-2.059v-6.854C466.683,187.461,465.762,186.542,464.626,186.542z
		"/>
	<path fill="currentColor" d="M495.317,263.307h4.265c3.027,0,5.482-2.457,5.482-5.484v-3.35
		C504.077,259.164,500.16,262.776,495.317,263.307z"/>
	<path fill="currentColor" d="M500.952,208.475h-23.303v-28.787c0-2.271-1.842-4.113-4.112-4.113h-24.676
		c-2.27,0-4.112,1.842-4.112,4.113v12.338h-10.966v-13.709c0-1.514-1.228-2.742-2.741-2.742H428.3c-1.512,0-2.741,1.229-2.741,2.742
		v13.709h-10.967v-13.709c0-1.514-1.228-2.742-2.741-2.742h-2.742c-1.512,0-2.741,1.229-2.741,2.742v13.709h-6.854
		c-2.27,0-4.113,1.842-4.113,4.113v61.684c0,0.184,0.01,0.365,0.027,0.544h98.655v4.94h1.234c4.843-0.531,8.76-4.144,9.747-8.834
		v-41.887C505.064,210.317,503.224,208.475,500.952,208.475z M417.333,244.801c0,1.135-0.92,2.057-2.056,2.057h-6.854
		c-1.135,0-2.057-0.922-2.057-2.057v-6.854c0-1.137,0.922-2.057,2.057-2.057h6.854c1.136,0,2.056,0.92,2.056,2.057V244.801z
		 M417.333,228.352c0,1.135-0.92,2.055-2.056,2.055h-6.854c-1.135,0-2.057-0.92-2.057-2.055v-6.855c0-1.135,0.922-2.057,2.057-2.057
		h6.854c1.136,0,2.056,0.922,2.056,2.057V228.352z M417.333,211.903c0,1.135-0.92,2.055-2.056,2.055h-6.854
		c-1.135,0-2.057-0.92-2.057-2.055v-6.855c0-1.137,0.922-2.055,2.057-2.055h6.854c1.136,0,2.056,0.918,2.056,2.055V211.903z
		 M439.267,244.801c0,1.135-0.921,2.057-2.057,2.057h-6.854c-1.134,0-2.056-0.922-2.056-2.057v-6.854
		c0-1.137,0.922-2.057,2.056-2.057h6.854c1.136,0,2.057,0.92,2.057,2.057V244.801z M439.267,228.352
		c0,1.135-0.921,2.055-2.057,2.055h-6.854c-1.134,0-2.056-0.92-2.056-2.055v-6.855c0-1.135,0.922-2.057,2.056-2.057h6.854
		c1.136,0,2.057,0.922,2.057,2.057V228.352z M439.267,211.903c0,1.135-0.921,2.055-2.057,2.055h-6.854
		c-1.134,0-2.056-0.92-2.056-2.055v-6.855c0-1.137,0.922-2.055,2.056-2.055h6.854c1.136,0,2.057,0.918,2.057,2.055V211.903z
		 M466.683,228.352c0,1.135-0.921,2.055-2.057,2.055h-6.854c-1.135,0-2.057-0.92-2.057-2.055v-6.855
		c0-1.135,0.922-2.057,2.057-2.057h6.854c1.136,0,2.057,0.922,2.057,2.057V228.352z M466.683,211.903
		c0,1.135-0.921,2.055-2.057,2.055h-6.854c-1.135,0-2.057-0.92-2.057-2.055v-6.855c0-1.137,0.922-2.055,2.057-2.055h6.854
		c1.136,0,2.057,0.918,2.057,2.055V211.903z M466.683,195.452c0,1.135-0.921,2.059-2.057,2.059h-6.854
		c-1.135,0-2.057-0.924-2.057-2.059v-6.854c0-1.137,0.922-2.057,2.057-2.057h6.854c1.136,0,2.057,0.92,2.057,2.057V195.452z
		 M494.099,244.801c0,1.135-0.922,2.057-2.057,2.057h-6.854c-1.135,0-2.057-0.922-2.057-2.057v-6.854
		c0-1.137,0.922-2.057,2.057-2.057h6.854c1.135,0,2.057,0.92,2.057,2.057V244.801z M494.099,228.352
		c0,1.135-0.922,2.055-2.057,2.055h-6.854c-1.135,0-2.057-0.92-2.057-2.055v-6.855c0-1.135,0.922-2.057,2.057-2.057h6.854
		c1.135,0,2.057,0.922,2.057,2.057V228.352z"/>
	<path fill="currentColor" d="M216.076,241.921c0.541,0.328,1.064,0.662,1.568,0.992c0.501,0.332,0.999,0.684,1.502,1.057
		c0.253-1.449,0.65-2.863,1.194-4.258c0.539-1.385,1.188-2.701,1.942-3.943c-1.084-2.736-2.643-5.148-4.671-7.236
		c-2.027-2.094-4.421-3.699-7.179-4.816c1.839,2.32,3.26,4.887,4.265,7.701c1.003,2.818,1.504,5.803,1.504,8.949
		c0,0.246-0.022,0.506-0.065,0.777C216.095,241.412,216.076,241.671,216.076,241.921z"/>
	<path fill="currentColor" d="M227.826,244.375c1.534-1.264,3.217-2.33,5.056-3.197c0.958-2.613,2.318-4.973,4.074-7.086
		c1.752-2.111,3.806-3.893,6.14-5.342c-2.669,0.082-5.185,0.662-7.55,1.736c-2.359,1.08-4.421,2.518-6.179,4.318
		c-1.752,1.803-3.137,3.887-4.14,6.244c-1.003,2.359-1.501,4.883-1.501,7.576C224.938,247.054,226.312,245.636,227.826,244.375z"/>
	<path fill="currentColor" d="M170.179,256.587c0.63-0.625,0.459-1.393-0.381-1.705c0,0-14.591-5.418-14.591-15.402v-8.957
		c36.791-4.434,64.472-23.766,64.472-46.957c0-10.868-6.115-20.892-16.374-28.933c-0.013-0.006-0.025-0.006-0.04-0.019
		c-2.925-28.534-30.53-50.921-64.194-50.921c-33.673,0-61.277,22.387-64.196,50.921c-0.006,0.013-0.026,0.013-0.039,0.019
		c-10.268,8.041-16.375,18.064-16.375,28.933c0,24.855,31.817,45.279,72.546,47.684v8.23c0,7.98-14.674,15.26-14.674,15.26
		c-0.813,0.416-1.188,1.365-0.879,2.213c0,0,0.226,0.59,0.675,1.414h52.253L170.179,256.587z M153.661,160.106l-21.117,6.981
		c-0.886,0.338-1.483,1.164-1.528,2.099v20.77c0,0.875-0.637,1.254-1.424,0.852l-16.301-8.496c-0.853-0.396-1.863-0.248-2.558,0.383
		l-2.762,2.729c-0.589,0.574-0.598,1.51-0.027,2.094c0.033,0.033,0.066,0.059,0.099,0.092l21.767,18.863
		c0.669,0.57,1.207,1.768,1.207,2.652v6.107c-31.796-1.961-56.429-15.365-56.429-31.666c0-8.139,6.187-15.536,16.295-21.178
		c-0.079-0.923-0.177-1.853-0.177-2.782c0-22.061,21.66-39.936,48.366-39.936c26.711,0,48.36,17.875,48.36,39.936
		c0,0.929-0.094,1.859-0.159,2.782c10.103,5.642,16.282,13.039,16.282,21.178c0,14.883-20.557,27.352-48.348,30.9v-5.342
		c0.055-1.002,0.461-1.951,1.155-2.678l14.774-13.793c0.708-0.73,1.129-1.678,1.181-2.691v-6.799c0-0.879-0.631-1.234-1.391-0.787
		l-14.327,8.365c-0.787,0.443-1.393,0.09-1.393-0.793v-28.75C155.207,160.321,154.513,159.827,153.661,160.106z"/>
	<path fill="currentColor" d="M81.156,263.437h117.486v0.041h25.083c0-0.014-0.003-0.025-0.003-0.041h5.016
		c0,0.016,0.001,0.027,0.001,0.041h20.065v-0.041h32.137v-0.059H81.156V263.437z"/>
	<path fill="currentColor" d="M239.648,249.404c2.551-2.547,5.601-4.271,9.156-5.186c-0.835-0.205-1.666-0.359-2.477-0.467
		c-0.815-0.102-1.662-0.154-2.54-0.154c-3.674,0-7.034,0.895-10.061,2.67c-3.035,1.787-5.429,4.145-7.183,7.09
		c0.711,1.568,1.255,3.221,1.632,4.939c0.006,0.021,0.006,0.045,0.013,0.07h6.24C235.401,254.906,237.132,251.917,239.648,249.404z"
		/>
	<path fill="currentColor" d="M217.893,249.373c-3.889-3.848-8.631-5.775-14.234-5.775c-0.876,0-1.722,0.049-2.538,0.154
		c-0.815,0.102-1.645,0.262-2.479,0.467c3.553,0.914,6.618,2.639,9.187,5.186c2.535,2.518,4.261,5.502,5.189,8.963h10.073
		C222.24,254.984,220.521,251.98,217.893,249.373z"/>
	<path fill="currentColor" d="M282.679,258.367h-1.738v5.012h11.082C288.125,263.378,284.692,261.386,282.679,258.367z"/>
	<path fill="currentColor" d="M400.884,263.307c-2.843,0-5.183-2.168-5.457-4.94H282.679c2.013,3.02,5.446,5.012,9.344,5.012h202.06
		v-0.071H400.884z"/>
	<path fill="currentColor" d="M400.884,263.307h93.199v-4.94h-98.655C395.701,261.139,398.041,263.307,400.884,263.307z"/>
	<polygon fill="currentColor" points="234.43,258.367 228.19,258.367 223.092,258.367 213.019,258.367 168.383,258.367 116.13,258.367 
		81.156,258.367 81.156,263.378 280.941,263.378 280.941,258.367 	"/>
</g>
</svg>
</template>