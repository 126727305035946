<template>
  <div class="block-wrapper">
    <div class="block-title">
      <slot name="title"></slot>
    </div>
    <div class="block-body">
      <slot name="body"></slot>
    </div>
  </div>
</template>
