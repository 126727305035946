
import { useStore  } from 'vuex';
// import { computed, ref } from 'vue';
import {getObjectValueFromString} from '../functions/utils.js'



const conditionsMaker = (conditionsArray) => {
  const store = useStore();
  let conditionOut = {
    stringToEval: "",
    stringToEvalDebug: []
  };

  // console.log('COND-ARRAY',{conditionsArray});
  // if (conditionsArray.conditions) {
  //   conditionOut.stringToEval += " "+ conditionsArray.andOr + "(" + conditionsMaker(conditionsArray.conditions) + ") ";
  // } else {

    conditionsArray.forEach((cond) => {
      // console.log('COND',{cond});


      if (cond.conditions) {
        conditionOut.stringToEval += " "+ cond.andOr + " (" + conditionsMaker(cond.conditions).stringToEval + ") ";
      } else {


        let stringToEvalItem = "";
        const storedVal = getObjectValueFromString(cond.simuDataPath, store.state.simuData);
        stringToEvalItem += cond.andOr !== null ? " " + cond.andOr + " " : '';
        
        if (cond.compType === 'fx') {
          if (cond.fx.isNegative) {
            stringToEvalItem += `!`;
          }
          if (cond.fx.name === 'includes()') {
            stringToEvalItem += `${cond.value}.includes("${storedVal}")`;
          }
          
        } else {
          stringToEvalItem += `"${storedVal}" ${cond.comp} "${cond.value}"`;
        }

        conditionOut.stringToEvalDebug.push({
          simuDataPath: cond.simuDataPath,
          stringToEvalItem
        });

        conditionOut.stringToEval += stringToEvalItem;

    }


    })
  // }

  return conditionOut;
}


export const useConditionsManager = (conditionsList) => {
  const out = {
    "debug" : [],
    "messages" : [],
  };

  conditionsList.forEach((conditionItem) => {

    const conditions = conditionItem.conditions;


    // let stringToEval = "";
    // let stringToEvalDebug = [];

    const {stringToEval, stringToEvalDebug} = conditionsMaker(conditions);

    // conditions.forEach((cond) => {
    //   let stringToEvalItem = "";
    //   const storedVal = getObjectValueFromString(cond.simuDataPath, store.state.simuData);
    //   stringToEvalItem += cond.andOr !== null ? " " + cond.andOr + " " : '';
      
    //   if (cond.compType === 'fx') {
    //     if (cond.fx.isNegative) {
    //       stringToEvalItem += `!`;
    //     }
    //     if (cond.fx.name === 'includes()') {
    //       stringToEvalItem += `${cond.value}.includes("${storedVal}")`;
    //     }
        
    //   } else {
    //     stringToEvalItem += `"${storedVal}" ${cond.comp} "${cond.value}"`;
    //   }

    //   stringToEvalDebug.push({
    //     simuDataPath: cond.simuDataPath,
    //     stringToEvalItem
    //   });

    //   stringToEval += stringToEvalItem;


    // })




      // console.log(conditionItem.id, stringToEval);
      const exeEval = eval(stringToEval);

      out.debug.push({
        id: conditionItem.id,
        order: conditionItem.order,
        varname: conditionItem.varname,
        theme: conditionItem.theme,
        poste: conditionItem.poste,
        stringToEvalDebug,
        stringToEval,
        exeEval
      });
      // const exeEval = false;
      if (exeEval) {
        out.messages.push({
          id: conditionItem.id,
          order: conditionItem.order,
          theme: conditionItem.theme,
          poste: conditionItem.poste,
          title:conditionItem.title,
          description:conditionItem.description,
          varname: conditionItem.varname
        });
      }
    
  })
  return out;

}