<template>
  <div>
    <!-- <h2>Limiter l'impact environnemental des bâtiments : tendre vers le rafraîchissement passif</h2> -->

    <section>
      <h5>Réduire l'impact énergétique des bâtiments en période estivale</h5>

      <p>Des vagues de chaleurs et épisodes de canicules plus fréquents, plus longs et plus chauds dans les années à venir ; Des pointes de consommation électrique dû à un usage intense de la climatisation, qui à son tour, contribue au réchauffement de l'air extérieur : <strong>avec le changement climatique et nos comportements, nos villes étouffent et nos bâtiments sont de moins en moins adaptés</strong>.</p>

      <p>Le rafraîchissement passif des bâtiments vise alors à répondre à un double objectif : </p>
      <ul>
        <li><strong>Préserver d'une part le confort d'été</strong> en atténuant la chaleur ressentie ;</li>
        <li>Et d'autre part, <strong>limiter l'impact environnemental via des solutions de rafraichissement choisies</strong> : intégrer des solutions de rafraichissement passif qui permettront de préserver le confort, tout en assurant un effet neutre ou maîtrisé sur l'environnement (protections solaires, murs / toitures / tuiles végétalisées, isolants biosourcés, système de ventilation naturelle, peinture / revêtement blanc réfléchissant etc.).</li>
      </ul>

      <p>Pour rester cohérent avec les objectifs de réduction des gaz à effet de serre, se tourner vers ces systèmes de rafraichissement passifs permet de réduire autant que possible les besoins énergétiques des bâtiments en retardant le recours à des systèmes actifs (climatisation). Ces solutions passives sont pensées pour compléter (et parfois remplacer ou suppléer) les systèmes actifs existants.</p>

      <p>Car même si les systèmes de rafraîchissement passifs contribuent au confort en période estivale, ils peuvent atteindre une certaine limite en périodes de canicule, durant lesquelles la température nocturne ne diminue pas (empêchant l'extraction de chaleur du bâtiment).</p>
    </section>

    <section>
      <h5>Maximiser les effets avec une approche systémique</h5>

      <p>Limiter l'impact du réchauffement doit alors s'opérer par une approche systémique, et il est primordial de prendre en considération un ensemble d'éléments pour maximiser les effets d'un tel rafraîchissement :</p>

      <ul>
        <li><strong>Tenir compte de l'environnement direct d'un bâtiment</strong> (climat, végétalisation, emploi de l'eau, ambiance minérale, parois réfléchissantes, organisation de l'espace urbain etc.) ;</li>
        <li><strong>Tenir compte de la structure du bâtiment</strong> (orientation, pièces traversantes avec des ouvertures en opposition, choix des matériaux, protection solaires et isolation efficace etc.) ;</li>
        <li><strong>Tenir compte des systèmes technologiques déjà existants</strong> (ventilation mécanique ou naturelle, gestion automatisée ou pas des fermetures, puits géothermiques etc.).</li>
        <li><strong>Intégrer l'approche dans tous les projets de rénovation</strong>.</li>
      </ul>
    </section>

    <section>
      <h5>Pour mieux comprendre le sujet, consultez nos ressources dédiées</h5>

      <ul>
        <li>
          <a href="https://lab.cercle-promodul.inef4.org/tool_type/fiches-pratiques/tool/confort-dete-et-rafraichissement-passif-comprendre-et-passer-a-laction" target="_blank">Confort d'été et rafraîchissement passif : comprendre et passer à l'action</a>
        </li>
        <li>
          <a href="https://lab.cercle-promodul.inef4.org/tool_type/guides-rapports-et-retours-dexperiences/tool/rafraichissement-passif-et-confort-dete-panorama-de-solutions-pour-ladaptation-du-batiment-au-changement-climatique" target="_blank">Rafraîchissement passif et confort d'été : Panorama de solutions pour l'adaptation du bâtiment au changement climatique</a>
        </li>
      </ul>

      <!-- <div class="row">
        <div class="col col-lg-6">
          <a href="https://lab.cercle-promodul.inef4.org/tool_type/fiches-pratiques/tool/confort-dete-et-rafraichissement-passif-comprendre-et-passer-a-laction" target="_blank">Confort d'été et rafraîchissement passif : comprendre et passer à l'action</a>
        </div>
        <div class="col col-lg-6">
          <a href="https://lab.cercle-promodul.inef4.org/tool_type/guides-rapports-et-retours-dexperiences/tool/rafraichissement-passif-et-confort-dete-panorama-de-solutions-pour-ladaptation-du-batiment-au-changement-climatique" target="_blank">Rafraîchissement passif et confort d'été : Panorama de solutions pour l'adaptation du bâtiment au changement climatique</a></div>
      </div> -->
    </section>
  </div>
</template>