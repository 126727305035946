<template>
  <div>
    <p>
      L’atténuation de la chaleur ressentie dans les bâtiments ne dépend pas
      uniquement des améliorations à apporter sur les structures ou sur les
      équipements : nos comportements jouent un rôle essentiel dans la recherche
      du confort et doivent évoluer dans le bon sens.
    </p>
    <p>
      Des gestes simples peuvent être mis en place par l’occupant pour atténuer
      le ressenti des fortes chaleurs dans l’habitat, et ainsi modifier le
      rapport aux solutions technologiques en limitant notamment le recours aux
      appareils qui produisent de la chaleur.
    </p>
  </div>
</template>
