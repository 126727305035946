<template>
  <InputCustom :type="props.type" :choicesList="theChoices" :varname="props.varname" v-model="theValue" />
  <!-- <hr>
  theValue:<pre>{{ theValue }}</pre> -->
  <!-- <hr>
  varname:<pre>{{ props.varname }}</pre>

  simuDataYearConstrut:<pre>{{ simuDataYearConstrut }}</pre>
  yearsChoices:<pre>{{ yearsChoices }}</pre>
  theChoices:<pre>{{ theChoices }}</pre>
  simuData:<pre>{{ simuData }}</pre> -->
</template>

<script setup>
import {useStore} from 'vuex';
import {computed, ref, watch, onMounted} from 'vue';
import {yearsConstructionLevel} from '../class/levelAndValues.config.js';
import InputCustom from './InputCustom.vue'

const props = defineProps({
    modelValue: {
      type: Object,
      required: true
    },
    varname: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'select'
    },
  });

const theValue = ref(props.modelValue);
const emit = defineEmits(['update:modelValue']);
const store = useStore();

const simuData = computed(() => store.getters.simuData);

watch(theValue, (newVal) => {
  checkTheValueInChoicesList();
  emit('update:modelValue', newVal);
})

//---
onMounted(() => {checkTheValueInChoicesList();});

const simuDataYearConstrut = simuData.value.buildingConfig.buildingConstructionYear;
const yearsChoices = [...yearsConstructionLevel].filter((item) => {
  return simuDataYearConstrut <= item.value && item.value !=='1974-';
})
const theChoices = [
  {value: 'unknow', label: 'Je ne sais pas'},
  {value: 'none', label: 'Non'},
  ...yearsChoices.map((item) => {
    return {
      value: item.value,
      label: `Oui, ${item.label}`
    };
  })
]

const isTheValueInChoicesList = () => {
  return theChoices.findIndex((item) => item.value === theValue.value) !== -1;
}

const checkTheValueInChoicesList = () => {
  const test = isTheValueInChoicesList()
  // alert(theValue.value + '/' + test)
  if (!test) theValue.value = null;
}
</script>