<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g id="good">
	<path fill="none" d="M286.184,212.19c0.246-1.504,0.37-3.115,0.37-4.834c0-2.148-0.201-4.464-0.621-6.949
		c2.484-1.325,4.447-3.49,5.89-6.511c1.443-3.021,2.166-6.042,2.166-9.097c0-3.055-0.739-5.907-2.215-8.56
		c4.363-4.128,6.545-9.045,6.545-14.751c0-2.064-0.403-4.347-1.225-6.865c-0.822-2.533-1.864-4.497-3.105-5.89
		c2.651-0.1,4.867-2.031,6.646-5.84c1.762-3.793,2.651-7.15,2.651-10.036c0-2.621-0.629-5.052-1.886-7.281
		c-0.756-1.339-1.738-2.605-2.948-3.796c-3.222-3.188-6.898-4.783-11.026-4.783h-34.191h-9.428c0-4.784,1.98-11.379,5.94-19.753
		c3.961-8.393,5.942-15.037,5.942-19.905c0-8.09-1.31-14.098-3.961-17.957c-2.652-3.894-7.921-5.841-15.859-5.841
		c-2.149,2.148-3.727,5.673-4.717,10.541c-0.99,4.866-2.249,10.069-3.776,15.54c-1.527,5.505-3.994,10.036-7.385,13.595
		c-1.812,1.88-4.984,5.639-9.532,11.278c-0.336,0.402-1.275,1.644-2.852,3.708c-1.579,2.064-2.871,3.759-3.894,5.084
		c-1.042,1.31-2.468,3.072-4.281,5.271c-1.829,2.182-3.474,3.994-4.951,5.438c-1.494,1.444-3.088,2.92-4.783,4.397
		c-1.695,1.494-3.34,2.617-4.952,3.356c-1.611,0.737-3.088,1.107-4.397,1.107h-3.977v79.333h3.977c1.074,0,2.367,0.117,3.894,0.37
		c1.527,0.234,2.888,0.502,4.096,0.805c1.191,0.285,2.769,0.738,4.699,1.359c1.947,0.621,3.39,1.09,4.346,1.426
		c0.957,0.335,2.417,0.84,4.397,1.544c1.979,0.705,3.172,1.142,3.591,1.31c17.438,6.025,31.57,9.045,42.394,9.045h5.478h9.525
		C276.642,228.048,284.453,222.763,286.184,212.19z"/>
	<path fill="none" d="M152.593,196.313c-2.148,0-4.028,0.789-5.589,2.349c-1.562,1.578-2.349,3.441-2.349,5.589
		c0,2.148,0.788,4.011,2.349,5.572c1.561,1.577,3.44,2.366,5.589,2.366c2.148,0,3.994-0.79,5.571-2.366
		c1.561-1.561,2.35-3.424,2.35-5.572c0-2.148-0.789-4.011-2.35-5.589C156.587,197.102,154.741,196.313,152.593,196.313z"/>
	<path fill="none" d="M396.018,116.997h-43.619c0-4.784,1.98-11.379,5.94-19.752c3.961-8.393,5.942-15.037,5.942-19.905
		c0-8.09-1.311-14.098-3.961-17.957c-2.652-3.894-7.922-5.841-15.859-5.841c-2.148,2.148-3.727,5.673-4.717,10.541
		c-0.99,4.866-2.249,10.069-3.776,15.54c-1.527,5.505-3.993,10.036-7.385,13.595c-1.812,1.88-4.983,5.639-9.532,11.278
		c-0.336,0.402-1.275,1.644-2.852,3.708c-1.579,2.064-2.871,3.759-3.895,5.084c-0.017,0.021-0.038,0.047-0.054,0.068
		c4.596,5.654,6.913,12.106,6.913,19.384c0,7.352-2.048,14.081-6.076,20.208c0.738,2.718,1.108,5.57,1.108,8.542
		c0,6.361-1.578,12.318-4.699,17.84c0.234,1.746,0.369,3.525,0.369,5.337c0,8.357-2.484,15.708-7.452,22.052
		c0.025,2.838-0.173,5.526-0.589,8.068c1.844,0.589,3.23,1.041,4.153,1.365c0.957,0.335,2.417,0.84,4.398,1.544
		c1.979,0.705,3.172,1.142,3.59,1.31c17.438,6.025,31.57,9.045,42.395,9.045h15.003c15.845,0,23.782-6.896,23.782-20.692
		c0-2.148-0.201-4.464-0.621-6.949c2.484-1.324,4.447-3.49,5.891-6.511c1.443-3.021,2.166-6.042,2.166-9.097
		s-0.739-5.907-2.216-8.56c4.363-4.128,6.545-9.045,6.545-14.751c0-2.064-0.403-4.348-1.225-6.865
		c-0.822-2.533-1.864-4.496-3.104-5.891c2.65-0.1,4.866-2.031,6.646-5.84c1.762-3.793,2.651-7.15,2.651-10.036
		c0-4.195-1.611-7.905-4.834-11.077C403.82,118.592,400.145,116.997,396.018,116.997z"/>
	<path fill="#010202" d="M421.678,162.151c4.028-6.127,6.076-12.856,6.076-20.208c0-8.508-3.155-15.893-9.433-22.186
		c-6.276-6.278-13.711-9.415-22.304-9.415h-21.818c3.979-8.19,5.957-16.111,5.957-23.799c0-9.667-1.441-17.354-4.346-23.06
		c-2.887-5.688-7.1-9.885-12.639-12.57c-5.537-2.686-11.78-4.028-18.711-4.028c-4.213,0-7.939,1.527-11.162,4.582
		c-3.54,3.474-6.109,7.938-7.669,13.393c-1.577,5.438-2.836,10.674-3.792,15.676c-0.94,5.001-2.417,8.524-4.398,10.606
		c-4.044,4.362-8.459,9.667-13.258,15.844c-1.619,2.1-3.133,4.027-4.548,5.794c-3.771-1.622-7.838-2.438-12.208-2.438h-21.817
		c3.978-8.19,5.957-16.111,5.957-23.799c0-9.667-1.442-17.354-4.345-23.06c-2.887-5.688-7.1-9.885-12.639-12.57
		c-5.538-2.686-11.781-4.028-18.711-4.028c-4.213,0-7.939,1.527-11.162,4.582c-3.54,3.474-6.109,7.938-7.669,13.393
		c-1.577,5.438-2.836,10.674-3.792,15.676c-0.94,5.001-2.417,8.524-4.397,10.606c-4.044,4.362-8.459,9.667-13.258,15.844
		c-8.358,10.841-13.997,17.234-16.985,19.216h-33.951c-4.381,0-8.124,1.561-11.229,4.648c-3.088,3.088-4.632,6.847-4.632,11.211
		v79.333c0,4.363,1.544,8.105,4.632,11.211c3.105,3.088,6.847,4.647,11.229,4.647h35.696c1.813,0,7.52,1.646,17.085,4.951
		c10.171,3.56,19.116,6.262,26.853,8.125c7.72,1.846,15.542,2.785,23.479,2.785h15.977c11.647,0,21.046-3.322,28.128-9.97
		c1.431-1.34,2.71-2.782,3.85-4.316c2.7,0.811,6.135,1.936,10.304,3.376c10.172,3.56,19.116,6.261,26.853,8.124
		c7.72,1.846,15.542,2.785,23.479,2.785h15.977c11.647,0,21.047-3.322,28.129-9.969c7.115-6.663,10.641-15.726,10.539-27.223
		c4.968-6.344,7.451-13.695,7.451-22.052c0-1.813-0.134-3.592-0.368-5.337c3.121-5.522,4.698-11.479,4.698-17.84
		C422.785,167.722,422.416,164.869,421.678,162.151z M158.165,219.028c-1.578,1.576-3.423,2.366-5.571,2.366
		c-2.148,0-4.028-0.79-5.589-2.366c-1.562-1.562-2.349-3.424-2.349-5.572s0.788-4.012,2.349-5.59c1.561-1.56,3.44-2.349,5.589-2.349
		c2.148,0,3.994,0.789,5.571,2.349c1.561,1.578,2.35,3.441,2.35,5.59S159.726,217.467,158.165,219.028z M253.246,237.253h-5.478
		c-10.824,0-24.956-3.02-42.394-9.045c-0.419-0.168-1.611-0.604-3.591-1.31c-1.981-0.704-3.44-1.208-4.397-1.544
		c-0.957-0.336-2.399-0.806-4.346-1.426c-1.93-0.622-3.508-1.074-4.699-1.359c-1.208-0.303-2.568-0.571-4.096-0.806
		c-1.527-0.252-2.82-0.369-3.894-0.369h-3.977v-79.333h3.977c1.31,0,2.787-0.37,4.397-1.107c1.611-0.739,3.257-1.862,4.952-3.356
		c1.694-1.477,3.289-2.953,4.783-4.397c1.477-1.443,3.122-3.256,4.951-5.438c1.813-2.199,3.239-3.961,4.281-5.271
		c1.023-1.325,2.315-3.02,3.894-5.084c1.577-2.064,2.516-3.307,2.852-3.709c4.548-5.639,7.72-9.398,9.532-11.278
		c3.391-3.559,5.857-8.09,7.385-13.595c1.527-5.471,2.787-10.674,3.776-15.54c0.99-4.867,2.568-8.393,4.717-10.54
		c7.938,0,13.207,1.947,15.859,5.841c2.651,3.859,3.961,9.867,3.961,17.957c0,4.868-1.981,11.513-5.942,19.905
		c-3.96,8.373-5.94,14.969-5.94,19.753h9.428h34.191c4.128,0,7.804,1.595,11.026,4.783c1.209,1.19,2.192,2.456,2.948,3.796
		c1.257,2.229,1.886,4.66,1.886,7.28c0,2.887-0.89,6.243-2.651,10.036c-1.779,3.81-3.995,5.74-6.646,5.841
		c1.241,1.394,2.283,3.356,3.105,5.89c0.821,2.519,1.225,4.801,1.225,6.865c0,5.706-2.182,10.623-6.545,14.751
		c1.477,2.652,2.215,5.505,2.215,8.56c0,3.056-0.722,6.075-2.166,9.097c-1.443,3.021-3.406,5.187-5.89,6.511
		c0.419,2.485,0.621,4.801,0.621,6.949c0,1.719-0.124,3.33-0.37,4.834c-1.731,10.573-9.542,15.858-23.412,15.858H253.246z
		 M409.226,152.098c-1.779,3.809-3.995,5.74-6.646,5.84c1.24,1.395,2.282,3.357,3.104,5.891c0.821,2.518,1.225,4.801,1.225,6.865
		c0,5.706-2.182,10.623-6.545,14.751c1.477,2.652,2.216,5.505,2.216,8.56s-0.723,6.075-2.166,9.097
		c-1.443,3.021-3.406,5.187-5.891,6.511c0.42,2.485,0.621,4.801,0.621,6.949c0,13.796-7.938,20.692-23.782,20.692h-15.003
		c-10.824,0-24.956-3.02-42.395-9.045c-0.418-0.168-1.611-0.604-3.59-1.31c-1.981-0.704-3.441-1.209-4.398-1.544
		c-0.922-0.324-2.309-0.775-4.153-1.364c0.417-2.542,0.614-5.23,0.589-8.068c4.968-6.343,7.452-13.694,7.452-22.052
		c0-1.813-0.134-3.592-0.369-5.337c3.121-5.522,4.699-11.479,4.699-17.84c0-2.972-0.37-5.824-1.108-8.542
		c4.028-6.127,6.076-12.856,6.076-20.208c0-7.277-2.316-13.729-6.913-19.384c0.017-0.021,0.038-0.047,0.054-0.067
		c1.023-1.325,2.315-3.02,3.895-5.084c1.576-2.064,2.516-3.307,2.852-3.709c4.549-5.639,7.721-9.398,9.532-11.278
		c3.392-3.559,5.857-8.09,7.385-13.595c1.527-5.471,2.786-10.674,3.776-15.54c0.99-4.867,2.568-8.393,4.717-10.54
		c7.938,0,13.207,1.947,15.859,5.841c2.65,3.859,3.961,9.867,3.961,17.957c0,4.868-1.981,11.513-5.942,19.905
		c-3.96,8.373-5.94,14.969-5.94,19.752h43.619c4.127,0,7.803,1.596,11.025,4.783c3.223,3.172,4.834,6.882,4.834,11.077
		C411.877,144.947,410.987,148.305,409.226,152.098z"/>
	<path id="symbole" class="icon-symbole symbole-good" fill="#009741" d="M531.753,101.192c2.745-1.652,6.307-0.838,8.051,1.839l5.89,9.008
		c1.605,2.421,1.209,5.644-0.908,7.61l-0.023,0.036l-0.093,0.08l-0.327,0.303l-1.349,1.279
		c-7.423,7.204-14.614,14.627-21.574,22.273c-13.082,14.384-28.628,33.234-39.077,51.503c-2.933,5.096-10.054,6.191-14.289,1.794
		l-38.635-40.147c-2.28-2.373-2.21-6.143,0.163-8.424c0.047-0.046,0.093-0.082,0.14-0.116l11.682-10.543
		c2.119-1.909,5.284-2.048,7.564-0.338l19.713,14.78C499.472,121.767,516.927,110.106,531.753,101.192z"/>
</g>
</svg>
</template>