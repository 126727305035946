<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="1000px"
    height="1000px"
    viewBox="0 0 1000 1000"
    enable-background="new 0 0 1000 1000"
    xml:space="preserve"
  >
    <g id="_x32__levels_bottom">
      <polygon
        id="roof_2_"
        fill="none"
        stroke="currentColor"
        stroke-width="5"
        stroke-miterlimit="10"
        points="469.704,420.023 677.764,493.333 
		885.827,566.643 469.704,566.643 53.579,566.643 261.64,493.333 	"
      />

      <rect
        id="empty_3_"
        x="115.757"
        y="575.536"
        fill="none"
        stroke="currentColor"
        stroke-width="5"
        stroke-miterlimit="10"
        width="707.893"
        height="154.72"
      />
      <rect
        id="selected_1_"
        x="115.757"
        y="737.816"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="5"
        stroke-miterlimit="10"
        width="707.893"
        height="154.721"
      />
    </g>
  </svg>
</template>
