<template>


    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['buildingType']"
    v-model="theValue['buildingType']"
    :qstParams="getQstParam('buildingType')"
    />

    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['buildingProjectState']"
    v-model="theValue['buildingProjectState']"
    :qstParams="getQstParam('buildingProjectState')"
    />

    <!-- theValue['buildingConstructionYear']: {{ theValue['buildingConstructionYear'] }} -->
    <TransitionQst class="transition">
    <div v-if="theValue['buildingProjectState'] === 'existing'">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['buildingConstructionYear']"
      v-model="theValue['buildingConstructionYear']"
      :qstParams="getQstParam('buildingConstructionYear')"
      />
    </div>
  </TransitionQst>

    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['buildingNbLevelTotal']"
    v-model="theValue['buildingNbLevelTotal']"
    :qstParams="getQstParam('buildingNbLevelTotal')"
    />

</template>

<script setup>
  import { ref, watch, computed } from 'vue';
  import InputFormRowGlobal from './InputFormRowGlobal.vue';

  const props = defineProps({
  stepId: {
    type: Number,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  },
  formName: {
      type: String,
      required: true
    },
  formQst: {
    type: Array,
    required: true
  },
  validationObj: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);

const theValue = ref(props.modelValue[[props.formName]]);
watch (theValue, (newVal) => {
    emit('update:modelValue', newVal)
  });

const getQstParam = (varname) => {
  const param = props.formQst.find((qst) => qst.varname === varname);
  return param;
}

  //-- FOR THE SPECIFIC FORM
const buildingProjectState = computed(() => theValue.value['buildingProjectState'])
watch(buildingProjectState, (newVal) => {
  theValue.value['buildingConstructionYear'] = newVal==='new' ? '2012+' : null;
}, {deep: true})
</script>
