<template>

<!-- theValue: <pre>{{ theValue }}</pre>
departmentData: <pre>{{ departmentData }}</pre> -->

    <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['department']"
    v-model="theValue['department']"
    :qstParams="getQstParam('department')"
    >
  </InputFormRowGlobal>

  <InputFormRowGlobal
    v-bind="$attrs"
    :inputValidationObj="props.validationObj[props.formName]['zoneClimat']"
    v-model="theValue['zoneClimat']"
    :qstParams="getQstParam('zoneClimat')"
    >
  </InputFormRowGlobal>

    <!-- zoneClimat: {{ theValue['zoneClimat'] }}
    <hr> -->
    <TransitionQst class="transition">
    <div v-if="departmentData && departmentData.isLittoral">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['isLittoral']"
      v-model="theValue['isLittoral']"
      :qstParams="getQstParam('isLittoral')"
      />
    </div>
  </TransitionQst>

  <TransitionQst class="transition">
    <div v-if="departmentData && departmentData.altiMax && showAltitude">
      <InputFormRowGlobal
      v-bind="$attrs"
      :inputValidationObj="props.validationObj[props.formName]['altitude']"
      v-model="theValue['altitude']"
      :qstParams="getQstParam('altitude')"
      :choicesList="listAltitude"
      />
    </div>
  </TransitionQst>

  <div class="debug-bloc" v-if="isDebug">
    altitude: {{ theValue['altitude'] }}
  </div>

    <!-- departmentData: <pre>{{ departmentData }}</pre>
    formData: <pre>{{ formData }}</pre> -->

</template>

<script setup>
  import { useStore } from 'vuex';
  import { ref, watch, computed } from 'vue';
  import InputFormRowGlobal from './InputFormRowGlobal.vue';
  import DepartmentsList from "../json/departements.json";

  const props = defineProps({
    stepId: {
      type: Number,
      required: true
    },
    modelValue: {
      type: Object,
      required: true
    },
    formName: {
      type: String,
      required: true
    },
    formQst: {
      type: Array,
      required: true
    },
    validationObj: {
      type: Object,
      required: true
    }
  });

const store = useStore();
const isDebug = computed(() => store.getters.isDebug);

const emit = defineEmits(['update:modelValue']);

const theValue = ref(props.modelValue[[props.formName]]);
watch (theValue, (newVal) => {
    emit('update:modelValue', newVal)
});

const getQstParam = (varname) => {
  const param = props.formQst.find((qst) => qst.varname === varname);
  return param;
}



  //-- FOR THE SPECIFIC FORM


  const departmentData = computed(() => {
    return DepartmentsList.departements.find(dep => dep.value === theValue.value['department']);
  })




  const listAltitude = computed(() => {
    let list = [{value: 1, label: "< 400 m"}];

    if (departmentData.value) {
      const altiMax = departmentData.value.altiMax;
      if (altiMax >= 400) {
        list.push({value: 2, label: "entre 400 et 800 m"})
      }
      if (altiMax > 800) {
        list.push({value: 3, label: "> 800 m"})
      }
    }

    return list
  })

  const showAltitude = computed(() => listAltitude.value.length > 1 && !theValue.value['isLittoral']);

  watch(departmentData, () => {
    if (!departmentData.value?.isLittoral ) {
      theValue.value['isLittoral'] = false;
    }

    // if (showAltitude.value !== true) {
    //   theValue.value['altitude'] = 4;// speciale value
    // } else {
    //   theValue.value['altitude'] = 1;
    // }
    
    theValue.value['zoneClimat'] = departmentData?.value?.climaticZone || null;
  })

  watch (showAltitude, (newVal) => {
    if (newVal !== true) {
      theValue.value['altitude'] = 4;// speciale value
    } else {
      theValue.value['altitude'] = 1;
    }
  })

  // watch (showAltitude, (newVal) => {
  //   if (newVal !== true) {
  //     theValue.value['altitude'] = 4;// speciale value
  //   }
  // })

</script>
