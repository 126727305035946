<template>

  <p>
    Afin de pouvoir comparer cetaines pièces de votre projet à l'ensemble du bâtiment, vous pouvez cliquer sur "Ajouter une pièce" ({{nbMaxRoom}} pièces maximum) ou passer directement aux résutats en cliquant sur "Suivant" 
  </p>
  <div class="roomsWrapper">
    <div class="roomItem add-room" :class="{'disabled': isMaxRoom}" @click.prevent="addRoom()">
      <i class="bi bi-plus-lg"></i>
      <div class="label">Ajouter une pièce</div>
      <!-- <i class="bi bi-plus-square-dotted"></i> -->
    </div>
    <div
    v-for="(room, roomIndex) in rooms" :key="`fromWall${roomIndex}`"
    class="roomItem"
    :class="{'alert alert-danger': !room.isRoomValid}"
    >

    <div class="roomItem-remove" @click.prevent="deleteRoom(room.roomId)">
      <!-- <i class="bi bi-x-circle-fill"></i> -->
      <i class="bi bi-trash3-fill"></i>
    </div>
    <div class="roomItem-content" @click.prevent="showRoomForm(room.roomId)">
      Pièce {{ roomIndex + 1 }}
      <!-- / ID: {{ room.roomId }} -->
      <div class="room-icon-wrapper">
        <component :is="getRoomTypeData(room.roomType).icon" class="room-type-icon"></component>
        <div class="room-type-label">{{ getRoomTypeData(room.roomType).label }}</div>
      </div>
      
    </div>
    <div v-if="!room.isRoomValid"><i class="bi bi-patch-exclamation-fill text-danger" ></i></div>
    <div v-else><i class="bi bi-patch-check-fill text-success"></i></div>

  </div>
  </div>

  <div class="text-warning text-center" v-if="isMaxRoom">
    Nombre pièces maximum ({{ nbMaxRoom }}) atteind !
  </div>
</template>

<script setup>
import {useStore} from 'vuex';
import {computed, ref, inject} from 'vue';
import {questions} from '../appConfig/index.js';

const emitter = inject('emitter'); // Inject `emitter`
const store = useStore();

const nbMaxRoom = ref(3);

const rooms = computed(() => {
  return store.getters.rooms;
});

const isMaxRoom = computed(() => {
  return rooms.value && rooms.value.length >= nbMaxRoom.value;
})

const addRoom = async () => {
  if (isMaxRoom.value) return;
  let newRoomId = null;
  await store.dispatch('createRoom').then((d) => {
    newRoomId = d;
  });
  showRoomForm(newRoomId);
}
const deleteRoom = (roomId) => {
  if (confirm('Voulez-vous supprimer cette pièce ?')) {
    store.dispatch('deleteRoom', {roomId});
  }
}
const roomFormOpen = ref(false);
const showRoomForm = (roomId) => {
  roomFormOpen.value = true;
  emitter.emit("form-modal-open", roomFormOpen.value);
  emitter.emit("form-modal-data", {componentName: 'FormRoom', componentId: roomId, title: `Pièce ${roomId}`, formType: 'modalForm', formItem: 'rooms' });

}

const getRoomTypeData = (roomTypeValue) => {
  const qst = questions.find((qst) => {
    return qst.varname === 'roomType';
  });
  const result = qst.choices.find(choice => choice.value === roomTypeValue);
  if (result) {
    return {icon: result.icon, label: result.label};
  } else {
    return  {icon: 'IconUnknow', label: 'unknow'};
  }
}

</script>

<style scoped lang="scss">

.roomsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  // border: 1px solid red;

  .roomItem{
    position: relative;
    width: 20%;
    min-width: 115px;
    height: 200px;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    margin: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    &.add-room {
      // background-color: yellow;
      color: #ccc;
      border-width: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      .bi {
        font-size: 6rem;
        // text-align: center;
        // width: 100%;
      }
      .label {
        text-align: center;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .roomItem-remove {
      color: darkred;
      position: absolute;
      top:0.1rem;
      right:0.1rem;
    }

    .roomItem-content{
      height: 100%;
      // background-color: pink;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .room-icon-wrapper {
        // width: 100%;

        .room-type-icon {
          // width: 100%;
          min-width: 100px;
          max-width: 40px;
          height: 100%;
          min-height: 100px;
          max-height: 40px;
        }
        .room-type-label {
          text-align: center;
        }
      }


    }
  }
}

</style>