<template>
  <div>
    <p>
      Le confort thermique et la température ressentie sont fonction de
      plusieurs paramètres sur lesquels il est possible d’agir pour les
      améliorer. Ils dépendent notamment de la vitesse de l’air, qui favorise ou
      non le rafraichissement du corps.
    </p>
    <p>
      Les brasseurs d’air fixes permettent d’abaisser la température ressentie
      grâce au déplacement d’air. Une vitesse d’air bien dimensionnée permet
      d’obtenir une sensation de brise agréable, sans une consommation
      énergétique trop importante
    </p>
    <p>
      A noter que les brasseurs d’air fixe ne seront utiles sur la chaleur
      ressentie que si l’utilisateur est présent dans la pièce en question.
    </p>
  </div>
</template>
