<template>
  <div>
    <p>
      La situation géographique d'un bâtiment a une incidence sur les conditions
      météorologiques dominantes (ensoleillement, pluie, nuageux etc.), sur la
      quantité d'ensoleillement perçu et les températures.
    </p>

    <p>
      L’axe de rotation de la Terre, inclinée de 23,5°C par rapport à son plan
      d'orbite autour du soleil implique plusieurs choses. L'élévation du soleil
      variant selon les saisons, les rayonnements perçus par un bâtiment seront
      différents selon le lieu où l’ont réside. De plus, l'hémisphère Nord est
      incliné vers le Soleil durant l'été. Il fait donc plus chaud l'été, car
      les rayons arrivent plus perpendiculairement que durant l’hiver.
    </p>
    <figure>
      <img
        src="@/assets/img/helps/situation_geo_1.png"
        alt="Axe rotation de la Terre"
      />
    </figure>

    <p>
      Le climat du département mais également l’altitude sont donc des critères
      qui participent aux variations des températures.
    </p>
    <p>En France, il existe trois grandes zones climatiques :</p>
    <ul>
      <li>Zone H1 : climat semi-continental</li>
      <li>Zone H2 : climat océanique</li>
      <li>Zone H3 : climat méditerranéen</li>
    </ul>
    <p>
      Pour la partie estivale, chacune de ces zones sont sous-divisées en zone
      a/b/c :
    </p>

    <ul>
      <li>« a » : tempéré en été avec des températures et un ensoleillement équilibré</li>
      <li>« b » : chaleurs et ensoleillement plus élevés en été</li>
      <li>« c » et « d » : fortes chaleurs et forte exposition à l’ensoleillement</li>
    </ul>
  </div>
</template>
