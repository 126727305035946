<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="590px" height="300px" viewBox="0 0 590 300" enable-background="new 0 0 590 300" xml:space="preserve">
<g fill="currentColor">
	<path d="M283.364,250.624c0,0.433-0.038,0.896-0.114,1.373c-0.07,0.479-0.104,0.936-0.104,1.38c0.95,0.582,1.868,1.174,2.752,1.758
		c0.88,0.592,1.755,1.212,2.638,1.876c0.444-2.571,1.141-5.078,2.097-7.544c0.948-2.458,2.089-4.793,3.408-6.992
		c-1.903-4.848-4.638-9.121-8.201-12.825c-3.559-3.71-7.763-6.554-12.606-8.534c3.23,4.111,5.725,8.662,7.49,13.647
		C282.482,239.76,283.364,245.047,283.364,250.624z"/>
	<path d="M303.779,257.725c2.691-2.238,5.648-4.128,8.875-5.667c1.686-4.63,4.073-8.812,7.156-12.556
		c3.077-3.744,6.682-6.898,10.782-9.467c-4.686,0.146-9.104,1.173-13.26,3.078c-4.146,1.913-7.765,4.461-10.851,7.652
		c-3.075,3.195-5.506,6.887-7.267,11.066c-1.762,4.182-2.639,8.654-2.639,13.426C298.706,262.473,301.12,259.963,303.779,257.725z"
		/>
	<path d="M315.376,282.521c1.703-6.136,4.744-11.431,9.161-15.886c4.481-4.509,9.833-7.568,16.079-9.188
		c-1.469-0.361-2.926-0.636-4.348-0.826c-1.434-0.178-2.922-0.274-4.461-0.274c-6.451,0-12.354,1.586-17.667,4.73
		c-5.327,3.168-9.534,7.346-12.614,12.564c1.252,2.782,2.205,5.71,2.867,8.753c0.012,0.041,0.012,0.082,0.023,0.127h-8.951
		c-1.496-5.995-4.516-11.319-9.132-15.938c-6.825-6.819-15.156-10.236-24.991-10.236c-1.539,0-3.025,0.088-4.458,0.274
		c-1.431,0.182-2.887,0.465-4.351,0.826c6.239,1.62,11.62,4.68,16.131,9.188c4.451,4.464,7.482,9.75,9.111,15.886H46.233v8.986
		h206.301v0.072h44.043c0-0.023-0.004-0.046-0.004-0.072h8.806c0,0.026,0.003,0.049,0.003,0.072h35.234v-0.072h213.919v-8.986
		H315.376z"/>
</g>
</svg>
</template>