<template>
  <div>
    <p>
      Les techniques de rafraichissement passif visent à réduire au maximum les
      besoins énergétiques des bâtiments tout en assurant un confort d’été pour
      les occupants : il s’agit alors de se tourner vers l’usage de certains
      équipements dont l’utilisation sera moins consommatrice en énergie et
      garantissant une atténuation de l’effet ressentit des fortes températures.
    </p>
    <p>
      Avant d’envisager des travaux et pour limiter l’impact du réchauffement,
      il est primordial de prendre en considération un ensemble d’éléments :
    </p>

    <ul>
      <li>
        Tenir compte de l’environnement direct du bâtiment (climat,
        végétalisation, emploi de l’eau, ambiance minérale, parois
        réfléchissantes, organisation de l’espace urbain etc.) ;
      </li>
      <li>
        Tenir compte des systèmes technologiques déjà existants (ventilation
        mécanique ou naturelle, gestion automatisée ou pas des fermetures, puits
        géothermiques etc.).
      </li>
    </ul>
  </div>
</template>
