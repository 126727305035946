export const roundNumber = (num, base = 100) => {
  return Math.round( num * base) / base;
}

// export const getRangeNumber = (num, range = 2) => {
//   const min = (num > range) ? num - range : 0;
//   const max = num + range;
//   return {
//     min,
//     max
//   };
// }

export const getRangeNumber = (num, base = 5) => {
  let min = Math.floor(num / base) * base;
  let max = Math.ceil(num / base) * base;

  if (min === max && min >= base) {
    min -= base;
  } else if(min === max && max < base) {
    max += base;
  }
  return {
    min,
    max
  };
}