<template>
  <div id="theRoomWrapper">

    <!-- roomId: {{ roomId }} -->
    <!-- <pre>{{ wallsClasses }}</pre> -->
    <div class="roomWrapper">

      <div id="theRoom" :class="roomShapeClass">
        <div class="walls">
          <div
            v-for="(wall, wallName) in roomData.walls"
            :key="`wall_${wall.id}`"
            class="wall"
            :class="
              wallsClasses[wallName].length > 0
                ? wallsClasses[wallName].join(' ')
                : ''
            "
            :id="`wall-${wall.id}`"
            @click.prevent="selectWall(wall.id)"
          >
            <div class="wall-desc">
              <span
                class="wall-status"
                :class="{
                  good: wall.status === true,
                  bad: wall.status === false,
                }"
              ></span>
              <span class="wall-name">Mur {{ wall.id }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column justify-content-center align-items-center px-5">
        <div>Orientation : {{ roomOrientationDeg }}°</div>
        <input
          type="range"
          class="form-range"
          id="customRange1"
          v-model.number="roomOrientationDeg"
          min="-180"
          max="180"
          step="45"
        />
    </div>
    <!-- wallSelected: {{ wallSelected }}<br>
  selectedWall: {{ selectedWall }}
  <pre>{{ walls }}</pre> -->
    <!-- <pre>{{ roomData }}</pre> -->
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, watch } from "vue";
const props = defineProps({
  wallSelected: {
    type: Number,
    default: 1,
    required: false,
  },
  roomId: {
    type: [Number, String],
    required: true,
  }
});

// const walls = reactive({
//   wall1: {id:1, status: false},
//   wall2: {id:2, status: false},
//   wall3: {id:3, status: false},
//   wall4: {id:4, status: false}
// });

const roomOrientationDeg = ref(0);
const cssData = computed(() => {
  return  {
    roomOrientationDeg: roomOrientationDeg.value + 'deg',
  }
});

const selectedWall = ref(null);
const emit = defineEmits(["selectWall", "updateOrientation"]);

onMounted(() => {
  selectedWall.value = props.wallSelected;
  roomOrientationDeg.value = roomData.roomOrientationDeg;
  // setWallsClasses();
});

const selectWall = (wallNum) => {
  selectedWall.value = wallNum;
  emit("selectWall", wallNum);
};

const store = useStore();
// const simuData = computed(() => {
//   return store.getters.simuData;
// });

const roomSimuData = computed(() => {
  return store.getters.room({ roomId: props.roomId });
});

let roomData = reactive({...roomSimuData.value});

// let roomData = reactive({
//   walls: {
//     wall1: { id: 1, info2D: {}, status: true },
//     wall2: { id: 2, info2D: {}, status: false },
//     wall3: { id: 3, info2D: {}, status: null },
//     wall4: { id: 4, info2D: {}, status: null },
//   },
// });



const roomShape = computed(() => {
  return roomSimuData.value.roomShape;
});

const roomShapeClass = computed(() => {
  return `roomShape_${roomShape.value}`;
});

watch([selectedWall, roomShape], () => {
  setRoomData();
});
watch(
  () => props.wallSelected, (newValue) => {
    selectWall(newValue);
  },
  { deep: true }
);
watch(roomOrientationDeg, () => {
  setRoomOrientation();
  emit('updateOrientation', roomOrientationDeg.value)
  store.dispatch('updateRoom', {roomId: props.roomId, roomData});
}, {deep: true});

const wallsClasses = computed(() => {
  let wc = {};
  for (const [wallName, wall] of Object.entries(roomData.walls)) {
    wc[wallName] = [];

    if (wall.info2D.type) {
      wc[wallName].push(`wall-${wall.info2D.type}`);
    }
    if (wall.info2D.showBefore === true) {
      wc[wallName].push("wall-show-before");
    }
    if (wall.info2D.showAfter === true) {
      wc[wallName].push("wall-show-after");
    }

    if (wall.id === selectedWall.value) {
      wc[wallName].push("selected");
    }
  }
  return wc;
});

// let roomData = reactive({
//   walls: {
//     wall1: { id: 1, info2D: {}, status: true },
//     wall2: { id: 2, info2D: {}, status: false },
//     wall3: { id: 3, info2D: {}, status: null },
//     wall4: { id: 4, info2D: {}, status: null },
//   },
// });
const setRoomData = () => {
  if (roomShape.value === 1) {
    roomData.walls.wall1.info2D = { type: "ext" };
    roomData.walls.wall2.info2D = { type: "ext" };
    roomData.walls.wall3.info2D = { type: "ext" };
    roomData.walls.wall4.info2D = { type: "ext" };
  } else if (roomShape.value === 2) {
    roomData.walls.wall1.info2D = { type: "ext" };
    roomData.walls.wall2.info2D = {
      type: "ext",
      showBefore: false,
      showAfter: true,
    };
    roomData.walls.wall3.info2D = { type: "int" };
    roomData.walls.wall4.info2D = {
      type: "ext",
      showBefore: false,
      showAfter: true,
    };
  } else if (roomShape.value === 3) {
    roomData.walls.wall1.info2D = {
      type: "ext",
      showBefore: false,
      showAfter: true,
    };
    roomData.walls.wall2.info2D = { type: "int" };
    roomData.walls.wall3.info2D = { type: "int" };
    roomData.walls.wall4.info2D = {
      type: "ext",
      showBefore: false,
      showAfter: true,
    };
  } else if (roomShape.value === 4) {
    false;
    roomData.walls.wall1.info2D = {
      type: "ext",
      showBefore: true,
      showAfter: false,
    };
    roomData.walls.wall2.info2D = {
      type: "ext",
      showBefore: false,
      showAfter: true,
    };
    roomData.walls.wall3.info2D = { type: "int" };
    roomData.walls.wall4.info2D = { type: "int" };
  } else if (roomShape.value === 5) {
    roomData.walls.wall1.info2D = {
      type: "ext",
      showBefore: true,
      showAfter: true,
    };
    roomData.walls.wall2.info2D = { type: "int" };
    roomData.walls.wall3.info2D = {
      type: "ext",
      showBefore: true,
      showAfter: true,
    };
    roomData.walls.wall4.info2D = { type: "int" };
  } else if (roomShape.value === 6) {
    roomData.walls.wall1.info2D = {
      type: "ext",
      showBefore: true,
      showAfter: true,
    };
    roomData.walls.wall2.info2D = { type: "int" };
    roomData.walls.wall3.info2D = { type: "int" };
    roomData.walls.wall4.info2D = { type: "int" };
  } else {
    roomData.walls.wall1.info2D = { type: "int" };
    roomData.walls.wall2.info2D = { type: "int" };
    roomData.walls.wall3.info2D = { type: "int" };
    roomData.walls.wall4.info2D = { type: "int" };
  }
};

const setRoomOrientation = () => {
  
  const ori = roomOrientationDeg.value;

  roomData.roomOrientationDeg = ori;

  if (ori === 0) {
    roomData.walls.wall1.wallOrientation = 'N';
    roomData.walls.wall2.wallOrientation = 'E';
    roomData.walls.wall3.wallOrientation = 'S';
    roomData.walls.wall4.wallOrientation = 'W';
  } else if (ori === 45) {
    roomData.walls.wall1.wallOrientation = 'NE';
    roomData.walls.wall2.wallOrientation = 'SE';
    roomData.walls.wall3.wallOrientation = 'SW';
    roomData.walls.wall4.wallOrientation = 'NW';
  } else if (ori === -45) {
    roomData.walls.wall1.wallOrientation = 'NW';
    roomData.walls.wall2.wallOrientation = 'NE';
    roomData.walls.wall3.wallOrientation = 'SE';
    roomData.walls.wall4.wallOrientation = 'SW';
  } else if (ori === 90) {
    roomData.walls.wall1.wallOrientation = 'E';
    roomData.walls.wall2.wallOrientation = 'S';
    roomData.walls.wall3.wallOrientation = 'W';
    roomData.walls.wall4.wallOrientation = 'N';
  } else if (ori === -90) {
    roomData.walls.wall1.wallOrientation = 'W';
    roomData.walls.wall2.wallOrientation = 'N';
    roomData.walls.wall3.wallOrientation = 'E';
    roomData.walls.wall4.wallOrientation = 'S';
  } else if (ori === 135) {
    roomData.walls.wall1.wallOrientation = 'SE';
    roomData.walls.wall2.wallOrientation = 'SW';
    roomData.walls.wall3.wallOrientation = 'NW';
    roomData.walls.wall4.wallOrientation = 'NE';
  } else if (ori === -135) {
    roomData.walls.wall1.wallOrientation = 'SW';
    roomData.walls.wall2.wallOrientation = 'NW';
    roomData.walls.wall3.wallOrientation = 'NE';
    roomData.walls.wall4.wallOrientation = 'SE';
  } else if (ori === 180 || ori === -180) {
    roomData.walls.wall1.wallOrientation = 'S';
    roomData.walls.wall2.wallOrientation = 'W';
    roomData.walls.wall3.wallOrientation = 'N';
    roomData.walls.wall4.wallOrientation = 'E';
  }
}
</script>

<style scoped lang="scss">
#theRoomWrapper {
  --compass-rotate-orientation: v-bind(cssData.roomOrientationDeg);
  @import '@/scss/var_app';
  @import '@/scss/theRoom.scss';
}
</style>
